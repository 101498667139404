import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerOverlayService 
{
  private disabled: boolean = false;
  private overlayReference: OverlayRef = null;

  constructor(private overlay: Overlay) { }

  public ToggleDisabled()
  {
    this.disabled = !this.disabled;
  }

  public Show()
  {
    if (this.disabled) { return; }

    if (!this.overlayReference)
    {
      this.overlayReference = this.overlay.create();
    }

    if (!this.overlayReference.hasAttached())
    {
      const loadingSpinnerOverlayPortal = new ComponentPortal(LoadingSpinnerComponent);
      this.overlayReference.attach(loadingSpinnerOverlayPortal);
    }
  }

  public Hide()
  {
    if (this.disabled) { return; }

    if (this.overlayReference)
    {
      this.overlayReference.detach();
    }
  }
}

import { Component, OnInit, Inject, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { NewProjectDialogData } from 'src/app/company-projects-table-view/company-projects-table-view.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.css']
})
export class CreateProjectDialogComponent implements OnInit 
{
  @ViewChildren(MatCheckbox) allCheckboxes: QueryList<MatCheckbox>;
  @ViewChild('condos', {read: MatCheckbox, static: false}) condosCheckbox: MatCheckbox;
  @ViewChild('townhomes', {read: MatCheckbox, static: false}) townhomesCheckbox: MatCheckbox;
  @ViewChild('detached', {read: MatCheckbox, static: false}) detachedCheckbox: MatCheckbox;

  public projectNameControl: FormControl = new FormControl('', Validators.required);
  
  public condosField: FormControl = new FormControl(true);
  public townhomesField: FormControl = new FormControl(true);
  public detachedField: FormControl = new FormControl(true);

  public worksheetsField: FormControl = new FormControl(false);
  public floorplansField: FormControl = new FormControl(false);

  constructor(public dialogRef: MatDialogRef<CreateProjectDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: NewProjectDialogData, private appUtilityService: AppUtilityService) { }

  public GetModalViewTitle(): string
  {
    if (this.data.projectToEdit != null)
    {
      return "Edit Project";
    }
    else
    {
      return "New Project";
    }
  }

  ngOnInit() 
  {
    if (this.data.projectToEdit != null)
    {
      this.projectNameControl.setValue(this.data.projectToEdit.project_name);
      this.condosField.setValue(this.data.projectToEdit.has_condos);
      this.townhomesField.setValue(this.data.projectToEdit.has_townhomes);
      this.detachedField.setValue(this.data.projectToEdit.has_detached);
      this.worksheetsField.setValue(this.data.projectToEdit.uses_worksheets);
      this.floorplansField.setValue(this.data.projectToEdit.uses_floorplans);
    }
  }

  GetProjectNameFieldErrorMessage(): string
  {
    if (this.projectNameControl.hasError('required'))
    {
      return "You must enter a project name";
    }
    else if (this.projectNameControl.hasError("not_unique"))
    {
      return "Project name already taken";
    }
  }

  OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  OnSaveButtonClicked(): void
  {
    let isProjectNameValid = true;

    if (this.projectNameControl.value != "" && this.data.projectToEdit == null)
    {
      for (let project of this.appUtilityService.projectsDict.values())
      {
        if (project.project_name.toLowerCase() == this.projectNameControl.value.toLowerCase())
        {
          isProjectNameValid = false;
          this.projectNameControl.setErrors({not_unique: true});
          break;
        }
      }
    }
    else if (this.data.projectToEdit == null)
    {
      isProjectNameValid = false;
      this.projectNameControl.setErrors({required: true});
    }

    if (isProjectNameValid)
    {
      let projectInfoDict: Map<string, any> = new Map<string, any>();
      projectInfoDict.set("project_name", this.projectNameControl.value);
      projectInfoDict.set("has_condos", this.condosField.value);
      projectInfoDict.set("has_detached", this.detachedField.value);
      projectInfoDict.set("has_townhomes", this.townhomesField.value);
      projectInfoDict.set("uses_worksheets", this.worksheetsField.value);
      projectInfoDict.set("uses_floorplans", this.floorplansField.value);

      this.dialogRef.close(projectInfoDict);
    }
  }
}

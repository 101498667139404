import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Elevation, Project } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

export interface CreateElevationDialogData
{
  selectedProject: Project;
  selectedHomeID: number;
  elevationToEdit: Elevation
}

@Component({
  selector: 'app-create-elevation-dialog',
  templateUrl: './create-elevation-dialog.component.html',
  styleUrls: ['./create-elevation-dialog.component.css']
})
export class CreateElevationDialogComponent implements OnInit
{
  public elevationTypeField: FormControl = new FormControl("");
  public priceField: FormControl = new FormControl(0);
  public footageField: FormControl = new FormControl(0);
  public unitDescriptionField: FormControl = new FormControl('');

  constructor(private dialogRef: MatDialogRef<CreateElevationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateElevationDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetModalViewTitle(): string
  {
    if (this.data.elevationToEdit != null)
    {
      return "Edit Elevation";
    }
    else
    {
      return "Create Elevation";
    }
  }

  ngOnInit() 
  {
    this.elevationTypeField.setValidators([Validators.required]);
    this.priceField.setValidators([Validators.required]);
    this.footageField.setValidators([Validators.required]);

    if (this.data.elevationToEdit != null)
    {
      this.elevationTypeField.setValue(this.data.elevationToEdit.elevation);
      this.priceField.setValue(this.data.elevationToEdit.price);
      this.footageField.setValue(this.data.elevationToEdit.footage);
      this.unitDescriptionField.setValue(this.data.elevationToEdit.unit_description);
    }
  }

  public GetElevationTypeFieldError(): string
  {
    if (this.elevationTypeField.hasError('required'))
    {
      return "Please enter an elevation type";
    }
    else if (this.elevationTypeField.hasError('unique'))
    {
      return "Elevation type " + this.elevationTypeField.value + " is not unique in this home";
    }
  }

  public GetPriceFieldError(): string
  {
    if (this.priceField.hasError('required'))
    {
      return "Please enter a price for this elevation";
    }
    else if (this.priceField.hasError("too_low"))
    {
      return "Price must be greater than 0";
    }

    return "";
  }

  public GetFootageFieldError(): string
  {
    if (this.footageField.hasError('required'))
    {
      return "Please enter the amount of footage for this elevation";
    }
    else if (this.footageField.hasError("too_low"))
    {
      return "Footage must be greater than 0";
    }

    return "";
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isElevationInfoValid: boolean = true;

    if (this.elevationTypeField.value == "")
    {
      this.elevationTypeField.setErrors({required: true});
      this.elevationTypeField.markAsTouched();
      isElevationInfoValid = false;
    }
    else
    {
      for (let elevation of this.data.selectedProject.elevations.values())
      {
        if (elevation.detached_id == this.data.selectedHomeID && elevation.elevation == this.elevationTypeField.value)
        {
          if (this.data.elevationToEdit == null || this.data.elevationToEdit.elevation_id != elevation.elevation_id)
          {
            this.elevationTypeField.setErrors({unique: true});
            this.elevationTypeField.markAsTouched();
            isElevationInfoValid = false;
          }
          
          break;
        }
      }
    }

    if (this.priceField.value < 0)
    {
      this.priceField.setErrors({too_low: true});
      this.priceField.markAsTouched();
      isElevationInfoValid = false;
    }

    if (this.footageField.value < 0)
    {
      this.footageField.setErrors({too_low: true});
      this.footageField.markAsTouched();
      isElevationInfoValid = false;
    }

    if (isElevationInfoValid)
    {
      let elevationInfoDict: Map<string, any> = new Map<string, any>();
    
      elevationInfoDict.set("detached_id", this.data.selectedHomeID);
      elevationInfoDict.set("elevation", this.elevationTypeField.value);
      elevationInfoDict.set("price", this.priceField.value);
      elevationInfoDict.set("footage", this.footageField.value);
      elevationInfoDict.set("unit_description", this.unitDescriptionField.value);

      this.dialogRef.close(elevationInfoDict);
    }
  }
}

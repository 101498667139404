import { Component, OnInit, Input, ViewChild, AfterViewInit, Host, ChangeDetectorRef } from '@angular/core';
import { Project, Lot, Elevation, EAvailabilityCode, Linker, Home } from 'src/DataModels';
import { MatSelect } from '@angular/material/select';
import { WorksheetPreferencesModuleComponent } from '../worksheet-preferences-module/worksheet-preferences-module.component';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-home-selection-row',
  templateUrl: './home-selection-row.component.html',
  styleUrls: ['./home-selection-row.component.css']
})
export class HomeSelectionRowComponent implements OnInit, AfterViewInit
{
  @ViewChild(('lotSelect'), {static: false}) lotSelect: MatSelect;
  @ViewChild(('homeSelect'), {static: false}) homeSelect: MatSelect;
  @ViewChild(('elevationSelect'), {static: false}) elevationSelect: MatSelect;

  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  private _homeIndex: number = null;

  @Input()
  set HomeIndex(homeIndex: number)
  {
    this._homeIndex = homeIndex;
  }

  get HomeIndex()
  {
    return this._homeIndex;
  }

  constructor(@Host() public parent: WorksheetPreferencesModuleComponent, public appUtilityService: AppUtilityService, private changeDetectorRef: ChangeDetectorRef) { }

  public isElevationChosen: boolean = false;
  public isLotChosen: boolean = false;
  public isHomeChosen: boolean = false;
  
  public selectedLotID: number;
  public selectedHomeID: number;

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    if (this.parent.currentChosenHomes[this.HomeIndex] != null)
    {
      this.isLotChosen = true;
      this.isElevationChosen = true;
      this.isHomeChosen = true;
      this.selectedLotID = this.parent.currentChosenHomes[this.HomeIndex].lot_id;
      this.selectedHomeID = this.SelectedProject.elevations.get(this.parent.currentChosenHomes[this.HomeIndex].elevation_id).detached_id;
      this.lotSelect.value = this.SelectedProject.lots.get(this.selectedLotID);
      this.homeSelect.value = this.SelectedProject.homes.get(this.selectedHomeID);
      this.elevationSelect.value = this.SelectedProject.elevations.get(this.parent.currentChosenHomes[this.HomeIndex].elevation_id);
      this.changeDetectorRef.detectChanges();
    }
  }

  public GetProjectLots(): Array<Lot>
  {
    if (this.SelectedProject.lots.size > 0 && this.SelectedProject.linkers.size > 0)
    {
      let availableLots: Set<Lot> = new Set<Lot>();

      for (let linker of this.SelectedProject.linkers.values())
      {
        let currentLot: Lot = this.SelectedProject.lots.get(linker.lot_id);

        if (currentLot.availability == EAvailabilityCode.Available)
        {
          let shouldAddLot: boolean = true;

          for (let i = 0; i < this.parent.currentChosenHomes.length; ++i)
          {
            if (this.parent.currentChosenHomes[i] != null && currentLot.lot_id == this.parent.currentChosenHomes[i].lot_id && i != this.HomeIndex)
            {
              shouldAddLot = false;
              break;
            }
          }

          if (shouldAddLot)
            availableLots.add(currentLot);
        }
      }

      if (availableLots.size > 0)
        return Array.from(availableLots.values());
    }

    return null;
  }

  public GetProjectHomes(): Array<Home>
  {
    if (this.SelectedProject.homes.size > 0 && this.SelectedProject.elevations.size > 0 && this.SelectedProject.linkers.size > 0 && this.selectedLotID != null)
    {
      let availableHomes: Set<Home> = new Set<Home>();

      for (let linker of this.SelectedProject.linkers.values())
      {
        if (linker.lot_id == this.selectedLotID)
          availableHomes.add(this.SelectedProject.homes.get(this.SelectedProject.elevations.get(linker.elevation_id).detached_id));
      }

      if (availableHomes.size > 0)
        return Array.from(availableHomes.values());
    }

    return null;
  }

  public GetProjectElevations(): Array<Elevation>
  {
    if (this.SelectedProject.elevations.size > 0 && this.SelectedProject.linkers.size > 0 && this.selectedHomeID != null && this.selectedLotID != null)
    {
      let availableElevations: Array<Elevation> = new Array<Elevation>();

      for (let elevation of this.SelectedProject.elevations.values())
      {
        if (elevation.detached_id == this.selectedHomeID)
        {
          for (let linker of this.SelectedProject.linkers.values())
          {
            if (linker.elevation_id == elevation.elevation_id && linker.lot_id == this.selectedLotID)
            {
              availableElevations.push(elevation);
              break;
            }
          }
        }
      }

      return availableElevations;
    }

    return null;
  }

  public OnLotSelected(lot: Lot): void
  {
    this.isLotChosen = true;
    this.selectedLotID = lot.lot_id;
  }

  public OnHomeSelected(home: Home): void
  {
    this.isHomeChosen = true;
    this.selectedHomeID = home.detached_id;
  }

  public OnElevationSelected(elevation: Elevation): void
  {
    this.isElevationChosen = true;

    for (let linker of this.SelectedProject.linkers.values())
    {
      if (linker.lot_id == this.selectedLotID && linker.elevation_id == elevation.elevation_id)
      {
        this.parent.currentChosenHomes[this.HomeIndex] = linker;
        break;
      }
    }
  }

  public OnClearHomeButtonPressed(): void
  {
    let updatedSelection: Array<Linker> = new Array<Linker>();
    for (let i = 0; i < this.parent.currentChosenHomes.length; ++i)
    {
      if (i != this.HomeIndex)
      {
        updatedSelection.push(this.parent.currentChosenHomes[i]);
      }
    }

    this.parent.currentChosenHomes = updatedSelection;
  }

  public GetHomeName(): string
  {
    if (this.parent.currentChosenHomes[this.HomeIndex] != null)
    {
      let selectedElevation: Elevation = this.SelectedProject.elevations.get(this.parent.currentChosenHomes[this.HomeIndex].elevation_id);
      if (selectedElevation != null)
      {
        let selectedHome: Home = this.SelectedProject.homes.get(selectedElevation.detached_id);
        if (selectedHome != null)
        {
          return selectedHome.name;
        }
      }
    }

    return null;
  }

  public GetHomeDescription(): string
  {
    if (this.parent.currentChosenHomes[this.HomeIndex] != null)
    {
      let selectedElevation: Elevation = this.SelectedProject.elevations.get(this.parent.currentChosenHomes[this.HomeIndex].elevation_id);
      if (selectedElevation != null)
      {
        let selectedHome: Home = this.SelectedProject.homes.get(selectedElevation.detached_id);
        if (selectedHome != null)
        {
          let strDescription: string = "";

          if (selectedHome.bedrooms > 0)
          {
            strDescription += (selectedHome.bedrooms + " " + (selectedHome.bedrooms == 1 ? "Bed" : "Beds")); 
            strDescription += " + ";
          }

          if (selectedHome.bathrooms > 0)
          {
            strDescription += (selectedHome.bathrooms + " " + (selectedHome.bathrooms == 1 ? "Bath" : "Baths")); 
          }

          return strDescription;
        }
      }
    }

    return null;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebCMSAPIResponse } from 'src/DataModels';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstantsService } from './app-constants.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService 
{
  constructor(private http: HttpClient, private appConstants: AppConstantsService) { }

  public GetSalesfishWebcallPath(): Observable<string>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/settings`).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let responseData = JSON.parse(response.data);
        return responseData["salesfish_url"];
      }

      return null;
    }));
  }

  public SetSalesfishWebcallPath(newWebcallPath: string): Observable<string>
  {
    let params = {"newWebcallPath": newWebcallPath};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/settings`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        return newWebcallPath;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
}

import { Component, OnInit, Input, Host, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Unit, Project, EAvailabilityCode, Building } from 'src/DataModels';
import { WorksheetPreferencesModuleComponent } from '../worksheet-preferences-module/worksheet-preferences-module.component';
import { MatSelect } from '@angular/material/select';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-suite-selection-row',
  templateUrl: './suite-selection-row.component.html',
  styleUrls: ['./suite-selection-row.component.css']
})
export class SuiteSelectionRowComponent implements OnInit, AfterViewInit
{
  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  @ViewChild(('suiteSelect'), {static: false}) suiteSelect: MatSelect;
  @ViewChild(('buildingSelect'), {static: false}) buildingSelect: MatSelect;

  private _suiteIndex: number = null;

  @Input()
  set SuiteIndex(suiteIndex: number)
  {
    this._suiteIndex = suiteIndex;
  }

  get SuiteIndex()
  {
    return this._suiteIndex;
  }

  public isSuiteChosen: boolean = false;
  public isBuildingChosen: boolean = false;
  
  public selectedBuildingID: number;

  constructor(@Host() public parent: WorksheetPreferencesModuleComponent, public appUtilityService: AppUtilityService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    if (this.parent.currentChosenSuites[this.SuiteIndex] != null)
    {
      this.isSuiteChosen = true;
      this.isBuildingChosen = true;
      this.selectedBuildingID = this.parent.currentChosenSuites[this.SuiteIndex].building_id;
      this.buildingSelect.value = this.SelectedProject.buildings.get(this.selectedBuildingID);
      this.suiteSelect.value = (this.parent.currentChosenSuites[this.SuiteIndex]);
      this.changeDetectorRef.detectChanges();
    }
  }

  public GetProjectBuildings(): Array<Building>
  {
    if (this.SelectedProject.buildings.size > 0)
    {
      return Array.from(this.SelectedProject.buildings.values());
    }

    return null;
  }

  public GetProjectSuites(): Array<Unit>
  {
    if (this.SelectedProject.units.size > 0)
    {
      let availableSuites: Array<Unit> = new Array<Unit>();
      
      for (let unit of this.SelectedProject.units.values())
      {
        if (this.SelectedProject.buildings.size <= 1)
        {
          if (!this.parent.currentChosenSuites.includes(unit) && unit.availability == EAvailabilityCode.Available)
            availableSuites.push(unit);
        }
        else if (this.SelectedProject.buildings.size > 1)
        {
          if (!this.parent.currentChosenSuites.includes(unit) && unit.availability == EAvailabilityCode.Available && this.selectedBuildingID == unit.building_id)
            availableSuites.push(unit);
        }
      }

      for (let i = 0; i < this.parent.currentChosenSuites.length; ++i)
      {
        if (this.parent.currentChosenSuites[i] != null && this.SuiteIndex == i)
        {
          availableSuites.push(this.parent.currentChosenSuites[i]);
          break;
        }
      }

      availableSuites = availableSuites.sort((s1: Unit, s2: Unit) => 
      {
        if (s1.suite_number < s2.suite_number)
        {
          return -1;
        }
        else if (s1.suite_number > s2.suite_number)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      });

      return availableSuites;
    }

    return null;
  }

  public OnClearSuiteButtonPressed()
  {
    let updatedSelection: Array<Unit> = new Array<Unit>();
    for (let i = 0; i < this.parent.currentChosenSuites.length; ++i)
    {
      if (i != this.SuiteIndex)
      {
        updatedSelection.push(this.parent.currentChosenSuites[i]);
      }
    }

    this.parent.currentChosenSuites = updatedSelection;
  }

  public OnSuiteSelected(suite: Unit): void
  {
    this.parent.currentChosenSuites[this.SuiteIndex] = suite;
    this.isSuiteChosen = true;
  }

  public OnBuildingSelected(building: Building): void
  {
    this.isBuildingChosen = true;
    this.selectedBuildingID = building.building_id;
  }

  public GetSuiteFloorNumber(): string
  {
    if (this.parent.currentChosenSuites[this.SuiteIndex] == null)
    {
      this.isSuiteChosen = false;
      this.changeDetectorRef.detectChanges();
      return "";
    }

    return this.parent.currentChosenSuites[this.SuiteIndex].floor_number.toString();
  }

  public GetSuiteModelTypeDescription(): string
  {
    if (this.parent.currentChosenSuites[this.SuiteIndex] == null)
    {
      this.isSuiteChosen = false;
      this.changeDetectorRef.detectChanges();
      return "";
    }

    for (let modeltype of this.SelectedProject.model_types.values())
    {
      if (modeltype.model_type_id == this.parent.currentChosenSuites[this.SuiteIndex].model_type_id)
      {
        return modeltype.description;
      }
    }

    return "";
  }

  public GetSuiteModelTypeName(): string
  {
    if (this.parent.currentChosenSuites[this.SuiteIndex] == null)
    {
      this.isSuiteChosen = false;
      this.changeDetectorRef.detectChanges();
      return "";
    }

    for (let modeltype of this.SelectedProject.model_types.values())
    {
      if (modeltype.model_type_id == this.parent.currentChosenSuites[this.SuiteIndex].model_type_id)
      {
        return modeltype.name;
      }
    }

    return "";
  }
}

import { Component, OnInit } from '@angular/core';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { Company, Project } from 'src/DataModels';
import { MatDialog } from '@angular/material/dialog';
import { CreateCompanyDialogComponent } from 'src/app/dialog/create-company-dialog/create-company-dialog.component';
import { CompaniesService } from 'src/app/companies.service';
import { stringify } from '@angular/compiler/src/util';
import { CMSComponentBase } from 'src/app/interfaces/cms-component-base';
import { DeleteCompanyDialogComponent } from '../dialog/delete-company-dialog/delete-company-dialog.component';

export interface NewCompanyDialogData { companyToEdit: Company }

export interface DeleteCompanyDialogData 
{ 
  companiesToDelete: Array<Company>
}

@Component({
  selector: 'app-companies-table-view',
  templateUrl: './companies-table-view.component.html',
  styleUrls: ['./companies-table-view.component.css']
})
export class CompaniesTableViewComponent implements OnInit
{
  private selectedCompanies: Set<Company>

  constructor(public dialog: MatDialog, public appUtilityService: AppUtilityService, private authService: AuthService, private companiesService: CompaniesService, private router: Router) 
  { 
  }

  public OnCompanyCheckboxToggled(isCheckboxCheckedEvent: boolean, selectedCompany: Company)
  {
    if (isCheckboxCheckedEvent)
    {
      this.selectedCompanies.add(selectedCompany);
    }
    else
    {
      this.selectedCompanies.delete(selectedCompany);  
    }
  }

  public GetNumProjectsMessageForCompany(companyID: string): string
  {
    let numProjects: number = 0;

    if (this.appUtilityService.companyProjectsDict.has(companyID))
    {
      numProjects = this.appUtilityService.companyProjectsDict.get(companyID).size;
    }

    return numProjects.toString() + " " +  ((numProjects == 1) ? "Project" : "Projects");
  }

  public OnCompanyLinkTapped(companyID: string)
  {
    this.router.navigateByUrl("admin/company/" + companyID + "/projects");
  }

  ngOnInit() 
  {
    this.appUtilityService.SetViewTitle("Companies");
    this.selectedCompanies = new Set<Company>();
  }

  public OnEditCompanyButtonTapped(company: Company)
  {
    const dialogRef = this.dialog.open(CreateCompanyDialogComponent, { width: '280px', height: '250px', data: { companyToEdit: company }})
    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.companiesService.UpdateCompanyName(company.company_id, result).subscribe((updatedCompany: Company) => 
        {
          if (updatedCompany != null)
          {
            company.company_name = updatedCompany.company_name;
            this.appUtilityService.StoreCMSData();
          }
        });
      }
    });
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateCompanyDialogComponent, { width: '280px', height: '250px', data: {  } });
    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.companiesService.CreateNewCompany(result).subscribe((newCompany: Company) => 
        {
          if (newCompany != null)
          {
            this.appUtilityService.companiesDict.set(newCompany.company_id, newCompany);
            this.appUtilityService.companyProjectsDict.set(newCompany.company_id, new Set<string>());
            this.appUtilityService.companyProjectRolesDict.set(newCompany.company_id, new Set<string>());
            this.appUtilityService.companyUsersDict.set(newCompany.company_id, new Set<string>());
            this.appUtilityService.StoreCMSData();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    return (this.authService.IsActiveUserAdmin() && this.selectedCompanies != null && this.selectedCompanies.size > 0);
  }

  OnDeleteButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(DeleteCompanyDialogComponent, { width: '350px', height: '350px', data: { companiesToDelete: Array.from(this.selectedCompanies.values()) } });
    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result != null)
      {
        this.companiesService.DeleteCompanies(result).subscribe((updatedCompanies: Map<string, Company>) => 
        {
          if (updatedCompanies != null)
          {
            this.appUtilityService.companiesDict = updatedCompanies;
            this.CleanLocalDictEntries();
            this.selectedCompanies.clear();

            this.appUtilityService.StoreCMSData();
          }
        });
      }
    });
  }

  private CleanLocalDictEntries()
  {
    for (var company of this.selectedCompanies)
    {
      for (var projectID of this.appUtilityService.companyProjectsDict.get(company.company_id))
      {
        this.appUtilityService.projectsDict.delete(projectID);
      }

      this.appUtilityService.companyProjectsDict.delete(company.company_id);
    }
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, flatMap, switchMap } from 'rxjs/operators';
import { WebCMSAPIResponse, User, UserRole, ProjectRole, EUserType } from 'src/DataModels';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { UserRolesService } from './user-roles.service';
import { CompileDiDependencyMetadata } from '@angular/compiler';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class AuthService
{
  public activeUser: User = null;
  public activeUserRole: UserRole = null;

  public strRedirectURL:string = null;

  constructor(private http: HttpClient, private router: Router, @Inject(UserRolesService) private userRolesService: UserRolesService, private appConstants: AppConstantsService) { }

  GetActiveUserType(): EUserType
  {
    if (this.activeUserRole != null)
    {
      return this.activeUserRole.user_type;
    }
    else
    {
      return null;
    }
  }

  IsActiveUserAdmin(): boolean
  {
    if (this.activeUserRole != null)
    {
      return this.activeUserRole.user_type == EUserType.neezo;
    }
    else
    {
      return false;
    }
  }

  IsLoggedIn(): boolean
  {
    return this.activeUser != null;
  }

  private FetchUserMetaData(username: string, password: string): Observable<User>
  {
    let params = {"username": username, "password": password};
    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}login.php`, params).pipe(map((response: WebCMSAPIResponse) =>
    {
      if (response.status == "success")
      {
        let responsePacket = JSON.parse(response.data);
        let sessionExpirationDate: string = responsePacket.session_expiration_date;
        let userInfoFromPacket = JSON.parse(responsePacket.user);
        let user: User = new User(userInfoFromPacket);
        this.activeUser = user;

        this.StoreSessionData(sessionExpirationDate, responsePacket.user);
        return this.activeUser;
      }
      else
      {
        console.log(response);
        return null
      }
    }));
  }

  Login(username: string, password: string): Observable<boolean>
  {
    return this.FetchUserMetaData(username, password).pipe(switchMap((user: User) => this.userRolesService.GetUserRoleByRoleID(user.user_role_id, this)));
  }

  /*
    Attempts to recover a previous session from local storage.
    Allows for the app to bypass the login screen provided that a previous
    session both exists and has not expired.
  */
  TryRecoverLoginSession(): boolean
  {
    let currentDatetime: Moment = moment();

    let strSessionExpiration: string = localStorage.getItem("session_expiration_date");
    if (strSessionExpiration != null)
    {
      let sessionExpirationDate: Moment = moment(strSessionExpiration);

      if (currentDatetime.isBefore(sessionExpirationDate))
      {
        let userJSON = localStorage.getItem("user");
        if (userJSON != null)
        {
          let userInfoPacket = JSON.parse(userJSON);
          let user: User = new User(userInfoPacket);
          this.activeUser = user;
        }
        else
        {
          this.ClearSessionData();
          return false;
        }

        let userRoleJSON = localStorage.getItem("userRole");
        if (userRoleJSON != null)
        {
          let userRoleInfoPacket = JSON.parse(userRoleJSON);
          let role: UserRole = new UserRole(userRoleInfoPacket);
          this.activeUserRole = role;
        }
        else
        {
          this.ClearSessionData();
          return false;
        }

        return true;
      }
      else
      {
        this.ClearSessionData();
        return false;
      }
    }

    return false;
  }

  Logout()
  {
    this.activeUser = null;
    this.activeUserRole = null;
    this.ClearSessionData();
    this.router.navigateByUrl('/login');
  }

  /*
    Stores a login session's metadata in local storage
  */
  private StoreSessionData(expirationDate: string, userJSON: string)
  {
    localStorage.setItem("session_expiration_date", expirationDate);
    localStorage.setItem("user", userJSON);
  }

  public StoreUserRole(userRoleJSON: string)
  {
    localStorage.setItem("userRole", userRoleJSON);
  }

  /*
    Deletes all login session data from local storage.
  */
  private ClearSessionData()
  {
    localStorage.clear();
    sessionStorage.clear();
  }
}

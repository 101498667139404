import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { CMSComponentBase } from '../interfaces/cms-component-base';
import { AppUtilityService } from '../app-utility.service';
import { ProjectRolesService } from '../project-roles.service';
import { ProjectRole, Company, ProjectAssignment } from 'src/DataModels';
import { CreateProjectRoleDialogComponent } from '../dialog/create-project-role-dialog/create-project-role-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { AuthService } from '../auth.service';
import { ProjectAssignmentsService } from '../project-assignments.service';

export interface CompanyRoleDialogData
{
  companyID: string,
  roleToEdit: ProjectRole
}

@Component({
  selector: 'app-company-roles-table-view',
  templateUrl: './company-roles-table-view.component.html',
  styleUrls: ['./company-roles-table-view.component.css']
})
export class CompanyRolesTableViewComponent implements OnInit 
{ 
  @ViewChildren(MatCheckbox) roleCheckboxes !: QueryList<MatCheckbox>;

  private selectedProjectRoleIDs: Set<string> = new Set<string>();

  public companyRoles: Map<string, Map<string, ProjectRole>> = new Map<string, Map<string, ProjectRole>>();

  private RefreshSelectedCompanyRolesIterator(): void
  {
    this.companyRoles.clear();

    for (let companyID of this.appUtilityService.companiesDict.keys())
    {
      this.companyRoles.set(companyID, new Map<string, ProjectRole>());

      for (let roleID of this.appUtilityService.companyProjectRolesDict.get(companyID))
      {
        (this.companyRoles.get(companyID)).set(roleID, this.appUtilityService.projectRolesDict.get(roleID));
      }
    }
  }

  constructor(private dialog: MatDialog, public appUtilityService: AppUtilityService, public projectRolesService: ProjectRolesService, public authService: AuthService, public projectAssignmentsService: ProjectAssignmentsService) 
  {
  }

  ngOnInit() 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      this.appUtilityService.SetViewTitle("Project Roles");
    }
    else
    {
      let companyName: string = this.appUtilityService.companiesDict.get(this.authService.activeUser.company_id).company_name;
      this.appUtilityService.SetViewTitle(companyName + " Roles");
    }

    this.RefreshSelectedCompanyRolesIterator();
  }

  public OnCompanyPanelClosed()
  {
    for (var checkbox of this.roleCheckboxes.toArray())
    {
      checkbox.checked = false;
    }

    this.selectedProjectRoleIDs.clear();
  }

  public OnRoleCheckboxToggled(checked: boolean, roleID: string)
  {
    if (checked)
    {
      this.selectedProjectRoleIDs.add(roleID);
    }
    else
    {
      this.selectedProjectRoleIDs.delete(roleID);
    }
  }

  public OnRoleNameTapped(companyID: string, role: ProjectRole)
  {
    const dialogRef = this.dialog.open(CreateProjectRoleDialogComponent, { width: '760px', height: '600px', data: { companyID: companyID, roleToEdit: role }});

    if (this.authService.activeUserRole.can_view_project_roles)
    {
      dialogRef.afterClosed().subscribe(result => 
      {
        if (result != null)
        {
          this.projectRolesService.UpdateProjectRole(role.project_role_id, companyID, result).subscribe((updatedProjectRole: ProjectRole) => 
          {
            if (updatedProjectRole != null)
            {
              this.appUtilityService.projectRolesDict.set(updatedProjectRole.project_role_id, updatedProjectRole);
              this.appUtilityService.StoreCMSData();
              this.RefreshSelectedCompanyRolesIterator();
            }
          });
        }
      });
    }
  }

  public OnAccordionAddButtonPressed(companyID: string)
  {
    this.CreateProjectRole(companyID);
  }

  public ShouldDisplayAccordionDeleteButton(): boolean
  {
    return (this.authService.IsActiveUserAdmin() && this.selectedProjectRoleIDs != null && this.selectedProjectRoleIDs.size > 0);
  }

  public OnAccordionDeleteButtonPressed(companyID: string)
  {
    this.DeleteProjectRole(companyID);
  }

  ShouldDisplayAddButton(): boolean 
  {
    return !this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_create_project_roles;
  }

  OnAddButtonPressed(): void 
  {
    this.CreateProjectRole(this.authService.activeUser.company_id);
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    return this.selectedProjectRoleIDs.size > 0 && !this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_delete_project_roles;
  }

  OnDeleteButtonPressed(): void 
  {
    this.DeleteProjectRole(this.authService.activeUser.company_id);
  }

  private CreateProjectRole(companyID: string)
  {
    const dialogRef = this.dialog.open(CreateProjectRoleDialogComponent, { width: '750px', height: '600px', data: { companyID: companyID, roleToEdit: null }});

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectRolesService.CreateNewProjectRole(result).subscribe((newProjectRole: ProjectRole) => 
        {
          if (newProjectRole != null)
          {
            this.appUtilityService.projectRolesDict.set(newProjectRole.project_role_id, newProjectRole);
            this.appUtilityService.companyProjectRolesDict.get(newProjectRole.company_id).add(newProjectRole.project_role_id);
            this.appUtilityService.StoreCMSData();
            this.RefreshSelectedCompanyRolesIterator();
          }
        });
      }
    });
  }

  private DeleteProjectRole(companyID: string)
  {
    if (this.selectedProjectRoleIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Project Roles?" }});

      dialogRef.afterClosed().subscribe(result => 
      {
        if (result == true)
        {
          this.projectRolesService.DeleteProjectRoles(companyID, Array.from(this.selectedProjectRoleIDs)).subscribe((result: boolean) => 
          {
            if (result == true)
            {
              // Delete local caches of project roles that match the ones selcted
              for (var projectRole of this.appUtilityService.projectRolesDict.values())
              {
                for (var roleID of this.selectedProjectRoleIDs)
                {
                  if (projectRole.project_role_id == roleID)
                  {
                    this.appUtilityService.companyProjectRolesDict.get(projectRole.company_id).delete(roleID);
                    this.appUtilityService.projectRolesDict.delete(roleID);
                    
                    //Reset all project role assignments referencing the selected role to the default NEEZO role
                    if (this.appUtilityService.userAssignmentsDict.size > 0)
                    {
                      let defaultNeezoRole = this.appUtilityService.GetDefaultNeezoProjectRole();

                      for (var userAssignmentsSet of this.appUtilityService.userAssignmentsDict.values())
                      {
                        for (var assignment of userAssignmentsSet)
                        {
                          if (assignment.project_role_id == roleID)
                          {
                            this.projectAssignmentsService.ChangeProjectRoleForAssignment(assignment.user_id, assignment.project_id, defaultNeezoRole.project_role_id, assignment.company_id).subscribe((newAssignment: ProjectAssignment) => 
                            {
                              if (newAssignment != null)
                              {
                                this.appUtilityService.userAssignmentsDict.get(assignment.user_id).delete(assignment);
                                this.appUtilityService.userAssignmentsDict.get(newAssignment.user_id).add(newAssignment);
                                this.appUtilityService.StoreCMSData();
                              }
                            });
                          } 
                        }
                      }
                    }
                  }
                }
              }

              this.appUtilityService.StoreCMSData();
              this.RefreshSelectedCompanyRolesIterator();
            }            
          });
        }
      });
    }
  }
}

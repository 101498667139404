import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Material Library Imports
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { AccordionListItemComponent } from './accordion-list-item.component';

@NgModule({
  declarations: [
    AccordionListItemComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
  ],
  exports: [
    AccordionListItemComponent
  ]
})
export class AccordionListItemModule { }

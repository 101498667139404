import { Component, OnInit, AfterViewInit, ViewChild, Input, Host, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Project, Contract, ProjectRole } from 'src/DataModels';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { CreateContractDialogComponent } from '../dialog/create-contract-dialog/create-contract-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { PDFAssembler } from 'pdfassembler';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: ContractsTableViewComponent }],
  selector: 'app-contracts-table-view',
  templateUrl: './contracts-table-view.component.html',
  styleUrls: ['./contracts-table-view.component.css']
})
export class ContractsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Contract>;

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public displayedColumns = ['Checkbox', 'Contract ID', 'Worksheet ID', 'Date Created', 'Contract Name','Sticky Column'];

  
  public contractsDataSource: MatTableDataSource<Contract> = new MatTableDataSource<Contract>();
  public selectedContractIDs: Set<number> = new Set<number>();

  constructor(@Host() public parent: ProjectDetailsTableViewComponent, public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver) 
  {
    super();
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit(): void 
  {
    this.RenderTableRows();
  }

  TrimContractName(contract: Contract): string
  {
    if (contract.contract_pdf_name.endsWith(".pdf"))
    {
      return contract.contract_pdf_name.slice(0, -4);
    }
    else
    {
      return contract.contract_pdf_name;
    }
  }

  FormatDateCreated(contract: Contract): string
  {
    if (contract.date_created != null)
    {
      let timestamp = moment(contract.date_created);
      return timestamp.format("LLLL");
    }

    return "";
  }

  RenderTableRows(): void 
  {
    if (this.project.contracts.size > 0)
    {
      let contracts = Array.from(this.project.contracts.values());
      this.contractsDataSource.data = contracts;

      this.table.renderRows();
      this.changeDetectorRef.detectChanges();
    }
  }

  OnSelectAllCheckboxChanged(checked: boolean): void
  {
    if (checked)
    {
      for (let contract of this.contractsDataSource.data)
      {
        this.selectedContractIDs.add(contract.contract_id);
      }
    }
    else
    {
      this.selectedContractIDs.clear();
    }
  }

  OnContractCheckboxChanged(checked: boolean, contractID: number): void
  {
    if (checked == true)
    {
      this.selectedContractIDs.add(contractID);
    }
    else
    {
      this.selectedContractIDs.delete(contractID);
    }
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_create_worksheets;
      }
    }

    return false;
  }
  
  ShouldDisplayEditButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_create_worksheets;
      }
    }

    return false;
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data && this.selectedContractIDs.size > 0;
    }
    // else if (!this.authService.IsActiveUserAdmin())
    // {
    //   let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
    //   if (activeProjectRole !== null)
    //   {
    //     return activeProjectRole.can_finalize_sales;
    //   }
    // }

    return false;
  }

  OnAddButtonPressed() 
  {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, { panelClass: 'worksheets-dialog', width: '250px', height: '250px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result == true)
      {
        this.RenderTableRows();
      }
    });
  }

  OnEditButtonPressed(contract: Contract) 
  {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, { panelClass: 'worksheets-dialog', width: '250px', height: '250px', data: { selectedProject: this.project, selectedContract: contract } });

    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result == true)
      {
        this.RenderTableRows();
      }
    });
  }

  OnDeleteButtonPressed() 
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Contracts?" }});
    
    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result === true)
      {
        this.projectsService.DeleteContractsFromProject(this.project, Array.from(this.selectedContractIDs.values())).subscribe((result: boolean) => 
        {
          if (result === true)
          {
            this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: boolean) =>
            {
              this.appUtilityService.StoreCMSData();
              this.parent.RefreshAllTables();
            });
          }
        });
      }
    });
  }

  HasSelectedRows(): boolean { return this.selectedContractIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedContractIDs; }
  ClearSelectedRows(): void { this.selectedContractIDs.clear(); }

  OnDownloadContractButtonPressed(contract: Contract)
  {
    let pdfAssembler = new PDFAssembler(this.appUtilityService.Base64ToFileHandle(contract.contract_pdf_data, contract.contract_pdf_name));
    pdfAssembler.getPDFStructure().then(function(pdfStructure) 
    {
      if (Object.keys(pdfStructure["/Root"]).includes("/AcroForm"))
      {
        delete pdfStructure["/Root"]["/AcroForm"];
      }

      pdfAssembler.assemblePdf(contract.contract_pdf_name).then(function(file)
      {
        fileSaver.saveAs(file, contract.contract_pdf_name + ".pdf");
      });
    });
  }
}

import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormControl, Validators } from '@angular/forms';
import { WebCMSAPIResponse } from 'src/DataModels';
import { Router } from '@angular/router';
import { stringify } from '@angular/compiler/src/util';
import { AppUtilityService } from '../app-utility.service';
import { Observable } from 'rxjs';
import { AppConstantsService } from '../app-constants.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.css']
})
export class LoginScreenComponent implements OnInit 
{
  hidePassword: boolean = true;

  usernameControl: FormControl = new FormControl('', [Validators.required]);
  passwordControl: FormControl = new FormControl('', [Validators.required]);

  constructor(private appConstants: AppConstantsService, private appUtilityService: AppUtilityService, private authService: AuthService, private router: Router, private snackbar: MatSnackBar) { }

  GetUsernameFieldErrorMessage(): string
  {
    if (this.usernameControl.hasError('required'))
    {
      return "You must enter a username";
    }
    else if (this.usernameControl.hasError('userNotFound'))
    {
      return this.usernameControl.value + " could not be found";
    }
  }

  GetPasswordFieldErrorMessage(): string
  {
    if (this.passwordControl.hasError('required'))
    {
      return "You must enter a password";
    }
    else if (this.passwordControl.hasError("passwordMismatch"))
    {
      return "Invalid password for " + this.usernameControl.value;
    }
  }

  OnLoginButtonPressed()
  {
    if (this.authService.IsLoggedIn()) { return; }

    let strUsername = this.usernameControl.value;
    let strPassword = this.passwordControl.value;

    if (strUsername == "" || strPassword == "") { return; }
    
    this.authService.Login(strUsername, strPassword).subscribe((result: boolean | WebCMSAPIResponse) => 
    {
      let isLoggedIn: boolean = result === true;

      if (isLoggedIn && this.authService.activeUserRole.can_access_backend)
      {
        let cmsLoadResult = this.appUtilityService.TryLoadCMSData();
        if (cmsLoadResult == true)
        {
          this.ChooseStartupRoute();
        }
        else if (cmsLoadResult instanceof Observable)
        {
          let cmsLoadObservable = cmsLoadResult as Observable<boolean>;
          cmsLoadObservable.subscribe((result: boolean) => 
          {
            this.ChooseStartupRoute();
          });
        }
      }
      else if (isLoggedIn && !this.authService.activeUserRole.can_access_backend)
      {
        this.snackbar.open("Your account does not have permission to proceed", null, {duration: 4000});
        this.authService.Logout();
      }
    })
  }

  private ChooseStartupRoute(): void
  {
    if (this.authService.strRedirectURL != null)
    {
      let href: string = this.authService.strRedirectURL;

      if (href.startsWith("http://"))
      {
        href = href.replace("http://", "");
      }
      else if (href.startsWith("https://"))
      {
        href = href.replace("https://", "");
      }

      let pathStartIndex: number = href.indexOf(this.appConstants.baseHref) + this.appConstants.baseHref.length;
      if (pathStartIndex < href.length)
      {
        this.authService.strRedirectURL = null;

        let strPath: string = href.substring(pathStartIndex);
        this.router.navigateByUrl(strPath);
      }
    }
    else if (this.authService.IsActiveUserAdmin())
    {
      // An admin just logged in, so transition so direct them to the clients viewer
      let redirectURL = this.authService.strRedirectURL ? this.router.parseUrl(this.authService.strRedirectURL) : '/admin/companies';
      this.router.navigateByUrl(redirectURL);
    }
    else
    {
      // A client-user just logged in, so transition them to their project viewer
      let redirectURL = this.authService.strRedirectURL ? this.router.parseUrl(this.authService.strRedirectURL) : '/projects';
      this.router.navigateByUrl(redirectURL);
    }
  }

  ngOnInit() 
  {
  }
}

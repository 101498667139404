import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { AuthService } from 'src/app/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Project, Building, Floor } from 'src/DataModels';

export interface GenerateFloorsDialogData
{
  selectedProject: Project;
  selectedBuildingID: number;
}

@Component({
  selector: 'app-generate-floors-dialog',
  templateUrl: './generate-floors-dialog.component.html',
  styleUrls: ['./generate-floors-dialog.component.css']
})
export class GenerateFloorsDialogComponent implements OnInit 
{
  public static MAX_FLOORS: Number = 160;

  public numFloorsField: FormControl = new FormControl(0);
  public startingFloorField: FormControl = new FormControl(0);

  constructor(private dialogRef: MatDialogRef<GenerateFloorsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GenerateFloorsDialogData, public appUtilityService: AppUtilityService, public authService: AuthService) { }

  public GetBuildingFloorsIterator(): IterableIterator<Floor>
  {
    if (this.data != null && this.data.selectedProject != null && this.data.selectedBuildingID != null && this.data.selectedProject.floors.size > 0)
    {
      let selectedBuildingFloors: Array<Floor> = new Array<Floor>();

      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.data.selectedBuildingID)
        {
          selectedBuildingFloors.push(floor);
        }
      }

      return selectedBuildingFloors.values();
    }

    return null;
  }

  public ShouldDisplayStartingFloorDropdown(): boolean
  {
    if (this.data.selectedProject != null && this.data.selectedBuildingID != null && this.data.selectedProject != null && this.data.selectedProject.floors.size > 0)
    {
      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.data.selectedBuildingID)
        {
          return true;
        }
      }
    }
    else
    {
      return false;
    }
  }

  ngOnInit() 
  {
    this.numFloorsField.setValidators([Validators.required]);
    this.numFloorsField.setValue(1);
    
    if (this.data.selectedProject != null && this.data.selectedBuildingID != null)
    {
      let highestFloorNumber: number = 0;

      if (this.data.selectedProject.floors.size > 0)
      {
        for (let floor of this.data.selectedProject.floors.values())
        {
          if (floor.building_id == this.data.selectedBuildingID && floor.floor_number > highestFloorNumber)
          {
            highestFloorNumber = floor.floor_number;
          }
        }

        this.startingFloorField.setValue(highestFloorNumber);
      }
      else
      {
        this.startingFloorField.setValue(0);
      }
    }
  }

  public ValidateNumFloors(): void
  {
    if (this.numFloorsField.value <= 0)
    {
      this.numFloorsField.setValue(1);
    }
    else if (this.numFloorsField.value > GenerateFloorsDialogComponent.MAX_FLOORS)
    {
      this.numFloorsField.setValue(GenerateFloorsDialogComponent.MAX_FLOORS);
    }
  }

  public GetNumFloorsFieldError(): string
  {
    if (this.numFloorsField.hasError('required'))
    {
      return "Please enter the number of floors you wish to generate";
    }
    else if (this.numFloorsField.hasError('too_low'))
    {
      return "The number of floors to generate must be greater than 0";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isFloorInfoValid: boolean = true;

    if (this.numFloorsField.value <= 0)
    {
      this.numFloorsField.setErrors({too_low: true});
      this.numFloorsField.markAsTouched();
      isFloorInfoValid = false;
    }

    if (isFloorInfoValid)
    {
      let generateFloorsInfoDict: Map<string, any> = new Map<string, any>();
      generateFloorsInfoDict.set("numFloors", this.numFloorsField.value);
      generateFloorsInfoDict.set("startingFloor", this.startingFloorField.value);

      this.dialogRef.close(generateFloorsInfoDict);
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteCompanyDialogData } from '../../companies-table-view/companies-table-view.component';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Company } from 'src/DataModels';

@Component({
  selector: 'app-delete-company-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.css']
})
export class DeleteCompanyDialogComponent implements OnInit 
{
  public deleteDatabasesSelectionDict: Map<string, boolean>;

  constructor(public dialogRef: MatDialogRef<DeleteCompanyDialogComponent>, private appUtilityService: AppUtilityService, @Inject(MAT_DIALOG_DATA) public data: DeleteCompanyDialogData) { }

  ngOnInit() 
  {
    this.deleteDatabasesSelectionDict = new Map<string, boolean>();
    for (var company of this.data.companiesToDelete)
    {
      this.deleteDatabasesSelectionDict.set(company.company_id, false);
    }
  }

  public OnDeleteDatabasesToggleClicked(companyID: string, shouldDeleteDatabases: boolean)
  {
    this.deleteDatabasesSelectionDict.set(companyID, shouldDeleteDatabases);
  }

  public OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  public OnDeleteButtonClicked()
  {
    this.dialogRef.close(this.deleteDatabasesSelectionDict);
  }
}

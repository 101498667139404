import { Component, OnInit, Input, AfterViewInit, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Project, EAnalyticType, ProjectAnalytic, Unit, Elevation, Lot, ProjectSession } from 'src/DataModels';

import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AppUtilityService } from '../app-utility.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewAllChartDataDialogComponent } from '../dialog/view-all-chart-data-dialog/view-all-chart-data-dialog.component';

import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { AuthService } from '../auth.service';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { of, Observable } from 'rxjs';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: ProjectAnalyticsViewComponent }],
  selector: 'app-project-analytics-view',
  templateUrl: './project-analytics-view.component.html',
  styleUrls: ['./project-analytics-view.component.css']
})
export class ProjectAnalyticsViewComponent extends CMSTableViewBase implements AfterViewInit
{
    private _project: Project = null;

    @Input()
    set project(project: Project)
    {
        this._project = project;
    }

    get project(): Project 
    {
        return this._project;
    }

    public allCharts = 
    [
        new NvyveChart("Most Viewed Area Amenities", EChartType.Bar, "Amenity Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularAreaAmenityData.bind(this)),
        new NvyveChart("Most Viewed Building Amenities", EChartType.Bar, "Amenity Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularBuildingAmenityData.bind(this), () => this.project.has_condos),
        new NvyveChart("Most Viewed Buildings", EChartType.Bar, "Building Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularBuildingsData.bind(this), () => this.project.has_condos || this.project.has_townhomes),
        new NvyveChart("Most Viewed Models", EChartType.Bar, "Model Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularModelsData.bind(this), () => this.project.has_condos || this.project.has_townhomes),
        new NvyveChart("Most Viewed Elevations", EChartType.Bar, "Elevation Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularElevationsData.bind(this), () => this.project.has_detached),
        new NvyveChart("Most Viewed Lots", EChartType.Bar, "Lot Number", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularLotsData.bind(this), () => this.project.has_detached),
        new NvyveChart("Most Viewed Suites", EChartType.Bar, "Suite Name", "Number of Views", 5, EChartSortingOrder.Ascending, this.GetMostPopularSuitesData.bind(this), () => this.project.has_condos || this.project.has_townhomes),
        new NvyveChart("Average Session Module Time", EChartType.Line, "Module Name", "Time (s)", 5, EChartSortingOrder.Ascending, this.GetAverageSessionModuleTimeData.bind(this)),
        new NvyveChart("Total Session Module Time", EChartType.Line, "Module Name", "Time (s)", 5, EChartSortingOrder.Ascending, this.GetTotalSessionModuleTimeData.bind(this)),
        new NvyveChart("Project Sessions Over Time", EChartType.Bar, "Day", "Number of Sessions", 7, EChartSortingOrder.Ascending, this.GetProjectSessionsOverTimeData.bind(this), () => this.authService.IsActiveUserAdmin(), true)
    ];

    public chartsDisplayed: Array<NvyveChart> = new Array<NvyveChart>();

    constructor(private dialog: MatDialog, public appUtilityService: AppUtilityService, public authService: AuthService, public changeDetectorRef: ChangeDetectorRef) 
    {
        super();
    }

    private InitializeAllCharts(): Observable<boolean>
    {
        for (let chart of this.allCharts)
        {
            if (!chart.isCanvasAttached)
            {
                chart.SetViewParent(this);

                if (chart.IsVisible())
                {
                    this.chartsDisplayed.push(chart);
                    chart.Initialize();
                }
            }
        }

        return of(true);
    }

    ngAfterViewInit()
    {
        this.OnTabEntered();
    }

    OnTabEntered()
    {
        if (this.project === null || this.project.analytics === null || this.project.analytics.size == 0) { return; }

        this.InitializeAllCharts().subscribe(_ => 
        {
            for (let chart of this.chartsDisplayed)
            {
                const chartContainerElementRef = document.getElementById(chart.tableName);
                if (chartContainerElementRef !== null)
                {
                    chartContainerElementRef.appendChild(chart.canvasRef);
                    chart.isCanvasAttached = true;
                } 
           }
        })
    }

    public OnChartViewAllButtonPressed(chart: NvyveChart)
    {
        const dialogRef = this.dialog.open(ViewAllChartDataDialogComponent, { width: '1600px', height: '900px', data: { chart: chart } });
    }

    // Sorting Helper Methods
    public GetSortedAggregateAnalyticsOfType(analyticType: EAnalyticType): Array<ProjectAnalytic>
    {
        let sortedColumnData: Array<ProjectAnalytic> = this.project.analytics.get(analyticType).sort((a1, a2) => 
        {
            if (a1.count > a2.count)
            {
                return 1;
            }
            else if (a1.count < a2.count)
            {
                return -1;
            }
            else
            {
                return 0;
            }
        });

        return sortedColumnData;
    }

    public GetSortedSessionAnalytics(): Array<ProjectSession>
    {
        let sortedColumnData: Array<ProjectSession> = this.project.sessions.sort((s1, s2) => 
        {
            if (s1.date_time.isAfter(s2.date_time))
            {
                return 1;
            }
            else if (s1.date_time.isBefore(s2.date_time))
            {
                return -1;
            }
            else
            {
                return 0;
            }
        });

        return sortedColumnData;
    }

    // Data Population Callbacks
    public GetMostPopularAreaAmenityData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.amenityId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];
            columnNames.push(currentAnalytic.data_type_name);
            columnData.push(currentAnalytic.count);
        }
    }

    public GetMostPopularBuildingAmenityData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.buildingAmenityId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];
            columnNames.push(currentAnalytic.data_type_name);
            columnData.push(currentAnalytic.count);
        }
    }
    
    public GetMostPopularBuildingsData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.buildingId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];

            if (!this.project.buildings.has(currentAnalytic.data_type_id))
            {
                continue;
            }
            else
            {
                columnNames.push(this.project.buildings.get(currentAnalytic.data_type_id).name);
                columnData.push(currentAnalytic.count);
            }
        }
    }

    public GetMostPopularElevationsData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.elevationId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];

            if (!this.project.elevations.has(currentAnalytic.data_type_id))
            {
                continue;
            }
            else
            {
                let referencedElevation: Elevation = this.project.elevations.get(currentAnalytic.data_type_id);
                if (referencedElevation !== null && this.project.homes.has(referencedElevation.detached_id))
                {
                    columnNames.push(this.project.homes.get(referencedElevation.detached_id).name + " " + referencedElevation.elevation);
                    columnData.push(currentAnalytic.count);
                }
                else
                {
                    continue;
                }
            }    
        }
    }

    public GetMostPopularLotsData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.lotId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];

            if (!this.project.lots.has(currentAnalytic.data_type_id))
            {
                continue;
            }
            else
            {
                let referencedLot: Lot = this.project.lots.get(currentAnalytic.data_type_id);
                if (referencedLot !== null)
                {
                    columnNames.push(referencedLot.lot_number.toString());
                    columnData.push(currentAnalytic.count);
                }
                else
                {
                    continue;
                }
            }
        }
    }

    public GetMostPopularModelsData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.modelId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];

            if (!this.project.models.has(currentAnalytic.data_type_id))
            {
                continue;
            }
            else
            {
                columnNames.push(this.project.models.get(currentAnalytic.data_type_id).name);
                columnData.push(currentAnalytic.count);
            }
        }
    }

    public GetMostPopularSuitesData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedColumnData = this.GetSortedAggregateAnalyticsOfType(EAnalyticType.suiteId);
        let numColumnsStored = sortedColumnData.length;

        for (let i = 0; i < numColumnsStored; ++i)
        {
            let currentAnalytic: ProjectAnalytic = sortedColumnData[(sortedColumnData.length - 1) - i];

            if (!this.project.models.has(currentAnalytic.data_type_id))
            {
                continue;
            }
            else
            {
                columnNames.push(this.project.models.get(currentAnalytic.data_type_id).name);
                columnData.push(currentAnalytic.count);
            }
        }
    }

    public GetAverageSessionModuleTimeData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedSessions = this.GetSortedSessionAnalytics();
        let numSessionsStored = sortedSessions.length;

        let tempModuleMap: Map<string, number> = new Map<string, number>();

        for (let i = 0; i < numSessionsStored; ++i)
        {
            let currentSession: ProjectSession = sortedSessions[(sortedSessions.length - 1) - i];

            for (let moduleTransition of currentSession.module_transitions)
            {
                if (!tempModuleMap.has(moduleTransition.prev_module_name))
                {
                    tempModuleMap.set(moduleTransition.prev_module_name, moduleTransition.prev_module_elapsed_time);
                }
                else
                {
                    tempModuleMap.set(moduleTransition.prev_module_name, tempModuleMap.get(moduleTransition.prev_module_name) + moduleTransition.prev_module_elapsed_time);
                }
            }
        }

        // Sort the module entries in ascending order by their elapsed time
        tempModuleMap = new Map([...tempModuleMap.entries()].sort((module1, module2) => 
        {
            if (module1[1] > module2[1])
            {
                return 1;
            }
            else if (module1[1] < module2[1])
            {
                return -1;
            }
            else
            {
                return 0;
            }
        }));

        if (tempModuleMap.size > 0)
        {
            for (let moduleName of tempModuleMap.keys()) 
            { 
                columnNames.push(moduleName); 
            }

            let overallSessionTime: number = 0;
            for (let moduleTotalTime of tempModuleMap.values())
            {
                overallSessionTime += moduleTotalTime;
                columnData.push(moduleTotalTime / numSessionsStored);
            }
            overallSessionTime /= numSessionsStored;

            columnNames.push("Overall Average")
            columnData.push(overallSessionTime);

            columnNames.reverse();
            columnData.reverse();
        }   
    }

    public GetTotalSessionModuleTimeData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedSessions = this.GetSortedSessionAnalytics();
        let numSessionsStored = sortedSessions.length;

        let tempModuleMap: Map<string, number> = new Map<string, number>();

        for (let i = 0; i < numSessionsStored; ++i)
        {
            let currentSession: ProjectSession = sortedSessions[(sortedSessions.length - 1) - i];

            for (let moduleTransition of currentSession.module_transitions)
            {
                if (!tempModuleMap.has(moduleTransition.prev_module_name))
                {
                    tempModuleMap.set(moduleTransition.prev_module_name, moduleTransition.prev_module_elapsed_time);
                }
                else
                {
                    tempModuleMap.set(moduleTransition.prev_module_name, tempModuleMap.get(moduleTransition.prev_module_name) + moduleTransition.prev_module_elapsed_time);
                }
            }
        }

        // Sort the module entries in ascending order by their elapsed time
        tempModuleMap = new Map([...tempModuleMap.entries()].sort((module1, module2) => 
        {
            if (module1[1] > module2[1])
            {
                return 1;
            }
            else if (module1[1] < module2[1])
            {
                return -1;
            }
            else
            {
                return 0;
            }
        }));

        if (tempModuleMap.size > 0)
        {
            for (let moduleName of tempModuleMap.keys()) 
            { 
                columnNames.push(moduleName); 
            }

            let overallSessionTime: number = 0;
            for (let moduleTotalTime of tempModuleMap.values())
            {
                overallSessionTime += moduleTotalTime;
                columnData.push(moduleTotalTime);
            }

            columnNames.push("Overall Total")
            columnData.push(overallSessionTime);

            columnNames.reverse();
            columnData.reverse();
        }
    }

    public GetProjectSessionsOverTimeData(columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment)
    {
        let sortedSessions = this.GetSortedSessionAnalytics();
        let tempModuleMap: Map<string, number> = new Map<string, number>();

        for (let session of sortedSessions)
        {
            // Compare session date_time with the chart's current selected date offset (i.e. ensure it is within the "week of" that date)
            if (session.date_time.isSame(selectedFilterDate, "year") && (session.date_time.isSame(selectedFilterDate, "month")) && session.date_time.isSame(selectedFilterDate, "week"))
            {
                // Get the weekday name from the session date_time
                let weekday: string = session.date_time.format('dddd');
                
                if (!tempModuleMap.has(weekday))
                {
                    tempModuleMap.set(weekday, 1);
                }
                else
                {
                    tempModuleMap.set(weekday, tempModuleMap.get(weekday) + 1);
                }
            }            
        }

        if (tempModuleMap.size > 0)
        {
            for (let weekdayName of tempModuleMap.keys()) 
            { 
                columnNames.push(weekdayName); 
            }

            for (let numSessions of tempModuleMap.values())
            {
                columnData.push(numSessions);
            }

            columnNames.reverse();
            columnData.reverse();
        }
    }

    RenderTableRows(): void { }
    ShouldDisplayAddButton(): boolean { return false; }
    ShouldDisplayEditButton(): boolean { return false; }
    ShouldDisplayDeleteButton(): boolean { return false; }
    OnAddButtonPressed() { }
    OnEditButtonPressed(row: any) { }
    OnDeleteButtonPressed() { }
    HasSelectedRows(): boolean { return false; }
    GetSelectedRows(): Set<string | number> { return null; }
    ClearSelectedRows(): void { }
}

export enum EChartType
{
    Line = "line",
    Bar = "bar",
    Radar = "radar",
    Pie = "pie",
    Doughnut = "doughnut",
    PolarArea = "polarArea",
    Scatter = "scatter",
    Bubble = "bubble"
}

export enum EChartSortingOrder
{
    None,
    Ascending,
    Descending,
}

export class NvyveChart
{
    public static commonChartBackgroundColors: Array<string> = 
    [
        'rgba(255, 0, 0, 0.5)', 
        'rgba(255, 224, 150, 0.5)', 
        'rgba(0, 255, 149, 0.5)', 
        'rgba(204, 234, 255, 0.5)', 
        'rgba(255, 0, 212, 0.5)', 
        'rgba(252, 0, 0, 0.5)', 
        'rgba(199, 194, 183, 0.5)', 
        'rgba(107, 150, 156, 0.5)', 
        'rgba(54, 75, 145, 0.5)', 
        'rgba(3, 83, 107, 0.5)', 
        'rgba(67, 74, 67, 0.5)', 
        'rgba(38, 34, 18, 0.5)', 
        'rgba(255, 206, 196, 0.5)',
        'rgba(255, 183, 0, 0.5)', 
        'rgba(212, 255, 243, 0.5)', 
        'rgba(0, 106, 255, 0.5)', 
        'rgba(255, 140, 213, 0.5)', 
        'rgba(247, 0, 0, 0.5)', 
        'rgba(128, 186, 147, 0.5)', 
        'rgba(107, 127, 156, 0.5)', 
        'rgba(145, 112 ,116, 0.5)', 
        'rgba(0, 18, 97, 0.5)', 
        'rgba(0, 39, 71, 0.5)', 
        'rgba(0, 35, 38, 0.5)', 
        'rgba(255, 133, 99, 0.5)', 
        'rgba(255, 230, 0, 0.5)', 
        'rgba(0, 255, 225, 0.5)', 
        'rgba(0, 51, 255, 0.5)', 
        'rgba(255, 0, 140, 0.5)', 
        'rgba(242, 0, 0, 0.5)', 
        'rgba(166, 117, 45, 0.5)', 
        'rgba(97, 12, 153, 0.5)', 
        'rgba(133, 126, 95, 0.5)', 
        'rgba(89, 62, 36, 0.2)', 
        'rgba(71, 0, 50, 0.5)', 
        'rgba(28, 0, 17, 0.5)', 
        'rgba(255, 106, 0, 0.5)', 
        'rgba(204, 255, 0, 0.5)', 
        'rgba(0, 230, 255, 0.5)', 
        'rgba(190, 186, 255, 0.5)',
        'rgba(255, 212, 232, 0.5)', 
        'rgba(237, 0, 0, 0.5)', 
        'rgba(69, 166, 33, 0.5)', 
        'rgba(138, 101, 153, 0.5)', 
        'rgba(120, 0, 48, 0.5)', 
        'rgba(82, 23, 0, 0.5)', 
        'rgba(56, 0, 0, 0.5)', 
        'rgba(255, 188, 140, 0.5)', 
        'rgba(207, 255, 156, 0.5)', 
        'rgba(0, 187, 255, 0.5)', 
        'rgba(183, 125, 255, 0.5)', 
        'rgba(255, 0, 81, 0.5)', 
        'rgba(232, 0, 0, 0.5)', 
        'rgba(161, 64, 61, 0.5)', 
        'rgba(150, 73, 24, 0.5)', 
        'rgba(0, 117, 108, 0.5)', 
        'rgba(79, 66, 0, 0.5)', 
        'rgba(0, 56, 30, 0.5)', 
        'rgba(255, 145, 0, 0.5)', 
        'rgba(76, 255, 0, 0.5)', 
        'rgba(0, 149, 255, 0.5)', 
        'rgba(187, 0, 255, 0.5)', 
        'rgba(255, 138, 159, 0.5)', 
        'rgba(212, 0, 0, 0.5)', 
        'rgba(156, 158, 47, 0.5)', 
        'rgba(150, 0, 113, 0.5)', 
        'rgb(77, 107 ,48, 0.5)', 
        'rgba(79, 50, 67, 0.5)', 
        'rgba(0, 2, 41, 0.5)'
    ];

    // Used to colour the borders of every bar in a bar chart,
    // matches nearly 1:1 with commonChartBackgroundColors but differs in
    // setting the opacity to 1 in order to have a solid colour border.
    public static commonChartBorderColors: Array<string> = 
    [ 
        'rgba(255, 0, 0, 1)', 
        'rgba(255, 224, 150, 1)', 
        'rgba(0, 255, 149, 1)', 
        'rgba(204, 234, 255, 1)', 
        'rgba(255, 0, 212, 1)', 
        'rgba(252, 0, 0, 1)', 
        'rgba(199, 194, 183, 1)', 
        'rgba(107, 150, 156, 1)', 
        'rgba(54, 75, 145, 1)', 
        'rgba(3, 83, 107, 1)', 
        'rgba(67, 74, 67, 1)', 
        'rgba(38, 34, 18, 1)', 
        'rgba(255, 206, 196, 1)',
        'rgba(255, 183, 0, 1)', 
        'rgba(212, 255, 243, 1)', 
        'rgba(0, 106, 255, 1)', 
        'rgba(255, 140, 213, 1)', 
        'rgba(247, 0, 0, 1)', 
        'rgba(128, 186, 147, 1)', 
        'rgba(107, 127, 156, 1)', 
        'rgba(145, 112 ,116, 1)', 
        'rgba(0, 18, 97, 1)', 
        'rgba(0, 39, 71, 1)', 
        'rgba(0, 35, 38, 1)', 
        'rgba(255, 133, 99, 1)', 
        'rgba(255, 230, 0, 1)', 
        'rgba(0, 255, 225, 1)', 
        'rgba(0, 51, 255, 1)', 
        'rgba(255, 0, 140, 1)', 
        'rgba(242, 0, 0, 1)', 
        'rgba(166, 117, 45, 1)', 
        'rgba(97, 12, 153, 1)', 
        'rgba(133, 126, 95, 1)', 
        'rgba(89, 62, 36, 1)', 
        'rgba(71, 0, 50, 1)', 
        'rgba(28, 0, 17, 1)', 
        'rgba(255, 106, 0, 1)', 
        'rgba(204, 255, 0, 1)', 
        'rgba(0, 230, 255, 1)', 
        'rgba(190, 186, 255, 1)',
        'rgba(255, 212, 232, 1)', 
        'rgba(237, 0, 0, 1)', 
        'rgba(69, 166, 33, 1)', 
        'rgba(138, 101, 153, 1)', 
        'rgba(120, 0, 48, 1)', 
        'rgba(82, 23, 0, 1)', 
        'rgba(56, 0, 0, 1)', 
        'rgba(255, 188, 140, 1)', 
        'rgba(207, 255, 156, 1)', 
        'rgba(0, 187, 255, 1)', 
        'rgba(183, 125, 255, 1)', 
        'rgba(255, 0, 81, 1)', 
        'rgba(232, 0, 0, 1)', 
        'rgba(161, 64, 61, 1)', 
        'rgba(150, 73, 24, 1)', 
        'rgba(0, 117, 108, 1)', 
        'rgba(79, 66, 0, 1)', 
        'rgba(0, 56, 30, 1)', 
        'rgba(255, 145, 0, 1)', 
        'rgba(76, 255, 0, 1)', 
        'rgba(0, 149, 255, 1)', 
        'rgba(187, 0, 255, 1)', 
        'rgba(255, 138, 159, 1)', 
        'rgba(212, 0, 0, 1)', 
        'rgba(156, 158, 47, 1)', 
        'rgba(150, 0, 113, 1)', 
        'rgb(77, 107 ,48, 1)', 
        'rgba(79, 50, 67, 1)', 
        'rgba(0, 2, 41, 1)'
    ];

    public static commonChartBorderWidth: number = 1;

    public tableName: string;
    public fullDataTableName: string;

    public type: EChartType;
    public xAxisLabel: string;
    public yAxisLabel: string;
    public maxNumColumns: number;
    public canUseDateFilter: boolean;
    public selectedFilterDate: moment.Moment = moment();
    public columnSortingOrder: EChartSortingOrder;

    private dataPopulationCallback: (columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment) => void = null;
    private visibilityConditionFunction: () => boolean = null; 

    // Data storage of ALL column names and corresponding data values associated with this table's desired analytic type
    private cachedColumnNames: Array<string> = null;
    private cachedColumnData: Array<number> = null;

    private reducedCachedColumnNames: Array<string> = null;
    private reducedCachedColumnData: Array<number> = null;

    private projectAnalyticsView: ProjectAnalyticsViewComponent = null;
    public canvasRef = null;
    private instance: Chart;

    public isCanvasAttached: boolean = false;

    constructor(_tableName: string, _type: EChartType,  _xAxisLabel: string, _yAxisLabel: string, _maxNumColumns: number, _columnSortingOrder: EChartSortingOrder, _dataPopulationCallback: (columnNames: Array<string>, columnData: Array<number>, selectedFilterDate: moment.Moment) => void, _visibilityConditionFunction: () => boolean = null, _canUseDateFilter: boolean = false)
    {
        this.tableName = _tableName;
        this.fullDataTableName = _tableName + " Full";

        this.type = _type;
        this.xAxisLabel = _xAxisLabel;
        this.yAxisLabel = _yAxisLabel;
        this.maxNumColumns = _maxNumColumns,
        this.columnSortingOrder = _columnSortingOrder,
        this.dataPopulationCallback = _dataPopulationCallback;
        this.visibilityConditionFunction = _visibilityConditionFunction;
        this.canUseDateFilter = _canUseDateFilter;
    }

    public SetViewParent(_projectAnalyticsView: ProjectAnalyticsViewComponent)
    {
        if (this.projectAnalyticsView === null) { this.projectAnalyticsView = _projectAnalyticsView; }
    }

    public Initialize()
    {
        let columnsAndDataDict = this.GetColumnNamesAndData();
        
        this.cachedColumnNames = columnsAndDataDict["columnNames"];
        this.cachedColumnData = columnsAndDataDict["columnDataList"];

        let reducedDataLength: number = this.cachedColumnNames.length > this.maxNumColumns ? this.maxNumColumns : this.cachedColumnNames.length; 
        this.reducedCachedColumnNames = this.cachedColumnNames.slice(this.cachedColumnNames.length - reducedDataLength);
        this.reducedCachedColumnData = this.cachedColumnData.slice(this.cachedColumnData.length - reducedDataLength);

        let options = this.BuildChartOptions();
        this.canvasRef = document.createElement("canvas");

        this.instance = new Chart(this.canvasRef, 
        {
            type: this.type,
            data: 
            {
                labels: this.reducedCachedColumnNames,
                datasets: 
                [
                    {
                        data: this.reducedCachedColumnData,
                        backgroundColor: NvyveChart.commonChartBackgroundColors,
                        borderColor: NvyveChart.commonChartBorderColors,
                        borderWidth: NvyveChart.commonChartBorderWidth,
                        fill: false,
                        barPercentage: 1,
                        categoryPercentage: 1.2 / 10 * this.reducedCachedColumnData.length
                    }
                ]
            },
            options: options
        });

        if (this.instance)
        {
            this.projectAnalyticsView.changeDetectorRef.detectChanges();
        }
    }

    public GetFullDataChartInstance(): Chart 
    {
        let options = this.BuildChartOptions();

        return new Chart(this.fullDataTableName, 
        {
            type: this.type,
            data:
            {
                labels: this.cachedColumnNames,
                datasets:
                [
                    {
                        data: this.cachedColumnData,
                        backgroundColor: NvyveChart.commonChartBackgroundColors,
                        borderColor: NvyveChart.commonChartBorderColors,
                        borderWidth: NvyveChart.commonChartBorderWidth,
                        fill: false,
                        barPercentage: 1,
                        categoryPercentage: 0.9
                    }
                ]
            },
            plugins: [ChartDataLabels],
            options: options
        });
    }

    private BuildChartOptions(): object
    {
        let options = 
        {
            legend:
            {
                display: this.type === EChartType.Pie || this.type === EChartType.Doughnut || this.type === EChartType.PolarArea,
                position: 'right',
                align: 'center'
            },
            scales: 
            {
                xAxes:
                [{
                    display: this.type === EChartType.Bar || this.type === EChartType.Line || this.type === EChartType.Scatter,
                    scaleLabel:
                    {
                        display: (this.xAxisLabel !== null) && (this.xAxisLabel !== ""),
                        labelString: this.xAxisLabel,
                        fontStyle: 'bold',
                        fontColor: 'rgba(0, 0, 0, 1)'
                    },
                }],
                yAxes: 
                [{
                    display: this.type === EChartType.Bar || this.type === EChartType.Line || this.type === EChartType.Scatter,
                    scaleLabel:
                    {
                        display: (this.yAxisLabel !== null) && (this.yAxisLabel !== ""),
                        labelString: this.yAxisLabel,
                        fontStyle: 'bold',
                        fontColor: 'rgba(0, 0, 0, 1)'
                    },
                    ticks: 
                    {
                        beginAtZero: true
                    }
                }]
            },
            plugins:
            {
                datalabels:
                {
                    display: this.type === EChartType.Pie || this.type === EChartType.Doughnut || this.type === EChartType.PolarArea,
                    visibility: 'auto',
                    color: 'rgba(0, 0, 0, 1)',

                    font:
                    {
                        style: 'bold'
                    },

                    // Formats the inner label of each value in the dataset to display what percentage it takes
                    // of the dataset's total
                    formatter: (value, ctx) => 
                    {
                        let total = 0;
                        
                        let dataset = ctx.chart.data.datasets[0];
                        for (let column of dataset.data)
                        {
                            total += column;
                        }

                        let percentage: number = (value * 100 / total);
                        return percentage <= 2 ? "" : percentage.toFixed(0) + "%";
                    }
                }
            }
        };

        return options;
    }

    private GetColumnNamesAndData()
    {
        let columnsAndDataDict = {};
        let columnNames = Array<string>();
        let columnData = Array<number>();

        this.dataPopulationCallback(columnNames, columnData, this.selectedFilterDate);

        if (this.columnSortingOrder == EChartSortingOrder.Ascending)
        {
            columnData.reverse();
            columnNames.reverse();
        }

        columnsAndDataDict["columnNames"] = columnNames;
        columnsAndDataDict["columnDataList"] = columnData;
        return columnsAndDataDict;
    }

    public OnFilterDateChanged(event: MatDatepickerInputEvent<moment.Moment>)
    {
        this.selectedFilterDate = event.value;
        this.Initialize();
    }

    public CanExpand(): boolean
    {
        // Disable the View All button if this chart lacks any extra data that wouldn't fit in the grid-view.
        return this.instance !== null && this.instance !== undefined && (this.cachedColumnData.length > this.reducedCachedColumnData.length) && !this.canUseDateFilter;
    }

    public IsVisible(): boolean
    {
        if (this.visibilityConditionFunction === null) 
        { 
            return true; 
        }
        else if (this.visibilityConditionFunction !== null && this.projectAnalyticsView !== null && this.projectAnalyticsView.project !== null)
        {
            return this.visibilityConditionFunction();
        }
        else
        {
            return false;
        }
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { ModelType, Project } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';

export interface CreateModelTypeDialogData
{
  selectedProject: Project;
  selectedModelID: number;
  modelTypeToEdit: ModelType;
}

@Component({
  selector: 'app-create-model-type-dialog',
  templateUrl: './create-model-type-dialog.component.html',
  styleUrls: ['./create-model-type-dialog.component.css']
})
export class CreateModelTypeDialogComponent implements OnInit 
{
  public nameField: FormControl = new FormControl('');
  public squareFootageField: FormControl = new FormControl(0);
  public squareFootageOutdoorField: FormControl = new FormControl(0);
  public priceField: FormControl = new FormControl(0);
  public numBedsField: FormControl = new FormControl(0);
  public numBathsField: FormControl = new FormControl(0);
  public hasDenField: FormControl = new FormControl(false);

  constructor(private dialogRef: MatDialogRef<CreateModelTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateModelTypeDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public CanEditModelTypeData(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }

    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      return projectRole.can_modify_unit_data;
    }

    return false;
  }

  public CanEditModelTypePricing(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }

    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      return projectRole.can_modify_pricing;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.modelTypeToEdit != null)
    {
      return "Edit Model Type";
    }
    else
    {
      return "Create Model Type";
    }
  }

  ngOnInit() 
  {
    this.nameField.setValidators([Validators.required]);
    this.squareFootageField.setValidators([Validators.required]);
    this.squareFootageOutdoorField.setValidators([Validators.required]);
    this.priceField.setValidators([Validators.required]);
    this.numBedsField.setValidators([Validators.required]);
    this.numBathsField.setValidators([Validators.required]);

    if (this.data.modelTypeToEdit != null)
    {
      this.nameField.setValue(this.data.modelTypeToEdit.name);
      this.squareFootageField.setValue(this.data.modelTypeToEdit.square_footage);
      this.squareFootageOutdoorField.setValue(this.data.modelTypeToEdit.square_footage_outdoor);
      this.priceField.setValue(this.data.modelTypeToEdit.price);
      this.numBedsField.setValue(this.data.modelTypeToEdit.num_beds);
      this.numBathsField.setValue(this.data.modelTypeToEdit.num_baths);
      this.hasDenField.setValue(this.data.modelTypeToEdit.has_den);
    }
  }

  public GetNameFieldError(): string
  {
    if (this.nameField.hasError('required'))
    {
      return "Please enter a name for this model type";
    }
    else if (this.nameField.hasError('unique'))
    {
      return this.nameField.value + " is already taken";
    }
  }

  public GetSquareFootageFieldError(): string
  {
    if (this.squareFootageField.hasError('required'))
    {
      return "Please enter the square footage";
    }
    else if (this.squareFootageField.hasError('too_low'))
    {
      return "Square footage must be greater than 0";
    }
  }

  public GetOutdoorSquareFootageFieldError(): string
  {
    if (this.squareFootageOutdoorField.hasError('required'))
    {
      return "Please enter the outdoor square footage";
    }
    else if (this.squareFootageOutdoorField.hasError('too_low'))
    {
      return "Outdoor square footage must be greater than or equal to 0";
    }
  }

  public GetPriceFieldError(): string
  {
    if (this.priceField.hasError('required'))
    {
      return "Please enter a price for this model type";
    }
    else if (this.priceField.hasError('too_low'))
    {
      return "Price must be 0 or greater";
    }
  }

  public GetNumBedsFieldError(): string
  {
    if (this.numBedsField.hasError('required'))
    {
      return "Please enter the number of beds";
    }
    else if (this.numBedsField.hasError('too_low'))
    {
      return "The number of bedrooms must be zero or above";
    }
  }

  public GetNumBathsFieldError(): string
  {
    if (this.numBathsField.hasError('required'))
    {
      return "Please enter the number of fullbaths";
    }
    else if (this.numBathsField.hasError('too_low'))
    {
      return "There must be more than zero fullbaths";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isModelTypeInfoValid: boolean = true;

    if (this.nameField.value == "")
    {
      this.nameField.setErrors({required: true});
      this.nameField.markAsTouched();
      isModelTypeInfoValid = false;
    }
    else
    {
      for (let modelType of this.data.selectedProject.model_types.values())
      {
        if (modelType.model_id == this.data.selectedModelID && modelType.name == this.nameField.value)
        {
          if (this.data.modelTypeToEdit == null || this.data.modelTypeToEdit.model_type_id != modelType.model_type_id)
          {
            this.nameField.setErrors({unique: true});
            this.nameField.markAsTouched();
            isModelTypeInfoValid = false;
          }
          
          break;
        }
      }
    }

    if (this.squareFootageField.value <= 0)
    {
      this.squareFootageField.setErrors({too_low: true});
      this.squareFootageField.markAsTouched();
      isModelTypeInfoValid = false;
    }

    if (this.squareFootageOutdoorField.value < 0)
    {
      this.squareFootageOutdoorField.setErrors({too_low: true});
      this.squareFootageOutdoorField.markAsTouched();
      isModelTypeInfoValid = false;
    }

    if (this.priceField.value < 0)
    {
      this.priceField.setErrors({too_low: true});
      this.priceField.markAsTouched();
      isModelTypeInfoValid = false;
    }

    if (this.numBedsField.value < 0)
    {
      this.numBedsField.setErrors({too_low: true});
      this.numBedsField.markAsTouched();
      isModelTypeInfoValid = false;
    }

    if (this.numBathsField.value < 0)
    {
      this.numBathsField.setErrors({too_low: true});
      this.numBathsField.markAsTouched();
      isModelTypeInfoValid = false;
    }

    if (isModelTypeInfoValid)
    {
      let modelTypeInfoDict: Map<string, any> = new Map<string, any>();
      modelTypeInfoDict.set("model_id", this.data.selectedModelID);
      modelTypeInfoDict.set("name", this.nameField.value);
      modelTypeInfoDict.set("square_footage", this.squareFootageField.value);
      modelTypeInfoDict.set("square_footage_outdoor", this.squareFootageOutdoorField.value);
      modelTypeInfoDict.set("price", this.priceField.value);
      modelTypeInfoDict.set("num_beds", this.numBedsField.value);
      modelTypeInfoDict.set("num_baths", this.numBathsField.value);
      modelTypeInfoDict.set("has_den", this.hasDenField.value);

      this.dialogRef.close(modelTypeInfoDict);
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { EAvailabilityCode, Lot, Project, Phase } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

export interface CreateLotDialogData
{
  selectedProject: Project;
  lotToEdit: Lot;
}

@Component({
  selector: 'app-create-lot-dialog',
  templateUrl: './create-lot-dialog.component.html',
  styleUrls: ['./create-lot-dialog.component.css']
})
export class CreateLotDialogComponent implements OnInit 
{
  public phaseIDField: FormControl = new FormControl(0);
  public lotNumberField: FormControl = new FormControl(0);
  
  public selectedAvailabilityStatus: EAvailabilityCode = EAvailabilityCode.Unavailable;
  public selectablePhaseIDs: Array<number>;
  public selectedPhaseID: number;
  public gradingField: FormControl = new FormControl('');
  public lotFrontageField: FormControl = new FormControl(0);
  public lotPricePremiumField: FormControl = new FormControl(0);
  public upgradePriceField: FormControl = new FormControl(0);
  public gradingPriceField: FormControl = new FormControl(0);

  public xPositionField: FormControl = new FormControl(0);
  public yPositionField: FormControl = new FormControl(0);
  public zPositionField: FormControl = new FormControl(0);
  
  public rotationAngleField: FormControl = new FormControl(0);
  
  public mirrorField: FormControl = new FormControl(false);
  public exteriorEnhancementField: FormControl = new FormControl(false);

  constructor(private dialogRef: MatDialogRef<CreateLotDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateLotDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetSelectablePhaseIDs(): Array<number> {
    let phases = Array.from(this.data.selectedProject.phases.values());
    let phase_ids = []
    for (let phase of phases){
      phase_ids.push(phase.phase_id)
    }
    return phase_ids
  }
  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public IsAvailabilityStatusDisabled(status: string): boolean
  {
    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      switch (status)
      {
        case EAvailabilityCode[EAvailabilityCode.Sold]:
          return !projectRole.can_finalize_sales;
        default:
          return false;
      }
    }

    return false;
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public CanEditLotAvailabilityStatus(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public CanEditLotPricingInfo(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.lotToEdit != null)
    {
      return "Edit Lot";
    }
    else
    {
      return "Create Lot";
    }
  }

  ngOnInit() 
  {
      this.lotNumberField.setValidators([Validators.required]);
      this.gradingField.setValidators([Validators.required]);
      this.lotFrontageField.setValidators([Validators.required]);
      this.lotPricePremiumField.setValidators([Validators.required]);
      this.upgradePriceField.setValidators([Validators.required]);
      this.gradingPriceField.setValidators([Validators.required]);

    if (this.data.lotToEdit != null)
    {
      this.selectedPhaseID = this.data.lotToEdit.phase_id;
      this.lotNumberField.setValue(this.data.lotToEdit.lot_number);
      this.selectedAvailabilityStatus = this.data.lotToEdit.availability;
      this.gradingField.setValue(this.data.lotToEdit.grading);
      this.lotFrontageField.setValue(this.data.lotToEdit.lot_frontage);
      this.lotPricePremiumField.setValue(this.data.lotToEdit.lot_price_premium);
      this.upgradePriceField.setValue(this.data.lotToEdit.upgrade_price);
      this.gradingPriceField.setValue(this.data.lotToEdit.grading_price);

      this.xPositionField.setValue(this.data.lotToEdit.x_position);
      this.yPositionField.setValue(this.data.lotToEdit.y_position);
      this.zPositionField.setValue(this.data.lotToEdit.z_position);

      this.rotationAngleField.setValue(this.data.lotToEdit.rotation_angle);

      this.mirrorField.setValue(this.data.lotToEdit.mirror);
      this.exteriorEnhancementField.setValue(this.data.lotToEdit.exterior_enhancement);
    }
  }

  public GetPhaseIDFieldError(): string
  {
    if (this.phaseIDField.hasError('required'))
    {
      return "Please enter a phase ID";
    }
    else if (this.phaseIDField.hasError('too_low'))
    {
      return "Phase ID must be 0 or above";
    }
  }

  public GetLotNumberFieldError(): string
  {
    if (this.lotNumberField.hasError('required'))
    {
      return "Please enter a lot number";
    }
    else if (this.lotNumberField.hasError('too_low'))
    {
      return "Lot Number must be 0 or above";
    }
  }

  public GetGradingFieldError(): string
  {
    if (this.gradingField.hasError('required'))
    {
      return "Please enter a grading";
    }
  }

  public GetLotFrontageFieldError(): string
  {
    if (this.lotFrontageField.hasError('required'))
    {
      return "Please enter the frontage for this lot";
    }
    else if (this.lotFrontageField.hasError('too_low'))
    {
      return "Lot frontage must be 0 or above";
    }
  }

  public GetLotPricePremiumFieldError(): string
  {
    if (this.lotPricePremiumField.hasError('required'))
    {
      return "Please enter the price premium for this lot";
    }
    else if (this.lotPricePremiumField.hasError('too_low'))
    {
      return "Price Premium must be 0 or above";
    }
  }

  public GetUpgradePriceFieldError(): string
  {
    if (this.upgradePriceField.hasError('required'))
    {
      return "Please enter the upgrade price for this lot";
    }
    else if (this.upgradePriceField.hasError('too_low'))
    {
      return "Upgrade price must be 0 or above";
    }
  }

  public GetGradingPriceFieldError(): string
  {
    if (this.gradingPriceField.hasError('required'))
    {
      return "Please enter the grading price for this lot";
    }
    else if (this.gradingPriceField.hasError('too_low'))
    {
      return "Grading price must be 0 or above";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isLotInfoValid: boolean = true;

    if (this.lotNumberField.value < 0)
    {
      this.lotNumberField.setErrors({too_low: true});
      this.lotNumberField.markAsTouched();
      isLotInfoValid = false;
    }

    if (this.lotFrontageField.value < 0)
    {
      this.lotFrontageField.setErrors({too_low: true});
      this.lotFrontageField.markAsTouched();
      isLotInfoValid = false;
    }

    if (this.lotPricePremiumField.value < 0)
    {
      this.lotPricePremiumField.setErrors({too_low: true});
      this.lotPricePremiumField.markAsTouched();
      isLotInfoValid = false;
    }

    if (this.gradingPriceField.value < 0)
    {
      this.upgradePriceField.setErrors({too_low: true});
      this.upgradePriceField.markAsTouched();
      isLotInfoValid = false;
    }

    if (this.gradingPriceField.value < 0)
    {
      this.gradingPriceField.setErrors({too_low: true});
      this.gradingPriceField.markAsTouched();
      isLotInfoValid = false;
    }

    if (isLotInfoValid)
    {
      let lotInfoDict: Map<string, any> = new Map<string, any>();
      lotInfoDict.set("phase_id", this.selectedPhaseID);
      lotInfoDict.set("lot_number", this.lotNumberField.value);
      lotInfoDict.set("availability", this.selectedAvailabilityStatus);
      lotInfoDict.set("grading", this.gradingField.value);
      lotInfoDict.set("lot_frontage", this.lotFrontageField.value);
      lotInfoDict.set("lot_price_premium", this.lotPricePremiumField.value);
      lotInfoDict.set("upgrade_price", this.upgradePriceField.value);
      lotInfoDict.set("grading_price", this.gradingPriceField.value);
      lotInfoDict.set("x_position", this.xPositionField.value);
      lotInfoDict.set("y_position", this.yPositionField.value);
      lotInfoDict.set("z_position", this.zPositionField.value);
      lotInfoDict.set("rotation_angle", this.rotationAngleField.value);
      lotInfoDict.set("mirror", this.mirrorField.value);
      lotInfoDict.set("exterior_enhancement", this.exteriorEnhancementField.value);
      this.dialogRef.close(lotInfoDict);
    }
  }
}

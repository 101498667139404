import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Project, WebCMSAPIResponse, Building, Elevation, Floor, Home, Lot, Linker, Model, ModelType, Phase, Unit, ProjectAnalytic, EAnalyticType, ProjectSession, Purchaser, Worksheet, EWorksheetStatus, EAvailabilityCode, Deposit, Contract, ContractTemplate, FloorplanDocument } from 'src/DataModels';
import { AppUtilityService } from './app-utility.service';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { JsonPipe } from '@angular/common';
import * as moment from 'moment';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class ProjectsService 
{
  constructor(private http: HttpClient, private dictionaryUtilitiesService: DictionaryUtilitiesService, private appConstants: AppConstantsService) { }

  public CreateNewProject(companyID: string, projectInfoDict: Map<string, any>): Observable<Project>
  {
    let params = {"projectName": projectInfoDict.get("project_name"), "companyID": companyID, "hasDetached": projectInfoDict.get("has_detached"), "hasCondos": projectInfoDict.get("has_condos"), "hasTownhomes": projectInfoDict.get("has_townhomes"), "usesWorksheets": projectInfoDict.get("uses_worksheets"), "usesFloorplans": projectInfoDict.get("uses_floorplans")};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) =>
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let projectInfoFromPacket = JSON.parse(response.data);
        let project: Project = new Project(projectInfoFromPacket);
        return project;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewBuilding(projectID: string, buildingInfoDict: Map<string, any>): Observable<Building>
  {
    let rawBuildingDict = this.dictionaryUtilitiesService.GetRawDictionary(buildingInfoDict);
    
    let params = {"projectID": projectID};
    params["building"] = JSON.stringify(rawBuildingDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let buildingInfoFromPacket = JSON.parse(response.data);
        let building: Building = new Building(buildingInfoFromPacket);
        return building;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewElevation(projectID: string, elevationInfoDict: Map<string, any>): Observable<Elevation>
  {
    let rawElevationDict = this.dictionaryUtilitiesService.GetRawDictionary(elevationInfoDict);

    let params = {"projectID": projectID};
    params["elevation"] = JSON.stringify(rawElevationDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let elevationInfoFromPacket = JSON.parse(response.data);
        let elevation: Elevation = new Elevation(elevationInfoFromPacket);
        return elevation;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public GenerateNewFloors(project: Project, buildingID: number, generateFloorsInfoDict: Map<string, any>): Observable<boolean>
  {
    let params = {"projectID": project.project_id, "buildingID": buildingID, "generateFloorsInfoDict": this.dictionaryUtilitiesService.GetRawDictionary(generateFloorsInfoDict)};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let arrGeneratedFloors: Array<object> = JSON.parse(response.data);
        
        for (let floorInfoFromPacket of arrGeneratedFloors)
        {
          let floor: Floor = new Floor(floorInfoFromPacket);
          project.floors.set(floor.floor_id, floor);
        }

        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public CreateNewFloor(projectID: string, floorInfoDict: Map<string, any>): Observable<Floor>
  {
    let rawFloorDict = this.dictionaryUtilitiesService.GetRawDictionary(floorInfoDict);

    let params = {"projectID": projectID};
    params["floor"] = JSON.stringify(rawFloorDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let floorInfoFromPacket = JSON.parse(response.data);
        let floor: Floor = new Floor(floorInfoFromPacket);
        return floor;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewHome(projectID: string, homeInfoDict: Map<string, any>, shouldInitElevations: boolean = false): Observable<Home | Map<string, any>>
  {
    let rawHomeDict = this.dictionaryUtilitiesService.GetRawDictionary(homeInfoDict);

    let params = {"projectID": projectID};
    params["home"] = JSON.stringify(rawHomeDict);
    params["shouldInitElevations"] = shouldInitElevations;

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let responseData = JSON.parse(response.data);
        
        let startElevation: Elevation = null;
        if (responseData.hasOwnProperty("elevation"))
        {
          let elevationInfoFromPacket = JSON.parse(responseData.elevation);
          startElevation = new Elevation(elevationInfoFromPacket);
        }

        if (responseData.hasOwnProperty("home"))
        {
          let homeInfoFromPacket = JSON.parse(responseData.home);
          let home: Home = new Home(homeInfoFromPacket);
          
          if (startElevation != null)
          {
            let returnMap: Map<string, any> = new Map<string, any>();
            returnMap.set("home", home);
            returnMap.set("elevation", startElevation);
            
            return returnMap;
          }
          else
          {
            return home;
          }
        }
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewLinker(projectID: string, linkerInfoDict: Map<string, any>): Observable<Linker>
  {
    let rawLinkerDict = this.dictionaryUtilitiesService.GetRawDictionary(linkerInfoDict);

    let params = {"projectID": projectID};
    params["linker"] = JSON.stringify(rawLinkerDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let linkerInfoFromPacket = JSON.parse(response.data);
        let linker: Linker = new Linker(linkerInfoFromPacket);
        return linker;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewLot(projectID: string, lotInfoDict: Map<string, any>): Observable<Lot>
  {
    let rawLotDict = this.dictionaryUtilitiesService.GetRawDictionary(lotInfoDict);

    let params = {"projectID": projectID};
    params["lot"] = JSON.stringify(rawLotDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let lotInfoFromPacket = JSON.parse(response.data);
        let lot: Lot = new Lot(lotInfoFromPacket);
        return lot;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public CreateNewPhase(projectID: string, phaseInfoDict: Map<string, any>): Observable<Phase>
  {
    let rawPhaseDict = this.dictionaryUtilitiesService.GetRawDictionary(phaseInfoDict);

    let params = {"projectID": projectID};
    params["phase"] = JSON.stringify(rawPhaseDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let phaseInfoFromPacket = JSON.parse(response.data);
        let phase: Phase = new Phase(phaseInfoFromPacket);
        return phase;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public CreateNewModel(projectID: string, modelInfoDict: Map<string, any>): Observable<Model>
  {
    let rawModelDict = this.dictionaryUtilitiesService.GetRawDictionary(modelInfoDict);

    let params = {"projectID": projectID};
    params["model"] = JSON.stringify(rawModelDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelInfoFromPacket = JSON.parse(response.data);
        let model: Model = new Model(modelInfoFromPacket);
        return model;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewModelType(projectID: string, modelTypeInfoDict: Map<string, any>): Observable<ModelType>
  {
    let rawModelTypeDict = this.dictionaryUtilitiesService.GetRawDictionary(modelTypeInfoDict);

    let params = {"projectID": projectID};
    params["modelType"] = JSON.stringify(rawModelTypeDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelTypeInfoFromPacket = JSON.parse(response.data);
        let modelType: ModelType = new ModelType(modelTypeInfoFromPacket);
        return modelType;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewUnit(projectID: string, unitInfoDict: Map<string, any>): Observable<Unit>
  {
    let rawUnitDict = this.dictionaryUtilitiesService.GetRawDictionary(unitInfoDict);

    let params = {"projectID": projectID};
    params["unit"] = JSON.stringify(rawUnitDict);

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let unitInfoFromPacket = JSON.parse(response.data);
        let unit: Unit = new Unit(unitInfoFromPacket);
        return unit;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public CreateNewWorksheet(project: Project, worksheetInfoDict: Map<string, any>): Observable<boolean>
  {
    let rawWorksheetDict = this.dictionaryUtilitiesService.GetRawDictionary(worksheetInfoDict);
    let params = {"projectID": project.project_id, "worksheetInfoDict": JSON.stringify(rawWorksheetDict)};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_worksheets`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let responseDataDict = JSON.parse(response.data);

        let worksheetInfoDict = responseDataDict["worksheet"];
        let worksheet: Worksheet = new Worksheet(worksheetInfoDict);
        project.worksheets.set(worksheet.worksheet_id, worksheet);

        if (responseDataDict.hasOwnProperty("purchasers"))
        {
          let arrPurchasersData = JSON.parse(responseDataDict["purchasers"]);
          for (let purchaserDataDict of arrPurchasersData)
          {
            let purchaser: Purchaser = new Purchaser(purchaserDataDict);
            project.purchasers.set(purchaser.purchaser_id, purchaser);
          }
        }

        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public CreateNewContractTemplate(project: Project, contract_template_pdf_name: string, contract_template_pdf_data): Observable<boolean>
  {
    let params = {"company_id": project.company_id, "contract_template_pdf_name": contract_template_pdf_name, "contract_template_pdf_data": contract_template_pdf_data};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let responseDataDict = JSON.parse(response.data);
        let contract: ContractTemplate = new ContractTemplate(responseDataDict);
        project.contract_templates.set(contract.contract_template_id, contract);

        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public CreateNewContract(project: Project, worksheet_id: number, contract_pdf_name: string, contract_pdf_data: string): Observable<boolean>
  {
    let params = {"project_id": project.project_id, "worksheet_id": worksheet_id, "contract_pdf_name": contract_pdf_name, "contract_pdf_data": contract_pdf_data };

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let responseDataDict = JSON.parse(response.data);
        let contract: Contract = new Contract(responseDataDict);
        project.contracts.set(contract.contract_id, contract);

        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public CreateNewFloorplanDocuments(project: Project, documentSelectionData: Map<string, string>): Observable<boolean>
  {
    let objDocumentSelection = this.dictionaryUtilitiesService.GetRawDictionary(documentSelectionData);
    let params = {"project_id": project.project_id, "document_selection_data": JSON.stringify(objDocumentSelection)};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let responseData = JSON.parse(response.data);

        for (let newDocument of responseData)
        {
          let floorplanDocument: FloorplanDocument = new FloorplanDocument(newDocument);
          project.floorplanDocuments.set(floorplanDocument.document_id, floorplanDocument);
        }

        return true;
      }

      return false;
    }));
  }

  /*
    Currently undecided on return type.
    For now, return a Map<string, string> containing the new project 
    and database names as a sort of confirmation that the change went
    through okay. It may be wasteful to pull and return a whole new Project
    instance when all that changed was two strings, so perhaps we could get
    away with simply changing them on the front-end provided we wait for
    the server callback to complete. In a sense, we'd be asking the server
    for permission to change the value of our variables here.

  */
  public ChangeProjectName(projectID: string, oldProjectName: string, newProjectName: string): Observable<Map<string, string>>
  {
    let params = {"projectID": projectID, "projectName": oldProjectName, "newProjectName": newProjectName};
    
    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let responseDataMap: Map<string, string> = new Map<string, string>();
        let responseData = JSON.parse(response.data);

        if (responseData.hasOwnProperty("project_name")) { responseDataMap.set("project_name", responseData.project_name); }
        if (responseData.hasOwnProperty("database_name")) { responseDataMap.set("database_name", responseData.database_name); }

        return responseDataMap;
      }
      else
      {
        console.log(response); 
      }

      return null;
    }));
  }

  public ChangeProjectCompany(projectID: string, newProjectCompanyID: string): Observable<Map<string, string>>
  {
    let params = {"projectID": projectID, "companyID": newProjectCompanyID};
    
    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let responseDataMap: Map<string, string> = new Map<string, string>();
        let responseData = JSON.parse(response.data);

        if (responseData.hasOwnProperty("company_id")) { responseDataMap.set("company_id", responseData.company_id); }

        return responseDataMap;
      }
      else
      {
        console.log(response); 
      }

      return null;
    }));
  }

  public UpdateProjectProductTypesAndModules(projectID: string, updateInfodict: Map<string, any>): Observable<Project>
  {
    let rawUpdateInfoDict = this.dictionaryUtilitiesService.GetRawDictionary(updateInfodict);
    let params = {"projectID": projectID, "updateInfoDict": JSON.stringify(rawUpdateInfoDict)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }
      {
        let projectInfoFromPacket = JSON.parse(response.data);
        let project: Project = new Project(projectInfoFromPacket);
        return project;
      }
    }));
  }

  public UpdateProjectBuilding(projectID: string, buildingID: number, updateInfoDict: Map<string, any>): Observable<Building>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "buildingID": buildingID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let buildingInfoFromPacket = JSON.parse(response.data);
        let building: Building = new Building(buildingInfoFromPacket);
        return building;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectElevation(projectID: string, elevationID: number, updateInfoDict: Map<string, any>): Observable<Elevation>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "elevationID": elevationID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let elevationInfoFromPacket = JSON.parse(response.data);
        let elevation: Elevation = new Elevation(elevationInfoFromPacket);
        return elevation;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectFloor(projectID: string, floorID: number, updateInfoDict: Map<string, any>): Observable<Floor>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "floorID": floorID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let floorInfoFromPacket = JSON.parse(response.data);
        let floor: Floor = new Floor(floorInfoFromPacket);
        return floor;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectHome(projectID: string, homeID: number, updateInfoDict: Map<string, any>): Observable<Home>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "homeID": homeID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let homeInfoFromPacket = JSON.parse(response.data);
        let home: Home = new Home(homeInfoFromPacket);
        return home;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectLinker(projectID: string, linkerID: number, updateInfoDict: Map<string, any>): Observable<Linker>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "linkerID": linkerID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let linkerInfoFromPacket = JSON.parse(response.data);
        let linker: Linker = new Linker(linkerInfoFromPacket);
        return linker;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectLot(projectID: string, lotID: number, updateInfoDict: Map<string, any>): Observable<Lot>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "lotID": lotID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let lotInfoFromPacket = JSON.parse(response.data);
        let lot: Lot = new Lot(lotInfoFromPacket);
        return lot;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public UpdateProjectPhase(projectID: string, phaseID: number, updateInfoDict: Map<string, any>): Observable<Phase>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "phaseID": phaseID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let phaseInfoFromPacket = JSON.parse(response.data);
        let phase: Phase = new Phase(phaseInfoFromPacket);
        return phase;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public UpdateProjectModel(projectID: string, modelID: number, updateInfoDict: Map<string, any>): Observable<Model>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "modelID": modelID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelInfoFromPacket = JSON.parse(response.data);
        let model: Model = new Model(modelInfoFromPacket);
        return model;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectModelType(projectID: string, modelTypeID: number, updateInfoDict: Map<string, any>): Observable<ModelType>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "modelTypeID": modelTypeID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelTypeInfoFromPacket = JSON.parse(response.data);
        let modelType: ModelType = new ModelType(modelTypeInfoFromPacket);
        return modelType;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectUnit(projectID: string, unitID: number, updateInfoDict: Map<string, any>): Observable<Unit>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "unitID": unitID, "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let unitInfoFromPacket = JSON.parse(response.data);
        let unit: Unit = new Unit(unitInfoFromPacket);
        return unit;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectSelectedUnits(projectID: string, selectedUnitIDs: Array<number>, updateInfoDict: Map<string, any>): Observable<Array<Unit>>
  {
    let updateInfoRaw = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": projectID, "selectedUnitIDs": JSON.stringify(selectedUnitIDs), "updateInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let unitsArray: Array<Unit> = new Array<Unit>();

        let unitsJSON = JSON.parse(response.data);
        for (let unitJSON of unitsJSON)
        {
          let unitInfoFromPacket = JSON.parse(unitJSON);
          let unit: Unit = new Unit(unitInfoFromPacket);
          unitsArray.push(unit);
        }

        return unitsArray;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateProjectWorksheetFormData(project: Project, worksheetID: number, updateInfoDict: Map<string, any>): Observable<boolean>
  {
    let rawUpdateDict = this.dictionaryUtilitiesService.GetRawDictionary(updateInfoDict);
    let params = {"projectID": project.project_id, "worksheetID": worksheetID, "updateInfoDict": JSON.stringify(rawUpdateDict)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_worksheets`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        // Delete old purchasers before applying the incoming update
        if (project.worksheets.get(worksheetID).str_purchaser_ids != null && project.worksheets.get(worksheetID).str_purchaser_ids.length > 0)
        {
          let arrPurchaserIDs = project.worksheets.get(worksheetID).str_purchaser_ids.split(",");
          for (let purchaserID of arrPurchaserIDs)
          {
            let purchaserIDTrim = purchaserID.trim();
            project.purchasers.delete(purchaserIDTrim);
          }
        }

        let responseDataDict = JSON.parse(response.data);

        let updatedWorksheet: Worksheet = new Worksheet(responseDataDict["worksheet"]);
        project.worksheets.set(worksheetID, updatedWorksheet);

        for (let purchaserDataDict of responseDataDict["purchasers"])
        {
          let updatedPurchaser: Purchaser = new Purchaser(purchaserDataDict);
          project.purchasers.set(updatedPurchaser.purchaser_id, updatedPurchaser);
        }

        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public UpdateProjectWorksheetStatus(project: Project, worksheetID: number, newStatus: EWorksheetStatus): Observable<boolean>
  {
    let params = {"projectID": project.project_id, "worksheetID": worksheetID, "updateInfoDict": JSON.stringify({"worksheet_status": newStatus.toString()})};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_worksheets`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        project.worksheets.get(worksheetID).worksheet_status = newStatus;
        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public UpdateContractTemplate(project: Project, contract_template_id: number, contract_template_pdf_name: string, contract_template_pdf_data: string): Observable<boolean>
  {
    let params = {"company_id": project.company_id, "contract_template_id": contract_template_id, "contract_template_pdf_name": contract_template_pdf_name, "contract_template_pdf_data": contract_template_pdf_data};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let contractInfoFromPacket = JSON.parse(response.data);
        let updatedContract: ContractTemplate = new ContractTemplate(contractInfoFromPacket);

        project.contract_templates.set(contract_template_id, updatedContract);
        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public UpdateProjectContract(project: Project, contract_id: number, contract_pdf_name: string, contract_pdf_data: string): Observable<boolean>
  {
    let params = {"project_id": project.project_id, "contract_id": contract_id, "contract_pdf_name": contract_pdf_name, "contract_pdf_data": contract_pdf_data};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        let contractInfoFromPacket = JSON.parse(response.data);
        let updatedContract: Contract = new Contract(contractInfoFromPacket);

        project.contracts.set(contract_id, updatedContract);
        return true;
      }
      else
      {
        console.log(response);
      }

      return false;
    }));
  }

  public DeleteProject(projectID: string, deleteProjectDatabase: boolean = false): Observable<Map<string, Project>>
  {
    let params = {"projectID": projectID, "deleteProjectDatabase": String(deleteProjectDatabase)};
    
    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectsResponse(response);
    }));
  }

  public DeleteProjects(projectsSelection: Map<string, boolean>): Observable<Map<string, Project>>
  {
    let rawSelectionDict = this.dictionaryUtilitiesService.GetRawDictionary(projectsSelection);
    let params = {"selection": JSON.stringify(rawSelectionDict)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectsResponse(response);
    }));
  }

  public DeleteBuildingsFromProject(projectID: string, buildingIDs: Array<number>): Observable<Map<number, Building>>
  {
    let params = {"projectID": projectID, "buildingIDs": JSON.stringify(buildingIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let buildingsDict: Map<number, Building> = new Map<number, Building>();

        let buildingsJSON = JSON.parse(response.data);
        for (let buildingJSON of buildingsJSON)
        {
          let buildingInfoFromPacket = JSON.parse(buildingJSON);
          let building: Building = new Building(buildingInfoFromPacket);
          buildingsDict.set(building.building_id, building);
        }

        return buildingsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteElevationsFromProject(projectID: string, elevationIDs: Array<number>): Observable<Map<number, Elevation>>
  {
    let params = {"projectID": projectID, "elevationIDs": JSON.stringify(elevationIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let elevationsDict: Map<number, Elevation> = new Map<number, Elevation>();

        let elevationsJSON = JSON.parse(response.data);
        for (let elevationJSON of elevationsJSON)
        {
          let elevationInfoFromPacket = JSON.parse(elevationJSON);
          let elevation: Elevation = new Elevation(elevationInfoFromPacket);
          elevationsDict.set(elevation.elevation_id, elevation);
        }

        return elevationsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteFloorsFromProject(projectID: string, floorIDs: Array<number>): Observable<Map<number, Floor>>
  {
    let params = {"projectID": projectID, "floorIDs": JSON.stringify(floorIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let floorsDict: Map<number, Floor> = new Map<number, Floor>();

        let floorsJSON = JSON.parse(response.data);
        for (let floorJSON of floorsJSON)
        {
          let floorInfoFromPacket = JSON.parse(floorJSON);
          let floor: Floor = new Floor(floorInfoFromPacket);
          floorsDict.set(floor.floor_id, floor);
        }

        return floorsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteHomesFromProject(projectID: string, homeIDs: Array<number>): Observable<Map<number, Home>>
  {
    let params = {"projectID": projectID, "homeIDs": JSON.stringify(homeIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let homesDict: Map<number, Home> = new Map<number, Home>();

        let homesJSON = JSON.parse(response.data);
        for (let homeJSON of homesJSON)
        {
          let homeInfoFromPacket = JSON.parse(homeJSON);
          let home: Home = new Home(homeInfoFromPacket);
          homesDict.set(home.detached_id, home);
        }

        return homesDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteLinkersFromProject(projectID: string, linkerIDs: Array<number>): Observable<Map<number, Linker>>
  {
    let params = {"projectID": projectID, "linkerIDs": JSON.stringify(linkerIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let linkersDict: Map<number, Linker> = new Map<number, Linker>();

        let linkersJSON = JSON.parse(response.data);
        for (let linkerJSON of linkersJSON)
        {
          let linkerInfoFromPacket = JSON.parse(linkerJSON);
          let linker: Linker = new Linker(linkerInfoFromPacket);
          linkersDict.set(linker.linker_id, linker);
        }

        return linkersDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteLotsFromProject(projectID: string, lotIDs: Array<number>): Observable<Map<number, Lot>>
  {
    let params = {"projectID": projectID, "lotIDs": JSON.stringify(lotIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let lotsDict: Map<number, Lot> = new Map<number, Lot>();

        let lotsJSON = JSON.parse(response.data);
        for (let lotJSON of lotsJSON)
        {
          let lotInfoFromPacket = JSON.parse(lotJSON);
          let lot: Lot = new Lot(lotInfoFromPacket);
          lotsDict.set(lot.lot_id, lot);
        }

        return lotsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public DeletePhasesFromProject(projectID: string, phaseIDs: Array<number>): Observable<Map<number, Phase>>
  {
    let params = {"projectID": projectID, "phaseIDs": JSON.stringify(phaseIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let phasesDict: Map<number, Phase> = new Map<number, Phase>();

        let phasesJSON = JSON.parse(response.data);
        for (let phaseJSON of phasesJSON)
        {
          let phaseInfoFromPacket = JSON.parse(phaseJSON);
          let phase: Phase = new Phase(phaseInfoFromPacket);
          phasesDict.set(phase.phase_id, phase);
        }

        return phasesDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }
  public DeleteModelsFromProject(projectID: string, modelIDs: Array<number>): Observable<Map<number, Model>>
  {
    let params = {"projectID": projectID, "modelIDs": JSON.stringify(modelIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelsDict: Map<number, Model> = new Map<number, Model>();

        let modelsJSON = JSON.parse(response.data);
        for (let modelJSON of modelsJSON)
        {
          let modelInfoFromPacket = JSON.parse(modelJSON);
          let model: Model = new Model(modelInfoFromPacket);
          modelsDict.set(model.model_id, model);
        }

        return modelsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteModelTypesFromProject(projectID: string, modelTypeIDs: Array<number>): Observable<Map<number, ModelType>>
  {
    let params = {"projectID": projectID, "modelTypeIDs": JSON.stringify(modelTypeIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let modelTypesDict: Map<number, ModelType> = new Map<number, ModelType>();

        let modelTypesJSON = JSON.parse(response.data);
        for (let modelTypeJSON of modelTypesJSON)
        {
          let modelTypeInfoFromPacket = JSON.parse(modelTypeJSON);
          let modelType: ModelType = new ModelType(modelTypeInfoFromPacket);
          modelTypesDict.set(modelType.model_type_id, modelType);
        }

        return modelTypesDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteUnitsFromProject(projectID: string, unitIDs: Array<number>): Observable<Map<number, Unit>>
  {
    let params = {"projectID": projectID, "unitIDs": JSON.stringify(unitIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let unitsDict: Map<number, Unit> = new Map<number, Unit>();

        let unitsJSON = JSON.parse(response.data);
        for (let unitJSON of unitsJSON)
        {
          let unitInfoFromPacket = JSON.parse(unitJSON);
          let unit: Unit = new Unit(unitInfoFromPacket);
          unitsDict.set(unit.unit_id, unit);
        }

        return unitsDict;
      }
      else
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteWorksheetsFromProject(project: Project, worksheetIDs: Array<number>): Observable<boolean>
  {
    let params = {"projectID": project.project_id, "selectedWorksheetIDs": JSON.stringify(worksheetIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_worksheets`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        for (let worksheetID of worksheetIDs)
        {
          let worksheetToDelete: Worksheet = project.worksheets.get(worksheetID);
          if (worksheetToDelete.str_purchaser_ids !== null && worksheetToDelete.str_purchaser_ids !== "")
          {
            let arrPurchaserIDs = worksheetToDelete.str_purchaser_ids.split(",");
            
            for (let purchaserID of arrPurchaserIDs)
            {
              let purchaserIDTrim = purchaserID.trim();
              if (project.purchasers.has(purchaserIDTrim))
              {
                project.purchasers.delete(purchaserIDTrim);
              }
            }
          }

          project.worksheets.delete(worksheetID);
        }

        return true;
      }

      return false;
    }));
  }

  public DeleteContractTemplates(project: Project, contractIDs: Array<number>): Observable<boolean>
  {
    let params = {"company_id": project.company_id, "contract_template_ids": JSON.stringify(contractIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        for (let contractID of contractIDs)
        {
          project.contract_templates.delete(contractID);
        }

        return true;
      }

      return false;
    }));
  }

  public DeleteContractsFromProject(project: Project, contractIDs: Array<number>): Observable<boolean>
  {
    let params = {"project_id": project.project_id, "contract_ids": JSON.stringify(contractIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_contracts`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        for (let contractID of contractIDs)
        {
          project.contracts.delete(contractID);
        }

        return true;
      }

      return false;
    }));
  }

  public DeleteFloorplanDocumentsFromProject(project: Project, documentIDs: Array<string>): Observable<boolean>
  {
    let params = {"project_id": project.project_id, "document_ids": JSON.stringify(documentIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        for (let documentID of documentIDs)
        {
          project.floorplanDocuments.delete(documentID);
        }

        return true;
      }

      return false;
    }));
  }

  public GetAllProjects(): Observable<Map<string, Project>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectsResponse(response);
    }));
  }

  public GetProjectsByCompanyID(companyID: string): Observable<Map<string, Project>>
  {
    let params = new HttpParams().set("companyID", companyID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectsResponse(response);
    }));
  }

  public GetProjectsByUserID(userID: string): Observable<Map<string, Project>>
  {
    let params = new HttpParams().set("userID", userID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectsResponse(response);
    }));
  }

  public GetProjectLastUpdated(project: Project): Observable<boolean>
  {
    if (project == null) { return; }

    let params = new HttpParams().set("projectID", project.project_id).set("GetTimeLastUpdated", "true");

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == 'success')
      {
        let responseJSON = JSON.parse(response.data);
        if (responseJSON.hasOwnProperty("time_last_updated"))
        {
          project.time_last_updated = moment(responseJSON["time_last_updated"]);
          return true;
        }
      }
      else if (response.status == 'error')
      {
        console.log(response);
      }

      return false;
    }));
  }

  public GetMetadataFor(project: Project): Observable<boolean>
  {
    if (project == null) { return; }

    let params = new HttpParams().set("projectID", project.project_id).set("companyID", project.company_id);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/projects`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectMetadataResponse(response, project);
    }));
  }

  private ParseGetProjectsResponse(response: WebCMSAPIResponse): Map<string, Project>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let projectsDict: Map<string, Project> = new Map<string, Project>();
      let responseDataJSON = JSON.parse(response.data);

      for (let projectJSON of responseDataJSON)
      {
        let projectInfoFromPacket = JSON.parse(projectJSON);
        let project: Project = new Project(projectInfoFromPacket);
        projectsDict.set(project.project_id, project);
      }

      return projectsDict;
    }
    else if (response.status == "error")
    {
      // TODO - More intelligent error handling / in-app presentation?
      console.log(response);
    }

    return null;
  }

  public ParseGetProjectMetadataResponse(response: WebCMSAPIResponse, project: Project): boolean
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let metadataInfoFromPacket = JSON.parse(response.data);

      if (metadataInfoFromPacket.hasOwnProperty("time_last_updated"))
      {
        project.time_last_updated = moment(metadataInfoFromPacket.time_last_updated);
      }

      project.buildings = new Map<number, Building>();
      if (metadataInfoFromPacket.hasOwnProperty("buildings"))
      {
          let projectBuildingsJSON = JSON.parse(metadataInfoFromPacket.buildings);

          for (let buildingJSON of projectBuildingsJSON)
          {
              let buildingInfoFromPacket = JSON.parse(buildingJSON);
              let building: Building = new Building(buildingInfoFromPacket);
              project.buildings.set(building.building_id, building);
          }
      }
      
      project.elevations = new Map<number, Elevation>();
      if (metadataInfoFromPacket.hasOwnProperty("elevations"))
      {
          let projectElevationsJSON = JSON.parse(metadataInfoFromPacket.elevations);
          
          for (let elevationJSON of projectElevationsJSON)
          {
              let elevationInfoFromPacket = JSON.parse(elevationJSON);
              let elevation: Elevation = new Elevation(elevationInfoFromPacket);
              project.elevations.set(elevation.elevation_id, elevation);
          }
      }

      project.floors = new Map<number, Floor>();
      if (metadataInfoFromPacket.hasOwnProperty("floors"))
      {
          let projectFloorsJSON = JSON.parse(metadataInfoFromPacket.floors);
          
          for (let floorJSON of projectFloorsJSON)
          {
              let floorInfoFromPacket = JSON.parse(floorJSON);
              let floor: Floor = new Floor(floorInfoFromPacket);
              project.floors.set(floor.floor_id, floor);
          }
      }

      project.homes = new Map<number, Home>();
      if (metadataInfoFromPacket.hasOwnProperty("homes"))
      {
          let projectHomesJSON = JSON.parse(metadataInfoFromPacket.homes);

          for (let homeJSON of projectHomesJSON)
          {
              let homeInfoFromPacket = JSON.parse(homeJSON);
              let home: Home = new Home(homeInfoFromPacket);
              project.homes.set(home.detached_id, home);
          }
      }

      project.linkers = new Map<number, Linker>();
      if (metadataInfoFromPacket.hasOwnProperty("linkers"))
      {
          let projectLinkersJSON = JSON.parse(metadataInfoFromPacket.linkers);

          for (let linkerJSON of projectLinkersJSON)
          {
              let linkerInfoFromPacket = JSON.parse(linkerJSON);
              let linker: Linker = new Linker(linkerInfoFromPacket);
              project.linkers.set(linker.linker_id, linker);
          }
      }

      project.lots = new Map<number, Lot>();
      if (metadataInfoFromPacket.hasOwnProperty("lots"))
      {
          let projectLotsJSON = JSON.parse(metadataInfoFromPacket.lots);

          for (let lotJSON of projectLotsJSON)
          {
              let lotInfoFromPacket = JSON.parse(lotJSON);
              let lot: Lot = new Lot(lotInfoFromPacket);
              project.lots.set(lot.lot_id, lot);
          }
      }

      project.phases = new Map<number, Phase>();
      if (metadataInfoFromPacket.hasOwnProperty("phases"))
      {
          let projectPhasesJSON = JSON.parse(metadataInfoFromPacket.phases);

          for (let phaseJSON of projectPhasesJSON)
          {
              let phaseInfoFromPacket = JSON.parse(phaseJSON);
              let phase: Phase = new Phase(phaseInfoFromPacket);
              project.phases.set(phase.phase_id, phase);
          }
      }

      project.model_types = new Map<number, ModelType>();
      if (metadataInfoFromPacket.hasOwnProperty("model_types"))
      {
          let projectModelTypesJSON = JSON.parse(metadataInfoFromPacket.model_types);

          for (let modelTypeJSON of projectModelTypesJSON)
          {
              let modelTypeInfoFromPacket = JSON.parse(modelTypeJSON);
              let modelType: ModelType = new ModelType(modelTypeInfoFromPacket);
              project.model_types.set(modelType.model_type_id, modelType);
          }
      }

      project.models = new Map<number, Model>();
      if (metadataInfoFromPacket.hasOwnProperty("models"))
      {
          let projectModelsJSON = JSON.parse(metadataInfoFromPacket.models);

          for (let modelJSON of projectModelsJSON)
          {
              let modelInfoFromPacket = JSON.parse(modelJSON);
              let model: Model = new Model(modelInfoFromPacket);
              project.models.set(model.model_id, model);
          }
      }

      project.units = new Map<number, Unit>();
      if (metadataInfoFromPacket.hasOwnProperty("units"))
      {
          let projectUnitsJSON = JSON.parse(metadataInfoFromPacket.units);

          for (let unitJSON of projectUnitsJSON)
          {
              let unitInfoFromPacket = JSON.parse(unitJSON);
              let unit: Unit = new Unit(unitInfoFromPacket);
              project.units.set(unit.unit_id, unit);
          }
      }

      project.analytics = new Map<EAnalyticType, Array<ProjectAnalytic>>();
      for (let analyticTypeID = 0; analyticTypeID < Object.keys(EAnalyticType).length; ++analyticTypeID)
      {
        project.analytics.set(analyticTypeID, new Array<ProjectAnalytic>());
      }

      if (metadataInfoFromPacket.hasOwnProperty("analytics"))
      {
        let projectAnalyticsJSON = JSON.parse(metadataInfoFromPacket.analytics);

        for (let analyticJSON of projectAnalyticsJSON)
        {
          let analyticInfoFromPacket = JSON.parse(analyticJSON);
          let analytic: ProjectAnalytic = new ProjectAnalytic(analyticInfoFromPacket);
          project.analytics.get(analytic.analytic_type).push(analytic);
        }
      }

      project.sessions = new Array<ProjectSession>();
      if (metadataInfoFromPacket.hasOwnProperty("sessions"))
      {
        let projectSessionsJSON = JSON.parse(metadataInfoFromPacket.sessions);

        for (let sessionInfoFromPacket of projectSessionsJSON)
        {
          let session: ProjectSession = new ProjectSession(sessionInfoFromPacket);
          project.sessions.push(session);
        }
      }

      project.purchasers = new Map<string, Purchaser>();
      if (metadataInfoFromPacket.hasOwnProperty("purchasers"))
      {
        let projectPurchasersJSON = JSON.parse(metadataInfoFromPacket.purchasers);

        for (let purchaserJSON of projectPurchasersJSON)
        {
          let purchaserInfoFromPacket = JSON.parse(purchaserJSON);
          let purchaser: Purchaser = new Purchaser(purchaserInfoFromPacket);
          project.purchasers.set(purchaser.purchaser_id, purchaser);
        }
      }

      project.worksheets = new Map<number, Worksheet>();
      if (metadataInfoFromPacket.hasOwnProperty("worksheets"))
      {
        let projectWorksheetsJSON = JSON.parse(metadataInfoFromPacket.worksheets);

        for (let worksheetJSON of projectWorksheetsJSON)
        {
          let worksheetInfoFromPacket = JSON.parse(worksheetJSON);
          let worksheet: Worksheet = new Worksheet(worksheetInfoFromPacket);
          project.worksheets.set(worksheet.worksheet_id, worksheet);
        }
      }

      project.defaultDepositStructure = new Array<Deposit>();
      if (metadataInfoFromPacket.hasOwnProperty("worksheet_default_deposit_structure"))
      {
        let defaultDepositStructureJSON = JSON.parse(metadataInfoFromPacket.worksheet_default_deposit_structure);

        for (let depositJSON of defaultDepositStructureJSON)
        {
          let depositInfoFromPacket = JSON.parse(depositJSON);
          let deposit: Deposit = new Deposit(depositInfoFromPacket);
          project.defaultDepositStructure.push(deposit);
        }
      }

      project.worksheetDeposits = new Map<number, Array<Deposit>>();
      if (metadataInfoFromPacket.hasOwnProperty("worksheet_deposits"))
      {
        let worksheetDepositsJSON = JSON.parse(metadataInfoFromPacket.worksheet_deposits);

        for (let depositJSON of worksheetDepositsJSON)
        {
          let depositInfoFromPacket = JSON.parse(depositJSON);
          let deposit: Deposit = new Deposit(depositInfoFromPacket);

          if (!project.worksheetDeposits.has(deposit.worksheet_id))
          {
            project.worksheetDeposits.set(deposit.worksheet_id, new Array<Deposit>());
            project.worksheetDeposits.get(deposit.worksheet_id).push(deposit);
          }
          else
          {
            project.worksheetDeposits.get(deposit.worksheet_id).push(deposit);
          }
        }
      }

      project.contract_templates = new Map<number, ContractTemplate>();
      if (metadataInfoFromPacket.hasOwnProperty("contract_templates"))
      {
        let contractTemplatesJSON = JSON.parse(metadataInfoFromPacket.contract_templates);

        for (let objContractTemplate of contractTemplatesJSON)
        {
          let template: ContractTemplate = new ContractTemplate(objContractTemplate);
          project.contract_templates.set(template.contract_template_id, template);
        }
      }

      project.contracts = new Map<number, Contract>();
      if (metadataInfoFromPacket.hasOwnProperty("contracts"))
      {
        let contractsJSON = JSON.parse(metadataInfoFromPacket.contracts);

        for (let contractJSON of contractsJSON)
        {
          let contractInfoFromPacket = JSON.parse(contractJSON);
          let contract: Contract = new Contract(contractInfoFromPacket);
          project.contracts.set(contract.contract_id, contract);
        }
      }

      project.floorplanDocuments = new Map<string, FloorplanDocument>();
      if (metadataInfoFromPacket.hasOwnProperty("floorplan_documents"))
      {
        let documentsJSON = JSON.parse(metadataInfoFromPacket.floorplan_documents);

        for (let documentInfoFromPacket of documentsJSON)
        {
          let floorplanDocument: FloorplanDocument = new FloorplanDocument(documentInfoFromPacket);
          project.floorplanDocuments.set(floorplanDocument.document_id, floorplanDocument);
        }
      }

      project.isMetadataPulled = true;
      return true;
    }
    else
    {
      console.log(response);
    }

    return false;
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { CMSComponentBase } from 'src/app/interfaces/cms-component-base';
import { AppUtilityService } from 'src/app/app-utility.service';
import { UserRole } from 'src/DataModels';
import { GenericDeleteConfirmationDialogComponent } from '../../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserRolesService } from 'src/app/user-roles.service';
import { CreateUserRoleDialogComponent } from '../dialog/create-user-role-dialog/create-user-role-dialog.component';

@Component({
  selector: 'app-user-roles-table-view',
  templateUrl: './user-roles-table-view.component.html',
  styleUrls: ['./user-roles-table-view.component.css'],
})
export class UserRolesTableViewComponent implements OnInit 
{
  private selectedUserRoleIDs: Set<string> = new Set<string>();

  constructor(public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public userRolesService: UserRolesService) 
  { 
  }

  ngOnInit() 
  {
    this.appUtilityService.SetViewTitle("User Roles");
  }

  public OnRoleCheckboxToggled(checked: boolean, roleID: string)
  {
    if (checked == true)
    {
      this.selectedUserRoleIDs.add(roleID);
    }
    else
    {
      this.selectedUserRoleIDs.delete(roleID);
    }
  }

  public OnRoleNameTapped(role: UserRole)
  {
    const dialogRef = this.dialog.open(CreateUserRoleDialogComponent, {width: "950px", height: "585px", data: { roleToEdit: role }});

    if (this.authService.activeUserRole.can_edit_user_roles)
    {
      dialogRef.afterClosed().subscribe(result => 
      {
        if (result != null)
        {
          this.userRolesService.UpdateUserRole(role.user_role_id, result).subscribe((updatedUserRole: UserRole) => 
          {
            if (updatedUserRole != null)
            {
              this.appUtilityService.userRolesDict.set(updatedUserRole.user_role_id, updatedUserRole);
              this.appUtilityService.StoreCMSData();
            }
          });
        }
      });
    }
  }

  // Nav Bar Utility Overrides 
  ShouldDisplayAddButton(): boolean 
  {
    return this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_create_user_roles;
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateUserRoleDialogComponent, {width: "950px", height: "585px", data: { }});

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.userRolesService.CreateNewUserRole(result).subscribe((newUserRole: UserRole) => 
        {
          if (newUserRole != null)
          {
            this.appUtilityService.userRolesDict.set(newUserRole.user_role_id, newUserRole);
            this.appUtilityService.StoreCMSData();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    return this.selectedUserRoleIDs.size > 0 && this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_delete_user_roles;
  }

  OnDeleteButtonPressed(): void 
  {
    if (this.selectedUserRoleIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete User Roles?" }});

      dialogRef.afterClosed().subscribe(result => 
      {
        if (result == true)
        {
          this.userRolesService.DeleteUserRoles(Array.from(this.selectedUserRoleIDs)).subscribe((result: Map<string, UserRole>) => 
          {
            if (result != null)
            {
              this.appUtilityService.userRolesDict = result;
              this.appUtilityService.StoreCMSData();
            }            
          });
        }
      });
    }
  }
}

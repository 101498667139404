import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Company } from 'src/DataModels';
import { NewCompanyDialogData } from 'src/app/admin/companies-table-view/companies-table-view.component';
import { FormControl, Validators } from '@angular/forms';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.css']
})
export class CreateCompanyDialogComponent implements OnInit 
{
  companyNameControl: FormControl = new FormControl('', Validators.required);

  constructor(public dialogRef: MatDialogRef<CreateCompanyDialogComponent>, private appUtilityService: AppUtilityService, @Inject(MAT_DIALOG_DATA) public data: NewCompanyDialogData) { }

  public GetCompanyDialogTitle(): string
  {
    if (this.data.companyToEdit != null)
    {
      return "Edit Company";
    }
    else
    {
      return "New Company";
    }
  }

  ngOnInit() 
  {
    if (this.data.companyToEdit != null)
    {
      this.companyNameControl.setValue(this.data.companyToEdit.company_name);
    }
  }

  GetCompanyNameFieldErrorMessage(): string
  {
    if (this.companyNameControl.hasError('required'))
    {
      return "You must enter a company name";
    }
    else if (this.companyNameControl.hasError('companyNameTaken'))
    {
      return this.companyNameControl.value + " is already in use by another company";
    }
  }

  OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  OnSaveButtonClicked(): void
  {
    let isCompanyNameValid: boolean = true;

    // First make sure the user actually entered a company name
    if (this.companyNameControl.value == "")
    {
      this.companyNameControl.setErrors({required: true});
      isCompanyNameValid = false;
      return;
    }
    
    // Next, make sure the company name is unique amongst those already registered.
    this.appUtilityService.companiesDict.forEach((value: Company, key: string) => 
    {
      if (value.company_name == this.companyNameControl.value)
      {
        this.companyNameControl.setErrors({companyNameTaken: true});
        isCompanyNameValid = false;

        // Return inside an Array.forEach only breaks out of the forEach callback
        // i.e. IT DOES NOT RETURN FROM THE CONTAINING FUNCTION ITSELF
        return;
      }
    });

    if (isCompanyNameValid)
    {
      // Finally, if input is valid, return the value to the view before
      this.dialogRef.close(this.companyNameControl.value);
    }
  }
}
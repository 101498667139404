import { Component, OnInit, Host, Input } from '@angular/core';
import { Project } from 'src/DataModels';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-worksheet-notes-module',
  templateUrl: './worksheet-notes-module.component.html',
  styleUrls: ['./worksheet-notes-module.component.css']
})
export class WorksheetNotesModuleComponent implements OnInit 
{
  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  public notesField: FormControl = new FormControl();

  constructor() { }

  ngOnInit() 
  {
  }
}

import { Component, OnInit, Input, AfterViewInit, ViewChild, Host, ChangeDetectorRef } from '@angular/core';
import { Project, FloorplanDocument, ProjectRole } from 'src/DataModels';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { CreateFloorplanDocumentDialogComponent } from '../dialog/create-floorplan-document-dialog/create-floorplan-document-dialog.component';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-floorplans-table-view',
  templateUrl: './floorplans-table-view.component.html',
  styleUrls: ['./floorplans-table-view.component.css']
})
export class FloorplansTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<FloorplanDocument>;
  @ViewChild('floorplansTablePaginator', {read: MatPaginator, static: false}) paginator: MatPaginator;

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public displayedColumns = ['Checkbox', 'Document Name', 'Date Created'];

  public floorplanDocumentsDataSource: MatTableDataSource<FloorplanDocument> = new MatTableDataSource<FloorplanDocument>();
  public selectedFloorplanDocumentIDs: Set<string> = new Set<string>();

  constructor(@Host() public parent: ProjectDetailsTableViewComponent, public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef) 
  {
    super();
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.floorplanDocumentsDataSource.paginator = this.paginator;
    this.RenderTableRows();
  }

  FormatDateCreated(floorplanDocument: FloorplanDocument): string
  {
    if (floorplanDocument.date_created != null)
    {
      let timestamp = moment(floorplanDocument.date_created);
      return timestamp.format("LLLL");
    }

    return "";
  }

  OnSelectAllCheckboxChanged(checked: boolean): void
  {
    if (checked)
    {
      for (let document of this.floorplanDocumentsDataSource.data)
      {
        this.selectedFloorplanDocumentIDs.add(document.document_id);
      }
    }
    else
    {
      this.selectedFloorplanDocumentIDs.clear();
    }
  }

  OnFloorplanDocumentCheckboxChanged(checked: boolean, floorplanDocumentID: string): void
  {
    if (checked == true)
    {
      this.selectedFloorplanDocumentIDs.add(floorplanDocumentID);
    }
    else
    {
      this.selectedFloorplanDocumentIDs.delete(floorplanDocumentID);
    }
  }

  RenderTableRows(): void 
  {
    if (this.project.floorplanDocuments.size > 0)
    {
      let documents = Array.from(this.project.floorplanDocuments.values());
      this.floorplanDocumentsDataSource.data = documents;
    }
    else
    {
      this.floorplanDocumentsDataSource = new MatTableDataSource<FloorplanDocument>();
    }

    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_manage_floorplans;
      }
    }

    return false;
  }

  ShouldDisplayEditButton(): boolean 
  {
    return false;
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data && this.selectedFloorplanDocumentIDs.size > 0;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_manage_floorplans && this.selectedFloorplanDocumentIDs.size > 0;
      }
    }

    return false;
  }

  OnAddButtonPressed() 
  {
    const dialogRef = this.dialog.open(CreateFloorplanDocumentDialogComponent, { width: '350px', height: '360px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result === true)
      {
        this.RenderTableRows();
      }
    });
  }

  OnEditButtonPressed(row: any) 
  {
  }

  OnDeleteButtonPressed() 
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '300px', height: '200px', data: { title: "Delete Floorplan Documents?" }});
    
    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result === true)
      {
        this.projectsService.DeleteFloorplanDocumentsFromProject(this.project, Array.from(this.selectedFloorplanDocumentIDs)).subscribe((result: boolean) =>
        {
          if (result === true)
          {
            this.RenderTableRows();
          }
        });
      }
    });
  }

  HasSelectedRows(): boolean 
  {
    return this.selectedFloorplanDocumentIDs.size > 0;
  }

  GetSelectedRows(): Set<string | number> 
  {
    return this.selectedFloorplanDocumentIDs;
  }

  ClearSelectedRows(): void 
  {
    this.selectedFloorplanDocumentIDs.clear();
  }
}

import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Project, Lot, Linker, Phase, EAvailabilityCode, ProjectRole } from 'src/DataModels';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { CreateLinkerDialogComponent } from '../dialog/create-linker-dialog/create-linker-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { Observable, of, forkJoin } from 'rxjs';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { CreateWorksheetDialogComponent } from '../dialog/worksheets/create-worksheet-dialog/create-worksheet-dialog.component';

@Component({
  selector: 'app-linkers-table-view',
  templateUrl: './linkers-table-view.component.html',
  styleUrls: ['./linkers-table-view.component.css']
})
export class LinkersTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Linker>
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  private clientColumns = ['Home Name', 'Elevation Type', 'Availability'];
  private adminColumns = ['Checkbox', 'Home Name', 'Elevation Type', 'Availability', 'Edit'];

  public linkersDataSource: MatTableDataSource<Linker> = new MatTableDataSource<Linker>();
  public selectedLinkerIDs: Set<number> = new Set<number>();

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project()
  {
    return this._project;
  }

  private _lot: Lot = null;

  @Input()
  set lot(lot: Lot)
  {
    this._lot = lot;
  }

  get lot()
  {
    return this._lot;
  }

  private _phase: Phase = null;
  @Input()
  set phase(phase: Phase)
  {
    this._phase = phase;
  }

  get phase()
  {
    return this._phase;
  }

  constructor(@Host() private parent: CMSTableViewBase, private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef) 
  {
    super();
  }

  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;    
  }

  public RenderTableRows()
  {
    if (this.lot == null) { return; }

    let linkers = this.GetLotLinkersIterator();
    linkers = linkers.sort((l1, l2) => 
    {
      let l1Name = this.GetHomeNameFromLinker(l1).toLowerCase();
      let l2Name = this.GetHomeNameFromLinker(l2).toLowerCase();

      if (l1Name < l2Name)
      {
        return -1;
      }
      else if (l1Name > l2Name)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.linkersDataSource.data = linkers;
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
  }

  public GetLotLinkersIterator(): Array<Linker>
  {
    let lotLinkers: Array<Linker> = new Array<Linker>();

    for (let linker of this.project.linkers.values())
    {
      if (linker.lot_id == this.lot.lot_id)
      {
        lotLinkers.push(linker);
      }
    }

    return lotLinkers;
  }

  public GetHomeNameFromLinker(linker: Linker): string
  {
    if (this.project.elevations.has(linker.elevation_id))
    {
      let elevation = this.project.elevations.get(linker.elevation_id);
      if (this.project.homes.has(elevation.detached_id))
      {
        let home = this.project.homes.get(elevation.detached_id);
        return home.name;
      }
    }
  }

  public GetElevationTypeFromLinker(linker: Linker): string
  {
    if (this.project.elevations.has(linker.elevation_id))
    {
      let elevation = this.project.elevations.get(linker.elevation_id);
      return elevation.elevation;
    }
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }

  ngOnInit() 
  {
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let linker of this.linkersDataSource.filteredData)
      {
        this.selectedLinkerIDs.add(linker.linker_id);
      }
    }
    else
    {
      this.selectedLinkerIDs.clear();
    }
  }

  public OnLinkerCheckboxToggled(checked: boolean, linkerID: number)
  {
    if (checked == true)
    {
      this.selectedLinkerIDs.add(linkerID);
    }
    else
    {
      this.selectedLinkerIDs.delete(linkerID);
    }
  }

  public ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnAddButtonPressed()
  {
    const dialogRef = this.dialog.open(CreateLinkerDialogComponent, { width: '350px', height: '480px', data: { selectedLotID: this.lot.lot_id, selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewLinker(this.project.project_id, result).subscribe((newLinker: Linker) => 
        {
          if (newLinker != null)
          {
            this.project.linkers.set(newLinker.linker_id, newLinker);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnEditButtonPressed(linker: Linker)
  {
    const dialogRef = this.dialog.open(CreateLinkerDialogComponent, { width: '350px', height: '480px', data: { selectedLotID: this.lot.lot_id, selectedProject: this.project, linkerToEdit: linker } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      if (result != null)
      {
        let updateCallbacks: Array<Observable<any>> = new Array<Observable<any>>();
        updateCallbacks.push(this.projectsService.UpdateProjectLinker(this.project.project_id, linker.linker_id, result));

        if (result.get("availability") != linker.availability && this.project.lots.size > 0)
        {
          let affectedLotFound: boolean = false;

          for (let lot of this.project.lots.values())
          {
            if (lot.lot_id == linker.lot_id)
            {
              let lotUpdateDict: Map<string, any> = new Map<string, any>();
              lotUpdateDict.set("availability", result.get("availability"));

              let updateLot = this.projectsService.UpdateProjectLot(this.project.project_id, lot.lot_id, lotUpdateDict);
              updateCallbacks.push(updateLot);
              affectedLotFound = true;
              break;
            }
          }

          if (!affectedLotFound)
          {
            updateCallbacks.push(of(null));
          }
        }
        else
        {
          updateCallbacks.push(of(null));
        }

        forkJoin(updateCallbacks).subscribe((response) => 
        {
          if (response[0] != null)
          {
            this.project.linkers.set(linker.linker_id, response[0]);
          }

          if (response[1] != null)
          {
            this.project.lots.set(response[1].lot_id, response[1]);

          }

          if (response[0] != null || response[1] != null)
          {
            this.appUtilityService.StoreCMSData();
            this.parent.RenderTableRows();
          }
        });
      }
    });
  }

  public ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedLinkerIDs.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnDeleteButtonPressed()
  {
    if (this.selectedLinkerIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Linkers?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteLinkersFromProject(this.project.project_id, Array.from(this.selectedLinkerIDs)).subscribe((updatedLinkers: Map<number, Linker>) => 
          {
            if (updatedLinkers != null)
            {
              this.project.linkers = updatedLinkers;
              this.appUtilityService.StoreCMSData();
              this.selectedLinkerIDs.clear();
              
              this.RenderTableRows();
            }
          });
        }
      });
    }
  }

  HasSelectedRows(): boolean { return this.selectedLinkerIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedLinkerIDs; } 
  ClearSelectedRows(): void { this.selectedLinkerIDs.clear(); }
}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { CsvUtilitiesService } from 'src/app/csv-utilities.service';
import { Project } from 'src/DataModels';
import { FormControl } from '@angular/forms';
import { Observable, forkJoin, of, from } from 'rxjs';

export interface ImportCsvDialogData
{
  selectedProject: Project;
  importRequestType: string;
}

@Component({
  selector: 'app-import-csv-dialog',
  templateUrl: './import-csv-dialog.component.html',
  styleUrls: ['./import-csv-dialog.component.css']
})
export class ImportCsvDialogComponent implements OnInit 
{
  @ViewChild('suitesFileInput', {static: false}) suitesFileInput: ElementRef;
  @ViewChild('townhomesFileInput', {static: false}) townhomesFileInput: ElementRef;
  @ViewChild('homesFileInput', {static: false}) homesFileInput: ElementRef;
  @ViewChild('lotsFileInput', {static: false}) lotsFileInput: ElementRef;

  public overwriteToggle: FormControl = new FormControl(false);
  public modelNameParseDelimiter: FormControl = new FormControl("");

  public chosenFilepaths: Map<string, string> = new Map<string, string>();

  constructor(private dialogRef: MatDialogRef<ImportCsvDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ImportCsvDialogData, private csvUtilityService: CsvUtilitiesService) { }

  public GetModalViewTitle(): string
  {
    return "Import CSV";
  }

  public IsSuitesUpload(): boolean
  {
    return this.data.importRequestType == "suites";
  }

  public IsHomesUpload(): boolean
  {
    return this.data.importRequestType == "homes";
  }

  ngOnInit() 
  {
  }

  public OnCSVFileChosen(fileInput, filepathDisplay: MatInput)
  {
    filepathDisplay.value = fileInput.files[0].name;

    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(fileInput.files[0]);
    fileReader.onloadend = function(event)
    {
      if (this.suitesFileInput != null && fileInput == this.suitesFileInput.nativeElement)
      {
        this.chosenFilepaths.set("suites", event.target.result);
      }
      else if (this.townhomesFileInput != null && fileInput == this.townhomesFileInput.nativeElement)
      {
        this.chosenFilepaths.set("townhomes", event.target.result);
      }
      else if (this.homesFileInput != null && fileInput == this.homesFileInput.nativeElement)
      {
        this.chosenFilepaths.set("homes", event.target.result);
      }
      else if (this.lotsFileInput != null && fileInput == this.lotsFileInput.nativeElement)
      {
        this.chosenFilepaths.set("lots", event.target.result);
      }
    }.bind(this);
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnImportButtonClicked()
  {
    let modelNameDelimiter: string = this.modelNameParseDelimiter.value != "" ? this.modelNameParseDelimiter.value : " ";

    if (this.IsSuitesUpload())
    {
      let suitesFilepath = this.chosenFilepaths.get("suites");
      let townhomesFilepath = this.chosenFilepaths.get("townhomes");

      let importCallbacks: Array<Observable<any>> = new Array<Observable<any>>();

      if (suitesFilepath != null && suitesFilepath != "")
      {
        importCallbacks.push(this.csvUtilityService.FromCSV(suitesFilepath, "condos", this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter));
      }
      else
      {
        importCallbacks.push(of(null));
      }

      if (townhomesFilepath != null && townhomesFilepath != "")
      {
        importCallbacks.push(this.csvUtilityService.FromCSV(townhomesFilepath, "townhomes", this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter));
      }
      else
      {
        importCallbacks.push(of(null));
      }

      if (importCallbacks.length > 0)
      {
        forkJoin(importCallbacks).subscribe((results) => 
        {
          let result: boolean = false;
          
          if (results[0] != null && results[1] != null && results[0] == true && results[1] == true)
          {
            result = true;
          }
          else if (results[0] != null && results[1] != null && (results[0] == false || results[1] == false))
          {
            result = false;
          }
          else if (results[0] != null && results[1] == null)
          {
            result = results[0];
          }
          else if (results[0] == null && results[1] != null)
          {
            result = results[1];
          }

          this.dialogRef.close(result);
        });
      }
    }
    else if (this.IsHomesUpload())
    {
      let homesFilepath = this.chosenFilepaths.get("homes");
      let lotsFilepath = this.chosenFilepaths.get("lots");

      if (homesFilepath != null && homesFilepath != "" && lotsFilepath != null && lotsFilepath != "")
      {
        let importHomesAndElevations = this.csvUtilityService.FromCSV(homesFilepath, "homes", this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter);
        let importLotsAndLinkers = this.csvUtilityService.FromCSV(lotsFilepath, "lots", this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter);
        
        importHomesAndElevations.subscribe((homesResult: boolean) => 
        {
          if (homesResult == true)
          {
            importLotsAndLinkers.subscribe((lotsResult: boolean) => 
            {
              this.dialogRef.close(lotsResult);
            });
          }
          else
          {
            this.dialogRef.close(homesResult);
          }
        });
      }
      else if (homesFilepath != null && homesFilepath != "" && (lotsFilepath == null || lotsFilepath == ""))
      {
        this.csvUtilityService.FromCSV(homesFilepath, this.data.importRequestType, this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter).subscribe((result: boolean) => 
        {
          this.dialogRef.close(result);
        });
      }
      else if (lotsFilepath != null && lotsFilepath != "" && (homesFilepath == null || homesFilepath == ""))
      {
        this.csvUtilityService.FromCSV(lotsFilepath, "lots", this.data.selectedProject, this.overwriteToggle.value, modelNameDelimiter).subscribe((result: boolean) => 
        {
          this.dialogRef.close(result);
        });
      }
    }
  }
}
import { Component, LOCALE_ID, enableProdMode } from '@angular/core';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AppUtilityService } from './app-utility.service';
import { Observable } from 'rxjs';
import { CsvUtilitiesService } from './csv-utilities.service';
import { ProjectAnalyticsService } from './project-analytics.service';
import { EAnalyticType } from 'src/DataModels';
import { AppConstantsService } from './app-constants.service';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent 
{
  title = 'architectural-webcms-frontend';

  constructor(private appConstants: AppConstantsService, private projectAnalyticsService: ProjectAnalyticsService, private authService: AuthService, private router: Router, private appUtilityService: AppUtilityService) 
  { 
    if (this.authService.TryRecoverLoginSession())
    {
      let cmsCacheRestoreResult = this.appUtilityService.TryLoadCMSData();
      if (cmsCacheRestoreResult == true)
      {
        this.ParseRedirectURL();
      }
      else if (cmsCacheRestoreResult instanceof Observable)
      {
        cmsCacheRestoreResult.subscribe(result => 
        {
          this.ParseRedirectURL();
        });
      }
    }
    else
    {
      this.ParseRedirectURL();
    }
  }

  private NavigateToLoginScreen(): void
  {
    this.router.navigateByUrl('/login');
  }

  private NavigateToStartupView(): void
  {
    if (this.authService.IsActiveUserAdmin())
    {
      this.router.navigateByUrl('/admin/companies');
    }
    else
    {
      this.router.navigateByUrl('/projects');
    }
  }

  private ParseRedirectURL(): void
  {
    let href: string = window.location.href;

    if (href.startsWith("http://"))
    {
      href = href.replace("http://", "");
    }
    else if (href.startsWith("https://"))
    {
      href = href.replace("https://", "");
    }

    let pathStartIndex: number = href.indexOf(this.appConstants.baseHref) + this.appConstants.baseHref.length;
    if (pathStartIndex < href.length)
    {
      let strPath: string = href.substring(pathStartIndex);
      this.router.navigateByUrl(strPath).then(null, () => 
      {
        // If navigation failed, likely due to the URL not matching any known endpoint, default to the initial post-login views for either account type
        if (this.authService.IsLoggedIn())
        {
          this.NavigateToStartupView();
        }
        else
        {
          this.NavigateToLoginScreen();
        }
      });
    }
    else
    {
      if (this.authService.IsLoggedIn())
        {
          this.NavigateToStartupView();
        }
        else
        {
          this.NavigateToLoginScreen();
        }
    }
  }
}

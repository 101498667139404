import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';
import { CompanyRoleDialogData } from 'src/app/company-roles-table-view/company-roles-table-view.component';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-create-project-role-dialog',
  templateUrl: './create-project-role-dialog.component.html',
  styleUrls: ['./create-project-role-dialog.component.css']
})
export class CreateProjectRoleDialogComponent implements OnInit 
{
  public roleNameField: FormControl = new FormControl({ value: '', disabled: this.IsDialogReadOnly()});

  public livesiteAvailabilityToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public livesitePricingToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public usersCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public projectsViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsEditPricingToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsEditUnitDataToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsHoldUnitsToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsGenerateReportsToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsCreateWorksheetsToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsApproveWorksheetsToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsFinalizeSalesToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsEditSettingsToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsManageFloorplansToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  constructor(public dialogRef: MatDialogRef<CreateProjectRoleDialogComponent>, private appUtilityService: AppUtilityService, @Inject(MAT_DIALOG_DATA) public data: CompanyRoleDialogData, public authService: AuthService) { }

  ngOnInit() 
  {
    // Defer attaching form validators to here so that they don't interfere
    // With the disabled property
    if (!this.IsDialogReadOnly())
    {
      this.roleNameField.setValidators([Validators.required]); 
    }

    if (this.data.roleToEdit != null)
    {
      this.roleNameField.setValue(this.data.roleToEdit.project_role_name);

      this.livesiteAvailabilityToggle.setValue(this.data.roleToEdit.can_toggle_livesite_availability_display);
      this.livesitePricingToggle.setValue(this.data.roleToEdit.can_toggle_livesite_pricing_display);

      this.usersCreateToggle.setValue(this.data.roleToEdit.can_add_users);

      this.projectsViewToggle.setValue(this.data.roleToEdit.can_view_data);
      this.projectsEditPricingToggle.setValue(this.data.roleToEdit.can_modify_pricing);
      this.projectsEditUnitDataToggle.setValue(this.data.roleToEdit.can_modify_unit_data);
      this.projectsHoldUnitsToggle.setValue(this.data.roleToEdit.can_hold_units);
      this.projectsGenerateReportsToggle.setValue(this.data.roleToEdit.can_generate_reports);
      this.projectsCreateWorksheetsToggle.setValue(this.data.roleToEdit.can_create_worksheets);
      this.projectsApproveWorksheetsToggle.setValue(this.data.roleToEdit.can_approve_worksheets);
      this.projectsFinalizeSalesToggle.setValue(this.data.roleToEdit.can_finalize_sales);
      this.projectsEditSettingsToggle.setValue(this.data.roleToEdit.can_edit_project_settings);
      this.projectsManageFloorplansToggle.setValue(this.data.roleToEdit.can_manage_floorplans);
    }
  }

  public IsDialogReadOnly(): boolean
  {
    return this.data.roleToEdit != null && !this.authService.activeUserRole.can_edit_project_roles;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.roleToEdit != null)
    {
      return this.IsDialogReadOnly() ? "Role Details" : "Edit Role";
    }
    else
    {
      return "New Role";
    }
  }

  public GetRoleNameFieldError(): string
  {
    if (this.roleNameField.hasError('required'))
    {
      return "Please enter a role name";
    }
    else if (this.roleNameField.hasError('unique'))
    {
      return this.roleNameField.value + " is already taken";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isRoleInfoValid: boolean = true;

    if (this.roleNameField.value == "")
    {
      this.roleNameField.setErrors({required: true});
      this.roleNameField.markAsTouched();
      isRoleInfoValid = false;
    }
    else
    {
      let allCompanyRoleIDs = this.appUtilityService.companyProjectRolesDict.get(this.data.companyID);
      if (allCompanyRoleIDs.size > 0)
      {
        for (let roleID of allCompanyRoleIDs)
        {
          let role = this.appUtilityService.projectRolesDict.get(roleID);
          if (role != null && role.project_role_name == this.roleNameField.value)
          {
            if (this.data.roleToEdit == null || this.data.roleToEdit.project_role_id != role.project_role_id)
            {
              this.roleNameField.setErrors({unique: true});
              this.roleNameField.markAsTouched();
              isRoleInfoValid = false;
            }
            
            break;
          }
        }
      }
    }

    if (isRoleInfoValid)
    {
      let roleInfoDict: Map<string, any> = new Map<string, any>();
    
      roleInfoDict.set("company_id", this.data.companyID);
      roleInfoDict.set("project_role_name", this.roleNameField.value);
  
      roleInfoDict.set("can_toggle_livesite_availability_display", this.livesiteAvailabilityToggle.value);
      roleInfoDict.set("can_toggle_livesite_pricing_display", this.livesitePricingToggle.value);

      roleInfoDict.set("can_add_users", this.usersCreateToggle.value);
  
      roleInfoDict.set("can_view_data", this.projectsViewToggle.value);
      roleInfoDict.set("can_generate_reports", this.projectsGenerateReportsToggle.value);
      roleInfoDict.set("can_modify_pricing", this.projectsEditPricingToggle.value);
      roleInfoDict.set("can_modify_unit_data", this.projectsEditUnitDataToggle.value);
      roleInfoDict.set("can_create_worksheets", this.projectsCreateWorksheetsToggle.value);
      roleInfoDict.set("can_approve_worksheets", this.projectsApproveWorksheetsToggle.value);
      roleInfoDict.set("can_finalize_sales", this.projectsFinalizeSalesToggle.value);
      roleInfoDict.set("can_hold_units", this.projectsHoldUnitsToggle.value);
      roleInfoDict.set("can_edit_project_settings", this.projectsEditSettingsToggle.value);
      roleInfoDict.set("can_manage_floorplans", this.projectsManageFloorplansToggle.value);
  
      this.dialogRef.close(roleInfoDict);
    }
  }
}

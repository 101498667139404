import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppUtilityService } from '../app-utility.service';
import { UserRole } from 'src/DataModels';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.css']
})
export class UserDetailsFormComponent implements OnInit 
{
  public hidePassword: boolean = true;

  public usernameField: FormControl = new FormControl('');
  public emailField: FormControl = new FormControl('');
  public passwordField: FormControl = new FormControl('');
  public selectedUserRoleID: string = "";

  public isInEditMode: boolean = false;

  constructor(public appUtilityService: AppUtilityService, public authService: AuthService, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
    this.usernameField.setValidators([Validators.required]);
    this.emailField.setValidators([Validators.required, Validators.email]);
    this.passwordField.setValidators([Validators.required]);
  }

  public GetUsername(): string { return this.usernameField.value; }
  public GetEmail(): string { return this.emailField.value; }
  public GetPassword(): string { return this.passwordField.value; }
  public GetUserRoleID(): string { return this.selectedUserRoleID; }

  public SetUsername(username: string): void { this.usernameField.setValue(username); }
  public SetEmail(email: string): void { this.emailField.setValue(email); }
  public SetPassword(password: string): void { this.passwordField.setValue(password); }
  public SetUserRoleID(userRoleID: string): void { this.selectedUserRoleID = userRoleID; }

  public SetEditMode(isInEditMode: boolean)
  {
    this.isInEditMode = isInEditMode;

    if (this.isInEditMode == true)
    {
      this.passwordField.clearValidators();
      this.passwordField.reset();
    }
  }

  public IsUserRoleSelectionEnabled(): boolean
  {
    return this.authService.activeUserRole.can_edit_users && this.authService.activeUserRole.can_assign_user_roles && (this.selectedUserRoleID != this.authService.activeUserRole.user_role_id) && (this.GetSelectableUserRolesIterator().length > 0);
  }

  public GetSelectableUserRolesIterator(): Array<UserRole>
  {
    let roles: Array<UserRole> = new Array<UserRole>();

    if (this.appUtilityService.userRolesDict !== null && this.appUtilityService.userRolesDict.size > 0)
    {

      for (let role of this.appUtilityService.userRolesDict.values())
      {
        // Users may only assign a role that is beneath their current level
        if (this.authService.activeUserRole.CompareTo(role) === 1)
        {
          roles.push(role);
        }
      }
    }

    return roles;
  }

  public GetUsernameFieldError(): string
  {
    return "Please enter a username";
  }

  public GetEmailFieldError(): string
  {
    if (this.emailField.hasError("required"))
    {
      return "Please enter an email address";
    }
    else if (this.emailField.hasError("email"))
    {
      return "Please enter a valid email address";
    }
  }

  public GetPasswordFieldError(): string
  {
    if (this.passwordField.hasError("required"))
    {
      return "Please enter a password";
    }
  }

  public GetUserRoleFieldError(): string
  {
    return "A User Role must be selected";
  }
}

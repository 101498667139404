import { Component, OnInit, Input, ViewChildren, QueryList, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Project, Model, EArchitectureType } from 'src/DataModels';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { CreateModelDialogComponent } from '../dialog/create-model-dialog/create-model-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { ModelsTableViewComponent } from '../models-table-view/models-table-view.component';
import { CMSTableViewBase, CMSComponentBase } from '../interfaces/cms-component-base';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: ModelsDualTableViewComponent }],
  selector: 'app-models-dual-table-view',
  templateUrl: './models-dual-table-view.component.html',
  styleUrls: ['./models-dual-table-view.component.css']
})
export class ModelsDualTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit
{
  @ViewChildren(CMSTableViewBase) tables: QueryList<CMSTableViewBase>;

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project()
  {
    return this._project;
  }

  constructor(private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef) 
  {
    super();
  }

  public HasLiveCondoModels(): boolean
  {
    if (this.project != null && this.project.models.size > 0)
    {
      for (let model of this.project.models.values())
      {
        if (model.architecture_type == EArchitectureType.condo)
        {
          return true;
        }
      }
    }

    return false;
  }

  public HasLiveTownhomeModels(): boolean
  {
    if (this.project != null && this.project.models.size > 0)
    {
      for (let model of this.project.models.values())
      {
        if (model.architecture_type == EArchitectureType.townhome)
        {
          return true;
        }
      }
    }

    return false;
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
  }

  RenderTableRows() 
  {
    for (let modelsTable of this.tables.toArray())
    {
      modelsTable.RenderTableRows();
    }

    this.changeDetectorRef.detectChanges();
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      // let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      // if (projectRole != null)
      // {
      //   return projectRole.can_modify_pricing;
      // }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(model: Model)
  {
    const dialogRef = this.dialog.open(CreateModelDialogComponent, { width: '350px', height: '350px', data: { selectedProject: this.project, modelToEdit: model } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectModel(this.project.project_id, model.model_id, result).subscribe((updatedModel: Model) => 
        {
          if (updatedModel != null)
          {
            this.project.models.set(model.model_id, updatedModel);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateModelDialogComponent, { width: '350px', height: '350px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewModel(this.project.project_id, result).subscribe((newModel: Model) => 
        {
          if (newModel != null)
          {
            this.project.models.set(newModel.model_id, newModel);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.tables == null) { return false; }

    let hasSelectedRows: boolean = false;

    for (let innerModelsTable of this.tables.toArray())
    {
      if (innerModelsTable.HasSelectedRows())
      {
        hasSelectedRows = true;
        break;
      }
    }

    if (this.authService.IsActiveUserAdmin() && hasSelectedRows)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  
  OnDeleteButtonPressed(): void
  {
    let allSelectedModelIDs: Set<number> = new Set<number>();
    for (let innerModelsTable of this.tables.toArray())
    {
      for (let modelID of innerModelsTable.GetSelectedRows())
      {
        allSelectedModelIDs.add(<number>modelID);
      }
    }

    if (allSelectedModelIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Models?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteModelsFromProject(this.project.project_id, Array.from(allSelectedModelIDs)).subscribe((updatedModels: Map<number, Model>) => 
          {
            if (updatedModels != null)
            {
              this.project.models = updatedModels;
              this.appUtilityService.StoreCMSData();
              
              for (let innerModelsTable of this.tables.toArray())
              {
                innerModelsTable.ClearSelectedRows();
              }

              this.RenderTableRows();
            }
          });
        }
      });
    }
  }

  HasSelectedRows(): boolean { return false; }
  GetSelectedRows(): Set<string | number> { return null } 
  ClearSelectedRows(): void { }
}

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppConstantsService
{
  //public baseHref: string = "/";
  public baseHref: string = "/livesitecms/frontend/";

  public baseURL: string = "https://www.neezostudios.com/livesitecms/api/"
  //public baseURL: string = "http://localhost/architectural_webcms_backend/";

  constructor() { }
}

import { Injectable, DebugElement } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { LoadingSpinnerOverlayService } from './loading-spinner-overlay.service';
import { finalize, map, tap } from 'rxjs/operators';
import { WebCMSAPIResponse } from '../DataModels';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class ErrorResponseInterceptor implements HttpInterceptor 
{
    constructor(private snackbar: MatSnackBar) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): import("rxjs").Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(map((event: HttpEvent<any>) => 
        {
            if (event instanceof HttpResponse)
            {
                try
                {
                    let response: WebCMSAPIResponse = event.body as WebCMSAPIResponse;
                    if (response != null && response.hasOwnProperty("status") && response.hasOwnProperty("message") && response.status == "error" && response.message != null && response.message != "")
                    {
                        this.snackbar.open(response.message, null, {duration: 4000});
                    }
                }
                catch (exception)
                {
                    console.log(exception);
                }
            }

            return event;
        }));
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { Project, Deposit } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import * as moment from 'moment';

@Component({
  selector: 'app-worksheet-deposit-structure-module',
  templateUrl: './worksheet-deposit-structure-module.component.html',
  styleUrls: ['./worksheet-deposit-structure-module.component.css']
})
export class WorksheetDepositStructureModuleComponent implements OnInit 
{
  private _totalDepositAmount: number = 0;

  @Input() set TotalDepositAmount(totalDepositAmount: number)
  {
    this._totalDepositAmount = totalDepositAmount;
  }

  get TotalDepositAmount()
  {
    return this._totalDepositAmount;
  }

  private _minDeposits: number = 2;

  @Input() set MinDeposits(minDeposits: number)
  {
    this._minDeposits = minDeposits;
  }

  get MinDeposits()
  {
    return this._minDeposits;
  }

  private _dueDateIncrement: number = 30;

  @Input() set DueDateIncrement(dueDateIncrement: number)
  {
    this._dueDateIncrement = dueDateIncrement;
  }

  get DueDateIncrement()
  {
    return this._dueDateIncrement;
  }

  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  public securityDepositAmount: number = 0;
  public depositPercentage: number = 0;
  public deposits: Array<Deposit> = new Array<Deposit>();

  constructor(public appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
  }

  InitWithDefaultDepositStructure()
  {
    this.depositPercentage = this.SelectedProject.default_deposit_percentage;
    this.securityDepositAmount = this.SelectedProject.default_security_deposit_amount;

    if (this.SelectedProject.defaultDepositStructure.length > 0)
    {
      this.deposits = Array.from(this.SelectedProject.defaultDepositStructure);
    }
    else
    {
      this.deposits = new Array<Deposit>();

      for (let i = 0; i < this.MinDeposits; ++i)
      {
        this.deposits.push(new Deposit());
        this.deposits[i].deposit_number = i + 1;
        this.deposits[i].days_until_due = this.DueDateIncrement * (this.deposits.length - 1);
      }
    }
  }

  GetDepositAmount(deposit: Deposit): number
  {
    if (this.TotalDepositAmount == 0) { return 0; }

    let fixedDepositPayment: number = (this.TotalDepositAmount / this.depositPercentage) / (this.deposits.length - 1);

    if (deposit.deposit_number == 1)
    {
      return this.securityDepositAmount;
    }
    else if (deposit.deposit_number == 2)
    {
      return fixedDepositPayment - this.securityDepositAmount;
    }
    else
    {
      return fixedDepositPayment;
    }
  }

  GetDepositDueDate(deposit: Deposit): string
  {
    if (deposit.deposit_number == 1)
    {
      return "Due upon signing";
    }
    else
    {
      return moment().add(deposit.days_until_due, 'days').format("DD/MM/YYYY");
    }
  }

  GetDepositMinDueDate(deposit: Deposit): number
  {
    let depositIndex = deposit.deposit_number - 1;
    
    if (depositIndex - 1 >= 0)
    {
      return this.deposits[depositIndex - 1].days_until_due + 1;
    }
    else
    {
      return 0;
    }
  }

  OnDepositDueDateChanged(deposit: Deposit)
  {
    let minDueDate = this.GetDepositMinDueDate(deposit);
    if (deposit.days_until_due < minDueDate)
    {
      deposit.days_until_due = minDueDate;
    }
  }

  OnSecurityDepositAmountChanged()
  {

  }

  OnDepositPercentageChanged()
  {

  }

  OnAddButtonPressed()
  {
    this.deposits.push(new Deposit());
    this.deposits[this.deposits.length - 1].deposit_number = this.deposits.length;
    this.deposits[this.deposits.length - 1].days_until_due = this.DueDateIncrement * (this.deposits.length - 1);
  }

  OnDeleteButtonPressed()
  {
    if (this.deposits.length - 1 >= this.MinDeposits)
    {
      this.deposits.pop();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, fromEventPattern } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Company, WebCMSAPIResponse } from 'src/DataModels';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class CompaniesService 
{
  constructor(private http: HttpClient, private dictionaryUtility: DictionaryUtilitiesService, private appConstants: AppConstantsService) { }

  public GetAllCompanies(): Observable<Map<string, Company>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/companies`).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetCompaniesResponse(response);
    }));
  }

  GetCompanyByID(companyID: string): Observable<Company> 
  {
    let params = new HttpParams().set("companyID", companyID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/companies`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let companyInfoFromPacket = JSON.parse(response.data);
        let company: Company = new Company(companyInfoFromPacket);
        return company;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return null;
    }));
  }

  /*
    Attempts to create a new company on the database, and if successful, returns a representation of that new company
  */
  public CreateNewCompany(companyName: string): Observable<Company>
  {
    let params = { "companyName": companyName };

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/companies`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let companyInfoFromPacket = JSON.parse(response.data);
        let company: Company = new Company(companyInfoFromPacket);
        return company;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateCompanyName(companyID: string, newCompanyName: string): Observable<Company>
  {
    let params = { "companyID": companyID, "companyName": newCompanyName };

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/companies`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let companyInfoFromPacket = JSON.parse(response.data);
        let company: Company = new Company(companyInfoFromPacket);
        return company;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return null;
    }));
  }

  public DeleteCompanies(deleteSelectionDict: Map<string, boolean>): Observable<Map<string, Company>>
  {
    let rawSelectionDict = this.dictionaryUtility.GetRawDictionary(deleteSelectionDict);
    let params = {"selection": JSON.stringify(rawSelectionDict)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/companies`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetCompaniesResponse(response);
    }));
  }

  /*
    Parses the response of any of the methods that retrive sets of company records and
    generates Company instances for each and every company found.
  */
  private ParseGetCompaniesResponse(response: WebCMSAPIResponse): Map<string, Company>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let companiesDict: Map<string, Company> = new Map<string, Company>();
      let responseDataJSON = JSON.parse(response.data);

      for (let companyJSON of responseDataJSON)
      {
        let companyInfoFromPacket = JSON.parse(companyJSON);
        let company: Company = new Company(companyInfoFromPacket);
        companiesDict.set(company.company_id, company);
      }

      return companiesDict;
    }
    else if (response.status == "error")
    {
      // TODO - More intelligent error handling / in-app presentation?
      console.log(response);
    }

    return null;
  }
}

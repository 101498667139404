import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Project } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { ProjectsService } from 'src/app/projects.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformModule } from '@angular/cdk/platform';

export interface CreateFloorplanDocumentDialogData
{
  selectedProject: Project,
}

@Component({
  selector: 'app-create-floorplan-document-dialog',
  templateUrl: './create-floorplan-document-dialog.component.html',
  styleUrls: ['./create-floorplan-document-dialog.component.css']
})
export class CreateFloorplanDocumentDialogComponent implements OnInit 
{
  private pdfUploadDict: Map<string, string> = new Map<string, string>();

  constructor(private dialogRef: MatDialogRef<CreateFloorplanDocumentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateFloorplanDocumentDialogData, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
  }

  OnFileChosen(fileInput)
  {
    this.pdfUploadDict.clear();

    for (let file of fileInput.files)
    {
      let fileReader: FileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = function(event)
      {
        this.pdfUploadDict.set(file.name, event.target.result);
      }.bind(this);
    }
  }

  GetSelectedFileNamesIterator(): Array<string>
  {
    if (this.pdfUploadDict.size > 0)
    {
      return Array.from(this.pdfUploadDict.keys());
    }

    return null;
  }

  OnSaveButtonClicked()
  {
    this.projectsService.CreateNewFloorplanDocuments(this.data.selectedProject, this.pdfUploadDict).subscribe((result: boolean) => 
    {
      this.dialogRef.close(result);
    });
  }

  OnCancelButtonClicked()
  {
    this.dialogRef.close(false);
  }
}

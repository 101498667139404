import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Project, User, ProjectRole, Company, ProjectAssignment } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { ProjectAssignmentsService } from 'src/app/project-assignments.service';
import { AuthService } from 'src/app/auth.service';
import { Observable, forkJoin, of } from 'rxjs';
import { GenericDeleteConfirmationDialogComponent } from '../generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';

export interface ProjectUserAssignmentsDialogData
{
  selectedProject: Project;
}

@Component({
  selector: 'app-project-user-assignments-dialog',
  templateUrl: './project-user-assignments-dialog.component.html',
  styleUrls: ['./project-user-assignments-dialog.component.css']
})
export class ProjectUserAssignmentsDialogComponent implements OnInit, AfterViewInit 
{
  public assignedUsers: Set<User> = new Set<User>();

  // Maps user ID to project role ID
  public initialSelectorValues: Map<string, string> = new Map<string, string>();
  public currentSelectorValues: Map<string, string> = new Map<string, string>();

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<ProjectUserAssignmentsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ProjectUserAssignmentsDialogData, public appUtilityService: AppUtilityService, private projectAssignmentsService: ProjectAssignmentsService, public authService: AuthService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
    if (this.data.selectedProject != null && this.appUtilityService.companyUsersDict.has(this.data.selectedProject.company_id))
    {
      for (let companyUserID of this.appUtilityService.companyUsersDict.get(this.data.selectedProject.company_id))
      {
        let userAssignments: Set<ProjectAssignment> = this.appUtilityService.userAssignmentsDict.get(companyUserID);
        for (let assignment of userAssignments)
        {
          if (assignment.project_id == this.data.selectedProject.project_id && assignment.user_id != this.authService.activeUser.user_id)
          {
            this.assignedUsers.add(this.appUtilityService.usersDict.get(companyUserID));
            this.initialSelectorValues.set(assignment.user_id, assignment.project_role_id);
            break;
          }
        }
      }
    }
  }

  ngAfterViewInit()
  {
    this.currentSelectorValues = new Map<string, string>(this.initialSelectorValues);
    this.changeDetectorRef.detectChanges();
  }

  public GetCompanyUsersIterator(): Array<User>
  {
    if (this.data.selectedProject != null)
    {
      let companyUserIDs = this.appUtilityService.companyUsersDict.get(this.data.selectedProject.company_id);
      if (companyUserIDs != null && companyUserIDs.size > 0)
      {
        let companyUsers: Array<User> = new Array<User>();
        
        for (let userID of companyUserIDs)
        {
          let user = this.appUtilityService.usersDict.get(userID);
          if (user != null && user.user_id != this.authService.activeUser.user_id)
          {
            companyUsers.push(user);
          }
        }

        return companyUsers;
      }
    }

    return null;
  }

  public GetNeezoProjectRolesIterator(): Array<ProjectRole>
  {
    let neezoProjectRoleIDs: Set<string> = null;
    let neezoRolesArray: Array<ProjectRole> = new Array<ProjectRole>();

    for (let company of this.appUtilityService.companiesDict.values())
    {
      if (company.company_name == "NEEZO Studios")
      {
        neezoProjectRoleIDs = this.appUtilityService.companyProjectRolesDict.get(company.company_id);
        break;
      }
    }

    for (let roleID of neezoProjectRoleIDs)
    {
      let role = this.appUtilityService.projectRolesDict.get(roleID);
      if (role != null)
      {
        neezoRolesArray.push(role);
      }
    }

    return neezoRolesArray;
  }

  public GetCompanyProjectRolesIterator(): Array<ProjectRole>
  {
    if (this.data.selectedProject != null)
    {
      let companyRoleIDs: Set<string> = this.appUtilityService.companyProjectRolesDict.get(this.data.selectedProject.company_id);
      let companyRolesArray: Array<ProjectRole> = new Array<ProjectRole>();

      for (let roleID of companyRoleIDs)
      {
        companyRolesArray.push(this.appUtilityService.projectRolesDict.get(roleID));
      }

      return companyRolesArray;
    }
    else
    {
      return null;
    }
  }

  public GetProjectCompanyName(): string
  {
    let projectOwningCompany: Company = this.appUtilityService.companiesDict.get(this.data.selectedProject.company_id);
    if (projectOwningCompany != null)
    {
      return projectOwningCompany.company_name;
    }
  }

  public OnDeleteAssignmentButtonClicked(user: User)
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Assignment?" }});
    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result == true)
      {
        let requestedAssignment: ProjectAssignment = null;
        if (this.appUtilityService.userAssignmentsDict.has(user.user_id))
        {
          for (let assignment of this.appUtilityService.userAssignmentsDict.get(user.user_id))
          {
            if (assignment.project_id == this.data.selectedProject.project_id)
            {
              requestedAssignment = assignment;
              break;
            }
          }

          if (requestedAssignment != null)
          {
            this.projectAssignmentsService.DeleteProjectAssignment(user.user_id, this.data.selectedProject.project_id).subscribe((response: Map<string, any>) => 
            {
              if (response != null)
              {
                this.assignedUsers.delete(user);

                this.appUtilityService.userAssignmentsDict.get(user.user_id).delete(requestedAssignment);
                this.appUtilityService.StoreCMSData();
              }
            });
          }
          else
          {
            this.assignedUsers.delete(user);
          }
        }
      }
      else
      {
        this.assignedUsers.delete(user);
      }
    });
  }

  public OnAddUserButtonClicked(user: User): void
  {
    if (user != null && this.data.selectedProject != null)
    {
      for (let assignment of this.appUtilityService.userAssignmentsDict.get(user.user_id).values())
      {
        if (assignment.project_id == this.data.selectedProject.project_id)
        {
          return;
        }
      }

      this.assignedUsers.add(user);
      this.initialSelectorValues.set(user.user_id, "None");
      this.currentSelectorValues.set(user.user_id, "None");
    }
  }

  public OnProjectRoleSelectionChanged(userID: string, projectRoleID: string)
  {
    this.currentSelectorValues.set(userID, projectRoleID);
  }

  public OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  public OnApplyButtonClicked(): void
  {
    let createCallbacks: Array<Observable<any>> = new Array<Observable<any>>();
    let updateCallbacks: Array<Observable<any>> = new Array<Observable<any>>();

    for (let userID of this.initialSelectorValues.keys())
    {
      let user: User = this.appUtilityService.usersDict.get(userID);

      if (this.initialSelectorValues.get(userID) == "None" && this.currentSelectorValues.get(userID) != "None")
      {
        // If we're going from None to some project role ID, then we must be creating a new project assignment
        let selectedProjectRoleID = this.currentSelectorValues.get(userID);
        let createNewAssignment = this.projectAssignmentsService.AssignUserToProject(userID, this.data.selectedProject.project_id, selectedProjectRoleID, this.data.selectedProject.company_id);
        createCallbacks.push(createNewAssignment);
      }
      else if (this.initialSelectorValues.get(userID) != "None" && this.currentSelectorValues.get(userID) != "None" && this.initialSelectorValues.get(userID) != this.currentSelectorValues.get(userID))
      {
        // If we're going from some project role ID to some other project role ID, then we must be changing the user's role (Update)
        let selectedProjectRoleID = this.currentSelectorValues.get(userID);
        let changeProjectRole = this.projectAssignmentsService.ChangeProjectRoleForAssignment(userID, this.data.selectedProject.project_id, selectedProjectRoleID, this.data.selectedProject.company_id);
        updateCallbacks.push(changeProjectRole);
      }
    }
    
    let callbacks: Array<Observable<any>> = new Array<Observable<any>>();
    
    if (createCallbacks.length > 0) { callbacks.push(forkJoin(createCallbacks)); }
    else { callbacks.push(of(null)); }

    if (updateCallbacks.length > 0) { callbacks.push(forkJoin(updateCallbacks)); }
    else { callbacks.push(of(null)); }

    if (callbacks.length > 0)
    {
      this.dialogRef.close(callbacks);
    }
    else
    {
      this.dialogRef.close(null);
    }
  }
}

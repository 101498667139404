import { Injectable, Inject } from '@angular/core';
import { User, Company, Project, UserRole, ProjectRole, ProjectAssignment } from 'src/DataModels';
import { Observable, forkJoin } from 'rxjs';
import { UsersService } from './users.service';
import { CompaniesService } from './companies.service';
import { ProjectsService } from './projects.service';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { UserRolesService } from './user-roles.service';
import { ProjectRolesService } from './project-roles.service';
import { ProjectAssignmentsService } from './project-assignments.service';
import { SettingsService } from './settings.service';
import { ClipboardService } from 'ngx-clipboard';
import { KeyValue } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AppUtilityService 
{
  private mobileQuery: MediaQueryList = null;

  private currentViewTitle: string = "";

  public currentSalesfishWebcallPath: string = "";

  // Local data stores
  public usersDict: Map<string, User> = new Map<string, User>();
  public companiesDict: Map<string, Company> = new Map<string, Company>();
  public projectsDict: Map<string, Project> = new Map<string, Project>();
  public userRolesDict: Map<string, UserRole> = new Map<string, UserRole>();
  public projectRolesDict: Map<string, ProjectRole> = new Map<string, ProjectRole>();
  public userAssignmentsDict: Map<string, Set<ProjectAssignment>> = new Map<string, Set<ProjectAssignment>>();

  // Sets of user IDs indexed by the owning companyID, used by admin-level users
  public companyUsersDict: Map<string, Set<string>> = new Map<string, Set<string>>();

  // Sets of project IDs indexed by the owning companyID, used by Admin-level users
  public companyProjectsDict: Map<string, Set<string>> = new Map<string, Set<string>>();

  // Sets of role IDs indexed by the owning companyID
  public companyProjectRolesDict: Map<string, Set<string>> = new Map<string, Set<string>>();

  // KeyValuePipe comparer functions
  public sortOrderCompanyNameAlphabetical = (a: KeyValue<string, Company>, b: KeyValue<string, Company>): number => 
  {
    let c1Name = a.value.company_name.toLowerCase();
    let c2Name = b.value.company_name.toLowerCase();

    if (c1Name < c2Name)
    {
      return -1;
    }
    else if (c1Name > c2Name)
    {
      return 1;
    }
    else
    {
      return 0;
    }
  }

  public sortOrderProjectNameAlphabetical = (a: KeyValue<string, Project>, b: KeyValue<string, Project>): number => 
  {
    let p1Name = a.value.project_name.toLowerCase();
    let p2Name = b.value.project_name.toLowerCase();

    if (p1Name < p2Name)
    {
      return -1;
    }
    else if (p1Name > p2Name)
    {
      return 1;
    }
    else
    {
      return 0;
    }
  }

  public sortOrderUserNameAlphabetical = (a: KeyValue<string, User>, b: KeyValue<string, User>): number =>
  {
    let u1Name = a.value.username.toLowerCase();
    let u2Name = b.value.username.toLowerCase();

    if (u1Name < u2Name)
    {
      return -1;
    }
    else if (u1Name > u2Name)
    {
      return 1;
    }
    else
    {
      return 0;
    }
  }

  public sortOrderProjectRoleNameAlphabetical = (a: KeyValue<string, ProjectRole>, b: KeyValue<string, ProjectRole>): number => 
  {
    let p1Name = a.value.project_role_name.toLowerCase();
    let p2Name = b.value.project_role_name.toLowerCase();

    if (p1Name < p2Name)
    {
      return -1;
    }
    else if (p1Name > p2Name)
    {
      return 1;
    }
    else
    {
      return 0;
    }
  }

  public sortOrderUserRoleNameAlphabetical = (a: KeyValue<string, UserRole>, b: KeyValue<string, UserRole>): number =>
  {
    let u1Name = a.value.user_role_name.toLowerCase();
    let u2Name = b.value.user_role_name.toLowerCase();

    if (u1Name < u2Name)
    {
      return -1;
    }
    else if (u1Name > u2Name)
    {
      return 1;
    }
    else
    {
      return 0;
    }
  }

  public shouldDisplayAddUserButtonCallback: () => boolean = null;
  public addUserButtonBehaviourCallback: () => void = null;

  public shouldDisplayProjectAssignmentsButtonCallback: () => boolean = null;
  public projectAssignmentsButtonBehaviourCallback: () => void = null;

  public shouldDisplayEditProjectSettingsButtonCallback: () => boolean = null;
  public editProjectSettingsButtonBehaviourCallback: () => void = null;

  public Base64ToFileHandle(base64: string, filename: string)
  {
    var arr = base64.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]); 
    var n = bstr.length; 
    var u8arr = new Uint8Array(n);
            
    while (n--)
      u8arr[n] = bstr.charCodeAt(n);
    
    return new Blob([u8arr], {type: "mime"});
  }

  public Base64ToArrayBuffer(base64: string)
  {
    var binary_string = atob(base64.split(',')[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);

    for (var i = 0; i < len; i++) 
      bytes[i] = binary_string.charCodeAt(i);

    return bytes.buffer;
  }

  constructor(private authService: AuthService, private usersService: UsersService, private companiesService: CompaniesService, private projectsService: ProjectsService, @Inject(UserRolesService) private userRolesService: UserRolesService, private projectRolesService: ProjectRolesService, private dictionaryUtilitiesService: DictionaryUtilitiesService, private projectAssignmentsService: ProjectAssignmentsService, private settingsService: SettingsService, private clipboardService: ClipboardService) { }

  public SetScreenWidthListener(mobileQuery: MediaQueryList)
  {
    this.mobileQuery = mobileQuery
  }

  public IsMobileScreenWidth(): boolean
  {
    return this.mobileQuery.matches;
  }

  public GetViewTitle(): string
  {
    return this.currentViewTitle;
  }

  public SetViewTitle(title: string)
  {
    if (title != null || title != undefined || title != "")
    {
      this.currentViewTitle = title;
    }
  }

  public CopyToClipboard(strData: string)
  {
    this.clipboardService.copyFromContent(strData);
  }

  public GetActiveUserProjectRole(project: Project): ProjectRole
  {
    let assignments = this.userAssignmentsDict.get(this.authService.activeUser.user_id);
    if (assignments != null)
    {
      for (let assignment of assignments)
      {
        if (assignment.project_id == project.project_id)
        {
          let assignedProjectRole: ProjectRole = this.projectRolesDict.get(assignment.project_role_id);
          return assignedProjectRole;
        }
      }
    }

    return null;
  }

  public GetDefaultNeezoProjectRole(): ProjectRole
  {
    if (this.companiesDict.size == 0 || this.projectRolesDict.size == 0)
    {
      return null;
    }

    let neezoProjectRoleIDs: Array<string> = null;

    for (let company of this.companiesDict.values())
    {
      if (company.company_name == "NEEZO Studios")
      {
        neezoProjectRoleIDs = Array.from(this.companyProjectRolesDict.get(company.company_id));
        break;
      }
    }

    let defaultRole: ProjectRole = this.projectRolesDict.get(neezoProjectRoleIDs[0]);
    for (let i = 1; i < neezoProjectRoleIDs.length; ++i)
    {
      let currentRole = this.projectRolesDict.get(neezoProjectRoleIDs[i]);

      if (defaultRole.CompareTo(currentRole) == 1)
      {
        defaultRole = currentRole;
      }
    }

    return defaultRole;
  }

  public TryLoadCMSData(): boolean | Observable<boolean>
  { 
    if (!this.authService.IsLoggedIn()) { return false; }
    // if (this.TryRecoverCMSData()) { return true; }

    if (this.authService.IsActiveUserAdmin())
    {
      // If the user has admin permissions, blindly load everything async.
      return this.TryLoadAdminCMSData();
    }
    else
    {
      // Otherwise, load only things that have to do with the current user acccount
      return this.TryLoadClientCMSData();
    }
  }

  private TryLoadAdminCMSData(): Observable<boolean>
  {
    let getUsers = this.usersService.GetAllUsers();
    let getCompanies = this.companiesService.GetAllCompanies();
    let getProjects = this.projectsService.GetAllProjects();
    let getProjectRoles = this.projectRolesService.GetAllProjectRoles();
    let getUserRoles = this.userRolesService.GetAllUserRoles();
    let getUserProjectAssignments = this.projectAssignmentsService.GetAllProjectAssignments();
    let getSalesfishWebcallPath = this.settingsService.GetSalesfishWebcallPath();

    return forkJoin([getCompanies, getUsers, getProjects, getProjectRoles, getUserRoles, getUserProjectAssignments, getSalesfishWebcallPath]).pipe(map(results => 
    {
      try
      {
        let hasPulledAllRequiredData: boolean = true;

        this.usersDict.clear();
        this.companiesDict.clear();
        this.projectsDict.clear();
        this.projectRolesDict.clear();
        this.userRolesDict.clear();
        this.userAssignmentsDict.clear();

        // Did we safely retrieve the set of CMS companies?
        if (results[0] != null)
        {
          this.companiesDict = results[0];
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the set of CMS users?
        if (results[1] != null)
        {
          this.usersDict = results[1];

          // Create a secondary index of users on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyUsersArray = new Set<string>();

            for (let user of results[1].values())
            {
              if (user.company_id == companyID)
              {
                companyUsersArray.add(user.user_id);
              }
            }

            this.companyUsersDict.set(companyID, companyUsersArray);
          }
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the set of registered projects?
        if (results[2] != null)
        {
          this.projectsDict = results[2];

          // Create a secondary index of projects on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyProjectsArray = new Set<string>();

            for (let project of results[2].values())
            {
              if (project.company_id == companyID)
              {
                companyProjectsArray.add(project.project_id);
              }
            }

            this.companyProjectsDict.set(companyID, companyProjectsArray);
          }
        }

        // Did we safely retrieve the set of company project roles?
        if (results[3] != null)
        {
          this.projectRolesDict = results[3];

          // Create a secondary index of project roles on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyRolesArray = new Set<string>();

            for (let projectRole of results[3].values())
            {
              if (projectRole.company_id == companyID)
              {
                companyRolesArray.add(projectRole.project_role_id); 
              }
            }

            this.companyProjectRolesDict.set(companyID, companyRolesArray);
          }
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the set of user roles?
        if (results[4] != null)
        {
          this.userRolesDict = results[4];
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the set of user project assignments?
        if (results[5] != null)
        {
          let allProjectAssignments: Set<ProjectAssignment> = results[5];

          for (let userID of this.usersDict.keys())
          {
            let userAssignmentsSet: Set<ProjectAssignment> = new Set<ProjectAssignment>();

            for (let assignment of allProjectAssignments.values())
            {
              if (assignment.user_id == userID)
              {
                userAssignmentsSet.add(assignment);
              }
            }

            this.userAssignmentsDict.set(userID, userAssignmentsSet);
          }
        }

        // Did we safely retrieve the salesfish web call path?
        if (results[6] != null)
        {
          this.currentSalesfishWebcallPath = results[6];
        }

        this.StoreCMSData();
        return hasPulledAllRequiredData;
      }
      catch (error)
      {
        console.log(error);
        this.authService.Logout();
        return false;
      }
    }));
  }

  private TryLoadClientCMSData(): Observable<boolean>
  {
    let getCompanies = this.companiesService.GetAllCompanies();
    let getUsers = this.usersService.GetUsersByCompanyID(this.authService.activeUser.company_id);
    let getUserProjects = this.projectsService.GetProjectsByUserID(this.authService.activeUser.user_id);
    
    let getUserCompanyProjectRoles = this.projectRolesService.GetProjectRolesByCompanyID(this.authService.activeUser.company_id);
    let getUserProjectRoles = this.projectRolesService.GetProjectRolesByUserID(this.authService.activeUser.user_id);
    let getAllApplicableProjectRoles = forkJoin([getUserCompanyProjectRoles, getUserProjectRoles]);
    
    let getUserRoles = this.userRolesService.GetClientUserRoles();
    let getUserProjectAssignments = this.projectAssignmentsService.GetProjectAssignmentsByUserID(this.authService.activeUser.user_id);

    return forkJoin([getCompanies, getUsers, getUserProjects, getAllApplicableProjectRoles, getUserRoles, getUserProjectAssignments]).pipe(map(results => 
    {
      try
      {
        let hasPulledAllRequiredData: boolean = true;

        this.usersDict.clear();
        this.companiesDict.clear();
        this.projectsDict.clear();
        this.userRolesDict.clear();
        this.companyProjectRolesDict.clear();

        // Did we safely retrieve the user's companies?
        if (results[0] != null)
        {
          this.companiesDict = results[0];
        }
        else
        {
          hasPulledAllRequiredData = false;
        }
        
        // Did we safely retrieve the other users from the user's company?
        if (results[1] != null)
        {
          this.usersDict = results[1];

          // Create a secondary index of users on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyUsersArray = new Set<string>();

            for (let user of results[1].values())
            {
              if (user.company_id == companyID)
              {
                companyUsersArray.add(user.user_id);
              }
            }

            this.companyUsersDict.set(companyID, companyUsersArray);
          }
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the user's projects?
        if (results[2] != null)
        {
          this.projectsDict = results[2];

          // Create a secondary index of projects on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyProjectsArray = new Set<string>();

            for (let project of results[2].values())
            {
              if (project.company_id == companyID)
              {
                companyProjectsArray.add(project.project_id);
              }
            }

            this.companyProjectsDict.set(companyID, companyProjectsArray);
          }
        }

        // Did we safely retrieve the user's company project roles?
        if (results[3] != null)
        {
          let parentCompanyProjectRoles = results[3][0];
          let assignedProjectRoles = results[3][1];

          this.projectRolesDict = parentCompanyProjectRoles != null ? parentCompanyProjectRoles : new Map<string, ProjectRole>();

          // Create a secondary index of project roles on the owning company ID
          for (let companyID of this.companiesDict.keys())
          {
            let companyRolesArray = new Set<string>();

            if (parentCompanyProjectRoles !== null)
            {
              for (let projectRole of parentCompanyProjectRoles.values())
              {
                if (projectRole.company_id == companyID)
                {
                  companyRolesArray.add(projectRole.project_role_id); 
                }
              } 
            }

            // Essentially, pick up on any additional project roles that were owned by a separate company
            // In other words, this is for the "broker relationship" where they may find themselves included on another company's projects
            if (assignedProjectRoles != null)
            {
              for (let projectRole of assignedProjectRoles.values())
              {
                if (!this.projectRolesDict.has(projectRole.project_role_id) && projectRole.company_id == companyID)
                {
                  this.projectRolesDict.set(projectRole.project_role_id, projectRole);
                  companyRolesArray.add(projectRole.project_role_id); 
                }
              }
            }

            this.companyProjectRolesDict.set(companyID, companyRolesArray);
          }
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the list of client-level user roles?
        if (results[4] != null)
        {
          this.userRolesDict = results[4];
        }
        else
        {
          hasPulledAllRequiredData = false;
        }

        // Did we safely retrieve the list of client-level project assignments?
        if (results[5] != null)
        {
          let companyProjectAssignments: Set<ProjectAssignment> = results[5];

          for (let userID of this.usersDict.keys())
          {
            let userAssignmentsSet: Set<ProjectAssignment> = new Set<ProjectAssignment>();

            for (let assignment of companyProjectAssignments.values())
            {
              if (assignment.user_id == userID)
              {
                userAssignmentsSet.add(assignment);
              }
            }

            this.userAssignmentsDict.set(userID, userAssignmentsSet);
          }
        }

        this.StoreCMSData();
        return hasPulledAllRequiredData;
      }
      catch (error)
      {
        console.log(error);
        this.authService.Logout();
        return false;
      }
    }));
  }

  /*
    Saves a snapshot of the currently pulled CMS data to localstorage
  */
  public StoreCMSData()
  {
    return;

    if (this.projectsDict.size > 0)
    {
      for (let project of this.projectsDict.values())
      {
        project.UpdateTimeLastStored();
      }
    }

    localStorage.setItem("projectsDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.projectsDict)));
    localStorage.setItem("usersDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.usersDict)));
    localStorage.setItem("companiesDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.companiesDict)));
    localStorage.setItem("userRolesDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.userRolesDict)));
    localStorage.setItem("projectRolesDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.projectRolesDict)));
    localStorage.setItem("companyProjectsDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.companyProjectsDict)));
    localStorage.setItem("companyProjectRolesDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.companyProjectRolesDict)));
    localStorage.setItem("companyUsersDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.companyUsersDict)));
    localStorage.setItem("userAssignmentsDict", JSON.stringify(this.dictionaryUtilitiesService.GetRawDictionary(this.userAssignmentsDict)));
  }

  /*
    Attempts to recover any and all serialized CMS data cached in localstorage.
    TODO - If we find that the data we possess is outdated, pull again.
  */
  private TryRecoverCMSData(): boolean
  {
    try
    {
      let usersDictJSON = localStorage.getItem("usersDict");
      if (usersDictJSON != null)
      {
        let objUsersDict = JSON.parse(usersDictJSON);
        this.usersDict = new Map<string, User>();

        Object.values(objUsersDict).forEach(objUser => 
        {
          let user: User = new User(objUser);
          this.usersDict.set(user.user_id, user);
        });
      }

      let companiesDictJSON = localStorage.getItem("companiesDict");
      if (companiesDictJSON != null)
      {
        let objCompaniesDict = JSON.parse(companiesDictJSON);
        this.companiesDict = new Map<string, Company>();

        Object.values(objCompaniesDict).forEach(objCompany => 
        {
          let company: Company = new Company(objCompany);
          this.companiesDict.set(company.company_id, company);
        });
      }

      let companyUsersDictJSON = localStorage.getItem("companyUsersDict");
      if (companyUsersDictJSON != null)
      {
        let objCompanyUsersDict = JSON.parse(companyUsersDictJSON);
        this.companyUsersDict = new Map<string, Set<string>>();

        Object.keys(objCompanyUsersDict).forEach(companyID => 
        {
          let companyUsersArray = new Set<string>();

          Object.values(objCompanyUsersDict[companyID]).forEach((userID: string) => 
          {
            companyUsersArray.add(userID);
          });

          this.companyUsersDict.set(companyID, companyUsersArray);
        });
      }

      let userRolesDictJSON = localStorage.getItem("userRolesDict");
      if (userRolesDictJSON != null)
      {
        let objUserRolesDict = JSON.parse(userRolesDictJSON);
        this.userRolesDict = new Map<string, UserRole>();

        Object.values(objUserRolesDict).forEach(objUserRole => 
        {
          let userRole: UserRole = new UserRole(objUserRole);
          this.userRolesDict.set(userRole.user_role_id, userRole);
        });
      }

      let projectRolesDictJSON = localStorage.getItem("projectRolesDict");
      if (projectRolesDictJSON != null)
      {
        let objProjectRolesDict = JSON.parse(projectRolesDictJSON);
        this.projectRolesDict = new Map<string, ProjectRole>();

        Object.values(objProjectRolesDict).forEach(objProjectRole => 
        {
          let projectRole: ProjectRole = new ProjectRole(objProjectRole);
          this.projectRolesDict.set(projectRole.project_role_id, projectRole);
        });
      }

      let projectsDictJSON = localStorage.getItem("projectsDict");
      if (projectsDictJSON != null)
      {
        let objProjectsDict = JSON.parse(projectsDictJSON);
        this.projectsDict = new Map<string, Project>();

        Object.values(objProjectsDict).forEach(objProject => 
        {
          let project: Project = new Project(objProject);
          this.projectsDict.set(project.project_id, project);
        });
      }

      let companyProjectsJSON = localStorage.getItem("companyProjectsDict");
      if (companyProjectsJSON != null)
      {
        let objCompanyProjectsDict = JSON.parse(companyProjectsJSON);
        this.companyProjectsDict = new Map<string, Set<string>>();

        Object.keys(objCompanyProjectsDict).forEach(companyID => 
        {
          let companyProjectsArray = new Set<string>();

          Object.values(objCompanyProjectsDict[companyID]).forEach((projectID: string) => 
          {
            companyProjectsArray.add(projectID);
          });

          this.companyProjectsDict.set(companyID, companyProjectsArray);
        });
      }

      let companyProjectRolesJSON = localStorage.getItem("companyProjectRolesDict");
      if (companyProjectRolesJSON != null)
      {
        let objCompanyRolesDict = JSON.parse(companyProjectRolesJSON);
        this.companyProjectRolesDict = new Map<string, Set<string>>();

        Object.keys(objCompanyRolesDict).forEach(companyID => 
        {
          let companyRolesArray = new Set<string>();

          Object.values(objCompanyRolesDict[companyID]).forEach((roleID: string) => 
          {
            companyRolesArray.add(roleID);
          });

          this.companyProjectRolesDict.set(companyID, companyRolesArray);
        });
      }

      let userAssignmentsDictJSON = localStorage.getItem("userAssignmentsDict");
      if (userAssignmentsDictJSON != null)
      {
        let objUserAssignmentsDict = JSON.parse(userAssignmentsDictJSON);
        this.userAssignmentsDict = new Map<string, Set<ProjectAssignment>>();

        Object.keys(objUserAssignmentsDict).forEach(userID => 
        {
          let userAssignmentsSet = new Set<ProjectAssignment>();

          Object.values(objUserAssignmentsDict[userID]).forEach(objAssignment => 
          {
            let assignment: ProjectAssignment = new ProjectAssignment(objAssignment);
            userAssignmentsSet.add(assignment);
          });

          this.userAssignmentsDict.set(userID, userAssignmentsSet);
        });
      }

      return companiesDictJSON != null && projectsDictJSON != null;
    }
    catch (error)
    {
      console.log(error);
      this.authService.Logout();
      return false;
    }
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, Host, ViewChildren, QueryList, ChangeDetectorRef, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AuthService } from '../auth.service';
import { Unit, Project, EAvailabilityCode, EOrientationType, ProjectRole, Model } from 'src/DataModels';
import { AppUtilityService } from '../app-utility.service';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatDialog } from '@angular/material/dialog';
import { CreateUnitDialogComponent } from '../dialog/create-unit-dialog/create-unit-dialog.component';
import { ProjectsService } from '../projects.service';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ImportCsvDialogComponent } from '../admin/dialog/import-csv-dialog/import-csv-dialog.component';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CreateWorksheetDialogComponent } from '../dialog/worksheets/create-worksheet-dialog/create-worksheet-dialog.component';
import { SuitesInnerTableViewComponent } from '../suites-inner-table-view/suites-inner-table-view.component';
import { ModelTypesTableViewComponent } from '../model-types-table-view/model-types-table-view.component';
import { CreateUnitDialogClientComponent } from '../dialog/create-unit-dialog-client/create-unit-dialog-client.component';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: SuitesTableViewComponent }],
  selector: 'app-suites-table-view',
  templateUrl: './suites-table-view.component.html',
  styleUrls: ['./suites-table-view.component.css'],
})
export class SuitesTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Unit>;
  @ViewChild('suitesTablePaginator', {read: MatPaginator, static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  // displayedColumns is what specifies the order in which each table column is rendered
  public clientDisplayedColumns = ['Checkbox', 'Unit No.', 'Unit Name', 'Unit Type', 'Unit Size', 'Floor', 'Orientation', 'Building', 'Base Price', 'Total Price', 'Included Premium', 'Availability', 'Sticky Column'];
  public adminDisplayedColumns = ['Checkbox', 'Unit ID', 'Unit No.', 'Unit Name', 'Unit Type', 'Unit Size', 'Floor', 'Orientation', 'Building', 'Base Price', 'Total Price', 'Included Premium', 'Availability', 'Sticky Column'];

  public suitesDataSource: MatTableDataSource<Unit> = new MatTableDataSource<Unit>();
  
  public expandedRow: Unit = null;
  private prevExpandedRow: Unit = null;

  public selectedUnitIDs: Set<number> = new Set<number>(); 

  public unitNameFilterField: FormControl = new FormControl();
  public buildingFilterField: FormControl = new FormControl()
  public floorFilterField: FormControl = new FormControl();
  public orientationFilterField: FormControl = new FormControl();
  public availabilityFilterField: FormControl = new FormControl();

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  private innerTableRef: ComponentRef<SuitesInnerTableViewComponent> = null;
  private innerTableRootDiv = null;

  constructor(@Host() private parent: ProjectDetailsTableViewComponent, private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  {
    super();
  }

  public OnUploadCSVButtonPressed()
  {
    const dialogRef = this.dialog.open(ImportCsvDialogComponent, {width: '600px', height: '425px', data: { selectedProject: this.project, importRequestType: "suites"}});

    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result == true)
      {
        this.appUtilityService.StoreCMSData();
        this.RenderTableRows();

        this.parent.RefreshAllTables();
      }
    });
  }

  public ClearSuitesFilter()
  {
    this.unitNameFilterField.setValue(null);
    this.buildingFilterField.setValue(null);
    this.floorFilterField.setValue(null);
    this.orientationFilterField.setValue(null);
    this.availabilityFilterField.setValue(null);
    this.suitesDataSource.filter = "";
  }

  public ApplySuitesTableFilter()
  {
    let filterDataDict = {};
    filterDataDict["unitName"] = this.unitNameFilterField.value;
    filterDataDict["building"] = this.buildingFilterField.value;
    filterDataDict["floor"] = this.floorFilterField.value;
    filterDataDict["orientation"] = this.orientationFilterField.value;
    filterDataDict["availability"] = this.availabilityFilterField.value;

    let strFilterData: string = JSON.stringify(filterDataDict);
    this.suitesDataSource.filter = strFilterData;

    // Also refresh the state of selectedUnitIDs so that we don't
    // accidentally leave any rows outside of the filtered data selected
    this.selectedUnitIDs.clear();
    this.OnSelectAllCheckboxChanged(this.selectAllCheckbox.checked);
  }

  private ValidateSuitesTableFilter(data: Unit, filter: string): boolean
  {
    let filterDataDict = JSON.parse(filter);
    let numFieldsMatched: number = 0;
    let numFieldsQueried: number = 0;

    if (filterDataDict["unitName"] != null && filterDataDict["unitName"] != "")
    {
      ++numFieldsQueried;

      let currentUnitName = this.GetModelTypeName(data);
      if (currentUnitName.toLowerCase().indexOf(filterDataDict["unitName"].toLowerCase()) != -1)
      {
        ++numFieldsMatched;
      }
    }

    if (filterDataDict["building"] != null && filterDataDict["building"] != "")
    {
      ++numFieldsQueried;

      let currentBuildingName: string = this.GetBuildingName(data);
      if (currentBuildingName.toLowerCase().indexOf(filterDataDict["building"].toLowerCase()) != -1)
      {
        ++numFieldsMatched;
      }
    }

    if (filterDataDict["floor"] != null && filterDataDict["floor"] != "")
    {
      ++numFieldsQueried;

      if (data.floor_number.toString() == filterDataDict["floor"]) 
      { 
        ++numFieldsMatched;
      }
    }

    if (filterDataDict["orientation"] != null)
    {
      ++numFieldsQueried;

      if (data.orientation == filterDataDict["orientation"])
      {
        ++numFieldsMatched
      }
    }

    if (filterDataDict["availability"] != null)
    {
      ++numFieldsQueried;

      if (data.availability == filterDataDict["availability"])
      {
        ++numFieldsMatched
      }
    }

    return numFieldsMatched == numFieldsQueried;
  }

  public GetAllProjectFloors(): IterableIterator<number>
  {
    if (this.project.floors.size > 0)
    {
      let uniqueFloorNumbers: Set<number> = new Set<number>();

      for (let floor of this.project.floors.values())
      {
        uniqueFloorNumbers.add(floor.floor_number);
      }

      return uniqueFloorNumbers.values();
    }
    else
    {
      return null;
    }
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public GetOrientationTypeArray(): Array<string>
  {
    let keys = Object.keys(EOrientationType);
    return keys;
  }

  public GetOrientationTypeCodeByName(orientation: string): number
  {
    return EOrientationType[orientation];
  }

  public RenderTableRows()
  {
    let suites = Array.from(this.project.units.values());

    suites = suites.sort((s1, s2) => 
    {
      if (s1.suite_number < s2.suite_number)
      {
        return -1;
      }
      else if (s1.suite_number > s2.suite_number)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.suitesDataSource.data = suites;
    
    this.table.renderRows();

    if (this.floorFilterField.value != null || this.buildingFilterField.value != null || this.unitNameFilterField.value != null || this.orientationFilterField.value != null || this.availabilityFilterField.value != null)
    {
      this.ApplySuitesTableFilter();
    }

    this.changeDetectorRef.detectChanges();
  }

  private SortColumnDataAccessor(row: Unit, columnHeader: string) : string | number
  {
    switch (columnHeader)
    {
      case "Unit ID":
        return row.unit_id;
      case "Unit No.":
        return row.suite_number;
      case "Unit Name":
        return this.GetModelTypeName(row);
      case "Unit Type":
        return this.GetModelTypeDescription(row);
      case "Unit Size":
        return this.GetSquareFootage(row);
      case "Floor":
        return row.floor_number;
      case "Orientation":
        return row.orientation;
      case "Building":
        return this.GetBuildingName(row);
      case "Base Price":
        return this.GetUnitBasePrice(row);
      case "Total Price":
        return this.GetUnitTotalPrice(row);
      case "Included Premium":
        return this.GetUnitPremium(row);
      case "Availability":
        return row.availability;
      default:
        return null;
    }
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }

  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminDisplayedColumns : this.clientDisplayedColumns;
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.suitesDataSource.sort = this.matSort;
    this.suitesDataSource.sortingDataAccessor = this.SortColumnDataAccessor.bind(this);
    this.suitesDataSource.filterPredicate = this.ValidateSuitesTableFilter.bind(this);
    this.suitesDataSource.paginator = this.paginator;

    this.RenderTableRows();

    this.InitInnerTableView();
  }

  public InitInnerTableView()
  {
    this.innerTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SuitesInnerTableViewComponent);
    this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);

    this.applicationRef.attachView(this.innerTableRef.hostView);
  }

  public BindInnerTableView(unit: Unit)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.unit_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
    }

    this.innerTableRef.instance.unit = unit;
    let expandableRowContainer = document.getElementById('inner-table-host-' + unit.unit_id);
    expandableRowContainer.appendChild(this.innerTableRootDiv);
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let unit of this.suitesDataSource.filteredData)
      {
        this.selectedUnitIDs.add(unit.unit_id);
      }
    }
    else
    {
      this.selectedUnitIDs.clear();
    }
  }

  public OnUnitCheckboxChanged(checked: boolean, unitID: number)
  {
    if (checked == true)
    {
      this.selectedUnitIDs.add(unitID);
    }
    else
    {
      this.selectedUnitIDs.delete(unitID);
    }
  }

  public GetModelTypeName(unit: Unit): string
  {
    let modelType = this._project.model_types.get(unit.model_type_id);
    if (modelType != null)
    {
      return modelType.name;
    }
    else
    {
      return "";
    }
  }

  public GetModelTypeDescription(unit: Unit): string
  {
    let modelType = this._project.model_types.get(unit.model_type_id);
    if (modelType != null)
    {
      return modelType.description;
    }
    else
    {
      return "";
    }
  }

  public GetSquareFootage(unit: Unit): string
  {
    let modelType = this._project.model_types.get(unit.model_type_id);
    if (modelType != null)
    {
      return modelType.square_footage.toString() + " sq ft";
    }
    else
    {
      return "";
    }
  }

  public GetBuildingName(unit: Unit): string
  {
    let building = this._project.buildings.get(unit.building_id);
    if (building != null)
    {
      return building.name;
    }
    else
    {
      return "";
    }
  }

  public GetUnitBasePrice(unit: Unit): number
  {
    let modelType = this.project.model_types.get(unit.model_type_id);
    if (modelType != null)
    {
      return modelType.price;
    }
  }

  public GetUnitTotalPrice(unit: Unit): number
  {
    let totalPrice: number = unit.premium + unit.orientation_premium;

    let modelType = this.project.model_types.get(unit.model_type_id);
    if (modelType != null)
    {
      totalPrice += modelType.price;
    }

    let building = this.project.buildings.get(unit.building_id);
    if (building != null)
    {
      totalPrice += building.premium;

      for (let floor of this.project.floors.values())
      {
        if (floor.building_id == building.building_id && floor.floor_number == unit.floor_number)
        {
          totalPrice += floor.premium;
          break;
        }
      }
    }

    return totalPrice;
  }

  public GetUnitPremium(unit: Unit): number
  {
    let totalPrice: number = unit.premium + unit.orientation_premium;

    let building = this.project.buildings.get(unit.building_id);
    if (building != null)
    {
      totalPrice += building.premium;

      for (let floor of this.project.floors.values())
      {
        if (floor.building_id == building.building_id && floor.floor_number == unit.floor_number)
        {
          totalPrice += floor.premium;
          break;
        }
      }
    }

    return totalPrice;
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing || projectRole.can_hold_units || projectRole.can_finalize_sales || projectRole.can_modify_unit_data;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(unit: Unit)
  {
    let tempSet = new Array<number>();
    tempSet.push(unit.unit_id);

    let dialogRef = null;

    if (this.authService.IsActiveUserAdmin())
    {
      dialogRef = this.dialog.open(CreateUnitDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, selectedUnitIDs: tempSet} });
    }
    else
    {
      dialogRef = this.dialog.open(CreateUnitDialogClientComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, selectedUnitIDs: tempSet} });
    }

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectUnit(this.project.project_id, unit.unit_id, result).subscribe((updatedUnit: Unit) => 
        {
          if (updatedUnit != null)
          {
            // Need to delete and reset the unit since the unit ID
            // may have changed, therefore making the old unit ID
            // key invalid
            this.project.units.delete(unit.unit_id);
            this.project.units.set(updatedUnit.unit_id, updatedUnit);

            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  public ShouldDisplayMultiEditButton(): boolean
  {
    return this.ShouldDisplayEditButton() && this.selectedUnitIDs.size > 0;
  } 

  public OnMultiEditButtonPressed()
  {
    let dialogRef = null;

    if (this.authService.IsActiveUserAdmin())
    {
      dialogRef = this.dialog.open(CreateUnitDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, selectedUnitIDs: Array.from(this.selectedUnitIDs)} });
    }
    else
    {
      dialogRef = this.dialog.open(CreateUnitDialogClientComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, selectedUnitIDs: Array.from(this.selectedUnitIDs)} });
    }

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectSelectedUnits(this.project.project_id, Array.from(this.selectedUnitIDs), result).subscribe((updatedUnits: Array<Unit>) => 
        {
          if (updatedUnits != null)
          {
            for (let unit of updatedUnits)
            {
              this.project.units.set(unit.unit_id, unit);
            }

            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateUnitDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewUnit(this.project.project_id, result).subscribe((newUnit: Unit) => 
        {
          if (newUnit != null)
          {
            this.project.units.set(newUnit.unit_id, newUnit);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.selectedUnitIDs.size > 0 && this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnDeleteButtonPressed(): void 
  {
    if (this.selectedUnitIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Units?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteUnitsFromProject(this.project.project_id, Array.from(this.selectedUnitIDs)).subscribe((updatedUnits: Map<number, Unit>) => 
          {
            if (updatedUnits != null)
            {
              this.project.units = updatedUnits;
              this.appUtilityService.StoreCMSData();
              this.selectedUnitIDs.clear();
              
              this.RenderTableRows();
            }
          });
        }
      });
    }
  }

  HasSelectedRows(): boolean { return this.selectedUnitIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedUnitIDs; } 
  ClearSelectedRows(): void { this.selectedUnitIDs.clear(); }

  ShouldDisplayCreateWorksheetButton(suite: Unit): boolean
  {
    if (this.project.uses_worksheets && suite.availability == EAvailabilityCode.Available)
    {
      if (this.authService.IsActiveUserAdmin())
      {
        return this.authService.activeUserRole.can_edit_projects_data;
      }
      else if (!this.authService.IsActiveUserAdmin())
      {
        let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
        if (activeProjectRole !== null)
        {
          return activeProjectRole.can_create_worksheets;
        }
      }
    }

    return false;
  }

  OnCreateWorksheetButtonPressed(suite: Unit)
  {
    const dialogRef = this.dialog.open(CreateWorksheetDialogComponent, { panelClass: 'worksheets-dialog', width: '80%', height: '100%', data: { selectedProject: this.project, selectedSuite: suite } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewWorksheet(this.project, result).subscribe(result => 
        {
          if (result == true)
          {
            this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: true) =>
              {
                this.appUtilityService.StoreCMSData();
                this.parent.RefreshAllTables();
              });
          }
        });
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesTableViewComponent } from './companies-table-view/companies-table-view.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { AdminRoutingModule } from './admin-routing/admin-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// Angular Material Library Imports
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatInput } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSelectModule } from '@angular/material/select';

import { DeleteCompanyDialogComponent } from './dialog/delete-company-dialog/delete-company-dialog.component';
import { DeleteProjectDialogComponent } from './dialog/delete-project-dialog/delete-project-dialog.component';
import { UserRolesTableViewComponent } from './user-roles-table-view/user-roles-table-view.component';
import { CreateUserRoleDialogComponent } from './dialog/create-user-role-dialog/create-user-role-dialog.component';
import { SettingsViewComponent } from './settings-view/settings-view.component';
import { ImportCsvDialogComponent } from './dialog/import-csv-dialog/import-csv-dialog.component';

@NgModule({
  declarations: [
    CompaniesTableViewComponent,
    DeleteCompanyDialogComponent,
    DeleteProjectDialogComponent,
    UserRolesTableViewComponent,
    CreateUserRoleDialogComponent,
    SettingsViewComponent,
    ImportCsvDialogComponent
  ],
  entryComponents: [
    DeleteCompanyDialogComponent,
    DeleteProjectDialogComponent,
    CreateUserRoleDialogComponent,
    ImportCsvDialogComponent,

  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatSliderModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    RouterModule,
  ]
})
export class AdminModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesTableViewComponent } from '../companies-table-view/companies-table-view.component';
import { AuthGuard } from 'src/app/auth.guard';
import { EUserType } from 'src/DataModels';
import { CompanyProjectsTableViewComponent } from 'src/app/company-projects-table-view/company-projects-table-view.component';
import { UserRolesTableViewComponent } from '../user-roles-table-view/user-roles-table-view.component';
import { SettingsViewComponent } from '../settings-view/settings-view.component';

const adminRoutes: Routes = [
  { 
    path: 'admin/companies', 
    component: CompaniesTableViewComponent, 
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.neezo] } 
  },
  {
    path: 'admin/company/:companyID/projects',
    component: CompanyProjectsTableViewComponent, 
    canActivate: [AuthGuard], 
    data: { allowedRoles: [EUserType.neezo] } 
  },
  {
    path: 'admin/userroles',
    component: UserRolesTableViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.neezo] }
  },
  {
    path: 'admin/settings',
    component: SettingsViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.neezo] }
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(adminRoutes)
  ]
})
export class AdminRoutingModule { }

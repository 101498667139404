import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, fromEventPattern } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { WebCMSAPIResponse, ProjectRole } from 'src/DataModels';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class ProjectRolesService 
{
  constructor(private http: HttpClient, private dictionaryUtility: DictionaryUtilitiesService, private appConstants: AppConstantsService) { }

  public GetAllProjectRoles(): Observable<Map<string, ProjectRole>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectRolesResponse(response);
    }));
  }

  public GetProjectRolesByCompanyID(companyID: string): Observable<Map<string, ProjectRole>>
  {
    let params = new HttpParams().set("companyID", companyID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectRolesResponse(response);
    }));
  }

  public GetProjectRolesByUserID(userID: string): Observable<Map<string, ProjectRole>>
  {
    let params = new HttpParams().set("userID", userID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectRolesResponse(response);
    }));
  }

  public CreateNewProjectRole(roleInfoDict: Map<string, any>): Observable<ProjectRole>
  {
    let roleInfoRaw = this.dictionaryUtility.GetRawDictionary(roleInfoDict);
    let params = {"roleInfoDict": JSON.stringify(roleInfoRaw)};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let roleInfoFromPacket = JSON.parse(response.data);
        let role: ProjectRole = new ProjectRole(roleInfoFromPacket);
        return role;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public UpdateProjectRole(roleID: string, companyID: string, roleInfoDict: Map<string, any>): Observable<ProjectRole>
  {
    let updateInfoRaw = this.dictionaryUtility.GetRawDictionary(roleInfoDict);
    let params = {"roleID": roleID, "companyID": companyID, "roleInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let roleInfoFromPacket = JSON.parse(response.data);
        let role: ProjectRole = new ProjectRole(roleInfoFromPacket);
        return role;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public DeleteProjectRoles(companyID: string, selectedRoleIDs: Array<string>): Observable<boolean>
  {
    let params = {"companyID": companyID, "rolesSelection": JSON.stringify(selectedRoleIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return response.status == "success";
    }));
  }

  private ParseGetProjectRolesResponse(response: WebCMSAPIResponse): Map<string, ProjectRole>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let rolesDict: Map<string, ProjectRole> = new Map<string, ProjectRole>();
      let responseDataJSON = JSON.parse(response.data);

      for (let roleJSON of responseDataJSON)
      {
        let roleInfoFromPacket = JSON.parse(roleJSON);
        let role: ProjectRole = new ProjectRole(roleInfoFromPacket);
        rolesDict.set(role.project_role_id, role);
      }

      return rolesDict;
    }
    else if (response.status == "error")
    {
      console.log(response);
      return null;
    }
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Floor, Project } from 'src/DataModels';
import { MatDialog } from '@angular/material/dialog';
import { CreateFloorsDialogComponent } from '../dialog/create-floors-dialog/create-floors-dialog.component';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { GenerateFloorsDialogComponent } from '../dialog/generate-floors-dialog/generate-floors-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { CMSTableViewBase } from '../interfaces/cms-component-base';

@Component({
  selector: 'app-floors-table-view',
  templateUrl: './floors-table-view.component.html',
  styleUrls: ['./floors-table-view.component.css']
})
export class FloorsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Floor>;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  private clientColumns = ['Floor Number', 'Premium', 'Edit'];
  private adminColumns = ['Checkbox', 'Floor ID', 'Building ID', 'Floor Number', 'Premium', 'Edit'];

  public selectedFloorIds: Set<number> = new Set<number>();

  public floorsDataSource: MatTableDataSource<Floor> = new MatTableDataSource<Floor>();

  private _buildingID: number = null;

  @Input()
  set buildingID(buildingID: number)
  {
    this._buildingID = buildingID;    
  }

  get buildingID(): number 
  {
    return this._buildingID;
  }

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public GetBuildingFloorsIterator(): Array<Floor>
  {
    let floorsArray: Array<Floor> = new Array<Floor>();

    for (let floor of this.project.floors.values())
    {
      if (floor.building_id == this.buildingID)
      {
        floorsArray.push(floor);
      }
    }

    return floorsArray;
  }

  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;
  }

  constructor(public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef) 
  {
    super();
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let floor of this.floorsDataSource.filteredData)
      {
        this.selectedFloorIds.add(floor.floor_id);
      }
    }
    else
    {
      this.selectedFloorIds.clear();
    }
  }

  public OnFloorCheckboxToggled(checked: boolean, floorID: number)
  {
    if (checked == true)
    {
      this.selectedFloorIds.add(floorID);
    }
    else
    {
      this.selectedFloorIds.delete(floorID);
    }
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnAddButtonPressed()
  {
    const dialogRef = this.dialog.open(GenerateFloorsDialogComponent, { width: '350px', height: '300px', data: { selectedProject: this.project, selectedBuildingID: this.buildingID} });

    dialogRef.afterClosed().subscribe(generateFloorsInfoDict => 
    {
      if (generateFloorsInfoDict != null)
      {
        this.projectsService.GenerateNewFloors(this.project, this.buildingID, generateFloorsInfoDict).subscribe((result: boolean) => 
        {
          if (result == true)
          {
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  public OnEditButtonPressed(floor: Floor)
  {
    const dialogRef = this.dialog.open(CreateFloorsDialogComponent, { width: '350px', height: '300px', data: { selectedProject: this.project, selectedBuildingID: this.buildingID,  floorToEdit: floor} });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectFloor(this.project.project_id, floor.floor_id, result).subscribe((updatedFloor: Floor) => 
        {
          this.project.floors.set(floor.floor_id, updatedFloor);
          this.appUtilityService.StoreCMSData();
          this.RenderTableRows();
        });
      }
    });
  }

  public ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedFloorIds.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnDeleteButtonPressed()
  {
    if (this.selectedFloorIds.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Floors?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteFloorsFromProject(this.project.project_id, Array.from(this.selectedFloorIds)).subscribe((updatedFloors: Map<number, Floor>) => 
          {
            this.project.floors = updatedFloors;
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          });
        }
      });
    }
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
  }

  public RenderTableRows()
  {
    this.floorsDataSource.data = this.GetBuildingFloorsIterator();
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  HasSelectedRows(): boolean { return this.selectedFloorIds.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedFloorIds; } 
  ClearSelectedRows(): void { this.selectedFloorIds.clear(); }
}

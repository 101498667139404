import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NvyveChart } from 'src/app/project-analytics-view/project-analytics-view.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ViewAllChartDataDialogData
{
  chart: NvyveChart
}

@Component({
  selector: 'app-view-all-chart-data-dialog',
  templateUrl: './view-all-chart-data-dialog.component.html',
  styleUrls: ['./view-all-chart-data-dialog.component.css']
})
export class ViewAllChartDataDialogComponent implements OnInit, AfterViewInit
{
  @ViewChild('canvas', {read: ElementRef, static: false}) canvas: ElementRef;

  constructor(private dialogRef: MatDialogRef<ViewAllChartDataDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ViewAllChartDataDialogData) { }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    if (this.data.chart !== null)
    {
      let instance = this.data.chart.GetFullDataChartInstance();
      instance.canvas = this.canvas.nativeElement;
    }
  }
}

import * as moment from 'moment';

export enum EOrientationType
{
    N = "N",
    NE = "NE",
    NW = "NW",
    E = "E",
    W = "W",
    S = "S",
    SE = "SE",
    SW = "SW",
}

export enum EAvailabilityCode
{
    Unavailable = 0,
    Available = 1,
    Hold = 4,
    Sold = 3,
    WorksheetPending = 20,
}

export enum EArchitectureType
{
    condo = "condo",
    townhome = "townhome",
    detached = "detached"
}

export enum EUserType
{
    none = "none",
    client = "client",
    neezo = "neezo",
    all = "all",
}

/*
    Typed HTTP Responses take interface parameters because
    they are incapable of producing actual instances of a
    class.
*/
export interface WebCMSAPIResponse
{
    // Response status type, either "success" or "error"
    status: string;

    // HTTP Response code
    code: number;

    // JSON string representation of any data returned from the server
    data: string;

    // Optional error message parameter
    message: string;
}

export class User
{
    public user_id: string;
    public username: string;
    public email: string;
    public company_id: string;
    public user_role_id: string;

    /*
        JSON.parse doesn't actually create a new User instance, but instead just creates
        a raw JavaScript Object that just so happens to have all of the fields returned by
        the database. Therefore, the User constructor needs to pick up the slack and
        construct an actual User constructor using the data retrieved from the server
        as well as instantiating any properties, if any, that are only created locally
        and not retrieved from the server. This quirk of JSON.parse also implies that
        the object it returns (which isn't a User) also wouldn't have any of User's methods.
    */
    constructor(userInfoFromPacket)
    {
        this.user_id = (userInfoFromPacket.hasOwnProperty("user_id")) ? userInfoFromPacket.user_id : "";
        this.username = (userInfoFromPacket.hasOwnProperty("username")) ? userInfoFromPacket.username : "";
        this.email = (userInfoFromPacket.hasOwnProperty("email")) ? userInfoFromPacket.email : "";
        this.company_id = (userInfoFromPacket.hasOwnProperty("company_id")) ? userInfoFromPacket.company_id : "";
        this.user_role_id = (userInfoFromPacket.hasOwnProperty("user_role_id")) ? userInfoFromPacket.user_role_id : "";
    }
}

export class Company
{
    public company_id: string;
    public company_name: string;

    constructor(companyInfoFromPacket)
    {
        this.company_id = (companyInfoFromPacket.hasOwnProperty("company_id")) ? companyInfoFromPacket.company_id : "";
        this.company_name = (companyInfoFromPacket.hasOwnProperty("company_name")) ? companyInfoFromPacket.company_name : "";
    }
}

export class ProjectAssignment
{
    public user_id: string;
    public project_id: string;
    public project_role_id: string;
    public company_id: string;

    constructor(assignmentInfoFromPacket)
    {
        this.user_id = (assignmentInfoFromPacket.hasOwnProperty("user_id")) ? assignmentInfoFromPacket.user_id : "";
        this.project_id = (assignmentInfoFromPacket.hasOwnProperty("project_id")) ? assignmentInfoFromPacket.project_id : "";
        this.project_role_id = (assignmentInfoFromPacket.hasOwnProperty("project_role_id")) ? assignmentInfoFromPacket.project_role_id : "";
        this.company_id = (assignmentInfoFromPacket.hasOwnProperty("company_id")) ? assignmentInfoFromPacket.company_id : "";
    }
}

export class ProjectRole
{
    public project_role_id: string;
    public company_id: string;
    public project_role_name: string;

    public can_toggle_livesite_availability_display: boolean;
    public can_toggle_livesite_pricing_display: boolean;

    public can_view_data: boolean;
    public can_generate_reports: boolean;
    public can_modify_pricing: boolean;
    public can_modify_unit_data: boolean;
    public can_create_worksheets: boolean;
    public can_approve_worksheets: boolean;
    public can_finalize_sales: boolean;
    public can_hold_units: boolean;
    public can_add_users: boolean;
    public can_edit_project_settings: boolean;
    public can_manage_floorplans: boolean;

    constructor(roleInfoFromPacket)
    {
        this.project_role_id = roleInfoFromPacket.hasOwnProperty("project_role_id") ? roleInfoFromPacket.project_role_id : "";
        this.company_id = roleInfoFromPacket.hasOwnProperty("company_id") ? roleInfoFromPacket.company_id : "";
        this.project_role_name = roleInfoFromPacket.hasOwnProperty("project_role_name") ? roleInfoFromPacket.project_role_name : "";

        this.can_toggle_livesite_availability_display = roleInfoFromPacket.hasOwnProperty("can_toggle_livesite_availability_display") ? roleInfoFromPacket.can_toggle_livesite_availability_display == "1" : false;
        this.can_toggle_livesite_pricing_display = roleInfoFromPacket.hasOwnProperty("can_toggle_livesite_pricing_display") ? roleInfoFromPacket.can_toggle_livesite_pricing_display == "1" : false;

        this.can_view_data = roleInfoFromPacket.hasOwnProperty("can_view_data") ? roleInfoFromPacket.can_view_data == "1" : false;
        this.can_generate_reports = roleInfoFromPacket.hasOwnProperty("can_generate_reports") ? roleInfoFromPacket.can_generate_reports == "1" : false;
        this.can_modify_pricing = roleInfoFromPacket.hasOwnProperty("can_modify_pricing") ? roleInfoFromPacket.can_modify_pricing == "1" : false;
        this.can_modify_unit_data = roleInfoFromPacket.hasOwnProperty("can_modify_unit_data") ? roleInfoFromPacket.can_modify_unit_data == "1" : false;
        this.can_create_worksheets = roleInfoFromPacket.hasOwnProperty("can_create_worksheets") ? roleInfoFromPacket.can_create_worksheets == "1" : false;
        this.can_approve_worksheets = roleInfoFromPacket.hasOwnProperty("can_approve_worksheets") ? roleInfoFromPacket.can_approve_worksheets == "1" : false;
        this.can_finalize_sales = roleInfoFromPacket.hasOwnProperty("can_finalize_sales") ? roleInfoFromPacket.can_finalize_sales == "1" : false;
        this.can_hold_units = roleInfoFromPacket.hasOwnProperty("can_hold_units") ? roleInfoFromPacket.can_hold_units == "1" : false;
        this.can_add_users = roleInfoFromPacket.hasOwnProperty("can_add_users") ? roleInfoFromPacket.can_add_users == "1" : false;
        this.can_edit_project_settings = roleInfoFromPacket.hasOwnProperty("can_edit_project_settings")  ? roleInfoFromPacket.can_edit_project_settings == "1" : false;
        this.can_manage_floorplans = roleInfoFromPacket.hasOwnProperty("can_manage_floorplans") ? roleInfoFromPacket.can_manage_floorplans == "1" : false;
    }

    private GetPermissionTotal(): number
    {
        let total: number = 0;
        total += +this.can_view_data;
        total += +this.can_generate_reports;
        total += +this.can_modify_pricing;
        total += +this.can_modify_unit_data;
        total += +this.can_create_worksheets;
        total += +this.can_approve_worksheets;
        total += +this.can_finalize_sales;
        total += +this.can_hold_units;
        total += +this.can_add_users;

        return total;
    }

    public CompareTo(other: ProjectRole): number
    {
        let permissionTotal: number = this.GetPermissionTotal();
        let otherPermissionTotal: number = other.GetPermissionTotal();

        if (permissionTotal > otherPermissionTotal)
        {
            return 1;
        }
        else if (permissionTotal < otherPermissionTotal)
        {
            return -1;
        }
        else
        {
            return 0;
        }
    }
}

export class UserRole
{
    public user_role_id: string;
    public user_type: EUserType;
    public user_role_name: string;

    public can_access_frontend: boolean;
    public can_access_backend: boolean;

    public can_view_companies: boolean;
    public can_create_companies: boolean;
    public can_edit_companies: boolean;
    public can_delete_companies: boolean;

    public can_view_users: boolean;
    public can_create_users: boolean;
    public can_edit_users: boolean;
    public can_assign_user_roles: boolean;
    public can_delete_users: boolean;

    public can_view_user_roles: boolean;
    public can_create_user_roles: boolean;
    public can_edit_user_roles: boolean;
    public can_delete_user_roles: boolean;

    public can_view_project_roles: boolean;
    public can_create_project_roles: boolean;
    public can_edit_project_roles: boolean;
    public can_delete_project_roles: boolean;

    public can_view_projects: boolean;
    public can_create_projects: boolean;
    public can_edit_projects: boolean;
    public can_edit_projects_data: boolean;
    public can_delete_projects: boolean;

    constructor(roleInfoFromPacket)
    {
        this.user_role_id = roleInfoFromPacket.hasOwnProperty("user_role_id") ? roleInfoFromPacket.user_role_id : "";
        this.user_role_name = roleInfoFromPacket.hasOwnProperty("user_role_name") ? roleInfoFromPacket.user_role_name : "";
        this.user_type = roleInfoFromPacket.hasOwnProperty("user_type") ? roleInfoFromPacket.user_type : EUserType.none;

        this.can_access_frontend = roleInfoFromPacket.hasOwnProperty("can_access_frontend") ? roleInfoFromPacket.can_access_frontend == "1" : false;
        this.can_access_backend = roleInfoFromPacket.hasOwnProperty("can_access_backend") ? roleInfoFromPacket.can_access_backend == "1" : false;

        this.can_view_companies = roleInfoFromPacket.hasOwnProperty("can_view_companies") ? roleInfoFromPacket.can_view_companies == "1" : false;
        this.can_create_companies = roleInfoFromPacket.hasOwnProperty("can_create_companies") ? roleInfoFromPacket.can_create_companies == "1" : false;
        this.can_edit_companies = roleInfoFromPacket.hasOwnProperty("can_edit_companies") ? roleInfoFromPacket.can_edit_companies == "1" : false;
        this.can_delete_companies = roleInfoFromPacket.hasOwnProperty("can_delete_companies") ? roleInfoFromPacket.can_delete_companies == "1" : false;

        this.can_view_users = roleInfoFromPacket.hasOwnProperty("can_view_users") ? roleInfoFromPacket.can_view_users == "1" : false;
        this.can_create_users = roleInfoFromPacket.hasOwnProperty("can_create_users") ? roleInfoFromPacket.can_create_users == "1" : false;
        this.can_edit_users = roleInfoFromPacket.hasOwnProperty("can_edit_users") ? roleInfoFromPacket.can_edit_users == "1" : false;
        this.can_assign_user_roles = roleInfoFromPacket.hasOwnProperty("can_assign_user_roles") ? roleInfoFromPacket.can_assign_user_roles == "1" : false;
        this.can_delete_users = roleInfoFromPacket.hasOwnProperty("can_delete_users") ? roleInfoFromPacket.can_delete_users == "1" : false;

        this.can_view_user_roles = roleInfoFromPacket.hasOwnProperty("can_view_user_roles") ? roleInfoFromPacket.can_view_user_roles == "1" : false;
        this.can_create_user_roles = roleInfoFromPacket.hasOwnProperty("can_create_user_roles") ? roleInfoFromPacket.can_create_user_roles == "1" : false;
        this.can_edit_user_roles = roleInfoFromPacket.hasOwnProperty("can_edit_user_roles") ? roleInfoFromPacket.can_edit_user_roles == "1" : false;
        this.can_delete_user_roles = roleInfoFromPacket.hasOwnProperty("can_delete_user_roles") ? roleInfoFromPacket.can_delete_user_roles == "1" : false;

        this.can_view_project_roles = roleInfoFromPacket.hasOwnProperty("can_view_project_roles") ? roleInfoFromPacket.can_view_project_roles == "1" : false;
        this.can_create_project_roles = roleInfoFromPacket.hasOwnProperty("can_create_project_roles") ? roleInfoFromPacket.can_create_project_roles == "1" : false;
        this.can_edit_project_roles = roleInfoFromPacket.hasOwnProperty("can_edit_project_roles") ? roleInfoFromPacket.can_edit_project_roles == "1" : false;
        this.can_delete_project_roles = roleInfoFromPacket.hasOwnProperty("can_delete_project_roles") ? roleInfoFromPacket.can_delete_project_roles == "1" : false;

        this.can_view_projects = roleInfoFromPacket.hasOwnProperty("can_view_projects") ? roleInfoFromPacket.can_view_projects == "1" : false;
        this.can_create_projects = roleInfoFromPacket.hasOwnProperty("can_create_projects") ? roleInfoFromPacket.can_create_projects == "1" : false;
        this.can_edit_projects = roleInfoFromPacket.hasOwnProperty("can_edit_projects") ? roleInfoFromPacket.can_edit_projects == "1" : false;
        this.can_edit_projects_data = roleInfoFromPacket.hasOwnProperty("can_edit_projects_data") ? roleInfoFromPacket.can_edit_projects_data == "1" : false;
        this.can_delete_projects = roleInfoFromPacket.hasOwnProperty("can_delete_projects") ? roleInfoFromPacket.can_delete_projects == "1" : false
    }

    private GetPermissionTotal(): number
    {
        let total: number = 0;
        total += +this.can_access_frontend;
        total += +this.can_access_backend;
        
        total += +this.can_view_companies;
        total += +this.can_create_companies;
        total += +this.can_edit_companies;
        total += +this.can_delete_companies;

        total += +this.can_view_users;
        total += +this.can_create_users;
        total += +this.can_edit_users;
        total += +this.can_assign_user_roles;
        total += +this.can_delete_users;

        total += +this.can_view_user_roles;
        total += +this.can_create_user_roles;
        total += +this.can_edit_user_roles;
        total += +this.can_delete_user_roles;

        total += +this.can_view_project_roles;
        total += +this.can_create_project_roles;
        total += +this.can_edit_project_roles;
        total += +this.can_delete_project_roles;

        total += +this.can_view_projects;
        total += +this.can_create_projects;
        total += +this.can_edit_projects;
        total += +this.can_delete_projects;

        return total;
    }

    public CompareTo(other: UserRole): number
    {
        let permissionTotal: number = this.GetPermissionTotal();
        let otherPermissionTotal: number = other.GetPermissionTotal();

        if (permissionTotal > otherPermissionTotal)
        {
            return 1;
        }
        else if (permissionTotal < otherPermissionTotal)
        {
            return -1;
        }
        else
        {
            return 0;
        }
    }
}

export enum EAnalyticType
{
    elevationId = 0,
    modelId = 1,
    suiteId = 2,
    lotId = 3,
    amenityId = 4,
    buildingId = 5,
    buildingAmenityId = 6,
}

export class ProjectAnalytic
{
    public analytic_id: number;
    public analytic_type: EAnalyticType;
    public data_type_id: number;
    public data_type_name: string;
    public count: number;

    constructor(analyticInfoFromPacket)
    {
        this.analytic_id = (analyticInfoFromPacket.hasOwnProperty("analytic_id")) ? parseInt(analyticInfoFromPacket.analytic_id) : 0;
        this.analytic_type = (analyticInfoFromPacket.hasOwnProperty("analytic_type")) ? parseInt(analyticInfoFromPacket.analytic_type) : null;
        this.data_type_id = (analyticInfoFromPacket.hasOwnProperty("data_type_id")) ? parseInt(analyticInfoFromPacket.data_type_id) : 0;
        this.data_type_name = (analyticInfoFromPacket.hasOwnProperty("data_type_name")) ? analyticInfoFromPacket.data_type_name : "";
        this.count = (analyticInfoFromPacket.hasOwnProperty("count")) ? parseInt(analyticInfoFromPacket.count) : 0;
    }
}

export class ModuleTransition
{
    public event_id: number;
    public prev_module_name: string;
    public next_module_name: string;
    public prev_module_elapsed_time: number;

    constructor(transitionInfoFromPacket)
    {
        this.event_id = (transitionInfoFromPacket.hasOwnProperty("event_id")) ? parseInt(transitionInfoFromPacket.event_id) : 0;
        this.prev_module_name = (transitionInfoFromPacket.hasOwnProperty("prev_module_name")) ? transitionInfoFromPacket.prev_module_name : "";
        this.next_module_name = (transitionInfoFromPacket.hasOwnProperty("next_module_name")) ? transitionInfoFromPacket.next_module_name : "";
        this.prev_module_elapsed_time = (transitionInfoFromPacket.hasOwnProperty("prev_module_elapsed_time")) ? parseFloat(transitionInfoFromPacket.prev_module_elapsed_time) : 0;
    }
}

export class ProjectSession
{
    public session_id: string;
    public date_time: moment.Moment = null;

    public module_transitions: Array<ModuleTransition>;

    constructor(sessionInfoFromPacket)
    {
        this.session_id = (sessionInfoFromPacket.hasOwnProperty("session_id")) ? sessionInfoFromPacket.session_id : "";
        this.date_time = (sessionInfoFromPacket.hasOwnProperty("date_time")) ? moment(sessionInfoFromPacket.date_time) : null;

        this.module_transitions = new Array<ModuleTransition>();
        if (sessionInfoFromPacket.hasOwnProperty("module_transitions") && Object.keys(sessionInfoFromPacket.module_transitions).length > 0)
        {
            for (let transitionInfoFromPacket  of sessionInfoFromPacket.module_transitions)
            {
                let transition: ModuleTransition = new ModuleTransition(transitionInfoFromPacket);
                this.module_transitions.push(transition);
            }
        }
    }
}

export enum EWorksheetStatus
{
    PendingApproval = "PendingApproval",
    Approved = "Approved",
    Declined = "Declined",
    ContractSigned = "ContractSigned"
}

export enum EIdentificationType
{
    None = "None",
    Passport = "Passport",
    DriverLicense = "DriverLicense",
}

export enum EInternationalStatus
{
    None = "None",
    Resident = "Resident",
    International = "International",
}

export class Purchaser
{
    public purchaser_id: string;
    public full_name: string;
    public date_of_birth: moment.Moment;
    public id_type: EIdentificationType;
    public id_number: string;
    public address: string;
    public city: string;
    public postal_code: string;
    public province: string;
    public country: string;
    public phone: string;
    public email: string;

    public id_photo_name: string;
    public id_photo_data: string;
    public cheque_photo_name: string;
    public cheque_photo_data: string;

    public sin_number: string;

    constructor(purchaserInfoFromPacket)
    {
        this.purchaser_id = (purchaserInfoFromPacket.hasOwnProperty("purchaser_id")) ? purchaserInfoFromPacket.purchaser_id : "";
        this.full_name = (purchaserInfoFromPacket.hasOwnProperty("full_name")) ? purchaserInfoFromPacket.full_name : "";
        this.date_of_birth = (purchaserInfoFromPacket.hasOwnProperty("date_of_birth")) ? moment(purchaserInfoFromPacket.date_of_birth, "DD/MM/YYYY") : null;
        this.id_type = (purchaserInfoFromPacket.hasOwnProperty("id_type")) ? purchaserInfoFromPacket.id_type : EIdentificationType.None;
        this.id_number = (purchaserInfoFromPacket.hasOwnProperty("id_number")) ? purchaserInfoFromPacket.id_number : "";
        this.address = (purchaserInfoFromPacket.hasOwnProperty("address")) ? purchaserInfoFromPacket.address : "";
        this.city = (purchaserInfoFromPacket.hasOwnProperty("city")) ? purchaserInfoFromPacket.city : "";
        this.postal_code = (purchaserInfoFromPacket.hasOwnProperty("postal_code")) ? purchaserInfoFromPacket.postal_code : "";
        this.province = (purchaserInfoFromPacket.hasOwnProperty("province")) ? purchaserInfoFromPacket.province : "";
        this.country = (purchaserInfoFromPacket.hasOwnProperty("country")) ? purchaserInfoFromPacket.country : "";
        this.phone = (purchaserInfoFromPacket.hasOwnProperty("phone")) ? purchaserInfoFromPacket.phone : "";
        this.email = (purchaserInfoFromPacket.hasOwnProperty("email")) ? purchaserInfoFromPacket.email : "";
        
        this.id_photo_name = (purchaserInfoFromPacket.hasOwnProperty("id_photo_name")) ? purchaserInfoFromPacket.id_photo_name : "";
        this.id_photo_data = (purchaserInfoFromPacket.hasOwnProperty("id_photo_data")) ? purchaserInfoFromPacket.id_photo_data : "";
        this.cheque_photo_name = (purchaserInfoFromPacket.hasOwnProperty("cheque_photo_name")) ? purchaserInfoFromPacket.cheque_photo_name : "";
        this.cheque_photo_data = (purchaserInfoFromPacket.hasOwnProperty("cheque_photo_data")) ? purchaserInfoFromPacket.cheque_photo_data : "";

        this.sin_number = (purchaserInfoFromPacket.hasOwnProperty("sin_number")) ? purchaserInfoFromPacket.sin_number : "";
    }
}

export class Worksheet
{
    public worksheet_id: number;
    public str_purchaser_ids: string;
    public str_model_ids: string;
    public str_unit_ids: string;
    public str_linker_ids: string;
    public parking_preferred: boolean;
    public locker_preferred: boolean;
    public purchaser_international_status: EInternationalStatus;
    public agent_full_name: string;
    public agent_email: string;
    public agent_phone: string;
    public brokerage_name: string;
    public brokerage_address: string;
    public brokerage_city: string;
    public brokerage_province: string;
    public brokerage_postal_code: string;
    public notes: string;
    public worksheet_status: EWorksheetStatus;
    public date_created: moment.Moment;
    public date_last_edited: moment.Moment;
    public creator_username: string;
    public editor_username: string;
    public deposit_percentage: number;
    public security_deposit_amount: number;

    constructor(worksheetInfoFromPacket)
    {
        this.worksheet_id = (worksheetInfoFromPacket.hasOwnProperty("worksheet_id")) ? Number(worksheetInfoFromPacket.worksheet_id) : 0;
        this.str_purchaser_ids = (worksheetInfoFromPacket.hasOwnProperty("purchaser_ids")) ? worksheetInfoFromPacket.purchaser_ids : "";
        this.str_model_ids = (worksheetInfoFromPacket.hasOwnProperty("model_ids")) ? worksheetInfoFromPacket.model_ids : "";
        this.str_unit_ids = (worksheetInfoFromPacket.hasOwnProperty("unit_ids")) ? worksheetInfoFromPacket.unit_ids : "";
        this.str_linker_ids = (worksheetInfoFromPacket.hasOwnProperty("linker_ids")) ? worksheetInfoFromPacket.linker_ids : "";
        this.parking_preferred = (worksheetInfoFromPacket.hasOwnProperty("parking_preferred")) ? worksheetInfoFromPacket.parking_preferred == "1" : false;
        this.locker_preferred = (worksheetInfoFromPacket.hasOwnProperty("locker_preferred")) ? worksheetInfoFromPacket.locker_preferred == "1" : false;
        this.purchaser_international_status = (worksheetInfoFromPacket.hasOwnProperty("purchaser_international_status")) ? worksheetInfoFromPacket.purchaser_international_status : EInternationalStatus.None;
        this.agent_full_name = (worksheetInfoFromPacket.hasOwnProperty("agent_full_name")) ? worksheetInfoFromPacket.agent_full_name : "";
        this.agent_email = (worksheetInfoFromPacket.hasOwnProperty("agent_email")) ? worksheetInfoFromPacket.agent_email : "";
        this.agent_phone = (worksheetInfoFromPacket.hasOwnProperty("agent_phone")) ? worksheetInfoFromPacket.agent_phone : "";
        this.brokerage_name = (worksheetInfoFromPacket.hasOwnProperty("brokerage_name")) ? worksheetInfoFromPacket.brokerage_name : "";
        this.brokerage_address = (worksheetInfoFromPacket.hasOwnProperty("brokerage_address")) ? worksheetInfoFromPacket.brokerage_address : "";
        this.brokerage_city = (worksheetInfoFromPacket.hasOwnProperty("brokerage_city")) ? worksheetInfoFromPacket.brokerage_city : "";
        this.brokerage_province = (worksheetInfoFromPacket.hasOwnProperty("brokerage_province")) ? worksheetInfoFromPacket.brokerage_province : "";
        this.brokerage_postal_code = (worksheetInfoFromPacket.hasOwnProperty("brokerage_postal_code")) ? worksheetInfoFromPacket.brokerage_postal_code : "";
        this.notes = (worksheetInfoFromPacket.hasOwnProperty("notes")) ? worksheetInfoFromPacket.notes : "";
        this.worksheet_status = (worksheetInfoFromPacket.hasOwnProperty("worksheet_status")) ? worksheetInfoFromPacket.worksheet_status : EWorksheetStatus.PendingApproval;
        this.date_created = (worksheetInfoFromPacket.hasOwnProperty("date_created")) ? moment(worksheetInfoFromPacket.date_created) : null;
        this.date_last_edited = (worksheetInfoFromPacket.hasOwnProperty("date_last_edited")) ? moment(worksheetInfoFromPacket.date_last_edited) : null;
        this.creator_username = (worksheetInfoFromPacket.hasOwnProperty("creator_username")) ? worksheetInfoFromPacket.creator_username : "";
        this.editor_username = (worksheetInfoFromPacket.hasOwnProperty("editor_username")) ? worksheetInfoFromPacket.editor_username : "";
        this.deposit_percentage = (worksheetInfoFromPacket.hasOwnProperty("deposit_percentage")) ? Number(worksheetInfoFromPacket.deposit_percentage) : 0;
        this.security_deposit_amount = (worksheetInfoFromPacket.hasOwnProperty("security_deposit_amount")) ? Number(worksheetInfoFromPacket.security_deposit_amount) : 0;
    }
}

export class Deposit
{
    public worksheet_id: number;
    public deposit_number: number;
    public days_until_due: number;

    constructor(depositInfoFromPacket = null)
    {
        this.worksheet_id = depositInfoFromPacket != null && depositInfoFromPacket.hasOwnProperty("worksheet_id") ? Number(depositInfoFromPacket.worksheet_id) : null;
        this.deposit_number = depositInfoFromPacket != null && depositInfoFromPacket.hasOwnProperty("deposit_number") ? Number(depositInfoFromPacket.deposit_number) : 0;
        this.days_until_due = depositInfoFromPacket != null && depositInfoFromPacket.hasOwnProperty("days_until_due") ? Number(depositInfoFromPacket.days_until_due) : 0;
    }
} 

export class FloorplanDocument
{
    public document_id: string;
    public date_created: string;
    public document_name: string;
    public document_url: string;

    constructor(documentInfoFromPacket)
    {
        this.document_id = documentInfoFromPacket.hasOwnProperty("document_id") ? documentInfoFromPacket.document_id : "";
        this.date_created = documentInfoFromPacket.hasOwnProperty("date_created") ? documentInfoFromPacket.date_created : null;
        this.document_name = documentInfoFromPacket.hasOwnProperty("document_name") ? documentInfoFromPacket.document_name : "";
        this.document_url = documentInfoFromPacket.hasOwnProperty("document_url") ? documentInfoFromPacket.document_url : "";
    }
}

export class Contract
{
    public contract_id: number;
    public worksheet_id: number;
    public contract_pdf_data: string;
    public contract_pdf_name: string;
    public date_created: string;

    constructor(contractInfoFromPacket)
    {
        this.contract_id = contractInfoFromPacket.hasOwnProperty("contract_id") ? Number(contractInfoFromPacket.contract_id) : null;
        this.worksheet_id = contractInfoFromPacket != null && contractInfoFromPacket.hasOwnProperty("worksheet_id") ? Number(contractInfoFromPacket.worksheet_id) : null;
        this.contract_pdf_name = contractInfoFromPacket.hasOwnProperty("contract_pdf_name") ? contractInfoFromPacket.contract_pdf_name : null;
        this.contract_pdf_data = contractInfoFromPacket.hasOwnProperty("contract_pdf_data") ? contractInfoFromPacket.contract_pdf_data : null;
        this.date_created = contractInfoFromPacket.hasOwnProperty("date_created") ? contractInfoFromPacket.date_created : null;
    }
}

export class ContractTemplate
{
    public contract_template_id: number;
    public company_id: string;
    public contract_template_pdf_name: string;
    public contract_template_pdf_data: string;
    public date_created: string;

    constructor(contractInfoFromPacket)
    {
        this.contract_template_id = contractInfoFromPacket.hasOwnProperty("contract_template_id") ? Number(contractInfoFromPacket.contract_template_id) : null;
        this.company_id = contractInfoFromPacket.hasOwnProperty("company_id") ? contractInfoFromPacket.company_id : null;
        this.contract_template_pdf_name = contractInfoFromPacket.hasOwnProperty("contract_template_pdf_name") ? contractInfoFromPacket.contract_template_pdf_name : null;
        this.contract_template_pdf_data = contractInfoFromPacket.hasOwnProperty("contract_template_pdf_data") ? contractInfoFromPacket.contract_template_pdf_data : null;
        this.date_created = contractInfoFromPacket.hasOwnProperty("date_created") ? contractInfoFromPacket.date_created : null
    }
}

export enum EContractFieldType
{
  TextField = "/Tx",
  Checkbox = "/Btn",
}

export class Project
{
    public project_id: string;
    public project_name: string;
    public company_id: string;
    
    public has_detached: boolean;
    public has_condos: boolean;
    public has_townhomes: boolean;

    public should_display_unit_availability: boolean;
    public should_display_pricing: boolean;
    public require_login_screen: boolean;

    public uses_worksheets: boolean;
    public uses_worksheets_parking_field: boolean;
    public uses_worksheets_locker_field: boolean;
    public uses_worksheets_international_status_field: boolean;
    public uses_worksheets_id_type_field: boolean;
    public uses_worksheets_upload_photo_id_field: boolean;
    public uses_worksheets_upload_cheque_photo_field: boolean;
    public uses_worksheets_notes_section_field: boolean;
    public uses_worksheets_deposit_structure_field: boolean;

    public uses_floorplans: boolean;

    public default_deposit_percentage: number;
    public default_security_deposit_amount: number;

    public contract_templates: Map<number, ContractTemplate>;

    public contract_template_pdf_name: string;
    public contract_template_pdf_data: string;

    public buildings: Map<number, Building>;
    public elevations: Map<number, Elevation>;
    public floors: Map<number, Floor>;
    public homes: Map<number, Home>;
    public linkers: Map<number, Linker>;
    public lots: Map<number, Lot>;
    public phases: Map<number, Phase>;
    public models: Map<number, Model>;
    public model_types: Map<number, ModelType>;
    public units: Map<number, Unit>;
    public analytics: Map<EAnalyticType, Array<ProjectAnalytic>>;
    public sessions: Array<ProjectSession>;
    public worksheets: Map<number, Worksheet>;
    public purchasers: Map<string, Purchaser>;
    public defaultDepositStructure: Array<Deposit>;
    public worksheetDeposits: Map<number, Array<Deposit>>;
    public contracts: Map<number, Contract>;
    public floorplanDocuments: Map<string, FloorplanDocument>;

    public isMetadataPulled: boolean = false;

    public time_last_updated: moment.Moment = null;
    public time_last_stored: moment.Moment = null;

    constructor(projectInfoFromPacket)
    {
        this.project_id = projectInfoFromPacket.hasOwnProperty("project_id") ? projectInfoFromPacket.project_id : "";
        this.project_name = projectInfoFromPacket.hasOwnProperty("project_name") ? projectInfoFromPacket.project_name : "";
        this.company_id = projectInfoFromPacket.hasOwnProperty("company_id") ? projectInfoFromPacket.company_id : "";

        this.has_detached = projectInfoFromPacket.hasOwnProperty("has_detached") ? projectInfoFromPacket.has_detached == "1" : false;
        this.has_condos = projectInfoFromPacket.hasOwnProperty("has_condos") ? projectInfoFromPacket.has_condos == "1" : false;
        this.has_townhomes = projectInfoFromPacket.hasOwnProperty("has_townhomes") ? projectInfoFromPacket.has_townhomes == "1" : false;

        this.should_display_unit_availability = projectInfoFromPacket.hasOwnProperty("should_display_unit_availability") ? projectInfoFromPacket.should_display_unit_availability == "1" : false;
        this.should_display_pricing = projectInfoFromPacket.hasOwnProperty("should_display_pricing") ? projectInfoFromPacket.should_display_pricing == "1" : false;
        this.require_login_screen = projectInfoFromPacket.hasOwnProperty("require_login_screen") ? projectInfoFromPacket.require_login_screen == "1" : false;

        this.uses_worksheets = projectInfoFromPacket.hasOwnProperty("uses_worksheets") ? projectInfoFromPacket.uses_worksheets == "1" : false;
        this.uses_worksheets_parking_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_parking_field") ? projectInfoFromPacket.uses_worksheets_parking_field == "1" : false;
        this.uses_worksheets_locker_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_locker_field") ? projectInfoFromPacket.uses_worksheets_locker_field == "1" : false;
        this.uses_worksheets_international_status_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_international_status_field") ? projectInfoFromPacket.uses_worksheets_international_status_field == "1" : false;
        this.uses_worksheets_id_type_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_id_type_field") ? projectInfoFromPacket.uses_worksheets_id_type_field == "1" : false;
        this.uses_worksheets_upload_photo_id_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_upload_photo_id_field") ? projectInfoFromPacket.uses_worksheets_upload_photo_id_field == "1" : false;
        this.uses_worksheets_upload_cheque_photo_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_upload_cheque_photo_field") ? projectInfoFromPacket.uses_worksheets_upload_cheque_photo_field == "1" : false;
        this.uses_worksheets_notes_section_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_notes_section_field") ? projectInfoFromPacket.uses_worksheets_notes_section_field == "1" : false;
        this.uses_worksheets_deposit_structure_field = projectInfoFromPacket.hasOwnProperty("uses_worksheets_deposit_structure_field") ? projectInfoFromPacket.uses_worksheets_deposit_structure_field == "1" : false;

        this.uses_floorplans = projectInfoFromPacket.hasOwnProperty("uses_floorplans") ? projectInfoFromPacket.uses_floorplans == "1" : false;

        this.default_deposit_percentage = projectInfoFromPacket.hasOwnProperty("default_deposit_percentage") ? Number(projectInfoFromPacket.default_deposit_percentage) : 0;
        this.default_security_deposit_amount = projectInfoFromPacket.hasOwnProperty("default_security_deposit_amount") ? Number(projectInfoFromPacket.default_security_deposit_amount) : 0;

        this.contract_template_pdf_name = projectInfoFromPacket.hasOwnProperty("contract_template_pdf_name") ? projectInfoFromPacket.contract_template_pdf_name : null;
        this.contract_template_pdf_data = projectInfoFromPacket.hasOwnProperty("contract_template_pdf_data") ? projectInfoFromPacket.contract_template_pdf_data : null;

        if (projectInfoFromPacket.hasOwnProperty("time_last_updated"))
        {
            let strTimeLastUpdated = projectInfoFromPacket.time_last_updated;
            this.time_last_updated = moment(strTimeLastUpdated);
        }

        if (projectInfoFromPacket.hasOwnProperty("time_last_stored"))
        {
            this.time_last_stored = moment(projectInfoFromPacket.time_last_stored);
        }
    }

    public UpdateTimeLastStored()
    {
        this.time_last_stored = moment();
    }

    public IsOutOfDate(): boolean
    {
        if (this.time_last_updated == null || this.time_last_stored == null) { return false; }
        return this.time_last_stored.isBefore(this.time_last_updated);
    }
}

export class Building
{
    public building_id: number;
    public name: string;
    public premium: number;
    public phase_id: number;
    public availability: number;

    constructor(buildingInfoFromPacket)
    {
        this.building_id = (buildingInfoFromPacket.hasOwnProperty("building_id")) ? Number(buildingInfoFromPacket.building_id) : 0;
        this.name = (buildingInfoFromPacket.hasOwnProperty("name")) ? buildingInfoFromPacket.name : "";
        this.premium = (buildingInfoFromPacket.hasOwnProperty("premium")) ? Number(buildingInfoFromPacket.premium) : 0;
        this.phase_id = (buildingInfoFromPacket.hasOwnProperty("phase_id")) ? Number(buildingInfoFromPacket.phase_id) : 0;
        this.availability = (buildingInfoFromPacket.hasOwnProperty("availability")) ? Number(buildingInfoFromPacket.availability) : 0;
    }
}

export class Elevation
{
    public elevation_id: number;
    public detached_id: number;
    public elevation: string;
    public price: number;
    public footage: number;
    public unit_description: string;

    constructor(elevationInfoFromPacket)
    {
        this.elevation_id = (elevationInfoFromPacket.hasOwnProperty("elevation_id")) ? Number(elevationInfoFromPacket.elevation_id) : 0;
        this.detached_id = (elevationInfoFromPacket.hasOwnProperty("detached_id")) ? Number(elevationInfoFromPacket.detached_id) : 0;
        this.elevation = (elevationInfoFromPacket.hasOwnProperty("elevation")) ? elevationInfoFromPacket.elevation : "";
        this.price = (elevationInfoFromPacket.hasOwnProperty("price")) ? Number(elevationInfoFromPacket.price) : 0;
        this.footage = (elevationInfoFromPacket.hasOwnProperty("footage")) ? Number(elevationInfoFromPacket.footage) : 0;
        this.unit_description = (elevationInfoFromPacket.hasOwnProperty("unit_description")) ? elevationInfoFromPacket.unit_description : "";
    }
}

export class Floor
{
    public floor_id: number;
    public building_id: number;
    public floor_number: number;
    public premium: number;

    constructor(floorInfoFromPacket)
    {
        this.floor_id = (floorInfoFromPacket.hasOwnProperty("floor_id")) ? Number(floorInfoFromPacket.floor_id) : 0;
        this.building_id = (floorInfoFromPacket.hasOwnProperty("building_id")) ? Number(floorInfoFromPacket.building_id) : 0;
        this.floor_number = (floorInfoFromPacket.hasOwnProperty("floor_number")) ? Number(floorInfoFromPacket.floor_number) : 0;
        this.premium = (floorInfoFromPacket.hasOwnProperty("premium")) ? Number(floorInfoFromPacket.premium) : 0;
    }
}

export class Home
{
    public detached_id: number;
    public name: string;
    public number: string;
    public start_elevation: number;
    public storeys: number;
    public bedrooms: number;
    public bathrooms: number;
    public has_parking: boolean;
    public has_locker: boolean;
    public has_storage: boolean;
    public has_balcony: boolean;
    public has_terrace: boolean;
    public has_den: boolean;
    public orientation: string;
    public frontage: number;

    constructor(homeInfoFromPacket)
    {
        this.detached_id = (homeInfoFromPacket.hasOwnProperty("detached_id")) ? Number(homeInfoFromPacket.detached_id) : 0;
        this.name = (homeInfoFromPacket.hasOwnProperty("name")) ? homeInfoFromPacket.name : "";
        this.number = (homeInfoFromPacket.hasOwnProperty("number")) ? homeInfoFromPacket.number : "";
        this.start_elevation = (homeInfoFromPacket.hasOwnProperty("start_elevation")) ? Number(homeInfoFromPacket.start_elevation) : 0;
        this.storeys = (homeInfoFromPacket.hasOwnProperty("storeys")) ? Number(homeInfoFromPacket.storeys) : 0;
        this.bedrooms = (homeInfoFromPacket.hasOwnProperty("bedrooms")) ? Number(homeInfoFromPacket.bedrooms) : 0;
        this.bathrooms = (homeInfoFromPacket.hasOwnProperty("bathrooms")) ? Number(homeInfoFromPacket.bathrooms) : 0;
        this.has_parking = (homeInfoFromPacket.hasOwnProperty("has_parking")) ? homeInfoFromPacket.has_parking == "1" : false;
        this.has_locker = (homeInfoFromPacket.hasOwnProperty("has_locker")) ? homeInfoFromPacket.has_locker == "1"  : false;
        this.has_storage = (homeInfoFromPacket.hasOwnProperty("has_storage")) ? homeInfoFromPacket.has_storage == "1"  : false;
        this.has_balcony = (homeInfoFromPacket.hasOwnProperty("has_balcony")) ? homeInfoFromPacket.has_balcony == "1"  : false;
        this.has_terrace = (homeInfoFromPacket.hasOwnProperty("has_terrace")) ? homeInfoFromPacket.has_terrace == "1"  : false;
        this.has_den = (homeInfoFromPacket.hasOwnProperty("has_den")) ? homeInfoFromPacket.has_den == "1"  : false;
        this.orientation = (homeInfoFromPacket.hasOwnProperty("orientation")) ? homeInfoFromPacket.orientation : "";
        this.frontage = (homeInfoFromPacket.hasOwnProperty("frontage")) ? Number(homeInfoFromPacket.frontage) : 0;
    }
}

export class Linker
{
    public linker_id: number;
    public lot_id: number;
    public elevation_id: number;
    public availability: number;

    constructor(linkerInfoFromPacket)
    {
        this.linker_id = (linkerInfoFromPacket.hasOwnProperty("linker_id")) ? Number(linkerInfoFromPacket.linker_id) : 0;
        this.lot_id = (linkerInfoFromPacket.hasOwnProperty("lot_id")) ? Number(linkerInfoFromPacket.lot_id) : 0;
        this.elevation_id = (linkerInfoFromPacket.hasOwnProperty("elevation_id")) ? Number(linkerInfoFromPacket.elevation_id) : 0;
        this.availability = (linkerInfoFromPacket.hasOwnProperty("availability")) ? Number(linkerInfoFromPacket.availability) : 0;
    }
}

export class Phase
{
    public phase_id: number;
    public phase_name: string;
    public phase_status: number;

    constructor(phaseInfoFromPacket)
    {
        this.phase_id = (phaseInfoFromPacket.hasOwnProperty("phase_id")) ? Number(phaseInfoFromPacket.phase_id) : 0;
        this.phase_name = (phaseInfoFromPacket.hasOwnProperty("phase_name")) ? String(phaseInfoFromPacket.phase_name) : '';
        this.phase_status = (phaseInfoFromPacket.hasOwnProperty("phase_status")) ? Number(phaseInfoFromPacket.phase_status) : 0;
    }
}

export class Lot
{
    public lot_id: number;
    public phase_id: number;
    public number: number;
    public marketing_number: number;
    public lot_number: number;
    public availability: number;
    public grading: string;
    public lot_frontage: number;
    public mirror: boolean;

    public x_position: number;
    public y_position: number;
    public z_position: number;

    public x_rotation: number;
    public y_rotation: number;
    public z_rotation: number;
    public w_rotation: number;
    public rotation_angle: number;
    
    public lot_price_premium: number;
    public upgrade_price: number;
    public grading_price: number;
    public exterior_enhancement: boolean;

    constructor(lotInfoFromPacket)
    {
        this.lot_id = (lotInfoFromPacket.hasOwnProperty("lot_id")) ? Number(lotInfoFromPacket.lot_id) : 0;
        this.phase_id = (lotInfoFromPacket.hasOwnProperty("phase_id")) ? Number(lotInfoFromPacket.phase_id) : 0;
        this.number = (lotInfoFromPacket.hasOwnProperty("number")) ? Number(lotInfoFromPacket.number) : 0;
        this.marketing_number = (lotInfoFromPacket.hasOwnProperty("marketing_number")) ? Number(lotInfoFromPacket.marketing_number) : 0;
        this.lot_number = (lotInfoFromPacket.hasOwnProperty("lot_number")) ? Number(lotInfoFromPacket.lot_number) : 0;
        this.availability = (lotInfoFromPacket.hasOwnProperty("availability")) ? Number(lotInfoFromPacket.availability) : 0;
        this.grading = (lotInfoFromPacket.hasOwnProperty("grading")) ? lotInfoFromPacket.grading : "";
        this.lot_frontage = (lotInfoFromPacket.hasOwnProperty("lot_frontage")) ? Number(lotInfoFromPacket.lot_frontage) : 0;
        this.mirror = (lotInfoFromPacket.hasOwnProperty("mirror")) ? lotInfoFromPacket.mirror == "1" : false;
        
        this.x_position = (lotInfoFromPacket.hasOwnProperty("x_position")) ? Number(lotInfoFromPacket.x_position) : 0;
        this.y_position = (lotInfoFromPacket.hasOwnProperty("y_position")) ? Number(lotInfoFromPacket.y_position) : 0;
        this.z_position = (lotInfoFromPacket.hasOwnProperty("z_position")) ? Number(lotInfoFromPacket.z_position) : 0;

        this.x_rotation = (lotInfoFromPacket.hasOwnProperty("x_rotation")) ? Number(lotInfoFromPacket.x_rotation) : 0;
        this.y_rotation = (lotInfoFromPacket.hasOwnProperty("y_rotation")) ? Number(lotInfoFromPacket.y_rotation) : 0;
        this.z_rotation = (lotInfoFromPacket.hasOwnProperty("z_rotation")) ? Number(lotInfoFromPacket.z_rotation) : 0;
        this.w_rotation = (lotInfoFromPacket.hasOwnProperty("w_rotation")) ? Number(lotInfoFromPacket.w_rotation) : 0;
        this.rotation_angle = (lotInfoFromPacket.hasOwnProperty("rotation_angle")) ? Number(lotInfoFromPacket.rotation_angle) : 0;

        this.lot_price_premium = (lotInfoFromPacket.hasOwnProperty("lot_price_premium")) ? Number(lotInfoFromPacket.lot_price_premium) : 0;
        this.upgrade_price = (lotInfoFromPacket.hasOwnProperty("upgrade_price")) ? Number(lotInfoFromPacket.upgrade_price) : 0;
        this.grading_price = (lotInfoFromPacket.hasOwnProperty("grading_price")) ? Number(lotInfoFromPacket.grading_price) : 0;
        this.exterior_enhancement = (lotInfoFromPacket.hasOwnProperty("exterior_enhancement")) ? lotInfoFromPacket.exterior_enhancement == "1" : false;
    }
}

export class Model
{
    private str_building_ids: string;

    public model_id: number;
    public name: string;
    public architecture_type: EArchitectureType;
    public linked_building_ids: Array<number>;

    constructor(modelInfoFromPacket)
    {
        this.model_id = (modelInfoFromPacket.hasOwnProperty("model_id")) ? Number(modelInfoFromPacket.model_id) : 0;
        this.str_building_ids = (modelInfoFromPacket.hasOwnProperty("building_ids")) ? modelInfoFromPacket.building_ids : "";
        this.name = (modelInfoFromPacket.hasOwnProperty("name")) ? modelInfoFromPacket.name : "";
        this.architecture_type = (modelInfoFromPacket.hasOwnProperty("architecture_type")) ? modelInfoFromPacket.architecture_type : EArchitectureType.condo;

        this.linked_building_ids = new Array<number>();
        if (this.str_building_ids !== null && this.str_building_ids !== "")
        {
            let arrBuildingIDs: Array<string> = this.str_building_ids.split(",");
        
            for (let buildingID of arrBuildingIDs)
            {
                this.linked_building_ids.push(Number(buildingID));
            }
        }
    }
}

export class ModelType
{
    public model_type_id: number;
    public model_id: number;
    public name: string;
    public description: string;
    public square_footage: number;
    public square_footage_outdoor: number;
    public price: number;
    public num_beds: number;
    public num_baths: number;
    public has_den: boolean;

    constructor(modelTypeInfoFromPacket)
    {
        this.model_type_id = (modelTypeInfoFromPacket.hasOwnProperty("model_type_id")) ? Number(modelTypeInfoFromPacket.model_type_id) : 0;
        this.model_id = (modelTypeInfoFromPacket.hasOwnProperty("model_id")) ? Number(modelTypeInfoFromPacket.model_id) : 0;
        this.name = (modelTypeInfoFromPacket.hasOwnProperty("name")) ? modelTypeInfoFromPacket.name : "";
        this.description = (modelTypeInfoFromPacket.hasOwnProperty("description")) ? modelTypeInfoFromPacket.description : "";
        this.square_footage = (modelTypeInfoFromPacket.hasOwnProperty("square_footage")) ? Number(modelTypeInfoFromPacket.square_footage) : 0;
        this.square_footage_outdoor = (modelTypeInfoFromPacket.hasOwnProperty("square_footage_outdoor")) ? Number(modelTypeInfoFromPacket.square_footage_outdoor) : 0;
        this.price = (modelTypeInfoFromPacket.hasOwnProperty("price")) ? Number(modelTypeInfoFromPacket.price) : 0;
        this.num_beds = (modelTypeInfoFromPacket.hasOwnProperty("num_beds")) ? Number(modelTypeInfoFromPacket.num_beds) : 0;
        this.num_baths = (modelTypeInfoFromPacket.hasOwnProperty("num_baths")) ? Number(modelTypeInfoFromPacket.num_baths) : 0;
        this.has_den = (modelTypeInfoFromPacket.hasOwnProperty("has_den")) ? modelTypeInfoFromPacket.has_den == "1"  : false;
    }
}

export class Unit
{
    public unit_id: number;
    public suite_number: number;
    public building_id: number;
    public floor_number: number;
    public model_type_id: number;
    public facing_direction: string;
    public premium: number;
    public is_reversed: boolean;
    public parking_included: boolean;
    public locker_included: boolean;
    public storage_included: boolean;
    public orientation: string;
    public orientation_premium: number;
    public availability: number;

    constructor(unitInfoFromPacket)
    {
        this.unit_id = (unitInfoFromPacket.hasOwnProperty("unit_id")) ? Number(unitInfoFromPacket.unit_id) : 0;
        this.suite_number = (unitInfoFromPacket.hasOwnProperty("suite_number")) ? Number(unitInfoFromPacket.suite_number) : 0;
        this.building_id = (unitInfoFromPacket.hasOwnProperty("building_id")) ? Number(unitInfoFromPacket.building_id) : 0;
        this.floor_number = (unitInfoFromPacket.hasOwnProperty("floor_number")) ? Number(unitInfoFromPacket.floor_number) : 0;
        this.model_type_id = (unitInfoFromPacket.hasOwnProperty("model_type_id")) ? Number(unitInfoFromPacket.model_type_id) : 0;
        this.facing_direction = (unitInfoFromPacket.hasOwnProperty("facing_direction")) ? unitInfoFromPacket.facing_direction : "";
        this.premium = (unitInfoFromPacket.hasOwnProperty("premium")) ? Number(unitInfoFromPacket.premium) : 0;
        this.is_reversed = (unitInfoFromPacket.hasOwnProperty("is_reversed")) ? unitInfoFromPacket.is_reversed == "1" : false;
        this.parking_included = (unitInfoFromPacket.hasOwnProperty("parking_included")) ? unitInfoFromPacket.parking_included == "1" : false;
        this.locker_included = (unitInfoFromPacket.hasOwnProperty("locker_included")) ? unitInfoFromPacket.locker_included == "1" : false;
        this.storage_included = (unitInfoFromPacket.hasOwnProperty("storage_included")) ? unitInfoFromPacket.storage_included == "1" : false;
        this.orientation = (unitInfoFromPacket.hasOwnProperty("orientation")) ? unitInfoFromPacket.orientation : "";
        this.orientation_premium = (unitInfoFromPacket.hasOwnProperty("orientation_premium")) ? Number(unitInfoFromPacket.orientation_premium) : 0;
        this.availability = (unitInfoFromPacket.hasOwnProperty("availability")) ? Number(unitInfoFromPacket.availability) : 0;
    }
}
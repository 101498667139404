import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Building, Floor, WebCMSAPIResponse, Project } from 'src/DataModels';
import { AppUtilityService } from './app-utility.service';
import { ProjectsService } from './projects.service';
import { map } from 'rxjs/operators';
import { AppConstantsService } from './app-constants.service';

@Injectable({
  providedIn: 'root'
})
export class CsvUtilitiesService 
{
  constructor(private papa: Papa, private http: HttpClient, private appUtilityService: AppUtilityService, private projectsService: ProjectsService, private appConstants: AppConstantsService) { }

  public FromCSV(csvFilePath: string, importRequestType: string, forProject: Project, overwriteDatabase: boolean, modelNameParseDelimiter: string): Observable<boolean>
  {
    return new Observable<boolean>(observer => 
    {
      return this.http.get(csvFilePath, {responseType: 'text'}).subscribe((fileContents: string) => 
      {
        if (fileContents != null)
        {
          return this.papa.parse(fileContents, 
          {
            header: true,
            skipEmptyLines: true,
            worker: true,
            complete: (results, file) =>
            {
              return this.SendCSVImportRequest(results.data, importRequestType, forProject, overwriteDatabase, modelNameParseDelimiter).subscribe((response: WebCMSAPIResponse) => 
              {
                observer.next(this.projectsService.ParseGetProjectMetadataResponse(response, forProject));
                observer.complete();
                observer.unsubscribe();
              })
            },

            error: (error, file) =>
            {
              console.log(error);
              return false;
            }
          }); 
        }
        else
        {
          return false;
        }
      });
    });
  }

  private SendCSVImportRequest(rawCSVDict, importRequestType: string, project: Project, overwriteDatabase: boolean, modelNameParseDelimiter: string): Observable<WebCMSAPIResponse>
  {
    let params = {"csvDict": JSON.stringify(rawCSVDict), "importType": importRequestType, "projectID": project.project_id, "companyID": project.company_id, "overwriteDatabase": overwriteDatabase, "modelNameParseDelimiter": modelNameParseDelimiter};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/import`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      return response;
    }));
  }

  public ToCSV()
  {

  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

// Angular Material Library Imports
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatInput } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule, MatMenu } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule } from '@angular/material/core';

import { AppNavShellComponent } from './app-nav-shell/app-nav-shell.component';
import { AdminModule } from '../admin/admin.module';
import { AccordionListItemModule } from '../accordion-list-item/accordion-list-item.module';

@NgModule({
  declarations: [
    AppNavShellComponent,
  ],
  imports: [
    AdminModule,
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTreeModule,
    MatMenuModule,
    MatExpansionModule,
    MatRippleModule,
    RouterModule,
    AccordionListItemModule,
  ],
  exports: [
    AppNavShellComponent
  ]
})
export class AppCommonNavModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from 'ngx-clipboard'
import { MatMomentDateModule } from '@angular/material-moment-adapter';

// Angular Material Library Imports
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatInput } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule, MatList } from '@angular/material/list';
import { MatToolbarModule, } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { AppComponent } from './app.component';
import { UsersService } from './users.service';
import { CompaniesService } from './companies.service';
import { ProjectsService } from './projects.service';
import { ProjectRolesService } from './project-roles.service';
import { UserRolesService } from './user-roles.service';
import { AppUtilityService } from './app-utility.service';
import { AuthService } from './auth.service';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { fromEventPattern } from 'rxjs';
import { CompanyProjectsTableViewComponent } from './company-projects-table-view/company-projects-table-view.component';
import { AdminModule } from './admin/admin.module';
import { AuthGuard } from './auth.guard';
import { AppNavShellComponent } from './app-common-nav/app-nav-shell/app-nav-shell.component';
import { AppCommonNavModule } from './app-common-nav/app-common-nav.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AdminRoutingModule } from './admin/admin-routing/admin-routing.module';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingSpinnerOverlayService } from './loading-spinner-overlay.service';
import { LoadingSpinnerInterceptor } from './loading-spinner-interceptor';
import { ProjectDetailsTableViewComponent } from './project-details-table-view/project-details-table-view.component';
import { CreateCompanyDialogComponent } from './dialog/create-company-dialog/create-company-dialog.component';
import { CreateProjectDialogComponent } from './dialog/create-project-dialog/create-project-dialog.component';
import { CompanyRolesTableViewComponent } from './company-roles-table-view/company-roles-table-view.component';
import { UsersTableViewComponent } from './users-table-view/users-table-view.component';
import { CreateProjectRoleDialogComponent } from './dialog/create-project-role-dialog/create-project-role-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from './dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { UserDetailsFormComponent } from './user-details-form/user-details-form.component';
import { UserDetailsViewComponent } from './user-details-view/user-details-view.component';
import { CreateUserDialogComponent } from './dialog/create-user-dialog/create-user-dialog.component';
import { ProjectAssignmentsService } from './project-assignments.service';
import { ProjectAssignmentsDialogComponent } from './dialog/project-assignments-dialog/project-assignments-dialog.component';
import { SuitesTableViewComponent } from './suites-table-view/suites-table-view.component';
import { BuildingsTableViewComponent } from './buildings-table-view/buildings-table-view.component';
import { CreateBuildingsDialogComponent } from './dialog/create-buildings-dialog/create-buildings-dialog.component';
import { CreateFloorsDialogComponent } from './dialog/create-floors-dialog/create-floors-dialog.component';
import { FloorsTableViewComponent } from './floors-table-view/floors-table-view.component';
import { HomesTableViewComponent } from './homes-table-view/homes-table-view.component';
import { ElevationsTableViewComponent } from './elevations-table-view/elevations-table-view.component';
import { CreateHomeDialogComponent } from './dialog/create-home-dialog/create-home-dialog.component';
import { CreateElevationDialogComponent } from './dialog/create-elevation-dialog/create-elevation-dialog.component';
import { ModelsTableViewComponent } from './models-table-view/models-table-view.component';
import { ModelTypesTableViewComponent } from './model-types-table-view/model-types-table-view.component';
import { CreateModelDialogComponent } from './dialog/create-model-dialog/create-model-dialog.component';
import { CreateModelTypeDialogComponent } from './dialog/create-model-type-dialog/create-model-type-dialog.component';
import { LotsTableViewComponent } from './lots-table-view/lots-table-view.component';
import { CreateLotDialogComponent } from './dialog/create-lot-dialog/create-lot-dialog.component';
import { CreateUnitDialogComponent } from './dialog/create-unit-dialog/create-unit-dialog.component';
import { SuitesInnerTableViewComponent } from './suites-inner-table-view/suites-inner-table-view.component';
import { LinkersTableViewComponent } from './linkers-table-view/linkers-table-view.component';
import { CreateLinkerDialogComponent } from './dialog/create-linker-dialog/create-linker-dialog.component';
import { ErrorResponseInterceptor } from './error-response-interceptor';
import { ProjectUserAssignmentsDialogComponent } from './dialog/project-user-assignments-dialog/project-user-assignments-dialog.component';
import { SettingsService } from './settings.service';
import { CsvUtilitiesService } from './csv-utilities.service';
import { GenerateFloorsDialogComponent } from './dialog/generate-floors-dialog/generate-floors-dialog.component';
import { ModelsDualTableViewComponent } from './models-dual-table-view/models-dual-table-view.component';
import { CurrencyPipe } from '@angular/common';
import { ProjectAnalyticsService } from './project-analytics.service';
import { ProjectAnalyticsViewComponent } from './project-analytics-view/project-analytics-view.component';
import { ViewAllChartDataDialogComponent } from './dialog/view-all-chart-data-dialog/view-all-chart-data-dialog.component';
import { AuthInterceptor } from './auth-interceptor';
import { ProjectSettingsDialogComponent } from './dialog/project-settings/project-settings-dialog/project-settings-dialog.component';
import { WorksheetSettingsTabComponent } from './dialog/project-settings/worksheet-settings-tab/worksheet-settings-tab.component';
import { WorksheetsTableViewComponent } from './worksheets-table-view/worksheets-table-view.component';
import { CreateWorksheetDialogComponent } from './dialog/worksheets/create-worksheet-dialog/create-worksheet-dialog.component';
import { WorksheetPreferencesModuleComponent } from './dialog/worksheets/worksheet-preferences/worksheet-preferences-module/worksheet-preferences-module.component';
import { WorksheetAgentDetailsModuleComponent } from './dialog/worksheets/worksheet-agent-details-module/worksheet-agent-details-module.component';
import { WorksheetBrokerageDetailsModuleComponent } from './dialog/worksheets/worksheet-brokerage-details-module/worksheet-brokerage-details-module.component';
import { WorksheetPurchasersModuleComponent } from './dialog/worksheets/purchasers/worksheet-purchasers-module/worksheet-purchasers-module.component';
import { WorksheetPurchaserFormComponent } from './dialog/worksheets/purchasers/worksheet-purchaser-form/worksheet-purchaser-form.component';
import { WorksheetNotesModuleComponent } from './dialog/worksheets/worksheet-notes-module/worksheet-notes-module.component';
import { WorksheetDepositStructureModuleComponent } from './dialog/worksheets/worksheet-deposit-structure-module/worksheet-deposit-structure-module.component';
import { PurchasersFormImageFieldComponent } from './dialog/worksheets/purchasers/purchasers-form-image-field/purchasers-form-image-field.component';
import { SuiteSelectionRowComponent } from './dialog/worksheets/worksheet-preferences/suite-selection-row/suite-selection-row.component';
import { HomeSelectionRowComponent } from './dialog/worksheets/worksheet-preferences/home-selection-row/home-selection-row.component';
import { CaptureDirective } from './capture.directive';
import { WorksheetDepositStructureTabComponent } from './dialog/project-settings/worksheet-deposit-structure-tab/worksheet-deposit-structure-tab.component';
import { ContractsTableViewComponent } from './contracts-table-view/contracts-table-view.component';
import { ContractSettingsTabComponent } from './dialog/project-settings/contract-settings-tab/contract-settings-tab.component';
import { CreateContractDialogComponent } from './dialog/create-contract-dialog/create-contract-dialog.component';
import { LivesiteSettingsTabComponent } from './dialog/project-settings/livesite-settings-tab/livesite-settings-tab.component';
import { LinkModelBuildingsDialogComponent } from './dialog/link-model-buildings-dialog/link-model-buildings-dialog.component';
import { FloorplansTableViewComponent } from './floorplans-table-view/floorplans-table-view.component';
import { CreateFloorplanDocumentDialogComponent } from './dialog/create-floorplan-document-dialog/create-floorplan-document-dialog.component';
import { PhaseTableViewComponent } from './phase-table-view/phase-table-view.component';
import { LotsBaseTableViewComponent } from './lots-base-table-view/lots-base-table-view.component';
import { CreatePhaseDialogComponent } from './dialog/create-phase-dialog/create-phase-dialog.component';
import { CreateUnitDialogClientComponent } from './dialog/create-unit-dialog-client/create-unit-dialog-client.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginScreenComponent,
    CompanyProjectsTableViewComponent,
    LoadingSpinnerComponent,
    ProjectDetailsTableViewComponent,
    CreateCompanyDialogComponent,
    CreateProjectDialogComponent,
    CompanyRolesTableViewComponent,
    UsersTableViewComponent,
    CreateProjectRoleDialogComponent,
    GenericDeleteConfirmationDialogComponent,
    UserDetailsFormComponent,
    UserDetailsViewComponent,
    CreateUserDialogComponent,
    ProjectAssignmentsDialogComponent,
    SuitesTableViewComponent,
    BuildingsTableViewComponent,
    CreateBuildingsDialogComponent,
    CreateFloorsDialogComponent,
    FloorsTableViewComponent,
    HomesTableViewComponent,
    ElevationsTableViewComponent,
    CreateHomeDialogComponent,
    CreateElevationDialogComponent,
    ModelsTableViewComponent,
    ModelTypesTableViewComponent,
    CreateModelDialogComponent,
    CreateModelTypeDialogComponent,
    LotsTableViewComponent,
    CreateLotDialogComponent,
    CreateUnitDialogComponent,
    SuitesInnerTableViewComponent,
    LinkersTableViewComponent,
    CreateLinkerDialogComponent,
    CreatePhaseDialogComponent,
    ProjectUserAssignmentsDialogComponent,
    GenerateFloorsDialogComponent,
    ModelsDualTableViewComponent,
    ProjectAnalyticsViewComponent,
    ViewAllChartDataDialogComponent,
    ProjectSettingsDialogComponent,
    WorksheetSettingsTabComponent,
    WorksheetsTableViewComponent,
    CreateWorksheetDialogComponent,
    WorksheetPreferencesModuleComponent,
    WorksheetAgentDetailsModuleComponent,
    WorksheetBrokerageDetailsModuleComponent,
    WorksheetPurchasersModuleComponent,
    WorksheetPurchaserFormComponent,
    WorksheetNotesModuleComponent,
    WorksheetDepositStructureModuleComponent,
    PurchasersFormImageFieldComponent,
    SuiteSelectionRowComponent,
    HomeSelectionRowComponent,
    CaptureDirective,
    WorksheetDepositStructureTabComponent,
    ContractsTableViewComponent,
    ContractSettingsTabComponent,
    CreateContractDialogComponent,
    LivesiteSettingsTabComponent,
    LinkModelBuildingsDialogComponent,
    FloorplansTableViewComponent,
    CreateFloorplanDocumentDialogComponent,
    PhaseTableViewComponent,
    LotsBaseTableViewComponent,
    CreatePhaseDialogComponent,
    CreateUnitDialogClientComponent,
  ],
  entryComponents: [
    LoadingSpinnerComponent,
    CreateCompanyDialogComponent,
    CreateProjectDialogComponent,
    CreateProjectRoleDialogComponent,
    GenericDeleteConfirmationDialogComponent,
    CreateUserDialogComponent,
    ProjectAssignmentsDialogComponent,
    CreateBuildingsDialogComponent,
    CreateFloorsDialogComponent,
    CreateHomeDialogComponent,
    CreateElevationDialogComponent,
    CreateModelDialogComponent,
    CreateModelTypeDialogComponent,
    CreateLotDialogComponent,
    CreatePhaseDialogComponent,
    CreateUnitDialogComponent,
    CreateLinkerDialogComponent,
    ProjectUserAssignmentsDialogComponent,
    GenerateFloorsDialogComponent,
    ViewAllChartDataDialogComponent,
    ProjectSettingsDialogComponent,
    CreateWorksheetDialogComponent,
    ModelTypesTableViewComponent,
    SuitesInnerTableViewComponent,
    FloorsTableViewComponent,
    ElevationsTableViewComponent,
    LinkersTableViewComponent,
    LotsBaseTableViewComponent,
    CreateContractDialogComponent,
    LinkModelBuildingsDialogComponent,
    CreateFloorplanDocumentDialogComponent,
    CreateUnitDialogClientComponent,
  ],
  imports: [
    ClipboardModule,
    AdminModule,
    AppCommonNavModule,
    AdminRoutingModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatStepperModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    OverlayModule,
  ],
  providers: [AppUtilityService, SettingsService, CsvUtilitiesService, UsersService, CompaniesService, ProjectsService, AuthService, UserRolesService, ProjectRolesService, ProjectAssignmentsService, LoadingSpinnerOverlayService, ProjectAnalyticsService, CurrencyPipe, {provide: MatDialogRef, useValue: {}}, {provide: MAT_DIALOG_DATA, useValue: {}} ,{provide: HTTP_INTERCEPTORS, useClass: LoadingSpinnerInterceptor, multi: true}, {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, {provide: HTTP_INTERCEPTORS, useClass: ErrorResponseInterceptor, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule { }

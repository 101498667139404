import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, fromEventPattern } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { WebCMSAPIResponse, UserRole, EUserType } from 'src/DataModels';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { AuthService } from './auth.service';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class UserRolesService 
{
  constructor(private http: HttpClient, private dictionaryUtility: DictionaryUtilitiesService, private appConstants: AppConstantsService) { }

  public GetAllUserRoles(): Observable<Map<string, UserRole>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetUserRolesResponse(response);
    }));
  }

  public GetClientUserRoles(): Observable<Map<string, UserRole>>
  {
    let params = new HttpParams().set("userRoleType", EUserType.client);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetUserRolesResponse(response);
    }));
  }

  public GetUserRoleByRoleID(roleID: string, authService: AuthService): Observable<boolean>
  {
    let params = new HttpParams().set("roleID", roleID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response.status == "success")
      {
        let roleInfoFromPacket = JSON.parse(response.data);
        let role: UserRole = new UserRole(roleInfoFromPacket);
        authService.activeUserRole = role;

        authService.StoreUserRole(JSON.stringify(authService.activeUserRole));
        return true;
      }
      else
      {
        console.log(response);
        return false;
      }
    }));
  }

  public CreateNewUserRole(userRoleInfoDict: Map<string, any>): Observable<UserRole>
  {
    let roleInfoRaw = this.dictionaryUtility.GetRawDictionary(userRoleInfoDict);
    let params = {"roleInfoDict": JSON.stringify(roleInfoRaw)};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response.status == "success")
      {
        let roleInfoFromPacket = JSON.parse(response.data);
        let role: UserRole = new UserRole(roleInfoFromPacket);
        return role;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public UpdateUserRole(userRoleID: string, userRoleInfoDict: Map<string, any>): Observable<UserRole>
  {
    let updateInfoRaw = this.dictionaryUtility.GetRawDictionary(userRoleInfoDict);
    let params = {"roleID": userRoleID, "roleInfoDict": JSON.stringify(updateInfoRaw)};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response.status == "success")
      {
        let roleInfoFromPacket = JSON.parse(response.data);
        let role: UserRole = new UserRole(roleInfoFromPacket);
        return role;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public DeleteUserRoles(selectedUserRoleIDs: Array<string>): Observable<Map<string, UserRole>>
  {
    let params = {"rolesSelection": JSON.stringify(selectedUserRoleIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/user_roles`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetUserRolesResponse(response);
    }));
  }

  private ParseGetUserRolesResponse(response: WebCMSAPIResponse): Map<string, UserRole>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let rolesDict: Map<string, UserRole> = new Map<string, UserRole>();
      let responseDataJSON = JSON.parse(response.data);

      for (let roleJSON of responseDataJSON)
      {
        let roleInfoFromPacket = JSON.parse(roleJSON);
        let role: UserRole = new UserRole(roleInfoFromPacket);
        rolesDict.set(role.user_role_id, role);
      }

      return rolesDict;
    }
    else if (response.status == "error")
    {
      console.log(response);
      return null;
    }
  }
}

import { Component, OnInit, Host } from '@angular/core';
import { ProjectSettingsDialogComponent } from '../project-settings-dialog/project-settings-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { ProjectRole } from 'src/DataModels';

@Component({
  selector: 'app-livesite-settings-tab',
  templateUrl: './livesite-settings-tab.component.html',
  styleUrls: ['./livesite-settings-tab.component.css']
})
export class LivesiteSettingsTabComponent implements OnInit 
{
  public displayUnitAvailabilityField: FormControl = new FormControl({value: true, disabled: this.IsDisplayAvailabilityToggleReadOnly()});
  public displayPricingField: FormControl = new FormControl({value: true, disabled: this.IsDisplayPricingToggleReadOnly()});
  public requireLoginScreenField: FormControl = new FormControl({value: false, disabled: !this.authService.IsActiveUserAdmin()})

  constructor(@Host() public parent: ProjectSettingsDialogComponent, public authService: AuthService, private appUtilityService: AppUtilityService) { }

  private IsDisplayAvailabilityToggleReadOnly(): boolean
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return false;
    }
    else
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.parent.data.project);
      return activeProjectRole !== null && !activeProjectRole.can_toggle_livesite_availability_display;
    }
  }

  private IsDisplayPricingToggleReadOnly(): boolean
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return false;
    }
    else
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.parent.data.project);
      return activeProjectRole !== null && !activeProjectRole.can_toggle_livesite_pricing_display;
    }
  }

  ngOnInit() 
  {
    this.parent.saveButtonBehaviourCallback = this.OnLivesiteSettingsSaveButtonClicked.bind(this);
  
    this.displayUnitAvailabilityField.setValue(this.parent.data.project.should_display_unit_availability);
    this.displayPricingField.setValue(this.parent.data.project.should_display_pricing);
    this.requireLoginScreenField.setValue(this.parent.data.project.require_login_screen);
  }

  OnLivesiteSettingsSaveButtonClicked(): Map<string, any>
  {
    let livesiteSettingsInfoDict: Map<string, any> = new Map<string, any>();
    
    livesiteSettingsInfoDict.set("should_display_unit_availability", this.displayUnitAvailabilityField.value);
    livesiteSettingsInfoDict.set("should_display_pricing", this.displayPricingField.value);
    livesiteSettingsInfoDict.set("require_login_screen", this.requireLoginScreenField.value);

    return livesiteSettingsInfoDict;
  }
}

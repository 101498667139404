import { Component, OnInit, ViewChild, AfterViewInit, QueryList, ViewChildren, ContentChildren, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { AppUtilityService } from '../app-utility.service';
import { ActivatedRoute, ParamMap, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { map, takeWhile, skip, skipWhile, filter } from 'rxjs/operators';
import { Project, User, ProjectAssignment } from 'src/DataModels';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { SuitesTableViewComponent } from '../suites-table-view/suites-table-view.component';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { BuildingsTableViewComponent } from '../buildings-table-view/buildings-table-view.component';
import { HomesTableViewComponent } from '../homes-table-view/homes-table-view.component';
import { ModelsTableViewComponent } from '../models-table-view/models-table-view.component';
import { LotsTableViewComponent } from '../lots-table-view/lots-table-view.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialogComponent } from '../dialog/create-user-dialog/create-user-dialog.component';
import { UsersService } from '../users.service';
import { AuthService } from '../auth.service';
import { ProjectUserAssignmentsDialogComponent } from '../dialog/project-user-assignments-dialog/project-user-assignments-dialog.component';
import { ProjectsService } from '../projects.service';
import { ModelsDualTableViewComponent } from '../models-dual-table-view/models-dual-table-view.component';
import { ProjectAnalyticsViewComponent } from '../project-analytics-view/project-analytics-view.component';
import { LoadingSpinnerOverlayService } from '../loading-spinner-overlay.service';
import { ProjectSettingsDialogComponent } from '../dialog/project-settings/project-settings-dialog/project-settings-dialog.component';

@Component({
  selector: 'app-project-details-table-view',
  templateUrl: './project-details-table-view.component.html',
  styleUrls: ['./project-details-table-view.component.css']
})
export class ProjectDetailsTableViewComponent extends CMSComponentBase implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatTabGroup, {static: false}) tabGroup: MatTabGroup;
  @ViewChildren(CMSTableViewBase) childTableViews: QueryList<CMSTableViewBase>;

  public selectedProject: Project = null;

  private selectedTabIndex: number = 0;

  private _activeProjectLastUpdatedRequest: Subscription = null;

  constructor(private dialog: MatDialog, public appUtilityService: AppUtilityService, public authService: AuthService, private usersService: UsersService, private projectsService: ProjectsService, private route: ActivatedRoute, private router: Router, private changeDetectorRef: ChangeDetectorRef, private loadingSpinner: LoadingSpinnerOverlayService) 
  { 
    super();
  }

  private CheckForProjectMetadataUpdates()
  {
    if (this.selectedProject.isMetadataPulled == false)
    {
      this.projectsService.GetMetadataFor(this.selectedProject).subscribe((result: boolean) => 
      {
        if (result)
        {
          this.appUtilityService.StoreCMSData();
          this.changeDetectorRef.detectChanges();
        }
      });
    }
    else if (this.selectedProject.isMetadataPulled)
    {
      if (this._activeProjectLastUpdatedRequest !== null && this._activeProjectLastUpdatedRequest !== undefined)
      {
        this._activeProjectLastUpdatedRequest.unsubscribe();
      }

      this.loadingSpinner.ToggleDisabled();
      this._activeProjectLastUpdatedRequest = this.projectsService.GetProjectLastUpdated(this.selectedProject).subscribe((result: boolean) =>
      {
        if (result == true && this.selectedProject.IsOutOfDate())
        {
          this.projectsService.GetMetadataFor(this.selectedProject).subscribe((result: boolean) => 
          {
            if (result)
            {
              this.appUtilityService.StoreCMSData();
              this.changeDetectorRef.detectChanges();
            }
          });
        }
      });
      this.loadingSpinner.ToggleDisabled();
    }
  }

  ngOnInit() 
  {
    this.route.paramMap.subscribe(params => 
    {
      if (params.has("projectID"))
      {
        let projectID = params.get("projectID");

        if (this.appUtilityService.projectsDict.has(projectID))
        {
          if (sessionStorage.getItem("currentViewingProject") != projectID)
          {
            sessionStorage.removeItem("selectedTabIndex");
          }

          sessionStorage.setItem("currentViewingProject", projectID);

          this.selectedProject = this.appUtilityService.projectsDict.get(projectID);
          this.appUtilityService.SetViewTitle(this.selectedProject.project_name);

          this.CheckForProjectMetadataUpdates();
        }
      }
    });
  }

  ngAfterViewInit()
  {
  }

  ngOnDestroy()
  {
  }

  GetSelectedIndex(): number
  {
    let cachedTabIndex = sessionStorage.getItem("selectedTabIndex");
    if (cachedTabIndex != null)
    {
      this.selectedTabIndex = parseInt(cachedTabIndex);
    }

    return this.selectedTabIndex;
  }

  OnTabChanged(tabChangeEvent: MatTabChangeEvent)
  {
    this.selectedTabIndex = tabChangeEvent.index;
    sessionStorage.setItem("selectedTabIndex", this.selectedTabIndex.toString())

    this.CheckForProjectMetadataUpdates();

    let arrChildTables: Array<CMSTableViewBase> = this.childTableViews.toArray();
    this.RefreshAllTables()
    for (let i = 0; i <= arrChildTables.length; ++i)
    {
      if (i == this.selectedTabIndex)
      {

        //arrChildTables[i].OnTabEntered();
        return;
      }
    }
  }

  public RefreshAllTables(): void
  {
    for (let table of this.childTableViews.toArray())
    {
      table.RenderTableRows();
    }
  }

  // Nav Bar Utility Callbacks
  OnNavBarAddUserButtonPressed(): void 
  {
    if (this.selectedProject != null)
    {
      const dialogRef = this.dialog.open(CreateUserDialogComponent, {width: '300px', height: '550px', data: { }});

      dialogRef.afterClosed().subscribe(result => 
      {
        if (result != null)
        {
          this.usersService.CreateNewUser(result.get("username"), result.get("email"), result.get("password"), this.selectedProject.company_id, result.get("userRoleID")).subscribe((newUser: User) => 
          {
            if (newUser != null)
            {
              this.appUtilityService.usersDict.set(newUser.user_id, newUser);
              this.appUtilityService.companyUsersDict.get(this.selectedProject.company_id).add(newUser.user_id);
              this.appUtilityService.userAssignmentsDict.set(newUser.user_id, new Set<ProjectAssignment>());
              this.appUtilityService.StoreCMSData();
            }
          });
        }
      });
    }
  }

  ShouldDisplayNavBarProjectAssignmentsButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_create_users)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.selectedProject);
      if (activeProjectRole != null)
      {
        return activeProjectRole.can_add_users;
      }

      return false;
    }
  }

  OnNavBarProjectAssignmentsButtonPressed(): void 
  {
    if (this.selectedProject != null)
    {
      const dialogRef = this.dialog.open(ProjectUserAssignmentsDialogComponent, {width: '300px', height: '550px', data: { selectedProject: this.selectedProject}});

      dialogRef.afterClosed().subscribe((assignmentCallbacks) => 
      {
        if (assignmentCallbacks != null)
        {
          forkJoin(assignmentCallbacks).subscribe(results => 
          {
            let createAssignmentResults: Array<ProjectAssignment> = results[0] as Array<ProjectAssignment>;
            let updateAssignmentResults: Array<ProjectAssignment> = results[1] as Array<ProjectAssignment>;
            let deleteAssignmentResults: Array<Map<string, any>> = results[2] as Array<Map<string, any>>;

            if (createAssignmentResults != null)
            {
              for (let newAssignment of createAssignmentResults)
              {
                this.appUtilityService.userAssignmentsDict.get(newAssignment.user_id).add(newAssignment);
              }

              this.appUtilityService.StoreCMSData();
            }

            if (updateAssignmentResults != null)
            {
              for (let updatedAssignment of updateAssignmentResults)
              {
                for (let assignment of this.appUtilityService.userAssignmentsDict.get(updatedAssignment.user_id))
                {
                  if (assignment.user_id == updatedAssignment.user_id && assignment.project_id == updatedAssignment.project_id)
                  {
                    this.appUtilityService.userAssignmentsDict.get(updatedAssignment.user_id).delete(assignment);
                    this.appUtilityService.userAssignmentsDict.get(updatedAssignment.user_id).add(updatedAssignment);
                    break;
                  }
                }
              }

              this.appUtilityService.StoreCMSData();
            }

            if (deleteAssignmentResults != null)
            {
              for (let deletedAssignmentResult of deleteAssignmentResults)
              {
                for (let assignment of this.appUtilityService.userAssignmentsDict.get(deletedAssignmentResult.get("userID")))
                {
                  if (assignment.user_id == deletedAssignmentResult.get("userID") && assignment.project_id == deletedAssignmentResult.get("projectID"))
                  {
                    this.appUtilityService.userAssignmentsDict.get(deletedAssignmentResult.get("userID")).delete(assignment);
                    break;
                  }
                }
              }

              this.appUtilityService.StoreCMSData();
            }
          });
        }
      });
    }
  }

  ShouldDisplayNavBarEditProjectSettingsButton(): boolean
  {
    let canDisplayEditButton: boolean = false;

    // First, check if the active user/project role would normally permit opening the settings tab
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects)
    {
      canDisplayEditButton = true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.selectedProject);
      if (activeProjectRole != null)
      {
        canDisplayEditButton = activeProjectRole.can_edit_project_settings;
      }
    }

    // Next, cancel showing the settings tab if the project hasn't enabled any settings that could be modified by
    // this dialog.
    if (!this.selectedProject.uses_worksheets)
    {
      canDisplayEditButton = false;
    }

    return canDisplayEditButton;
  }

  OnNavBarEditProjectSettingsButtonPressed()
  {
    if (this.selectedProject != null)
    {
      const dialogRef = this.dialog.open(ProjectSettingsDialogComponent, {width: '800px', height: '680px', data: { project: this.selectedProject}});

      dialogRef.afterClosed().subscribe(() => {});
    }
  }

  ShouldDisplayAddButton() { return false; }
  ShouldDisplayEditButton() { return false; }
  ShouldDisplayDeleteButton() { return false; }

  OnAddButtonPressed() { }
  OnEditButtonPressed() { }
  OnDeleteButtonPressed() { }
}

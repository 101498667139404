import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { CMSComponentBase } from '../interfaces/cms-component-base';
import { AppUtilityService } from '../app-utility.service';
import { AuthService } from '../auth.service';
import { UsersService } from '../users.service';
import { MatDialog } from '@angular/material/dialog';
import { User, ProjectAssignment, Project, Company, UserRole } from 'src/DataModels';
import { MatCheckbox } from '@angular/material/checkbox';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { CreateUserDialogComponent } from '../dialog/create-user-dialog/create-user-dialog.component';
import { ProjectAssignmentsDialogComponent } from '../dialog/project-assignments-dialog/project-assignments-dialog.component';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-users-table-view',
  templateUrl: './users-table-view.component.html',
  styleUrls: ['./users-table-view.component.css']
})
export class UsersTableViewComponent implements OnInit
{
  @ViewChildren(MatCheckbox) userCheckboxes !: QueryList<MatCheckbox>;

  public companyUsers: Map<string, Map<string, User>> = new Map<string, Map<string, User>>();
  private selectedUserIDs: Set<string> = new Set<string>();

  private RefreshSelectedCompanyUsersIterator(): void
  {
    this.companyUsers.clear();

    for (let companyID of this.appUtilityService.companiesDict.keys())
    {
      this.companyUsers.set(companyID, new Map<string, User>());

      for (let userID of this.appUtilityService.companyUsersDict.get(companyID))
      {
        (this.companyUsers.get(companyID)).set(userID, this.appUtilityService.usersDict.get(userID));
      }
    }
  }

  constructor(private dialog: MatDialog, public appUtilityService: AppUtilityService, public authService: AuthService, public usersService: UsersService)
  {
  }

  ngOnInit()
  {
    if (this.authService.IsActiveUserAdmin())
    {
      this.appUtilityService.SetViewTitle("Users");
    }
    else
    {
      let companyName = this.appUtilityService.companiesDict.get(this.authService.activeUser.company_id).company_name;
      this.appUtilityService.SetViewTitle(companyName + " Users");
    }

    this.RefreshSelectedCompanyUsersIterator();
  }

  public GetCompanyUsersIterator(companyID: string): IterableIterator<User>
  {
    let companyUserIDs: Set<string> = this.appUtilityService.companyUsersDict.get(companyID);
    if (companyUserIDs != null)
    {
      let companyUsers: Array<User> = new Array<User>();

      for (let userID of companyUserIDs)
      {
        if (this.appUtilityService.usersDict.has(userID))
        {
          companyUsers.push(this.appUtilityService.usersDict.get(userID));
        }
      }

      companyUsers = companyUsers.sort((u1, u2) =>
      {
        let u1Name = u1.username.toLowerCase();
        let u2Name = u2.username.toLowerCase();

        if (u1Name < u2Name)
        {
          return -1;
        }
        else if (u1Name > u2Name)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      });

      return companyUsers.values();
    }

    return null;
  }

  public OnCompanyPanelClosed()
  {
    for (var checkbox of this.userCheckboxes.toArray())
    {
      checkbox.checked = false;
    }

    this.selectedUserIDs.clear();
  }

  public OnUserCheckboxToggled(checked: boolean, userID: string)
  {
    if (checked == true)
    {
      this.selectedUserIDs.add(userID);
    }
    else
    {
      this.selectedUserIDs.delete(userID);
    }
  }

  public OnEditUserButtonPressed(user: User)
  {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {width: '300px', height: '470px', data: { userToEdit: user }});

    let selectedUserRole: UserRole = null;

    for (let userRole of this.appUtilityService.userRolesDict.values())
    {
      if (userRole.user_role_id === user.user_role_id)
      {
        selectedUserRole = userRole;
        break;
      }
    }

    if (this.authService.activeUserRole.can_edit_users && selectedUserRole !== null && this.authService.activeUserRole.CompareTo(selectedUserRole) <= 1)
    {
      dialogRef.afterClosed().subscribe((result) =>
      {
        if (result !== null && result !== undefined)
        {
          this.usersService.UpdateUserAccountInfo(user.user_id, result.get("username"), result.get("email"), result.get("password"), user.company_id, result.get("userRoleID")).subscribe((updatedUser: User) =>
          {
            if (updatedUser != null)
            {
              this.appUtilityService.usersDict.set(updatedUser.user_id, updatedUser);
              this.appUtilityService.StoreCMSData();
              this.RefreshSelectedCompanyUsersIterator();
            }
          });
        }
      });
    }
  }

  public OnAssignProjectsButtonPressed(user: User)
  {
    const dialogRef = this.dialog.open(ProjectAssignmentsDialogComponent, {width: '300px', height: '400px', data: { selectedCompanyID: user.company_id, selectedUserID: user.user_id }});

    dialogRef.afterClosed().subscribe((assignmentCallbacks) =>
    {
      if (assignmentCallbacks != null)
      {
        forkJoin(assignmentCallbacks).subscribe(results =>
        {
          let createAssignmentResults: Array<ProjectAssignment> = results[0] as Array<ProjectAssignment>;
          let updateAssignmentResults: Array<ProjectAssignment> = results[1] as Array<ProjectAssignment>;

          if (createAssignmentResults != null)
          {
            for (let newAssignment of createAssignmentResults)
            {
              this.appUtilityService.userAssignmentsDict.get(user.user_id).add(newAssignment);
            }

            this.appUtilityService.StoreCMSData();
          }

          if (updateAssignmentResults != null)
          {
            for (let updatedAssignment of updateAssignmentResults)
            {
              for (let assignment of this.appUtilityService.userAssignmentsDict.get(user.user_id))
              {
                if (assignment.user_id == updatedAssignment.user_id && assignment.project_id == updatedAssignment.project_id)
                {
                  this.appUtilityService.userAssignmentsDict.get(user.user_id).delete(assignment);
                  this.appUtilityService.userAssignmentsDict.get(user.user_id).add(updatedAssignment);
                  break;
                }
              }
            }

            this.appUtilityService.StoreCMSData();
          }
        });
      }
    });
  }

  public OnAccordionAddButtonPressed(companyID: string)
  {
    this.CreateUser(companyID);
  }

  public ShouldDisplayAccordionDeleteButton(): boolean
  {
    return (this.authService.IsActiveUserAdmin() && this.selectedUserIDs != null && this.selectedUserIDs.size > 0);
  }

  public OnAccordionDeleteButtonPressed(companyID: string)
  {
    this.DeleteUser(companyID);
  }

  ShouldDisplayAddButton(): boolean
  {
    return !this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_create_users;
  }

  OnAddButtonPressed(): void
  {
    this.CreateUser(this.authService.activeUser.company_id);
  }

  ShouldDisplayDeleteButton(): boolean
  {
    return !this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_delete_users && this.selectedUserIDs.size > 0;
  }

  OnDeleteButtonPressed(): void
  {
    this.DeleteUser(this.authService.activeUser.company_id);
  }

  private CreateUser(companyID: string)
  {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {width: '300px', height: '470px', data: { }});

      dialogRef.afterClosed().subscribe(result =>
      {
        if (result != null)
        {
          this.usersService.CreateNewUser(result.get("username"), result.get("email"), result.get("password"), companyID, result.get("userRoleID")).subscribe((newUser: User) =>
          {
            if (newUser != null)
            {
              this.appUtilityService.usersDict.set(newUser.user_id, newUser);
              this.appUtilityService.companyUsersDict.get(companyID).add(newUser.user_id);
              this.appUtilityService.userAssignmentsDict.set(newUser.user_id, new Set<ProjectAssignment>());
              this.appUtilityService.StoreCMSData();

              this.RefreshSelectedCompanyUsersIterator();
            }
          });
        }
      });
  }

  private DeleteUser(companyID: string)
  {
    if (this.selectedUserIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Users?" }});

      dialogRef.afterClosed().subscribe(result =>
      {
        if (result == true)
        {
          this.usersService.DeleteUserAccounts(companyID, Array.from(this.selectedUserIDs)).subscribe((result: boolean) =>
          {
            if (result == true)
            {
              // Delete local caches of users that match the ones selected
              for (var user of this.appUtilityService.usersDict.values())
              {
                for (var userID of this.selectedUserIDs)
                {
                  if (user.user_id == userID)
                  {
                    this.appUtilityService.companyUsersDict.get(companyID).delete(userID);
                    this.appUtilityService.usersDict.delete(userID);
                  }
                }
              }

              this.appUtilityService.StoreCMSData();
              this.RefreshSelectedCompanyUsersIterator();
            }
          });
        }
      });
    }
  }
}

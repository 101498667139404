import { Component, OnInit, Input, Host, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { EIdentificationType, EInternationalStatus, Project } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl } from '@angular/forms';
import { PurchasersFormImageFieldComponent } from '../purchasers-form-image-field/purchasers-form-image-field.component';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-worksheet-purchaser-form',
  templateUrl: './worksheet-purchaser-form.component.html',
  styleUrls: ['./worksheet-purchaser-form.component.css']
})
export class WorksheetPurchaserFormComponent implements OnInit 
{
  @ViewChild('select', {static: false}) public idTypeSelect: MatSelect;
  @ViewChild('photoIDField', {static: false}) public photoIDField: PurchasersFormImageFieldComponent;
  @ViewChild('chequePhotoField', {static: false}) public chequePhotoField: PurchasersFormImageFieldComponent;

  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  private _isSecondPurchaser: boolean = false;

  @Input()
  set IsSecondPurchaser(isSecondPurchaser)
  {
    this._isSecondPurchaser = isSecondPurchaser;
  }

  get IsSecondPurchaser()
  {
    return this._isSecondPurchaser;
  }

  private _isSecondPurchaserActive: boolean = false;
  
  set IsSecondPurchaserActive(isSecondPurchaserActive)
  {
    this._isSecondPurchaserActive = isSecondPurchaserActive;
  }

  get IsSecondPurchaserActive()
  {
    return this._isSecondPurchaserActive;
  }

  private _selectedIdType: EIdentificationType = EIdentificationType.None;

  set SelectedIdType(idType: EIdentificationType)
  {
    this._selectedIdType = idType;
  }

  get SelectedIdType()
  {
    return this._selectedIdType;
  }

  constructor(public appUtilityService: AppUtilityService) { }

  public fullNameField: FormControl = new FormControl();
  public birthdayField: FormControl = new FormControl({value: '', disabled: true});
  public driverLicenseField: FormControl = new FormControl();
  public passportField: FormControl = new FormControl();
  public sinField: FormControl = new FormControl();
  public addressField: FormControl = new FormControl();
  public cityField: FormControl = new FormControl();
  public postalCodeField: FormControl = new FormControl();
  public provinceField: FormControl = new FormControl();
  public countryField: FormControl = new FormControl();
  public phoneField: FormControl = new FormControl();
  public emailField: FormControl = new FormControl();

  ngOnInit() 
  {
  }

  public OnAddPurchaserButtonPressed(): void
  {
    this.IsSecondPurchaserActive = true;
  }

  public OnRemovePurchaserButtonPressed(): void
  {
    this.IsSecondPurchaserActive = false;
  }

  public OnSelectedIdTypeChanged(selectedType: string)
  {
    if (selectedType == 'DriverLicense')
    {
      this.SelectedIdType = EIdentificationType.DriverLicense;
    }
    else if (selectedType == 'Passport')
    {
      this.SelectedIdType = EIdentificationType.Passport;
    }
  }
}

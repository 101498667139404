import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { DeleteProjectDialogData } from 'src/app/company-projects-table-view/company-projects-table-view.component';

@Component({
  selector: 'app-delete-project-dialog',
  templateUrl: './delete-project-dialog.component.html',
  styleUrls: ['./delete-project-dialog.component.css']
})
export class DeleteProjectDialogComponent implements OnInit 
{
  private deleteDatabaseSelectionDict: Map<string, boolean>;

  constructor(public dialogRef: MatDialogRef<DeleteProjectDialogComponent>, private appUtilityService: AppUtilityService, @Inject(MAT_DIALOG_DATA) public data: DeleteProjectDialogData) { }

  ngOnInit() 
  {
    this.deleteDatabaseSelectionDict  = new Map<string, boolean>();
    for (var project of this.data.projectsToDelete)
    {
      this.deleteDatabaseSelectionDict.set(project.project_id, false);
    }
  }

  public OnDeleteDatabaseToggleClicked(projectID: string, shouldDeleteDatabase: boolean)
  {
    this.deleteDatabaseSelectionDict.set(projectID, shouldDeleteDatabase);
  }

  public OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  public OnDeleteButtonClicked()
  {
    this.dialogRef.close(this.deleteDatabaseSelectionDict);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ProjectsService } from '../projects.service';
import { AppUtilityService } from '../app-utility.service';
import { Project, Company } from 'src/DataModels';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { CreateProjectDialogComponent } from '../dialog/create-project-dialog/create-project-dialog.component';
import { CMSComponentBase } from '../interfaces/cms-component-base';
import { DeleteCompanyDialogComponent } from '../admin/dialog/delete-company-dialog/delete-company-dialog.component';
import { DeleteProjectDialogComponent } from '../admin/dialog/delete-project-dialog/delete-project-dialog.component';
import { Location } from '@angular/common';
import { ProjectSettingsDialogComponent } from '../dialog/project-settings/project-settings-dialog/project-settings-dialog.component';

export interface NewProjectDialogData 
{
  projectName: string;
  companyID: string;

  projectToEdit: Project;
}

export interface DeleteProjectDialogData
{
  projectsToDelete: Set<Project>
}

@Component({
  selector: 'app-company-projects-table-view',
  templateUrl: './company-projects-table-view.component.html',
  styleUrls: ['./company-projects-table-view.component.css']
})
export class CompanyProjectsTableViewComponent implements OnInit 
{
  public _selectedCompanyID: string = "";

  public selectedCompanyID: Observable<string> = this.route.paramMap.pipe(map((params: ParamMap) => 
  {
    if (params.has("companyID"))
    {
      this._selectedCompanyID = params.get("companyID");
      this.RefreshSelectedCompanyProjectsIterator();

      return this._selectedCompanyID;
    }  
  }));

  public selectedCompanyProjects: Map<string, Project> = new Map<string, Project>();
  public projectsSelection: Set<Project> = new Set<Project>();

  private RefreshSelectedCompanyProjectsIterator(): void
  {
    this.selectedCompanyProjects.clear();

    if (this.authService.IsActiveUserAdmin())
    {
      // Admin-level view, display only the projects tied to the specified company given in the URL
      for (let projectID of this.appUtilityService.companyProjectsDict.get(this._selectedCompanyID).values())
      {
        this.selectedCompanyProjects.set(projectID, this.appUtilityService.projectsDict.get(projectID));
      }
    }
    else
    {
      // Client-level view, display all projects pulled by the CMS.
      // The login process for client-level users already ensures that all projects pulled are ones
      // that the client has permission for anyways.
      for (let project of this.appUtilityService.projectsDict.values())
      {
        this.selectedCompanyProjects.set(project.project_id, project);
      }
    }
  }

  constructor(public dialog: MatDialog, public route: ActivatedRoute, private router: Router, public projectsService: ProjectsService, public appUtilityService: AppUtilityService, public authService: AuthService, private location: Location) 
  { 
  }

  /*
    Used to get the company name to display underneath the project name when
    accessing this page as a client-level user.
  */
  public GetProjectOwningCompanyName(companyID: string): string
  {
    if (this.appUtilityService.companiesDict.has(companyID))
    {
      let owningCompany: Company = this.appUtilityService.companiesDict.get(companyID);
      return owningCompany.company_name;
    }
    else
    {
      return "";
    }
  }

  public OnProjectLinkTapped(project: Project)
  {
    if (project.isMetadataPulled && !project.IsOutOfDate())
    {
      this.router.navigateByUrl("project/" + project.project_id);
    }
    else
    {
      this.projectsService.GetMetadataFor(project).subscribe((result: boolean) => 
      {
        if (result == true)
        {
          this.appUtilityService.StoreCMSData();
          this.router.navigateByUrl("project/" + project.project_id);
        }
        else
        {
          // TODO modal error display?
        }
      });
    }
  }

  public OnProjectCheckboxToggled(checked: boolean, project: Project)
  {
    if (checked)
    {
      this.projectsSelection.add(project);
    }
    else
    {
      this.projectsSelection.delete(project);
    }
  }

  ngOnInit() 
  {
    this.route.paramMap.subscribe(params => 
    {
      if (params.has("companyID"))
      {
        let companyID = params.get("companyID");

        if (this.appUtilityService.companiesDict.has(companyID))
        {
          let companyName = this.appUtilityService.companiesDict.get(companyID).company_name;
          this.appUtilityService.SetViewTitle(companyName + " Projects")
        }
      }
      else
      {
        this.appUtilityService.SetViewTitle("Your Projects");
        this._selectedCompanyID = this.authService.activeUser.company_id;
        this.RefreshSelectedCompanyProjectsIterator();
      }
    });
  }

  OnBackButtonPressed()
  {
    this.location.back();
  }

  public OnEditProjectButtonClicked(project: Project)
  {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, { width: '300px', height: '400px', data: { projectToEdit: project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        let updateCallbacks: Array<Observable<any>> = new Array<Observable<any>>();

        if (project.project_name != result.get("project_name"))
        {
          updateCallbacks.push(this.projectsService.ChangeProjectName(project.project_id, project.project_name, result.get("project_name")));
        }
        else
        {
          updateCallbacks.push(of(null));
        }

        if (project.has_condos != result.get("has_condos") || project.has_detached != result.get("has_detached") || project.has_townhomes != result.get("has_townhomes"))
        {
          updateCallbacks.push(this.projectsService.UpdateProjectProductTypesAndModules(project.project_id, result));
        }
        else
        {
          updateCallbacks.push(of(null));
        }

        if (project.uses_worksheets != result.get("uses_worksheets") || project.uses_floorplans != result.get("uses_floorplans"))
        {
          updateCallbacks.push(this.projectsService.UpdateProjectProductTypesAndModules(project.project_id, result));
        }
        else
        {
          updateCallbacks.push(of(null));
        }

        if (updateCallbacks.length > 0)
        {
          forkJoin(updateCallbacks).subscribe((response) => 
          {
            let projectDataChanged = false;

            if (response[0] != null)
            {
              this.appUtilityService.projectsDict.get(project.project_id).project_name = response[0].get("project_name");
              projectDataChanged = true;
            }

            if (response[1] != null)
            {
              this.appUtilityService.projectsDict.get(project.project_id).has_condos = response[1].has_condos;
              this.appUtilityService.projectsDict.get(project.project_id).has_detached = response[1].has_detached;
              this.appUtilityService.projectsDict.get(project.project_id).has_townhomes = response[1].has_townhomes;
              projectDataChanged = true;
            }

            if (response[2] != null)
            {
              this.appUtilityService.projectsDict.get(project.project_id).uses_worksheets = response[2].uses_worksheets;
              this.appUtilityService.projectsDict.get(project.project_id).uses_floorplans = response[2].uses_floorplans;
              projectDataChanged = true;
            }

            if (projectDataChanged)
            {
              this.appUtilityService.StoreCMSData();
              this.RefreshSelectedCompanyProjectsIterator();
            }
          });
        }
      }
    });
  }

  private OpenProjectSettingsDialog(project: Project): void
  {
    const dialogRef = this.dialog.open(ProjectSettingsDialogComponent, {width: '800px', height: '680px', data: { project: project}});
    dialogRef.afterClosed().subscribe(() => {});
  }

  OnProjectSettingsButtonClicked(project: Project)
  {
    if (project !== null)
    {
      if (!project.isMetadataPulled)
      {
        this.projectsService.GetMetadataFor(project).subscribe((result: boolean) => 
        {
          if (result == true)
          {
            this.OpenProjectSettingsDialog(project);
          }
        });
      }
      else
      {
        this.OpenProjectSettingsDialog(project);
      }
    }
  }

  ShouldDisplayAddButton(): boolean
  {
    return this.authService.activeUserRole.can_create_projects;
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, { width: '300px', height: '400px', data: {  } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewProject(this._selectedCompanyID, result).subscribe((newProject: Project) => 
        {
          if (newProject != null)
          {
            this.appUtilityService.projectsDict.set(newProject.project_id, newProject);
            this.appUtilityService.companyProjectsDict.get(this._selectedCompanyID).add(newProject.project_id);
            this.appUtilityService.StoreCMSData();
            this.RefreshSelectedCompanyProjectsIterator();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    return (this.authService.IsActiveUserAdmin() && this.projectsSelection != null && this.projectsSelection.size > 0);
  }

  OnDeleteButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(DeleteProjectDialogComponent, { width: '350px', height: '350px', data: { projectsToDelete: Array.from(this.projectsSelection.values()) } });
    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result != null)
      {
        this.projectsService.DeleteProjects(result).subscribe((updatedProjects: Map<string, Project>) => 
        {
          if (updatedProjects != null)
          {
            this.appUtilityService.projectsDict = updatedProjects;
            this.CleanLocalDictEntries();
            this.projectsSelection.clear();

            this.appUtilityService.StoreCMSData();
            this.RefreshSelectedCompanyProjectsIterator();
          }
        });
      }
    });
  }

  private CleanLocalDictEntries()
  {
    for (var project of this.projectsSelection)
    {
      this.appUtilityService.companyProjectsDict.get(project.company_id).delete(project.project_id);
    }
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Home, Project, EOrientationType, Elevation } from 'src/DataModels';
import { FormControl, Validators } from '@angular/forms';
import { debug } from 'util';

export interface CreateHomeDialogData
{
  selectedProject: Project;
  homeToEdit: Home;
}

@Component({
  selector: 'app-create-home-dialog',
  templateUrl: './create-home-dialog.component.html',
  styleUrls: ['./create-home-dialog.component.css']
})
export class CreateHomeDialogComponent implements OnInit 
{
  public homeNameField: FormControl = new FormControl('');
  public numberField: FormControl = new FormControl('');
  public startElevationField: FormControl = new FormControl(0);
  public storeysField: FormControl = new FormControl(0);
  public bedroomsField: FormControl = new FormControl(0);
  public bathroomsField: FormControl = new FormControl(0);
  public frontageField: FormControl = new FormControl(0);

  public denField: FormControl = new FormControl(false);

  constructor(private dialogRef: MatDialogRef<CreateHomeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateHomeDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetOrientationOptions(): Array<string>
  {
    let keys = Object.keys(EOrientationType);
    return keys;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.homeToEdit != null)
    {
      return "Edit Home";
    }
    else
    {
      return "Create Home";
    }
  }

  public GetHomeElevationsIterator(): IterableIterator<Elevation>
  {
    if (this.data.homeToEdit != null && this.data.selectedProject.elevations.size > 0)
    {
      let elevations: Array<Elevation> = new Array<Elevation>();
      for (let elevation of this.data.selectedProject.elevations.values())
      {
        if (elevation.detached_id == this.data.homeToEdit.detached_id)
        {
          elevations.push(elevation);
        }
      }

      return elevations.values();
    }

    return null;
  }

  ngOnInit() 
  {
    this.homeNameField.setValidators([Validators.required]);

    if (this.data.homeToEdit != null)
    {
      this.homeNameField.setValue(this.data.homeToEdit.name);
      this.numberField.setValue(this.data.homeToEdit.number);
      this.storeysField.setValue(this.data.homeToEdit.storeys);
      this.bedroomsField.setValue(this.data.homeToEdit.bedrooms);
      this.bathroomsField.setValue(this.data.homeToEdit.bathrooms);
      this.frontageField.setValue(this.data.homeToEdit.frontage);

      this.denField.setValue(this.data.homeToEdit.has_den);

      if (this.data.selectedProject.elevations.has(this.data.homeToEdit.start_elevation))
      {
        this.startElevationField.setValue(this.data.homeToEdit.start_elevation);
      }
    }
  }

  public GetNameFieldError(): string
  {
    if (this.homeNameField.hasError('required'))
    {
      return "Please enter a name for this home";
    }
    else if (this.homeNameField.hasError('unique'))
    {
      return this.homeNameField.value + " already exists";
    }

    return "";
  }

  public GetNumberFieldError(): string
  {
    if (this.numberField.hasError('required'))
    {
      return "Please enter a home number";
    }
    else if (this.numberField.hasError('unique'))
    {
      return this.numberField.value + " already exists";
    }

    return "";
  }

  public GetStoreysFieldError(): string
  {
    if (this.storeysField.hasError('required'))
    {
      return "Please enter the number of storeys for this home";
    }
    else if (this.storeysField.hasError('too_low'))
    {
      return "Storeys must be greater than 0";
    }

    return "";
  }

  public GetBedroomsFieldError(): string
  {
    if (this.bedroomsField.hasError('required'))
    {
      return "Please enter the number of bedrooms for this home";
    } 
    else if (this.bedroomsField.hasError('too_low'))
    {
      return "Bedrooms must be greater than 0";
    }

    return "";
  }

  public GetBathroomsFieldError(): string
  {
    if (this.bathroomsField.hasError('required'))
    {
      return "Please enter the number of bathrooms for this home";
    } 
    else if (this.bathroomsField.hasError('too_low'))
    {
      return "Bathrooms must be greater than 0";
    }

    return "";
  }

  public GetFrontageFieldError(): string
  {
    if (this.frontageField.hasError('required'))
    {
      return "Please enter the frontage of this home";
    } 
    else if (this.frontageField.hasError('too_low'))
    {
      return "Frontage must be greater than 0";
    }

    return "";
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isHomeInfoValid: boolean = true;

    if (this.homeNameField.value == "")
    {
      this.homeNameField.setErrors({required: true});
      this.homeNameField.markAsTouched();
      isHomeInfoValid = false;
    }
    else
    {
      for (let home of this.data.selectedProject.homes.values())
      {
        if (home.name == this.homeNameField.value)
        {
          if (this.data.homeToEdit == null || this.data.homeToEdit.detached_id != home.detached_id)
          {
            this.homeNameField.setErrors({unique: true});
            this.homeNameField.markAsTouched();
            isHomeInfoValid = false;
          }

          break;
        }
      }
    }

    if (this.storeysField.value < 0)
    {
      this.storeysField.setErrors({too_low: true});
      this.storeysField.markAsTouched();
      isHomeInfoValid = false;
    }

    if (this.bedroomsField.value < 0)
    {
      this.bedroomsField.setErrors({too_low: true});
      this.bedroomsField.markAsTouched();
      isHomeInfoValid = false;
    }

    if (this.bathroomsField.value < 0)
    {
      this.bathroomsField.setErrors({too_low: true});
      this.bathroomsField.markAsTouched();
      isHomeInfoValid = false;
    }

    if (this.frontageField.value < 0)
    {
      this.frontageField.setErrors({too_low: true});
      this.frontageField.markAsTouched();
      isHomeInfoValid = false;
    }

    if (isHomeInfoValid)
    {
      let homeInfoDict: Map<string, any> = new Map<string, any>();

      homeInfoDict.set("name", this.homeNameField.value);
      homeInfoDict.set("number", this.numberField.value);
      homeInfoDict.set("storeys", this.storeysField.value);
      homeInfoDict.set("bedrooms", this.bedroomsField.value);
      homeInfoDict.set("bathrooms", this.bathroomsField.value);
      homeInfoDict.set("frontage", this.frontageField.value);
      homeInfoDict.set("has_den", this.denField.value);

      if (this.data.homeToEdit != null)
      {
        homeInfoDict.set("start_elevation", this.startElevationField.value);
      }

      this.dialogRef.close(homeInfoDict);
    }
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { Project, ProjectRole, Building, Unit, Floor, EAvailabilityCode } from 'src/DataModels';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateBuildingsDialogComponent } from '../dialog/create-buildings-dialog/create-buildings-dialog.component';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CreateFloorsDialogComponent } from '../dialog/create-floors-dialog/create-floors-dialog.component';
import { FloorsTableViewComponent } from '../floors-table-view/floors-table-view.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: BuildingsTableViewComponent }],
  selector: 'app-buildings-table-view',
  templateUrl: './buildings-table-view.component.html',
  styleUrls: ['./buildings-table-view.component.css'],
})
export class BuildingsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Building>;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  public selectedBuildingIDs: Set<number> = new Set<number>();

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public expandedRow: Building = null;
  public prevExpandedRow: Building = null;

  public buildingsDataSource: MatTableDataSource<Building> = new MatTableDataSource<Building>();

  public clientDisplayedColumns = ['Building Name', 'Building Premium', 'Building Phase ID', 'Building Availability', 'Edit', 'Expand Icon'];
  public adminDisplayedColumns = ['Checkbox', 'Building ID', 'Building Name', 'Building Premium', 'Building Phase ID', 'Building Availability', 'Edit', 'Expand Icon'];
  
  private innerTableRef: ComponentRef<FloorsTableViewComponent> = null;
  private innerTableRootDiv = null;

  public GetDisplayedColumns(): Array<string>
  {
    return this.authService.IsActiveUserAdmin() ? this.adminDisplayedColumns : this.clientDisplayedColumns;
  }

  constructor(public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  {
    super(); 
  }

  public RenderTableRows()
  {
    let buildings: Array<Building> =  Array.from(this.project.buildings.values());
    buildings = buildings.sort((b1, b2) => 
    {
      let b1Name = b1.name.toLowerCase();
      let b2Name = b2.name.toLowerCase();

      if (b1Name < b2Name)
      {
        return -1;
      }
      else if (b1Name > b2Name)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.buildingsDataSource.data = buildings;
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
    this.InitInnerTableView();
  }

  public InitInnerTableView()
  {
    this.innerTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FloorsTableViewComponent);
    this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);
    this.innerTableRef.instance.project = this.project;

    this.applicationRef.attachView(this.innerTableRef.hostView);
  }

  public BindInnerTableView(building: Building)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.building_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
      // document.removeChild(this.innerTableRootDiv);
    }

    this.innerTableRef.instance.buildingID = building.building_id;
    let expandableRowContainer = document.getElementById('inner-table-host-' + building.building_id);
    expandableRowContainer.appendChild(this.innerTableRootDiv);
    // document.appendChild(this.innerTableRootDiv);

    this.innerTableRef.instance.RenderTableRows();
    this.prevExpandedRow = building;

    this.changeDetectorRef.detectChanges();
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let building of this.buildingsDataSource.filteredData)
      {
        this.selectedBuildingIDs.add(building.building_id);
      }
    }
    else
    {
      this.selectedBuildingIDs.clear();
    }
  }

  public OnBuildingRowCheckboxToggled(checked: boolean, buildingID: number)
  {
    if (checked == true)
    {
      this.selectedBuildingIDs.add(buildingID);
    }
    else
    {
      this.selectedBuildingIDs.delete(buildingID);
    }
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(building: Building)
  {
    const dialogRef = this.dialog.open(CreateBuildingsDialogComponent, { width: '350px', height: '450px', data: { selectedProject: this.project, buildingToEdit: building } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectBuilding(this.project.project_id, building.building_id, result).subscribe((updatedBuilding: Building) => 
        {
          if (updatedBuilding != null)
          {
            this.project.buildings.set(building.building_id, updatedBuilding);
            this.appUtilityService.StoreCMSData();

            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateBuildingsDialogComponent, { width: '350px', height: '450px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewBuilding(this.project.project_id, result).subscribe((newBuilding: Building) => 
        {
          if (newBuilding != null)
          {
            this.project.buildings.set(newBuilding.building_id, newBuilding);
            this.appUtilityService.StoreCMSData();

            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.selectedBuildingIDs.size > 0)
    {
      if (this.authService.IsActiveUserAdmin())
      {
        return true;
      }
      else
      {
        // TODO Client-level buildings permissions?
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  OnDeleteButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, { width: '250px', height: '200px', data: { title: "Delete Buildings?" } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null && result == true)
      {
        this.projectsService.DeleteBuildingsFromProject(this.project.project_id, Array.from(this.selectedBuildingIDs)).subscribe((updatedBuildings: Map<number, Building>) =>
        {
          if (updatedBuildings != null)
          {
            this.project.buildings = updatedBuildings;
            this.appUtilityService.StoreCMSData();

            this.RenderTableRows();
          }
        });
      }
    });
  }

  HasSelectedRows(): boolean { return this.selectedBuildingIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedBuildingIDs; } 
  ClearSelectedRows(): void { this.selectedBuildingIDs.clear(); }
}

import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Injector, ApplicationRef, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Project, Model, ModelType, EArchitectureType, Building } from 'src/DataModels';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CreateModelDialogComponent } from '../dialog/create-model-dialog/create-model-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ModelsDualTableViewComponent } from '../models-dual-table-view/models-dual-table-view.component';
import { CurrencyPipe } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { ModelTypesTableViewComponent } from '../model-types-table-view/model-types-table-view.component';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: ModelsTableViewComponent }],
  selector: 'app-models-table-view',
  templateUrl: './models-table-view.component.html',
  styleUrls: ['./models-table-view.component.css'],
})
export class ModelsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Model>;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;
  @ViewChild('modelsTablePaginator', {read: MatPaginator, static: false}) paginator: MatPaginator;

  public modelsDataSource: MatTableDataSource<Model> = new MatTableDataSource<Model>();
  public expandedRow: Model = null;
  private prevExpandedRow: Model = null;

  private clientColumns = ['Name', 'Buildings', 'Description', 'Price Range', 'Footage Range', 'Outdoor Footage Range', 'Sticky Column'];
  private adminColumns = ['Checkbox', 'Name', 'Buildings', 'Description', 'Price Range', 'Footage Range', 'Outdoor Footage Range', 'Sticky Column'];

  public selectedModelIDs: Set<number> = new Set<number>();

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project()
  {
    return this._project;
  }

  private _architectureType: EArchitectureType = EArchitectureType.condo;

  @Input()
  set architectureType(architectureType: EArchitectureType)
  {
    this._architectureType = architectureType;
  }

  get architectureType()
  {
    return this._architectureType;
  }

  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;
  }

  private modeltypesTableRef: ComponentRef<ModelTypesTableViewComponent> = null;
  private modeltypesTableRootDiv = null;

  constructor(@Host() public parent: ModelsDualTableViewComponent, private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private currencyPipe: CurrencyPipe, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  { 
    super();
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.modelsDataSource.paginator = this.paginator;
    this.RenderTableRows();

    this.InitInnerTableView();
  }

  public InitInnerTableView()
  {
    this.modeltypesTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModelTypesTableViewComponent);
    this.modeltypesTableRef = componentFactory.create(this.injector, [], this.modeltypesTableRootDiv);
    this.modeltypesTableRef.instance.project = this.project;

    this.applicationRef.attachView(this.modeltypesTableRef.hostView);
  }

  public BindInnerTableView(model: Model)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.model_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
    }

    this.modeltypesTableRef.instance.modelID = model.model_id;
    let expandableRowContainer = document.getElementById('inner-table-host-' + model.model_id);
    expandableRowContainer.appendChild(this.modeltypesTableRootDiv);
    
    this.modeltypesTableRef.instance.RenderTableRows();
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let model of this.modelsDataSource.filteredData)
      {
        this.selectedModelIDs.add(model.model_id);
      }
    }
    else
    {
      this.selectedModelIDs.clear();
    }
  }

  public OnModelRowCheckboxToggled(checked: boolean, modelID: number)
  {
    if (checked == true)
    {
      this.selectedModelIDs.add(modelID);
    }
    else
    {
      this.selectedModelIDs.delete(modelID);
    }
  }

  public GetModelParentBuildingNames(model: Model): string
  {
    if (model !== null && this.project.buildings.size > 0)
    {
      if (model.linked_building_ids.length > 0)
      {
        let strBuildings: string = "";

        for (let buildingID of model.linked_building_ids)
        {
          if (this.project.buildings.has(buildingID))
          {
            let building: Building = this.project.buildings.get(buildingID);
            strBuildings += building.name + ", ";
          }
        }

        strBuildings = strBuildings.substring(0, strBuildings.length - 2);
        return strBuildings;
      }
      else
      {
        return "Any Building"
      }
    }

    return "Any Building";
  }

  // Returns the description field on the first model type, if it exists
  public GetModelTypeDescription(model: Model): string
  {
    for (let modeltype of this.project.model_types.values())
    {
      if (modeltype.model_id == model.model_id)
      {
        return modeltype.description;
      }
    }

    return "";
  }

  public GetPriceRange(model: Model): string
  {
    let strPriceRange: string = "";
    let childModelTypes: Array<ModelType> = new Array<ModelType>();

    for (let modeltype of this.project.model_types.values())
    {
      if (modeltype.model_id == model.model_id)
      {
        childModelTypes.push(modeltype);
      }
    }

    if (childModelTypes.length > 0)
    {
      let lowestPrice = childModelTypes[0].price;
      let highestPrice = childModelTypes[0].price;

      for (let i = 1; i < childModelTypes.length; ++i)
      {
        if (childModelTypes[i].price < lowestPrice)
        {
          lowestPrice = childModelTypes[i].price;
        }
        else if (childModelTypes[i].price > highestPrice)
        {
          highestPrice = childModelTypes[i].price;
        }
      }

      if (lowestPrice == highestPrice)
      {
        strPriceRange = this.currencyPipe.transform(lowestPrice).toString();
      }
      else
      {
        strPriceRange = this.currencyPipe.transform(lowestPrice).toString() + " - " + this.currencyPipe.transform(highestPrice).toString();
      }
    }

    return strPriceRange;
  }

  public GetFootageRange(model: Model): string
  {
    let strFootageRange: string = "";
    let childModelTypes: Array<ModelType> = new Array<ModelType>();

    for (let modeltype of this.project.model_types.values())
    {
      if (modeltype.model_id == model.model_id)
      {
        childModelTypes.push(modeltype);
      }
    }

    if (childModelTypes.length > 0)
    {
      let lowestFootage = childModelTypes[0].square_footage;
      let highestFootage = childModelTypes[0].square_footage;

      for (let i = 1; i < childModelTypes.length; ++i)
      {
        if (childModelTypes[i].square_footage < lowestFootage)
        {
          lowestFootage = childModelTypes[i].square_footage;
        }
        else if (childModelTypes[i].square_footage > highestFootage)
        {
          highestFootage = childModelTypes[i].square_footage;
        }
      }

      if (lowestFootage == highestFootage)
      {
        strFootageRange = lowestFootage.toString();
      }
      else
      {
        strFootageRange = lowestFootage.toString() + " - " + highestFootage.toString();
      }

      strFootageRange += " sq ft"
    }

    return strFootageRange;
  }

  public GetOutdoorFootageRange(model: Model): string
  {
    let strOutdoorFootageRange: string = "";
    let childModelTypes: Array<ModelType> = new Array<ModelType>();

    for (let modeltype of this.project.model_types.values())
    {
      if (modeltype.model_id == model.model_id)
      {
        childModelTypes.push(modeltype);
      }
    }

    if (childModelTypes.length > 0)
    {
      let lowestOutdoorFootage = childModelTypes[0].square_footage_outdoor;
      let highestOutdoorFootage = childModelTypes[0].square_footage_outdoor;

      for (let i = 1; i < childModelTypes.length; ++i)
      {
        if (childModelTypes[i].square_footage_outdoor < lowestOutdoorFootage)
        {
          lowestOutdoorFootage = childModelTypes[i].square_footage_outdoor;
        }
        else if (childModelTypes[i].square_footage_outdoor > highestOutdoorFootage)
        {
          highestOutdoorFootage = childModelTypes[i].square_footage_outdoor;
        }
      }

      if (lowestOutdoorFootage == highestOutdoorFootage)
      {
        strOutdoorFootageRange = lowestOutdoorFootage.toString();
      }
      else
      {
        strOutdoorFootageRange = lowestOutdoorFootage.toString() + " - " + highestOutdoorFootage.toString();
      }

      strOutdoorFootageRange += " sq ft"
    }

    return strOutdoorFootageRange;
  }

  public RenderTableRows()
  {
    let applicableModels: Array<Model> = new Array<Model>();
    for (let model of this.project.models.values())
    {
      if (model.architecture_type == this.architectureType)
      {
        applicableModels.push(model);
      }
    }

    applicableModels = applicableModels.sort((m1, m2) => 
    {
      let m1Name = m1.name.toLowerCase();
      let m2Name = m2.name.toLowerCase();

      if (m1Name < m2Name)
      {
        return -1;
      }
      else if (m1Name > m2Name)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.modelsDataSource.data = applicableModels;
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  HasSelectedRows(): boolean { return this.selectedModelIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedModelIDs; } 
  
  ClearSelectedRows(): void 
  { 
    this.selectedModelIDs.clear(); 
    this.selectAllCheckbox.checked = false;
  }

  ShouldDisplayAddButton(): boolean 
  {
    throw new Error("Method not implemented.");
  }
  
  ShouldDisplayEditButton(): boolean 
  {
    throw new Error("Method not implemented.");
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    throw new Error("Method not implemented.");
  }
  
  OnAddButtonPressed() 
  {
    throw new Error("Method not implemented.");
  }
  
  OnEditButtonPressed(row: any) 
  {
    throw new Error("Method not implemented.");
  }
  
  OnDeleteButtonPressed() 
  {
    throw new Error("Method not implemented.");
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { EUserType } from 'src/DataModels';
import { CMSComponentBase } from 'src/app/interfaces/cms-component-base';
import { AppUtilityService } from './app-utility.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad 
{
  constructor(private authService: AuthService, private appUtilityService: AppUtilityService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    return this.IsLoggedInAndHasPermission(route);
  }
  
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> 
  {
    return this.canActivate(childRoute, state);
  }
  
  canLoad(route: Route): boolean
  {
    return this.IsLoggedInAndHasPermission(route);
  }

  private IsLoggedInAndHasPermission(route: Route | ActivatedRouteSnapshot): boolean
  {
    if (this.authService.IsLoggedIn())
    {
      if (route.data.allowedRoles && route.data.allowedRoles.indexOf(EUserType.all) !== -1)
      {
        return true;
      }
      else if (route.data.allowedRoles && route.data.allowedRoles.indexOf(this.authService.GetActiveUserType()) === -1)
      {
        this.router.navigate(['/']);
        return false;
      }
      else
      {
        return true;
      }
    }
    else
    {
      this.authService.strRedirectURL = window.location.href;
      this.router.navigate(['/login']);
      return false;
    }
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { Unit, EAvailabilityCode, Project, Floor, EOrientationType } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';

export interface CreateUnitDialogClientData
{
  selectedProject: Project
  selectedUnitIDs: Array<number>;
}

@Component({
  selector: 'app-create-unit-dialog',
  templateUrl: './create-unit-dialog-client.component.html',
  styleUrls: ['./create-unit-dialog-client.component.css']
})
export class CreateUnitDialogClientComponent implements OnInit 
{
  public premiumField: FormControl = new FormControl(0);
  public orientationPremiumField: FormControl = new FormControl(0);
  public availabilityField: FormControl = new FormControl({value: EAvailabilityCode.Unavailable, disabled: !this.CanEditUnitAvailabilityStatus()});

  public parkingIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public lockerIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public storageIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});

  constructor(private dialogRef: MatDialogRef<CreateUnitDialogClientComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateUnitDialogClientData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetOrientationOptions(): Array<string>
  {
    let keys = Object.keys(EOrientationType);
    return keys;
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public IsAvailabilityStatusDisabled(status: string): boolean
  {
    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      switch (status)
      {
        case EAvailabilityCode[EAvailabilityCode.Sold]:
          return !projectRole.can_finalize_sales;
        default:
          return false;
      }
    }
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public CanEditUnitAvailabilityStatus(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public CanEditUnitPricingInfo(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public CanEditUnitData(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_unit_data;
      }

      return false;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    return "Edit Unit";
  }

  public IsInMultiEditMode(): boolean
  {
    return this.data.selectedUnitIDs != null && this.data.selectedUnitIDs.length > 1;
  }

  public IsInSingleEditMode(): boolean
  {
    return this.data.selectedUnitIDs != null && this.data.selectedUnitIDs.length == 1;
  }

  ngOnInit() 
  {
    this.premiumField.setValidators([Validators.required]);
    this.orientationPremiumField.setValidators([Validators.required]);

    if (this.IsInSingleEditMode())
    {
      let unitToEdit: Unit = this.data.selectedProject.units.get(this.data.selectedUnitIDs[0]);
      if (unitToEdit != null)
      {
        this.availabilityField.setValue(unitToEdit.availability);

        this.premiumField.setValue(unitToEdit.premium);
        this.orientationPremiumField.setValue(unitToEdit.orientation_premium);
        this.parkingIncludedField.setValue(unitToEdit.parking_included);
        this.lockerIncludedField.setValue(unitToEdit.locker_included);
        this.storageIncludedField.setValue(unitToEdit.storage_included);
      }
    }
    else if (this.IsInMultiEditMode())
    {
        this.availabilityField.setValue(null);
        this.premiumField.setValue(null);
        this.orientationPremiumField.setValue(null);
        this.parkingIncludedField.setValue(null);
        this.lockerIncludedField.setValue(null);
        this.storageIncludedField.setValue(null);
    }
  }

  public GetPremiumFieldError(): string
  {
    if (this.premiumField.hasError('required'))
    {
      return "Please enter a suite premium";
    }
    else if (this.premiumField.hasError('too_low'))
    {
      return "Suite premium must be greater than 0";
    }
  }

  public GetOrientationPremiumFieldError(): string
  {
    if (this.orientationPremiumField.hasError('required'))
    {
      return "Please enter a suite premium";
    }
    else if (this.orientationPremiumField.hasError('too_low'))
    {
      return "Suite premium must be greater than 0";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isUnitInfoValid: boolean = true;

    if (this.premiumField.value < 0)
    {
      this.premiumField.setErrors({too_low: true});
      this.premiumField.markAsTouched();
      isUnitInfoValid = false;
    }

    if (this.orientationPremiumField.value < 0)
    {
      this.orientationPremiumField.setErrors({too_low: true});
      this.orientationPremiumField.markAsTouched();
      isUnitInfoValid = false;
    }

    if (isUnitInfoValid)
    {
      let unitInfoDict: Map<string, any> = new Map<string, any>();

      unitInfoDict.set('premium', this.premiumField.value);
      unitInfoDict.set('parking_included', this.parkingIncludedField.value);
      unitInfoDict.set('locker_included', this.lockerIncludedField.value);
      unitInfoDict.set('storage_included', this.storageIncludedField.value);
      unitInfoDict.set('orientation_premium', this.orientationPremiumField.value);
      unitInfoDict.set('availability', this.availabilityField.value);
      
      this.dialogRef.close(unitInfoDict);
    }
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRole } from 'src/DataModels';
import { AuthService } from 'src/app/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { AppUtilityService } from 'src/app/app-utility.service';

export interface CreateUserRoleDialogData
{
  roleToEdit: UserRole;
}

@Component({
  selector: 'app-create-user-role-dialog',
  templateUrl: './create-user-role-dialog.component.html',
  styleUrls: ['./create-user-role-dialog.component.css']
})
export class CreateUserRoleDialogComponent implements OnInit 
{
  public roleNameField: FormControl = new FormControl({value: '', disabled: this.IsDialogReadOnly()});
  public userTypeSelection: string = "client";

  public accessFrontendToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public accessBackendToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public companiesViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public companiesCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public companiesEditToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public companiesDeleteToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public usersViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public usersCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public usersEditToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public usersAssignRolesToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public usersDeleteToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public userRolesViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public userRolesCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public userRolesEditToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public userRolesDeleteToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public projectRolesViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectRolesCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectRolesEditToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectRolesDeleteToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  public projectsViewToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsCreateToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsEditToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsEditProjectDataToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});
  public projectsDeleteToggle: FormControl = new FormControl({value: false, disabled: this.IsDialogReadOnly()});

  constructor(private dialogRef: MatDialogRef<CreateUserRoleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateUserRoleDialogData, private authService: AuthService, private appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
    // Defer attaching form validators to here so that they don't interfere
    // With the disabled property
    if (!this.IsDialogReadOnly())
    {
      this.roleNameField.setValidators([Validators.required]); 
    }

    if (this.data.roleToEdit != null)
    {
      this.roleNameField.setValue(this.data.roleToEdit.user_role_name);
      this.userTypeSelection = this.data.roleToEdit.user_type;

      this.accessFrontendToggle.setValue(this.data.roleToEdit.can_access_frontend);
      this.accessBackendToggle.setValue(this.data.roleToEdit.can_access_backend);

      this.companiesViewToggle.setValue(this.data.roleToEdit.can_view_companies);
      this.companiesCreateToggle.setValue(this.data.roleToEdit.can_create_companies);
      this.companiesEditToggle.setValue(this.data.roleToEdit.can_edit_companies);
      this.companiesDeleteToggle.setValue(this.data.roleToEdit.can_delete_companies);

      this.usersViewToggle.setValue(this.data.roleToEdit.can_view_users);
      this.usersCreateToggle.setValue(this.data.roleToEdit.can_create_users);
      this.usersEditToggle.setValue(this.data.roleToEdit.can_edit_users);
      this.usersAssignRolesToggle.setValue(this.data.roleToEdit.can_assign_user_roles);
      this.usersDeleteToggle.setValue(this.data.roleToEdit.can_delete_users);

      this.userRolesViewToggle.setValue(this.data.roleToEdit.can_view_user_roles);
      this.userRolesCreateToggle.setValue(this.data.roleToEdit.can_create_user_roles);
      this.userRolesEditToggle.setValue(this.data.roleToEdit.can_edit_user_roles);
      this.userRolesDeleteToggle.setValue(this.data.roleToEdit.can_delete_user_roles);

      this.projectRolesViewToggle.setValue(this.data.roleToEdit.can_view_project_roles);
      this.projectRolesCreateToggle.setValue(this.data.roleToEdit.can_create_project_roles);
      this.projectRolesEditToggle.setValue(this.data.roleToEdit.can_edit_project_roles);
      this.projectRolesDeleteToggle.setValue(this.data.roleToEdit.can_delete_project_roles);

      this.projectsViewToggle.setValue(this.data.roleToEdit.can_view_projects);
      this.projectsCreateToggle.setValue(this.data.roleToEdit.can_create_projects);
      this.projectsEditToggle.setValue(this.data.roleToEdit.can_edit_projects);
      this.projectsEditProjectDataToggle.setValue(this.data.roleToEdit.can_edit_projects_data);
      this.projectsDeleteToggle.setValue(this.data.roleToEdit.can_delete_projects);
    }
  }

  public IsDialogReadOnly(): boolean
  {
    return this.data.roleToEdit != null && !this.authService.activeUserRole.can_edit_user_roles;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.roleToEdit != null)
    {
      return this.IsDialogReadOnly() ? "User Role Details" : "Edit User Role";
    }
    else
    {
      return "New User Role";
    }
  }

  public GetRoleNameFieldError(): string
  {
    if (this.roleNameField.hasError('required'))
    {
      return "Please enter a role name";
    }
    else if (this.roleNameField.hasError('unique'))
    {
      return this.roleNameField.value + " is already taken";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isRoleInfoValid: boolean = true;

    if (this.roleNameField.value == "")
    {
      this.roleNameField.setErrors({required: true});
      this.roleNameField.markAsTouched();
      isRoleInfoValid = false;
    }
    else
    {
      if (this.appUtilityService.userRolesDict.size > 0)
      {
        for (let role of this.appUtilityService.userRolesDict.values())
        {
          if (role.user_role_name == this.roleNameField.value)
          {
            if (this.data.roleToEdit == null || this.data.roleToEdit.user_role_id != role.user_role_id)
            {
              this.roleNameField.setErrors({unique: true});
              this.roleNameField.markAsTouched();
              isRoleInfoValid = false;
            }
            
            break;
          }
        }
      }
    }

    if (isRoleInfoValid)
    {
      let roleInfoDict: Map<string, any> = new Map<string, any>();
    
      roleInfoDict.set("user_role_name", this.roleNameField.value);
      roleInfoDict.set("user_type", this.userTypeSelection);

      roleInfoDict.set("can_access_frontend", this.accessFrontendToggle.value);
      roleInfoDict.set("can_access_backend", this.accessBackendToggle.value);

      roleInfoDict.set("can_view_companies", this.companiesViewToggle.value);
      roleInfoDict.set("can_create_companies", this.companiesCreateToggle.value);
      roleInfoDict.set("can_edit_companies", this.companiesEditToggle.value);
      roleInfoDict.set("can_delete_companies", this.companiesDeleteToggle.value);

      roleInfoDict.set("can_view_users", this.usersViewToggle.value);
      roleInfoDict.set("can_create_users", this.usersCreateToggle.value);
      roleInfoDict.set("can_edit_users", this.usersEditToggle.value);
      roleInfoDict.set("can_assign_user_roles", this.usersAssignRolesToggle.value);
      roleInfoDict.set("can_delete_users", this.usersDeleteToggle.value);

      roleInfoDict.set("can_view_user_roles", this.userRolesViewToggle.value);
      roleInfoDict.set("can_create_user_roles", this.userRolesCreateToggle.value);
      roleInfoDict.set("can_edit_user_roles", this.userRolesEditToggle.value);
      roleInfoDict.set("can_delete_user_roles", this.userRolesDeleteToggle.value);

      roleInfoDict.set("can_view_project_roles", this.projectRolesViewToggle.value);
      roleInfoDict.set("can_create_project_roles", this.projectRolesCreateToggle.value);
      roleInfoDict.set("can_edit_project_roles", this.projectRolesEditToggle.value);
      roleInfoDict.set("can_delete_project_roles", this.projectRolesDeleteToggle.value);

      roleInfoDict.set("can_view_projects", this.projectsViewToggle.value);
      roleInfoDict.set("can_create_projects", this.projectsCreateToggle.value);
      roleInfoDict.set("can_edit_projects", this.projectsEditToggle.value);
      roleInfoDict.set("can_edit_projects_data", this.projectsEditProjectDataToggle.value);
      roleInfoDict.set("can_delete_projects", this.projectsDeleteToggle.value);

      this.dialogRef.close(roleInfoDict);
    }
  }
}

import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ChangeDetectorRef, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { Project, Phase, EAvailabilityCode } from 'src/DataModels';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { CreatePhaseDialogComponent } from '../dialog/create-phase-dialog/create-phase-dialog.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of } from 'rxjs';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LotsBaseTableViewComponent } from '../lots-base-table-view/lots-base-table-view.component';
import { logging } from 'protractor';


@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: PhaseTableViewComponent }],
  selector: 'app-phase-table-view',
  templateUrl: './phase-table-view.component.html',
  styleUrls: ['./phase-table-view.component.css']
})
export class PhaseTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit {
  @ViewChild(MatTable, {static: false}) table: MatTable<Phase>;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;
  @ViewChild(LotsBaseTableViewComponent,{static:false}) lotsBaseTableView: LotsBaseTableViewComponent;
  @ViewChild('lotsTablePaginator', {read: MatPaginator, static: false}) paginator: MatPaginator;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;
  private clientColumns = ['Phase ID','Phase Name', 'Phase Status'];
  private adminColumns = ['Checkbox', 'Phase ID','Phase Name', 'Phase Status', 'Sticky Column'];
  public phaseDataSource: MatTableDataSource<Phase> = new MatTableDataSource<Phase>();
  public selectedPhaseIDs: Set<number> = new Set<number>();
  private _project: Project = null;
  private innerTableRef: ComponentRef<LotsBaseTableViewComponent> = null;
  private innerTableRootDiv = null;
  public expandedRow: Phase = null;
  private prevExpandedRow: Phase = null;
  @Input()
  set project(project: Project)
  {
    this._project = project;
  }
  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;
  }
  get project()
  {
    return this._project;
  }

  constructor(@Host() public parent: ProjectDetailsTableViewComponent, private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  { 
    super();
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }
  public OnPhaseRowCheckboxToggled(checked: boolean, phaseID: number)
  {
    if (checked == true)
    {
      this.selectedPhaseIDs.add(phaseID);
    }
    else
    {
      this.selectedPhaseIDs.delete(phaseID);
    }
  }
  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let phase of this.phaseDataSource.filteredData)
      {
        this.selectedPhaseIDs.add(phase.phase_id);
      }
    }
    else
    {
      this.selectedPhaseIDs.clear();
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit()
  {
    this.phaseDataSource.sort = this.matSort;
    this.phaseDataSource.sortingDataAccessor = this.SortColumnDataAccessor.bind(this);
    this.phaseDataSource.paginator = this.paginator;

    this.RenderTableRows();
    this.InitInnerTableView();
  }
  public InitInnerTableView()
  {
    this.innerTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LotsBaseTableViewComponent);
    this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);
    this.innerTableRef.instance.project = this.project;
    this.innerTableRef.instance.innerContext = true;

    this.applicationRef.attachView(this.innerTableRef.hostView);
  }
  public BindInnerTableView(phase: Phase)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.phase_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
    }
    let filterDataDict = {};
    filterDataDict["phase_id"] = phase.phase_id;
    this.innerTableRef.instance.lotsDataSource.filter = JSON.stringify(filterDataDict);
    let expandableRowContainer = document.getElementById('inner-table-host-' + phase.phase_id);
    expandableRowContainer.appendChild(this.innerTableRootDiv);

    this.innerTableRef.instance.RenderTableRows();
  }
  public ShouldExpandLotsTableRow(row): boolean
  {
    if (this.authService.IsActiveUserAdmin())
    {
      this.expandedRow = this.expandedRow === row ? null : row;
      if (this.expandedRow != null)
      {
        this.BindInnerTableView(row);
        return true;
      }
    }
    else
    {
      return false;
    }
  }
  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreatePhaseDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewPhase(this.project.project_id, result).subscribe((newPhase: Phase) => 
        {
          if (newPhase != null)
          {
            this.project.phases.set(newPhase.phase_id, newPhase);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }
  public RenderTableRows()
  {
    let phases: Array<Phase> = Array.from(this.project.phases.values());
    phases = phases.sort((l1, l2) => 
    {
      if (l1.phase_id < l2.phase_id)
      {
        return -1;
      }
      else if (l1.phase_id > l2.phase_id)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });
    this.phaseDataSource.data = phases;
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();

  }

  private SortColumnDataAccessor(row: Phase, columnHeader: string): string | number
  {
    switch (columnHeader)
    {
      case "Phase ID":
        return row.phase_id;
      case "Phase Name":
        return row.phase_name;
      case "Phase Status":
        return row.phase_status;
      default:
        return null;
    }
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing || projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(phase: Phase)
  {
    const dialogRef = this.dialog.open(CreatePhaseDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, phaseToEdit: phase } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      if (result != null) {
        let updatePhaseCallback = this.projectsService.UpdateProjectPhase(this.project.project_id, phase.phase_id, result);
        for (let lot of this.project.lots.values()) {
          if (lot.phase_id == phase.phase_id) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LotsBaseTableViewComponent);
            this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);
            this.innerTableRef.instance.project = this.project;
            this.innerTableRef.instance.innerContext = true;
            this.innerTableRef.instance.CascadeResults(result, lot)

          }
        }
        
        forkJoin(updatePhaseCallback).subscribe(results => {
          if (results[0] != null) {
            this.project.phases.set(results[0].phase_id, results[0]);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
            this.InitInnerTableView();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedPhaseIDs.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  PhaseHasChildren(phase_id:number): boolean {
    let lots = Array.from(this.project.lots.values())
    if (lots.filter(x => x.phase_id === phase_id).length > 0) {
      return true;
    } else {
      return false;
    }
  }
  OnDeleteButtonPressed(): void {
    if (this.selectedPhaseIDs.size > 0) {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Phases?" }});
      let selectedPhaseIDsArray = []
      for(let phase_id of this.selectedPhaseIDs) {
        if (this.PhaseHasChildren(phase_id) == false) {
          selectedPhaseIDsArray.push(phase_id)
        }
      }
      if (selectedPhaseIDsArray.length > 0) {
        dialogRef.afterClosed().subscribe((result) => {
          if (result == true) {
            this.projectsService.DeletePhasesFromProject(this.project.project_id, selectedPhaseIDsArray).subscribe((updatedPhases: Map<number, Phase>) => {

              if (updatedPhases != null) {
                this.project.phases = updatedPhases;
                this.appUtilityService.StoreCMSData();
                this.selectedPhaseIDs.clear();
                this.RenderTableRows();
              }
            });
          }
        });
      }
    }
  }
  HasSelectedRows(): boolean { return this.selectedPhaseIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedPhaseIDs; } 
  ClearSelectedRows(): void { this.selectedPhaseIDs.clear(); }
  public OnTabEntered(): void{}
}

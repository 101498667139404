import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DictionaryUtilitiesService 
{
  constructor() { }

  public GetRawDictionary(dictionary: Map<string, any> | Set<any>)
  {
    if (dictionary == null || dictionary == undefined) { return null; }

    let rawDict = {};
    for (let [k, v] of dictionary)
    {
      if (v instanceof Map)
      {
        rawDict[k] = this.GetRawDictionary(v);
      }
      else if (v instanceof Set)
      {
        // Currently assumes that the set is only one level deep, 
        // and not a set of sets.
        rawDict[k] = Array.from(v.values());
      }
      else
      {
        // Determine if the current entry is a primitive or a more complex object
        // Primitives don't need any of the extra dictionary unpacking logic
        if (v !== Object(v))
        {
          rawDict[k] = v;
        }
        else
        {
          let rawObj = {};
          for (const [k2, v2] of Object.entries(v))
          {
            if (v2 instanceof Map)
            {
              rawObj[k2] = this.GetRawDictionary(v2);
            }
            else if (v2 instanceof Set)
            {
              rawObj[k2] = Array.from(v2.values());
            }
            else
            {
              rawObj[k2] = v2;
            }
          }

          rawDict[k] = rawObj;
        }
      }
    }

    return rawDict;
  }
}

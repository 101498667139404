import { Component, OnInit, Input } from '@angular/core';
import { Project, Unit } from 'src/DataModels';
import { MatTableDataSource } from '@angular/material/table';
import { AppUtilityService } from '../app-utility.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-suites-inner-table-view',
  templateUrl: './suites-inner-table-view.component.html',
  styleUrls: ['./suites-inner-table-view.component.css']
})
export class SuitesInnerTableViewComponent implements OnInit 
{
  public adminColumnNames = ['Premium', 'Orientation Premium', 'Parking', 'Storage', 'Locker', 'Mirror'];
  public clientColumnNames = ['Premium', 'Orientation Premium', 'Parking', 'Storage', 'Locker', 'Mirror'];

  public innerUnitsDataSource: MatTableDataSource<Unit> = null;

  private _unit: Unit = null;
  
  @Input()
  set unit(unit: Unit)
  {
    this._unit = unit;
    this.innerUnitsDataSource = new MatTableDataSource([this.unit]);
  }

  get unit()
  {
    return this._unit;
  }

  constructor(public appUtilityService: AppUtilityService, public authService: AuthService) { }

  public GetDisplayedColumns()
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.adminColumnNames;
    }
    else
    {
      return this.clientColumnNames;
    }
  }

  ngOnInit() 
  {
  }
}

import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Project, Elevation, Home } from 'src/DataModels';
import { MatDialog } from '@angular/material/dialog';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { CreateElevationDialogComponent } from '../dialog/create-elevation-dialog/create-elevation-dialog.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { CMSTableViewBase } from '../interfaces/cms-component-base';

@Component({
  selector: 'app-elevations-table-view',
  templateUrl: './elevations-table-view.component.html',
  styleUrls: ['./elevations-table-view.component.css']
})
export class ElevationsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Elevation>;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  private clientColumns = ['Elevation', 'Price', 'Footage', 'Unit Description', 'Edit'];
  // private adminColumns = ['Checkbox', 'Elevation ID', 'Detached ID', 'Elevation', 'Price', 'Footage', 'Unit Description', 'Edit'];
  private adminColumns = ['Checkbox', 'Elevation', 'Price', 'Footage', 'Unit Description', 'Edit'];

  public selectedElevationIds: Set<number> = new Set<number>();

  public elevationsDataSource: MatTableDataSource<Elevation> = new MatTableDataSource<Elevation>();

  private _homeID: number = null;

  @Input()
  set homeID(homeID: number)
  {
    this._homeID = homeID;    
  }

  get homeID(): number 
  {
    return this._homeID;
  }

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public GetHomeElevationsIterator(): Array<Elevation>
  {
    let elevationsArray: Array<Elevation> = new Array<Elevation>();

    for (let elevation of this.project.elevations.values())
    {
      if (elevation.detached_id == this.homeID)
      {
        elevationsArray.push(elevation);
      }
    }

    return elevationsArray;
  }

  public GetDisplayedColumns()
  {
    return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;
  }

  constructor(public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef) 
  {
    super();
  }

  public IsStartElevationRow(elevation: Elevation): boolean
  {
    let home: Home = this.project.homes.get(this.homeID);
    return home.start_elevation == elevation.elevation_id
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let elevation of this.elevationsDataSource.filteredData)
      {
        this.selectedElevationIds.add(elevation.elevation_id);
      }
    }
    else
    {
      this.selectedElevationIds.clear();
    }
  }

  public OnElevationCheckboxToggled(checked: boolean, elevationID: number)
  {
    if (checked == true)
    {
      this.selectedElevationIds.add(elevationID);
    }
    else
    {
      this.selectedElevationIds.delete(elevationID);
    }
  }

  public ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnAddButtonPressed()
  {
    const dialogRef = this.dialog.open(CreateElevationDialogComponent, { width: '350px', height: '450px', data: { selectedProject: this.project, selectedHomeID: this.homeID } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewElevation(this.project.project_id, result).subscribe((newElevation: Elevation) => 
        {
          if (newElevation != null)
          {
            this.project.elevations.set(newElevation.elevation_id, newElevation);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(elevation: Elevation)
  {
    const dialogRef = this.dialog.open(CreateElevationDialogComponent, { width: '350px', height: '450px', data: { selectedProject: this.project, selectedHomeID: this.homeID,  elevationToEdit: elevation} });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectElevation(this.project.project_id, elevation.elevation_id, result).subscribe((updatedElevation: Elevation) => 
        {
          if (updatedElevation != null)
          {
            this.project.elevations.set(elevation.elevation_id, updatedElevation);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  public ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedElevationIds.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  public OnDeleteButtonPressed()
  {
    if (this.selectedElevationIds.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Elevations?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteElevationsFromProject(this.project.project_id, Array.from(this.selectedElevationIds)).subscribe((updatedElevations: Map<number, Elevation>) => 
          {
            this.project.elevations = updatedElevations;
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
            this.selectedElevationIds.clear();
          });
        }
      });
    }
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
  }

  public RenderTableRows()
  {
    let elevations: Array<Elevation> = Array.from(this.GetHomeElevationsIterator());
    elevations = elevations.sort((e1, e2) => 
    {
      let e1Name = e1.elevation.toLowerCase();
      let e2Name = e2.elevation.toLowerCase();

      if (e1Name < e2Name)
      {
        return -1;
      }
      else if (e1Name > e2Name)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.elevationsDataSource.data = elevations;
    this.table.renderRows();
    this.changeDetectorRef.detectChanges();
  }

  HasSelectedRows(): boolean { return this.selectedElevationIds.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedElevationIds; } 
  ClearSelectedRows(): void { this.selectedElevationIds.clear(); }
}

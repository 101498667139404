import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { LoadingSpinnerOverlayService } from './loading-spinner-overlay.service';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerInterceptor implements HttpInterceptor 
{
    constructor(private loadingSpinnerOverlayService: LoadingSpinnerOverlayService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): import("rxjs").Observable<HttpEvent<any>> 
    {
        this.loadingSpinnerOverlayService.Show();

        return next.handle(req).pipe(finalize(() => 
        {
            this.loadingSpinnerOverlayService.Hide();
        }))
    }
}

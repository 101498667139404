import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GenericDeleteConfirmationDialogData
{
  title: string
}

@Component({
  selector: 'app-generic-delete-confirmation-dialog',
  templateUrl: './generic-delete-confirmation-dialog.component.html',
  styleUrls: ['./generic-delete-confirmation-dialog.component.css']
})
export class GenericDeleteConfirmationDialogComponent implements OnInit 
{
  constructor(public dialogRef: MatDialogRef<GenericDeleteConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GenericDeleteConfirmationDialogData) { }

  ngOnInit() 
  {
  }

  public GetDeleteDialogTitle()
  {
    if (this.data.title != null || this.data.title != "")
    {
      return this.data.title;
    }
    else
    {
      return "Delete";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close(false);
  }

  public OnDeleteButtonClicked()
  {
    this.dialogRef.close(true);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { AppConstantsService } from './app-constants.service';
import { noop } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private appConstants: AppConstantsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): import("rxjs").Observable<HttpEvent<any>>
    {
        // Don't modify the params of requests internal to the application (i.e. CSV Uploading).
        if (!req.url.startsWith("data:application"))
        {
            if (req.method === "GET" || req.method === "DELETE")
            {
                req = req.clone(
                {
                    headers: req.headers, 
                    reportProgress: req.reportProgress, 
                    params: req.params,
                    responseType: req.responseType,
                    withCredentials: req.withCredentials,
                    body: req.body,
                    method: req.method,
                });
            }
            else
            {

            }
        }

        return next.handle(req);
    }
}

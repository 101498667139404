import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { AuthService } from 'src/app/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Building, EAvailabilityCode, Project } from 'src/DataModels';
import { stringify } from 'querystring';

export interface CreateBuildingsDialogData
{
  selectedProject: Project;
  buildingToEdit: Building;
}

@Component({
  selector: 'app-create-buildings-dialog',
  templateUrl: './create-buildings-dialog.component.html',
  styleUrls: ['./create-buildings-dialog.component.css']
})
export class CreateBuildingsDialogComponent implements OnInit 
{
  public buildingNameField: FormControl = new FormControl('');
  public premiumField: FormControl = new FormControl(0);
  public phaseIDField: FormControl = new FormControl(0);
  public selectedAvailabilityStatus: EAvailabilityCode = EAvailabilityCode.Unavailable;

  constructor(private dialogRef: MatDialogRef<CreateBuildingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateBuildingsDialogData, public appUtilityService: AppUtilityService, public authService: AuthService) { }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public IsAvailabilityStatusDisabled(status: string): boolean
  {
    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      switch (status)
      {
        case EAvailabilityCode[EAvailabilityCode.Sold]:
          return !projectRole.can_finalize_sales;
        default:
          return false;
      }
    }
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public CanEditBuildingAvailabilityStatus(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public CanEditBuildingPricingInfo(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.buildingToEdit != null)
    {
      return "Edit Building";
    }
    else
    {
      return "Create Building";
    }
  }

  ngOnInit() 
  {
    this.buildingNameField.setValidators([Validators.required]);
    this.phaseIDField.setValidators([Validators.required]);

    if (this.data.buildingToEdit != null)
    {
      this.buildingNameField.setValue(this.data.buildingToEdit.name);
      this.premiumField.setValue(this.data.buildingToEdit.premium);
      this.phaseIDField.setValue(this.data.buildingToEdit.phase_id);
      this.selectedAvailabilityStatus = this.data.buildingToEdit.availability;
    }
  }

  public GetBuildingNameFieldError(): string
  {
    if (this.buildingNameField.hasError('required'))
    {
      return "Please enter a building name";
    }
    else if (this.buildingNameField.hasError('unique'))
    {
      return this.buildingNameField.value + " is already taken";
    }
  }

  public GetPremiumFieldError(): string
  {
    if (this.premiumField.hasError('required'))
    {
      return "Please enter a premium price";
    }
    else if (this.premiumField.hasError('invalid_price'))
    {
      return "Building Premium must be greater than 0";
    }
  }

  public GetPhaseIDFieldError(): string
  {
    if (this.phaseIDField.hasError('required'))
    {
      return "Please enter a phase ID";
    }
    else if (this.phaseIDField.hasError("invalid_phase"))
    {
      return "Phase ID must be greater than 0";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isBuildingInfoValid = true;

    if (this.buildingNameField.value == "")
    {
      isBuildingInfoValid = false;
      this.buildingNameField.setErrors({required: true});
      this.buildingNameField.markAsTouched();
    }
    else
    {
      for (let building of this.data.selectedProject.buildings.values())
      {
        if (building.name == this.buildingNameField.value)
        {
          if (this.data.buildingToEdit == null || this.data.buildingToEdit.building_id != building.building_id)
          {
            this.buildingNameField.setErrors({unique: true});
            this.buildingNameField.markAsTouched();
            isBuildingInfoValid = false;
          }

          break;
        }
      }
    }

    if (this.premiumField.value < 0)
    {
      isBuildingInfoValid = false;
      this.premiumField.setErrors({invalid_price: true});
      this.premiumField.markAsTouched();
    }

    if (this.phaseIDField.value <= 0)
    {
      isBuildingInfoValid = false;
      this.phaseIDField.setErrors({invalid_phase: true});
      this.phaseIDField.markAsTouched();
    }

    if (isBuildingInfoValid)
    {
      let responseDict: Map<string, any> = new Map<string, any>();
      responseDict.set("name", this.buildingNameField.value);
      responseDict.set("premium", this.premiumField.value);
      responseDict.set("phase_id", this.phaseIDField.value);
      responseDict.set("availability", this.selectedAvailabilityStatus);

      this.dialogRef.close(responseDict);
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { Linker, Home, Elevation, EAvailabilityCode, Project, EOrientationType } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';

export interface CreateLinkerDialogData
{
  selectedLotID: number;
  selectedProject: Project;
  linkerToEdit: Linker;
}

@Component({
  selector: 'app-create-linker-dialog',
  templateUrl: './create-linker-dialog.component.html',
  styleUrls: ['./create-linker-dialog.component.css']
})
export class CreateLinkerDialogComponent implements OnInit 
{
  public selectedHome: Home = null;
  public selectedElevation: Elevation = null;
  public selectedAvailabilityStatus: EAvailabilityCode = EAvailabilityCode.Unavailable;

  constructor(private dialogRef: MatDialogRef<CreateLinkerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateLinkerDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public IsStepperLinear(): boolean
  {
    return this.data.linkerToEdit == null;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.linkerToEdit != null)
    {
      return "Edit Linker";
    }
    else
    {
      return "Create Linker";
    }
  }

  public GetSelectedHomeElevations(): Array<Elevation>
  {
    let elevations: Array<Elevation> = new Array<Elevation>();

    if (this.selectedHome != null)
    {
      for (let elevation of this.data.selectedProject.elevations.values())
      {
        if (elevation.detached_id == this.selectedHome.detached_id)
        {
          elevations.push(elevation);
        }
      }
    }

    return elevations;
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  ngOnInit() 
  {
    if (this.data.linkerToEdit != null)
    {
      this.selectedElevation = this.data.selectedProject.elevations.get(this.data.linkerToEdit.elevation_id);
      this.selectedHome = this.data.selectedProject.homes.get(this.selectedElevation.detached_id);
      this.selectedAvailabilityStatus = this.data.linkerToEdit.availability;
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let linkerInfoDict: Map<string, any> = new Map<string, any>();
    linkerInfoDict.set("lot_id", this.data.selectedLotID);
    linkerInfoDict.set("elevation_id", this.selectedElevation.elevation_id);
    linkerInfoDict.set("availability", this.selectedAvailabilityStatus);

    this.dialogRef.close(linkerInfoDict);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-accordion-list-item',
  templateUrl: './accordion-list-item.component.html',
  styleUrls: ['./accordion-list-item.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded => *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('* => expanded', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccordionListItemComponent implements OnInit 
{
  private _iconName: string = "";
  
  @Input()
  set IconName(iconName: string)
  {
    this._iconName = iconName;
  }

  get IconName()
  {
    return this._iconName;
  }

  private _isExpanded: boolean = false;

  private _label: string = "";

  @Input()
  set Label(label: string)
  {
    this._label = label;
  }

  get Label()
  {
    return this._label;
  }

  @Input()
  set IsExpanded(isExpanded: boolean)
  {
    this._isExpanded = isExpanded;
  }

  get IsExpanded()
  {
    return this._isExpanded;
  }

  constructor() { }

  ngOnInit() 
  {
  }

  public OnAccordionHeaderClicked(): void
  {
    this.IsExpanded = !this.IsExpanded;
  }
}

import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, AfterViewInit, ChangeDetectorRef, Output, AfterViewChecked, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project, EIdentificationType, Worksheet, Unit, Purchaser, Linker, Building, ModelType, Lot, Elevation } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { AuthService } from 'src/app/auth.service';
import { WorksheetPreferencesModuleComponent } from '../worksheet-preferences/worksheet-preferences-module/worksheet-preferences-module.component';
import { WorksheetAgentDetailsModuleComponent } from '../worksheet-agent-details-module/worksheet-agent-details-module.component';
import { WorksheetBrokerageDetailsModuleComponent } from '../worksheet-brokerage-details-module/worksheet-brokerage-details-module.component';
import { WorksheetPurchasersModuleComponent } from '../purchasers/worksheet-purchasers-module/worksheet-purchasers-module.component';
import { WorksheetNotesModuleComponent } from '../worksheet-notes-module/worksheet-notes-module.component';
import { WorksheetDepositStructureModuleComponent } from '../worksheet-deposit-structure-module/worksheet-deposit-structure-module.component';
import { WorksheetPurchaserFormComponent } from '../purchasers/worksheet-purchaser-form/worksheet-purchaser-form.component';
import * as moment from 'moment';

export interface CreateWorksheetDialogData
{
  selectedProject: Project;
  selectedWorksheet: Worksheet;
  selectedSuite: Unit;
  selectedHome: Linker;
  isInExportMode: boolean;
}

@Component({
  selector: 'app-create-worksheet-dialog',
  templateUrl: './create-worksheet-dialog.component.html',
  styleUrls: ['./create-worksheet-dialog.component.css'],
})
export class CreateWorksheetDialogComponent implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild(WorksheetPreferencesModuleComponent, {static: false}) preferencesModule;
  @ViewChild(WorksheetAgentDetailsModuleComponent, {static: false}) agentDetailsModule;
  @ViewChild(WorksheetBrokerageDetailsModuleComponent, {static: false}) brokerageDetailsModule;
  @ViewChild(WorksheetPurchasersModuleComponent, {static: false}) purchasersModule;
  @ViewChild(WorksheetNotesModuleComponent, {static: false}) notesModule;
  @ViewChild(WorksheetDepositStructureModuleComponent, {static: false}) depositStructureModule;

  @Output() OnDialogLoadComplete: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dialogRef: MatDialogRef<CreateWorksheetDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateWorksheetDialogData, public appUtilityService: AppUtilityService, public authService: AuthService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    if (this.data.selectedProject != null && this.data.selectedWorksheet != null)
    {
      this.LoadSelectedWorksheetData();
    }
    else if (this.data.selectedProject != null && (this.data.selectedProject.has_condos || this.data.selectedProject.has_townhomes) && this.data.selectedSuite != null)
    {
      this.PrefillSelectedSuite();

      if (this.data.selectedProject.uses_worksheets_deposit_structure_field)
      {
        this.depositStructureModule.InitWithDefaultDepositStructure();
      }

      this.changeDetectorRef.detectChanges();
    }
    else if (this.data.selectedProject != null && this.data.selectedProject.has_detached && this.data.selectedHome != null)
    {
      this.PrefillSelectedHome();

      if (this.data.selectedProject.uses_worksheets_deposit_structure_field)
      {
        this.depositStructureModule.InitWithDefaultDepositStructure();
      }

      this.changeDetectorRef.detectChanges();
    }
    else
    {
      if (this.data.selectedProject.uses_worksheets_deposit_structure_field)
      {
        this.depositStructureModule.InitWithDefaultDepositStructure();
      }

      this.changeDetectorRef.detectChanges();
    }

    this.OnDialogLoadComplete.emit(null);
  }

  private PrefillSelectedSuite(): void
  {
    this.preferencesModule.currentChosenSuites.push(this.data.selectedSuite);
    this.preferencesModule.changeDetectorRef.detectChanges();
  }

  private PrefillSelectedHome(): void
  {
    this.preferencesModule.currentChosenHomes.push(this.data.selectedHome);
    this.preferencesModule.changeDetectorRef.detectChanges();
  }

  private LoadSelectedWorksheetData()
  {
    // Preferences
    if (this.data.selectedProject.has_condos || this.data.selectedProject.has_townhomes)
    {
      if (this.data.selectedWorksheet.str_unit_ids !== null && this.data.selectedWorksheet.str_unit_ids !== "")
      {
        let arrSuiteIDs: Array<string> = this.data.selectedWorksheet.str_unit_ids.split(",");
        for (let suiteID of arrSuiteIDs)
        {
          let suiteIDTrim = Number(suiteID.trim());

          if (this.data.selectedProject.units.has(suiteIDTrim))
          {
            let currentSuite: Unit = this.data.selectedProject.units.get(suiteIDTrim);
            this.preferencesModule.currentChosenSuites.push(currentSuite);
          }
        }
      }

      if (this.data.selectedProject.uses_worksheets_parking_field)
      {
        this.preferencesModule.parkingField.setValue(this.data.selectedWorksheet.parking_preferred);
      }

      if (this.data.selectedProject.uses_worksheets_locker_field)
      {
        this.preferencesModule.lockerField.setValue(this.data.selectedWorksheet.locker_preferred);
      }
    }

    if (this.data.selectedProject.has_detached)
    {
      if (this.data.selectedWorksheet.str_linker_ids !== null && this.data.selectedWorksheet.str_linker_ids !== "")
      {
        let arrLinkerIDs: Array<string> = this.data.selectedWorksheet.str_linker_ids.split(", ");
        for (let strLinkerID of arrLinkerIDs)
        {
          let linkerID = Number(strLinkerID);

          if (this.data.selectedProject.linkers.has(linkerID))
          {
            let currentLinker: Linker = this.data.selectedProject.linkers.get(linkerID);
            this.preferencesModule.currentChosenHomes.push(currentLinker);
          }
        }
      }
    }
    
    if (this.data.selectedProject.uses_worksheets_international_status_field)
    {
      this.preferencesModule.InternationalStatus = this.data.selectedWorksheet.purchaser_international_status;

      if (this.preferencesModule.internationalSelect)
        this.preferencesModule.internationalSelect.value = this.data.selectedWorksheet.purchaser_international_status.toString().toLowerCase();
    }

    // Agent Details
    this.agentDetailsModule.fullNameField.setValue(this.data.selectedWorksheet.agent_full_name);
    this.agentDetailsModule.emailField.setValue(this.data.selectedWorksheet.agent_email);
    this.agentDetailsModule.phoneField.setValue(this.data.selectedWorksheet.agent_phone);

    // Brokerage Details
    this.brokerageDetailsModule.nameField.setValue(this.data.selectedWorksheet.brokerage_name);
    this.brokerageDetailsModule.addressField.setValue(this.data.selectedWorksheet.brokerage_address);
    this.brokerageDetailsModule.cityField.setValue(this.data.selectedWorksheet.brokerage_city);
    this.brokerageDetailsModule.provinceField.setValue(this.data.selectedWorksheet.brokerage_province);
    this.brokerageDetailsModule.postalCodeField.setValue(this.data.selectedWorksheet.brokerage_postal_code);

    // Purchaser Information
    if (this.data.selectedWorksheet.str_purchaser_ids !== null && this.data.selectedWorksheet.str_purchaser_ids !== "")
    {
      let purchaserForms: Array<WorksheetPurchaserFormComponent> = this.purchasersModule.purchaserForms.toArray();
      let arrPurchaserIDs = this.data.selectedWorksheet.str_purchaser_ids.split(",");
      
      let purchaserID1 = arrPurchaserIDs[0].trim();
      if (this.data.selectedProject.purchasers.has(purchaserID1))
      {
        let purchaser1: Purchaser = this.data.selectedProject.purchasers.get(purchaserID1);

        purchaserForms[0].fullNameField.setValue(purchaser1.full_name);
        purchaserForms[0].birthdayField.setValue(purchaser1.date_of_birth);
        
        if (this.data.selectedProject.uses_worksheets_id_type_field)
        {
          if (purchaserForms[0].idTypeSelect)
            purchaserForms[0].idTypeSelect.value = purchaser1.id_type;
          
          purchaserForms[0].SelectedIdType = purchaser1.id_type;
          
          if (purchaser1.id_type == EIdentificationType.DriverLicense)
          {
            purchaserForms[0].driverLicenseField.setValue(purchaser1.id_number);
          }
          else if (purchaser1.id_type == EIdentificationType.Passport)
          {
            purchaserForms[0].passportField.setValue(purchaser1.id_number);
          } 
        }

        if (this.data.selectedProject.uses_worksheets_upload_photo_id_field)
        {
          if (purchaser1.id_photo_name != "" && purchaser1.id_photo_data != "")
          {
            purchaserForms[0].photoIDField.SelectedImageURL = purchaser1.id_photo_data;
            purchaserForms[0].photoIDField.SelectedImageBinary = purchaser1.id_photo_data;
            purchaserForms[0].photoIDField.SelectedImageName = purchaser1.id_photo_name;
            purchaserForms[0].photoIDField.HasSelectedImage = true;
          }
        }

        if (this.data.selectedProject.uses_worksheets_upload_cheque_photo_field)
        {
          if (purchaser1.cheque_photo_name != "" && purchaser1.cheque_photo_data != "")
          {
            purchaserForms[0].chequePhotoField.SelectedImageURL = purchaser1.cheque_photo_data;
            purchaserForms[0].chequePhotoField.SelectedImageBinary = purchaser1.cheque_photo_data;
            purchaserForms[0].chequePhotoField.SelectedImageName = purchaser1.cheque_photo_name;
            purchaserForms[0].chequePhotoField.HasSelectedImage = true;
          }
        }

        purchaserForms[0].addressField.setValue(purchaser1.address);
        purchaserForms[0].cityField.setValue(purchaser1.city);
        purchaserForms[0].postalCodeField.setValue(purchaser1.postal_code);
        purchaserForms[0].provinceField.setValue(purchaser1.province);
        purchaserForms[0].countryField.setValue(purchaser1.country);
        purchaserForms[0].phoneField.setValue(purchaser1.phone);
        purchaserForms[0].emailField.setValue(purchaser1.email);
        purchaserForms[0].sinField.setValue(purchaser1.sin_number);
      }

      if (arrPurchaserIDs.length > 1)
      {
        let purchaserID2 = arrPurchaserIDs[1].trim();

        if (this.data.selectedProject.purchasers.has(purchaserID1))
        {
          purchaserForms[1].IsSecondPurchaserActive = true;

          let purchaser2: Purchaser = this.data.selectedProject.purchasers.get(purchaserID2);

          purchaserForms[1].fullNameField.setValue(purchaser2.full_name);
          purchaserForms[1].birthdayField.setValue(purchaser2.date_of_birth);
          
          if (this.data.selectedProject.uses_worksheets_id_type_field)
          {
            if (purchaserForms[1].idTypeSelect)
              purchaserForms[1].idTypeSelect.value = purchaser2.id_type;
            
            purchaserForms[1].SelectedIdType = purchaser2.id_type;
            
            if (purchaser2.id_type == EIdentificationType.DriverLicense)
            {
              purchaserForms[1].driverLicenseField.setValue(purchaser2.id_number);
            }
            else if (purchaser2.id_type == EIdentificationType.Passport)
            {
              purchaserForms[1].passportField.setValue(purchaser2.id_number);
            } 
          }

          if (this.data.selectedProject.uses_worksheets_upload_photo_id_field)
          {
            if (purchaser2.id_photo_name != "" && purchaser2.id_photo_data != "")
            {
              purchaserForms[1].photoIDField.SelectedImageURL = purchaser2.id_photo_data;
              purchaserForms[1].photoIDField.SelectedImageBinary = purchaser2.id_photo_data;
              purchaserForms[1].photoIDField.SelectedImageName = purchaser2.id_photo_name;
              purchaserForms[1].photoIDField.HasSelectedImage = true;
            }
          }

          purchaserForms[1].addressField.setValue(purchaser2.address);
          purchaserForms[1].cityField.setValue(purchaser2.city);
          purchaserForms[1].postalCodeField.setValue(purchaser2.postal_code);
          purchaserForms[1].provinceField.setValue(purchaser2.province);
          purchaserForms[1].countryField.setValue(purchaser2.country);
          purchaserForms[1].phoneField.setValue(purchaser2.phone);
          purchaserForms[1].emailField.setValue(purchaser2.email);
          purchaserForms[1].sinField.setValue(purchaser2.sin_number);
        }
      }
    }

    // Notes
    if (this.data.selectedProject.uses_worksheets_notes_section_field)
    {
      this.notesModule.notesField.setValue(this.data.selectedWorksheet.notes);
    }

    // Deposit Structure
    if (this.data.selectedProject.uses_worksheets_deposit_structure_field)
    {
      this.depositStructureModule.securityDepositAmount = this.data.selectedWorksheet.security_deposit_amount;
      this.depositStructureModule.depositPercentage = this.data.selectedWorksheet.deposit_percentage;
      this.depositStructureModule.deposits = this.data.selectedProject.worksheetDeposits.get(this.data.selectedWorksheet.worksheet_id);
    }

    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked()
  {
  }

  GetWorksheetTitle(): string
  {
    if (this.data.selectedProject != null)
    {
      return this.data.selectedProject.project_name + " " + "Worksheet";
    }
    else
    {
      return "Worksheet";
    }
  }

  ShouldDisplayLastModifiedDate(): boolean
  {
    if (this.data.selectedWorksheet != null && this.data.selectedWorksheet.date_created != null && this.data.selectedWorksheet.date_last_edited != null)
    {
      return this.data.selectedWorksheet.date_created.isBefore(this.data.selectedWorksheet.date_last_edited);
    }
    else
    {
      return false;
    }
  }

  ShouldDisplayNotesModule(): boolean
  {
    if (this.data.selectedProject != null)
    {
      return this.data.selectedProject.uses_worksheets_notes_section_field;
    }
    else
    {
      return false;
    }
  }

  ShouldDisplayDepositStructureModule(): boolean
  {
    if (this.data.selectedProject != null)
    {
      return this.data.selectedProject.uses_worksheets_deposit_structure_field;
    }
    else
    {
      return false;
    }
  }

  GetTotalDepositAmount(): number
  {
    let total: number = 0;

    if (this.data.selectedProject.has_condos || this.data.selectedProject.has_townhomes)
    {
      if (this.preferencesModule)
      {
        for (let suite of this.preferencesModule.currentChosenSuites)
        {
          if (suite != null)
          {
            total += suite.premium;

            let modeltype: ModelType = this.data.selectedProject.model_types.get(suite.model_type_id);
            total += modeltype.price;

            let building: Building = this.data.selectedProject.buildings.get(suite.building_id);
            total += building.premium;

            if (this.data.selectedProject.floors.size > 0)
            {
              for (let floor of this.data.selectedProject.floors.values())
              {
                if (floor.building_id == building.building_id && floor.floor_number == suite.floor_number)
                {
                  total += floor.premium;
                  break;
                }
              }
            }
          }
        }
      }
    }

    if (this.data.selectedProject.has_detached)
    {
      if (this.preferencesModule)
      {
        for (let home of this.preferencesModule.currentChosenHomes)
        {
          if (home != null)
          {
            let lot: Lot = this.data.selectedProject.lots.get(home.lot_id);
            total += lot.lot_price_premium;
            total += lot.grading_price;

            let elevation: Elevation = this.data.selectedProject.elevations.get(home.elevation_id);
            total += elevation.price;
          }
        }
      }
    }

    return total;
  }

  OnSaveButtonClicked()
  {
    let worksheetInfoDict: Map<string, any> = new Map<string, any>();
    worksheetInfoDict.set("username", this.authService.activeUser.username);

    // Preferences
    if (this.data.selectedProject.has_condos || this.data.selectedProject.has_townhomes)
    {
      let strChosenModelIDs: string = "";
      let strChosenUnitIDs: string = "";

      if (this.preferencesModule.currentChosenSuites.length > 0)
      {
        for (let suite of this.preferencesModule.currentChosenSuites)
        {
          strChosenUnitIDs += suite.unit_id + ", ";
          strChosenModelIDs += suite.model_type_id + ", ";
        }

        strChosenUnitIDs = strChosenUnitIDs.substring(0, strChosenUnitIDs.length - 2);
        strChosenModelIDs = strChosenModelIDs.substring(0, strChosenModelIDs.length - 2);
      }

      worksheetInfoDict.set("model_ids", strChosenModelIDs);
      worksheetInfoDict.set("unit_ids", strChosenUnitIDs);

      if (this.data.selectedProject.uses_worksheets_parking_field)
      {
        worksheetInfoDict.set("parking_preferred", this.preferencesModule.parkingField.value);
      }

      if (this.data.selectedProject.uses_worksheets_locker_field)
      {
        worksheetInfoDict.set("locker_preferred", this.preferencesModule.lockerField.value);
      }

      if (this.data.selectedProject.uses_worksheets_international_status_field)
      {
        worksheetInfoDict.set("purchaser_international_status", this.preferencesModule.InternationalStatus);
      }
    }

    if (this.data.selectedProject.has_detached)
    {
      let strChosenLinkerIDs: string = "";

      if (this.preferencesModule.currentChosenHomes.length > 0)
      {
        for (let linker of this.preferencesModule.currentChosenHomes)
        {
          strChosenLinkerIDs += linker.linker_id + ", ";
        }

        strChosenLinkerIDs = strChosenLinkerIDs.substring(0, strChosenLinkerIDs.length - 2);
      }

      worksheetInfoDict.set("linker_ids", strChosenLinkerIDs);
    }

    // Agent Details
    worksheetInfoDict.set("agent_full_name", this.agentDetailsModule.fullNameField.value);
    worksheetInfoDict.set("agent_email", this.agentDetailsModule.emailField.value);
    worksheetInfoDict.set("agent_phone", this.agentDetailsModule.phoneField.value);

    // Brokerage Details
    worksheetInfoDict.set("brokerage_name", this.brokerageDetailsModule.nameField.value);
    worksheetInfoDict.set("brokerage_address", this.brokerageDetailsModule.addressField.value);
    worksheetInfoDict.set("brokerage_city", this.brokerageDetailsModule.cityField.value);
    worksheetInfoDict.set("brokerage_province", this.brokerageDetailsModule.provinceField.value);
    worksheetInfoDict.set("brokerage_postal_code", this.brokerageDetailsModule.postalCodeField.value);

    // Purchaser Information
    let purchaserForms: Array<WorksheetPurchaserFormComponent> = this.purchasersModule.purchaserForms.toArray();
    let purchasers: Array<Map<string, any>> = new Array<Map<string, any>>();

    let purchaser1InfoDict: Map<string, any> = new Map<string, any>();
    purchaser1InfoDict.set("full_name", purchaserForms[0].fullNameField.value);

    if (purchaserForms[0].birthdayField.value != "")
    {
      purchaser1InfoDict.set("date_of_birth", purchaserForms[0].birthdayField.value.format('D/M/YYYY'));
    }

    if (this.data.selectedProject.uses_worksheets_id_type_field) 
    { 
      purchaser1InfoDict.set("id_type", purchaserForms[0].SelectedIdType);
      
      if (purchaserForms[0].SelectedIdType == EIdentificationType.DriverLicense)
      {
        purchaser1InfoDict.set("id_number", purchaserForms[0].driverLicenseField.value);
      }
      else if (purchaserForms[0].SelectedIdType == EIdentificationType.Passport)
      {
        purchaser1InfoDict.set("id_number", purchaserForms[0].passportField.value);
      }
    }

    if (this.data.selectedProject.uses_worksheets_upload_photo_id_field && purchaserForms[0].photoIDField.HasSelectedImage)
    {
      purchaser1InfoDict.set("id_photo_data", purchaserForms[0].photoIDField.SelectedImageBinary);
      purchaser1InfoDict.set("id_photo_name", purchaserForms[0].photoIDField.SelectedImageName);
    }

    if (this.data.selectedProject.uses_worksheets_upload_cheque_photo_field && purchaserForms[0].chequePhotoField.HasSelectedImage)
    {
      purchaser1InfoDict.set("cheque_photo_data", purchaserForms[0].chequePhotoField.SelectedImageBinary);
      purchaser1InfoDict.set("cheque_photo_name", purchaserForms[0].chequePhotoField.SelectedImageName);
    }

    purchaser1InfoDict.set("address", purchaserForms[0].addressField.value);
    purchaser1InfoDict.set("city", purchaserForms[0].cityField.value);
    purchaser1InfoDict.set("postal_code", purchaserForms[0].postalCodeField.value);
    purchaser1InfoDict.set("province", purchaserForms[0].provinceField.value);
    purchaser1InfoDict.set("country", purchaserForms[0].countryField.value);
    purchaser1InfoDict.set("phone", purchaserForms[0].phoneField.value);
    purchaser1InfoDict.set("email", purchaserForms[0].emailField.value);
    purchaser1InfoDict.set("sin_number", purchaserForms[0].sinField.value);

    purchasers.push(purchaser1InfoDict);

    if (purchaserForms[1].IsSecondPurchaserActive)
    {
      let purchaser2InfoDict: Map<string, any> = new Map<string, any>();
      purchaser2InfoDict.set("full_name", purchaserForms[1].fullNameField.value);

      if (purchaserForms[1].birthdayField.value != "")
      {
        purchaser2InfoDict.set("date_of_birth", purchaserForms[1].birthdayField.value.format('D/M/YYYY'));
      }

      if (this.data.selectedProject.uses_worksheets_id_type_field) 
      { 
        purchaser2InfoDict.set("id_type", purchaserForms[1].SelectedIdType);
        
        if (purchaserForms[1].SelectedIdType == EIdentificationType.DriverLicense)
        {
          purchaser2InfoDict.set("id_number", purchaserForms[1].driverLicenseField.value);
        }
        else if (purchaserForms[1].SelectedIdType == EIdentificationType.Passport)
        { 
          purchaser2InfoDict.set("id_number", purchaserForms[1].passportField.value);
        }   
      }

      if (this.data.selectedProject.uses_worksheets_upload_photo_id_field && purchaserForms[1].photoIDField.HasSelectedImage)
      {
        purchaser2InfoDict.set("id_photo_data", purchaserForms[1].photoIDField.SelectedImageBinary);
        purchaser2InfoDict.set("id_photo_name", purchaserForms[1].photoIDField.SelectedImageName);
      }

      purchaser2InfoDict.set("address", purchaserForms[1].addressField.value);
      purchaser2InfoDict.set("city", purchaserForms[1].cityField.value);
      purchaser2InfoDict.set("postal_code", purchaserForms[1].postalCodeField.value);
      purchaser2InfoDict.set("province", purchaserForms[1].provinceField.value);
      purchaser2InfoDict.set("country", purchaserForms[1].countryField.value);
      purchaser2InfoDict.set("phone", purchaserForms[1].phoneField.value);
      purchaser2InfoDict.set("email", purchaserForms[1].emailField.value);
      purchaser2InfoDict.set("sin_number", purchaserForms[1].sinField.value);

      purchasers.push(purchaser2InfoDict);
    }

    worksheetInfoDict.set("purchasers", purchasers);

    // Notes
    if (this.data.selectedProject.uses_worksheets_notes_section_field)
    {
      worksheetInfoDict.set("notes", this.notesModule.notesField.value);
    }

    // Deposit Structure
    if (this.data.selectedProject.uses_worksheets_deposit_structure_field)
    {
      worksheetInfoDict.set("deposit_percentage", this.depositStructureModule.depositPercentage);
      worksheetInfoDict.set("security_deposit_amount", this.depositStructureModule.securityDepositAmount);
      worksheetInfoDict.set("deposits", this.depositStructureModule.deposits);
    }

    this.dialogRef.close(worksheetInfoDict);
  }
}

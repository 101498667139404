import { Component, OnInit, Inject } from '@angular/core';
import { EAvailabilityCode, Phase, Project } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

export interface CreatePhaseDialogData
{
  selectedProject: Project;
  phaseToEdit: Phase;
}
@Component({
  selector: 'app-create-phase-dialog',
  templateUrl: './create-phase-dialog.component.html',
  styleUrls: ['./create-phase-dialog.component.css']
})
export class CreatePhaseDialogComponent implements OnInit {
  public selectedAvailabilityStatus: EAvailabilityCode = EAvailabilityCode.Unavailable;
  public phaseNameField: FormControl = new FormControl('');
  constructor(private dialogRef: MatDialogRef<CreatePhaseDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreatePhaseDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public IsAvailabilityStatusDisabled(status: string): boolean
  {
    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      switch (status)
      {
        case EAvailabilityCode[EAvailabilityCode.Sold]:
          return !projectRole.can_finalize_sales;
        default:
          return false;
      }
    }

    return false;
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public CanEditLotAvailabilityStatus(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.phaseToEdit != null)
    {
      return "Edit Lot";
    }
    else
    {
      return "Create Lot";
    }
  }

  public GetPhaseNameFieldError(): string
  {
    if (this.phaseNameField.hasError('required'))
    {
      return "Please enter a phase name";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isPhaseInfoValid: boolean = true;

    if (this.phaseNameField.value == '')
    {
      this.phaseNameField.setErrors({required: true});
      this.phaseNameField.markAsTouched();
      isPhaseInfoValid = false;
    }

    if (isPhaseInfoValid)
    {
      let phaseInfoDict: Map<string, any> = new Map<string, any>();
      phaseInfoDict.set("phase_name", this.phaseNameField.value);
      phaseInfoDict.set("availability", this.selectedAvailabilityStatus);
      this.dialogRef.close(phaseInfoDict);
    }
  }

  ngOnInit() 
  {
      this.phaseNameField.setValidators([Validators.required]);
    if (this.data.phaseToEdit != null)
    {
      this.phaseNameField.setValue(this.data.phaseToEdit.phase_name);
      this.selectedAvailabilityStatus = this.data.phaseToEdit.phase_status;
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProjectAssignment, WebCMSAPIResponse, Project } from '../DataModels';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class ProjectAssignmentsService 
{
  constructor(private http: HttpClient, private appConstants: AppConstantsService) { }

  public GetAllProjectAssignments(): Observable<Set<ProjectAssignment>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectAssignmentsResponse(response);
    })); 
  }

  public GetProjectAssignmentsByCompanyID(companyID: string): Observable<Set<ProjectAssignment>>
  {
    let params = new HttpParams().set("companyID", companyID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectAssignmentsResponse(response);
    })); 
  }

  public GetProjectAssignmentsByUserID(userID: string): Observable<Set<ProjectAssignment>>
  {
    let params = new HttpParams().set("userID", userID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectAssignmentsResponse(response);
    }));
  }

  public AssignUserToProject(userID: string, projectID: string, projectRoleID: string, companyID: string): Observable<ProjectAssignment>
  {
    let params = {"userID": userID, "projectID": projectID, "projectRoleID": projectRoleID, "companyID": companyID};

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let assignmentInfoFromPacket = JSON.parse(response.data);
        let assignment: ProjectAssignment = new ProjectAssignment(assignmentInfoFromPacket);
        return assignment;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public ChangeProjectRoleForAssignment(userID: string, projectID: string, projectRoleID: string, companyID: string): Observable<ProjectAssignment>
  {
    let params = {"userID": userID, "projectID": projectID, "projectRoleID": projectRoleID, "companyID": companyID};

    return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response.status == "success")
      {
        let assignmentInfoFromPacket = JSON.parse(response.data);
        let assignment: ProjectAssignment = new ProjectAssignment(assignmentInfoFromPacket);
        return assignment;
      }
      else
      {
        console.log(response);
        return null;
      }
    }));
  }

  public DeleteProjectAssignment(userID: string, projectID: string): Observable<Map<string, any>>
  {
    let params = {"userID": userID, "projectID": projectID};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_assignments`, {"params" :params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return null; }
      
      if (response.status == "success")
      {
        let responseDict: Map<string, any> = new Map<string, any>();
        responseDict.set("userID", userID);
        responseDict.set("projectID", projectID);

        return responseDict;
      }
      else
      {
        return null;
      }
    }));
  }

  private ParseGetProjectAssignmentsResponse(response: WebCMSAPIResponse): Set<ProjectAssignment>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let assignmentsSet: Set<ProjectAssignment> = new Set<ProjectAssignment>();
      let responseDataJSON = JSON.parse(response.data);

      for (let assignmentJSON of responseDataJSON)
      {
        let assignmentInfoFromPacket = JSON.parse(assignmentJSON);
        let assignment: ProjectAssignment = new ProjectAssignment(assignmentInfoFromPacket);
        assignmentsSet.add(assignment);
      }

      return assignmentsSet;
    }
    else
    {
      console.log(response);
      return null;
    }
  }
}

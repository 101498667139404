export abstract class CMSComponentBase
{
    public ShouldDisplayNavBarAddUserButton(): boolean { return false; }
    public OnNavBarAddUserButtonPressed(): void { }

    public ShouldDisplayNavBarProjectAssignmentsButton(): boolean { return false; }
    public OnNavBarProjectAssignmentsButtonPressed() { }

    public ShouldDisplayNavBarEditProjectSettingsButton(): boolean { return false; }
    public OnNavBarEditProjectSettingsButtonPressed() { }

    abstract ShouldDisplayAddButton(): boolean;
    abstract ShouldDisplayEditButton(): boolean;
    abstract ShouldDisplayDeleteButton(): boolean;

    abstract OnAddButtonPressed();
    abstract OnEditButtonPressed(row);
    abstract OnDeleteButtonPressed();
}

export abstract class CMSTableViewBase
{
    abstract RenderTableRows(): void;

    public OnTabEntered(): void {};

    public ShouldDisplayNavBarAddUserButton(): boolean { return false; }
    public OnNavBarAddUserButtonPressed(): void { }

    public ShouldDisplayNavBarProjectAssignmentsButton(): boolean { return false; }
    public OnNavBarProjectAssignmentsButtonPressed() { }

    public ShouldDisplayNavBarEditProjectSettingsButton(): boolean { return false; }
    public OnNavBarEditProjectSettingsButtonPressed() { }

    abstract ShouldDisplayAddButton(): boolean;
    abstract ShouldDisplayEditButton(): boolean;
    abstract ShouldDisplayDeleteButton(): boolean;

    abstract OnAddButtonPressed();
    abstract OnEditButtonPressed(row);
    abstract OnDeleteButtonPressed();

    abstract HasSelectedRows(): boolean;
    abstract GetSelectedRows(): Set<number | string>;
    abstract ClearSelectedRows(): void;
}
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, AfterViewChecked, QueryList, ViewChildren } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CMSComponentBase } from 'src/app/interfaces/cms-component-base';
import { MatSidenav } from '@angular/material/sidenav';
import { Project } from 'src/DataModels';
import { ProjectsService } from 'src/app/projects.service';
import { MatRipple } from '@angular/material/core';

export enum ESidenavMenuOption
{
  None = -1,
  Home = 0,
  Users = 1,
  UserRoles = 2,
  ProjectRoles = 3,
  Settings = 4,
}

@Component({
  selector: 'app-nav-shell',
  templateUrl: './app-nav-shell.component.html',
  styleUrls: ['./app-nav-shell.component.css'],
})
export class AppNavShellComponent implements AfterViewInit
{
  @ViewChild(MatSidenav, {static: false}) sidenav: MatSidenav;
  @ViewChildren(MatRipple) sidenavElementRipples: QueryList<MatRipple>;

  @ViewChild('homeRipple', { static: false, read: MatRipple }) homeRipple: MatRipple;
  @ViewChild('usersRipple', { static: false, read: MatRipple }) usersRipple: MatRipple;
  @ViewChild('userRolesRipple', { static: false, read: MatRipple }) userRolesRipple: MatRipple;
  @ViewChild('projectRolesRipple', { static: false, read: MatRipple }) projectRolesRipple: MatRipple;
  @ViewChild('settingsRipple', { static: false, read: MatRipple }) settingsRipple: MatRipple;

  private selectedNavCategory: ESidenavMenuOption;

  private categoryRipplesMap: Map<ESidenavMenuOption, MatRipple> = new Map<ESidenavMenuOption, MatRipple>();

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private changeDetector: ChangeDetectorRef, private media: MediaMatcher, public authService: AuthService, public appUtilityService: AppUtilityService, private projectsService: ProjectsService, private location: Location, private router: Router) 
  {
    this.selectedNavCategory = ESidenavMenuOption.None;

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetector.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.appUtilityService.SetScreenWidthListener(this.mobileQuery);
  }

  ngAfterViewInit(): void 
  {
    if (this.homeRipple != null) { this.categoryRipplesMap.set(ESidenavMenuOption.Home, this.homeRipple); }
    if (this.usersRipple != null) { this.categoryRipplesMap.set(ESidenavMenuOption.Users, this.usersRipple); }
    if (this.userRolesRipple != null) { this.categoryRipplesMap.set(ESidenavMenuOption.UserRoles, this.userRolesRipple); }
    if (this.projectRolesRipple != null) { this.categoryRipplesMap.set(ESidenavMenuOption.ProjectRoles, this.projectRolesRipple); }
    if (this.settingsRipple != null) { this.categoryRipplesMap.set(ESidenavMenuOption.Settings, this.settingsRipple); }

    let cachedSelectedCategory: string = sessionStorage.getItem("selectedNavCategory");
    if (cachedSelectedCategory != null)
    {
      this.OnSidenavOptionChanged(parseInt(cachedSelectedCategory));
    }
    else
    {
      this.OnSidenavOptionChanged(ESidenavMenuOption.Home);
    }
  }

  private OnSidenavOptionChanged(selectedOption: ESidenavMenuOption)
  {
    if (this.selectedNavCategory != ESidenavMenuOption.None && this.categoryRipplesMap.has(this.selectedNavCategory))
      this.categoryRipplesMap.get(this.selectedNavCategory).fadeOutAll();

    this.selectedNavCategory = selectedOption;
    sessionStorage.setItem("selectedNavCategory", this.selectedNavCategory.toString());

    if (this.selectedNavCategory != ESidenavMenuOption.None && this.categoryRipplesMap.has(this.selectedNavCategory))
      this.categoryRipplesMap.get(this.selectedNavCategory).launch({centered: true, persistent: true});
  }

  public CloseSidenav(): void
  {
    if (this.appUtilityService.IsMobileScreenWidth())
    {
      this.sidenav.close();
    }
  }

  public OnRouterActivate($event)
  {
    if (this.sidenav)
    {
      this.sidenav.opened = !this.appUtilityService.IsMobileScreenWidth();
    }

    this.SetupAppNavbarCallbacksForView($event);
  }

  public SetupAppNavbarCallbacksForView($event)
  {
    if ($event instanceof CMSComponentBase)
    {
      let nextComponent = $event as CMSComponentBase;

      this.appUtilityService.shouldDisplayAddUserButtonCallback = nextComponent.ShouldDisplayNavBarAddUserButton.bind(nextComponent);
      this.appUtilityService.addUserButtonBehaviourCallback = nextComponent.OnNavBarAddUserButtonPressed.bind(nextComponent);

      this.appUtilityService.shouldDisplayProjectAssignmentsButtonCallback = nextComponent.ShouldDisplayNavBarProjectAssignmentsButton.bind(nextComponent);
      this.appUtilityService.projectAssignmentsButtonBehaviourCallback = nextComponent.OnNavBarProjectAssignmentsButtonPressed.bind(nextComponent);

      this.appUtilityService.shouldDisplayEditProjectSettingsButtonCallback = nextComponent.ShouldDisplayNavBarEditProjectSettingsButton.bind(nextComponent);
      this.appUtilityService.editProjectSettingsButtonBehaviourCallback = nextComponent.OnNavBarEditProjectSettingsButtonPressed.bind(nextComponent);
    }
    else
    {
      this.appUtilityService.shouldDisplayAddUserButtonCallback = null;
      this.appUtilityService.addUserButtonBehaviourCallback = null;

      this.appUtilityService.shouldDisplayProjectAssignmentsButtonCallback = null;
      this.appUtilityService.projectAssignmentsButtonBehaviourCallback = null;

      this.appUtilityService.shouldDisplayEditProjectSettingsButtonCallback = null;
      this.appUtilityService.editProjectSettingsButtonBehaviourCallback = null
    }
  }

  ngOnDestroy(): void
  {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  OnLogoutButtonPressed()
  {
    this.authService.Logout();
  }

  OnUsersButtonPressed()
  {
    this.OnSidenavOptionChanged(ESidenavMenuOption.Users);

    this.router.navigateByUrl('/users');
  }

  OnUserRolesButtonPressed()
  {
    this.OnSidenavOptionChanged(ESidenavMenuOption.UserRoles);

    this.router.navigateByUrl('admin/userroles');
  }

  OnProjectRolesButtonPressed()
  {
    this.OnSidenavOptionChanged(ESidenavMenuOption.ProjectRoles);

    this.router.navigateByUrl('/projectroles');
  }

  OnHomeButtonPressed()
  {
    this.OnSidenavOptionChanged(ESidenavMenuOption.Home);

    if (this.authService.IsActiveUserAdmin())
    {
      this.router.navigateByUrl('admin/companies');
    }
    else
    {
      this.router.navigateByUrl('/projects');
    }
  }

  OnSettingsButtonPressed(): void
  {
    this.OnSidenavOptionChanged(ESidenavMenuOption.Settings);
    this.router.navigateByUrl('admin/settings')
  }

  ShouldDisplayProjectAssignmentsButton(): boolean
  {
    if (this.appUtilityService.shouldDisplayProjectAssignmentsButtonCallback != null)
    {
      return this.appUtilityService.shouldDisplayProjectAssignmentsButtonCallback();
    }

    return false;
  }

  OnProjectAssignmentsButtonPressed(): void
  {
    if (this.appUtilityService.projectAssignmentsButtonBehaviourCallback != null)
    {
      this.appUtilityService.projectAssignmentsButtonBehaviourCallback();
    }
  }

  ShouldDisplayEditProjectSettingsButton(): boolean
  {
    if (this.appUtilityService.shouldDisplayEditProjectSettingsButtonCallback != null)
    {
      return this.appUtilityService.shouldDisplayEditProjectSettingsButtonCallback();
    }

    return false;
  }

  OnEditProjectSettingsButtonPressed(): void
  {
    if (this.appUtilityService.editProjectSettingsButtonBehaviourCallback != null)
    {
      this.appUtilityService.editProjectSettingsButtonBehaviourCallback();
    }
  }
}

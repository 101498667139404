import { Component, OnInit, Inject, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, enableProdMode } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Project, Worksheet, EContractFieldType, Contract, Unit, Home, Purchaser, Deposit, Linker, ModelType, Floor, Elevation, Lot, EIdentificationType, ContractTemplate } from 'src/DataModels';

import html2canvas from "html2canvas";

import * as pdfjs from 'pdfjs-dist/build/pdf';
import VerbosityLevel from 'pdfjs-dist';
import * as PDFAssembler from 'pdfassembler';
import { MatMenuTrigger } from '@angular/material/menu';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';
import { ProjectsService } from 'src/app/projects.service';
import { MatButtonToggleGroup, MatButtonToggleChange } from '@angular/material/button-toggle';
import { FormControl } from '@angular/forms';

export interface CreateContractDialogData
{
  selectedProject: Project,
  selectedContract: Contract,
  showContractFilePicker: boolean,
  selectedContractTemplate: ContractTemplate,
}

export enum EEditMode
{
  None = "None",
  NewFormField = "NewFormField",
  MoveAndResize = "MoveAndResize",
  DeleteField = "DeleteField",
  AppendWorksheetData = "AppendWorksheetData",
  EditFieldText = "EditFieldText",
  ClearFieldContents = "ClearFieldContents",
}

export enum EMouseDragMode
{
  None,
  DrawField,
  MoveField,
  ResizeTopLeft,
  ResizeBottomLeft,
  ResizeTopRight,
  ResizeBottomRight,
  ResizeTopEdge,
  ResizeBottomEdge,
  ResizeLeftEdge,
  ResizeRightEdge,
}

export class ContractFormField
{
  public type: EContractFieldType;
  public pageNumber: number;

  public hostAnnot: object = null;
  public canvasInput = null;
  public isTextArea: boolean = false;
  public parentComponent: CreateContractDialogComponent = null;

  // Coordinates of the form field's bounding box as drawn on screen
  public canvasX1: number;
  public canvasY1: number;
  public canvasWidth: number;
  public canvasHeight: number;

  // Backup of the form field's bounding box coordinates, made before each
  // resize or move operation 
  public canvasX1Prev: number;
  public canvasY1Prev: number;
  public canvasWidthPrev: number;
  public canvasHeightPrev: number;

  // Coordinates of the form field's bounding box in PDF space
  public pdfX1: number;
  public pdfY1: number;
  public pdfX2: number;
  public pdfY2: number;

  // The value to print inside the bounding box
  public strValue: string = "";

  public BindToPDF(objPDFStructure)
  {
    delete this.hostAnnot["/AP"];

    if (this.canvasHeight > 60)
    {
      // Set the 13th bit of the Ff (field properties) flag, enabling the exported
      // field to be multiline
      this.hostAnnot["/Ff"] = 1 << 12;
    }

    this.hostAnnot["/V"] = "(" + this.strValue + ")";
    this.hostAnnot["/Rect"] = [this.pdfX1, this.pdfY1, this.pdfX2, this.pdfY2];
  }

  private DrawTextField(context): void
  {
    context.font = "16px Helvetica";
    context.fillStyle = "black";

    if ((context.measureText(this.strValue).width) > this.canvasWidth)
    {
      let culledText: string = this.strValue;

      while ((context.measureText(culledText).width) > this.canvasWidth)
      {
        culledText = culledText.slice(0, -1);
      }

      context.fillText(culledText, this.canvasX1 + 2, this.canvasY1 + this.canvasHeight - 2);
    }
    else
    {
      context.fillText(this.strValue, this.canvasX1 + 2, this.canvasY1 + this.canvasHeight - 2);
    }
  }

  private DrawTextArea(context): void
  {
    context.font = "16px Helvetica";
    context.fillStyle = "black";

    let maxCharsPerLine: number = Math.ceil(this.canvasWidth / context.measureText('3').width);
    let lines: Array<string> = this.strValue.match(new RegExp('.{1,' + maxCharsPerLine + '}', 'g'));
    
    if (lines !== null)
    {
      let lineSpacing: number = Math.round(context.measureText('3').width) * 2;
      let currentLineY = lineSpacing + this.canvasY1;

      for (let i = 0; i < lines.length; ++i)
      {
        context.fillText(lines[i], this.canvasX1 + 2, currentLineY);
        currentLineY += lineSpacing;
      }
    }
  }

  public DrawText(context): void
  {
    if (this.strValue !== null && this.strValue !== "")
    {
      if (this.isTextArea)
      {
        this.DrawTextArea(context);
      }
      else
      {
        this.DrawTextField(context);
      }
    }
  }
}

@Component({
  selector: 'app-create-contract-dialog',
  templateUrl: './create-contract-dialog.component.html',
  styleUrls: ['./create-contract-dialog.component.css']
})
export class CreateContractDialogComponent implements OnInit, AfterViewInit
{ 
  @ViewChild(MatMenuTrigger, {static: false}) contextMenu: MatMenuTrigger;
  @ViewChild(MatButtonToggleGroup, {static: false}) editorMenuToggleGroup: MatButtonToggleGroup;

  public selectedWorksheet: Worksheet = null;
  public selectedTemplate: ContractTemplate = null;

  private _isViewExpanded: boolean = false;

  get IsViewExpanded(): boolean
  {
    return this._isViewExpanded;
  }

  set IsViewExpanded(expanded: boolean)
  {
    this._isViewExpanded = expanded;

    if (this._isViewExpanded)
    {
      this.InitCanvasFields();
    }
  }

  private _shouldExpandView: boolean = false;
  
  get ShouldExpandView(): boolean
  {
    return this._shouldExpandView;
  }

  set ShouldExpandView(expand: boolean)
  {
    this._shouldExpandView = expand;

    if (this._shouldExpandView)
    {
      this.dialogRef

      if (!this.appUtilityService.IsMobileScreenWidth())
      {
        setTimeout(() => { this.dialogRef.updateSize("10%", "20%") }, 1000);
        setTimeout(() => { this.dialogRef.updateSize("15%", "30%") }, 1020);
        setTimeout(() => { this.dialogRef.updateSize("20%", "40%") }, 1040);
        setTimeout(() => { this.dialogRef.updateSize("25%", "50%") }, 1060);
        setTimeout(() => { this.dialogRef.updateSize("30%", "60%") }, 1080);
        setTimeout(() => { this.dialogRef.updateSize("35%", "70%") }, 1100);
        setTimeout(() => { this.dialogRef.updateSize("40%", "80%") }, 1120);
        setTimeout(() => { this.dialogRef.updateSize("45%", "90%") }, 1140);
        setTimeout(() => { this.dialogRef.updateSize("50%", "100%"); this.IsViewExpanded = true; }, 1160);
      }
      else
      {
        setTimeout(() => { this.dialogRef.updateSize("20%", "20%") }, 1000);
        setTimeout(() => { this.dialogRef.updateSize("30%", "30%") }, 1020);
        setTimeout(() => { this.dialogRef.updateSize("40%", "40%") }, 1040);
        setTimeout(() => { this.dialogRef.updateSize("50%", "50%") }, 1060);
        setTimeout(() => { this.dialogRef.updateSize("60%", "60%") }, 1080);
        setTimeout(() => { this.dialogRef.updateSize("70%", "70%") }, 1100);
        setTimeout(() => { this.dialogRef.updateSize("80%", "80%") }, 1120);
        setTimeout(() => { this.dialogRef.updateSize("90%", "90%") }, 1140);
        setTimeout(() => { this.dialogRef.updateSize("100%", "100%"); this.IsViewExpanded = true }, 1160);
      }
    }
  }

  public contractNameField: FormControl = new FormControl();

  private canvasPages: Array<ImageData> = new Array<ImageData>();
  private canvasPageStartOffsets: Array<number> = new Array<number>();

  private contractCanvasRoot = null;

  private canvas = null;
  private context = null;

  private textInputCanvas = null;
  private textInputCanvasContext = null;

  private fieldCanvas = null;
  private fieldCanvasContext = null;

  private dragCanvas = null;
  private dragCanvasContext = null;

  private canvasTotalHeight: number = 0;

  public selectedPDFName: string = null;
  public selectedPDFData = null;
  private pdfAssembler: PDFAssembler.PDFAssembler = null;
  private pdfStructure = null;
  private contractFormFields: Set<ContractFormField> = new Set<ContractFormField>();

  public contextMenuPosition = { x: '0px', y: '0px' };

  public currentChosenSuite: Unit = null;
  public currentChosenHome: Linker = null;
  public currentChosenPurchaser: Purchaser = null;
  public currentChosenDeposit: Deposit = null;

  public currentChosenFormField: ContractFormField = null;

  private isDragging: boolean = false;
  private currentDragRect = {x1: 0, y1: 0, x2: 0, y2: 0, w: 0, h: 0};
  private currentDragMode: EMouseDragMode = EMouseDragMode.None;

  private currentEditMode: EEditMode = EEditMode.AppendWorksheetData;

  constructor(private dialogRef: MatDialogRef<CreateContractDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateContractDialogData, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
    this.contractCanvasRoot = document.getElementById('contract-canvas-root');

    this.canvas = document.getElementById('canvas-layer-0');
    this.textInputCanvas = document.getElementById('canvas-layer-1')
    this.fieldCanvas = document.getElementById('canvas-layer-2');
    this.dragCanvas = document.getElementById('canvas-layer-3');

    this.dragCanvas.onmousemove = this.OnCanvasMouseMove.bind(this);
    this.dragCanvas.onmousedown = this.OnCanvasMouseDown.bind(this);
    this.dragCanvas.onmouseup = this.OnCanvasMouseUp.bind(this);
    this.dragCanvas.oncontextmenu = () => { return false; };

    this.context = this.canvas.getContext('2d');
    this.textInputCanvasContext = this.textInputCanvas.getContext('2d');
    this.fieldCanvasContext = this.fieldCanvas.getContext('2d');
    this.dragCanvasContext = this.dragCanvas.getContext('2d');

    this.dragCanvasContext.fillStyle = "rgba(0, 0, 200, 0.5)";

    this.canvasPageStartOffsets.push(0);

    if (this.data.selectedContract != null)
    {
      this.selectedWorksheet = this.data.selectedProject.worksheets.get(this.data.selectedContract.worksheet_id);
      this.selectedPDFName = this.data.selectedContract.contract_pdf_name;
      this.selectedPDFData = this.data.selectedContract.contract_pdf_data;
      this.contractNameField.setValue(this.data.selectedContract.contract_pdf_name);
      
      this.TryOpenContractEditor();
    }
    else if (this.data.selectedContractTemplate != null)
    {
      this.selectedPDFName = this.data.selectedContractTemplate.contract_template_pdf_name;
      this.selectedPDFData = this.data.selectedContractTemplate.contract_template_pdf_data;
      this.contractNameField.setValue(this.selectedPDFName);
      
      this.OpenContractTemplate();
    }
  }

  ngAfterViewInit()
  {
    this.contextMenu.menuClosed.subscribe(() => { this.OnContextMenuClosed(); });
    this.editorMenuToggleGroup.value = EEditMode.AppendWorksheetData;

    this.changeDetectorRef.detectChanges();
  }

  public IsContractPDFCanvasHidden(): boolean
  {
    if (this.data.selectedContractTemplate != null || this.data.showContractFilePicker == true)
    {
      return this.selectedPDFData == null || this.selectedPDFName == null;
    }
    else if (this.data.selectedContract != null)
    {
      return false;
    }
    else
    {
      return this.selectedPDFName == null || this.selectedPDFData == null || this.selectedWorksheet == null || this.selectedTemplate == null;
    }
  }

  public OnFormEditModeChanged(event: MatButtonToggleChange)
  {
    this.currentChosenFormField = null;

    // Set default canvas cursor appropriate for the selected mode
    switch (event.value)
    {
      case EEditMode.NewFormField:
        this.contractCanvasRoot.style.cursor = "crosshair";
        break;
      case EEditMode.EditFieldText:
        this.fieldCanvasContext.clearRect(0, 0, this.fieldCanvas.width, this.fieldCanvas.height);
        this.contractCanvasRoot.style.cursor = "text";
        break;
      default:
        this.contractCanvasRoot.style.cursor = "default";
        break;
    }

    this.currentEditMode = event.value;
    this.InitCanvasFields();
  }

  private OnContextMenuClosed(): void
  {
    this.currentChosenSuite = null;
    this.currentChosenHome = null;
    this.currentChosenPurchaser = null;
    this.currentChosenDeposit = null;
    this.currentChosenFormField = null;
  }
  
  public InitCanvasFields(mouseX: number = 0, mouseY: number = 0, forceTextInputCanvasClear: boolean = false): void
  {
    this.fieldCanvasContext.clearRect(0, 0, this.fieldCanvas.width, this.fieldCanvas.height);

    if (this.currentEditMode != EEditMode.EditFieldText || forceTextInputCanvasClear)
    {
      this.textInputCanvasContext.clearRect(0, 0, this.textInputCanvas.width, this.textInputCanvas.height);
      this.textInputCanvas.style.visibility = "hidden";
    }

    for (let field of this.contractFormFields)
    {
      if (field == this.currentChosenFormField) continue;

      this.fieldCanvasContext.clearRect(field.canvasX1, field.canvasY1, field.canvasWidth, field.canvasHeight);

      this.fieldCanvasContext.beginPath();
      this.fieldCanvasContext.rect(field.canvasX1, field.canvasY1, field.canvasWidth, field.canvasHeight);

      this.fieldCanvasContext.fillStyle = this.fieldCanvasContext.isPointInPath(mouseX, mouseY) && !this.isDragging ? "#D4F6FB" : "#F4F5F5";
      this.fieldCanvasContext.fill();

      field.DrawText(this.fieldCanvasContext);
    }

    if (this.currentEditMode == EEditMode.MoveAndResize && this.currentChosenFormField != null)
    {
      let field = this.currentChosenFormField;

      this.fieldCanvasContext.beginPath();
      this.fieldCanvasContext.rect(field.canvasX1, field.canvasY1, field.canvasWidth, field.canvasHeight);
      
      this.fieldCanvasContext.fillStyle = "#D4F6FB";
      this.fieldCanvasContext.fill();

      // Draw handles on form bounding boxes to guide resize operations
      this.fieldCanvasContext.fillStyle = "black";
      
      this.fieldCanvasContext.beginPath();
      this.fieldCanvasContext.strokeRect(field.canvasX1, field.canvasY1, field.canvasWidth, field.canvasHeight);

      this.fieldCanvasContext.fillRect(field.canvasX1 - 2.5, field.canvasY1 + (field.canvasHeight / 2), 5, 5);
      this.fieldCanvasContext.fillRect(field.canvasX1 + field.canvasWidth - 2.5, field.canvasY1 + (field.canvasHeight / 2), 5, 5);
      this.fieldCanvasContext.fillRect(field.canvasX1 + (field.canvasWidth / 2), field.canvasY1 - 2.5, 5, 5);
      this.fieldCanvasContext.fillRect(field.canvasX1 + (field.canvasWidth / 2), field.canvasY1 + field.canvasHeight - 2.5, 5, 5);

      field.DrawText(this.fieldCanvasContext);
    }
  }

  private InitCanvasMoveAndResizeCursor(mouseX: number, mouseY: number, field: ContractFormField)
  {
    field.canvasX1Prev = field.canvasX1;
    field.canvasY1Prev = field.canvasY1;
    field.canvasWidthPrev = field.canvasWidth;
    field.canvasHeightPrev = field.canvasHeight;

    this.contractCanvasRoot.style.cursor = "default";

    if (mouseX < field.canvasX1 + field.canvasWidth &&
          mouseX + 2 > field.canvasX1 &&
          mouseY < field.canvasY1 + field.canvasHeight &&
          mouseY + 2 > field.canvasY1)
      {
        this.contractCanvasRoot.style.cursor = "move";
        this.currentDragMode = EMouseDragMode.MoveField;

        if (mouseX < field.canvasX1 + 10 &&
            mouseX > field.canvasX1 - 10)
        {
          this.contractCanvasRoot.style.cursor = "ew-resize";
          this.currentDragMode = EMouseDragMode.ResizeLeftEdge;
        }
        else if (mouseX < field.canvasX1 + field.canvasWidth + 10 &&
                  mouseX > field.canvasX1 + field.canvasWidth - 10)
        {
          this.contractCanvasRoot.style.cursor = "ew-resize";
          this.currentDragMode = EMouseDragMode.ResizeRightEdge;
        }
        else if (mouseY < field.canvasY1 + 10 &&
                  mouseY > field.canvasY1 - 10)
        {
          this.contractCanvasRoot.style.cursor = "ns-resize";
          this.currentDragMode = EMouseDragMode.ResizeTopEdge
        }
        else if (mouseY < field.canvasY1 + field.canvasHeight + 10 &&
                  mouseY > field.canvasY1 + field.canvasHeight - 10)
        {
          this.contractCanvasRoot.style.cursor = "ns-resize";
          this.currentDragMode = EMouseDragMode.ResizeBottomEdge;
        }
      }
  }

  private OnCanvasMouseMove(event): void
  {
    let rect = this.canvas.getBoundingClientRect();
    let mouseX = event.clientX - rect.left;
    let mouseY = event.clientY - rect.top;

    if (this.isDragging)
    {
      switch (this.currentEditMode)
      {
        case EEditMode.NewFormField:
          this.dragCanvasContext.clearRect(0, 0, this.dragCanvas.width, this.dragCanvas.height);

          this.currentDragRect.x2 = mouseX;
          this.currentDragRect.y2 = mouseY;
          this.currentDragRect.w = mouseX - this.currentDragRect.x1;
          this.currentDragRect.h = mouseY - this.currentDragRect.y1;

          this.dragCanvasContext.setLineDash([6]);
          this.dragCanvasContext.strokeRect(this.currentDragRect.x1, this.currentDragRect.y1, this.currentDragRect.w, this.currentDragRect.h);
          break;
        case EEditMode.MoveAndResize:
          switch (this.currentDragMode)
          {
            case EMouseDragMode.MoveField:
              this.currentChosenFormField.canvasX1 = mouseX - (this.currentChosenFormField.canvasWidth / 2);
              this.currentChosenFormField.canvasY1 = mouseY - (this.currentChosenFormField.canvasHeight / 2);
              break;
            case EMouseDragMode.ResizeLeftEdge:
              if (mouseX + 20 < (this.currentChosenFormField.canvasX1Prev + this.currentChosenFormField.canvasWidthPrev))
              {
                this.currentChosenFormField.canvasWidth += this.currentChosenFormField.canvasX1 - mouseX;
                this.currentChosenFormField.canvasX1 = mouseX;
              }
              
              break;
            case EMouseDragMode.ResizeRightEdge:
              if ((mouseX - 20) > this.currentChosenFormField.canvasX1Prev)
              {
                this.currentChosenFormField.canvasWidth = mouseX - this.currentChosenFormField.canvasX1;
              }

              break;
            case EMouseDragMode.ResizeTopEdge:
              if (mouseY < (this.currentChosenFormField.canvasY1Prev + this.currentChosenFormField.canvasHeightPrev) - 20)
              {
                this.currentChosenFormField.canvasHeight += this.currentChosenFormField.canvasY1 - mouseY;
                this.currentChosenFormField.canvasY1 = mouseY;
              }
              
              break;
            case EMouseDragMode.ResizeBottomEdge:
              if (mouseY > (this.currentChosenFormField.canvasY1Prev + 20))
              {
                this.currentChosenFormField.canvasHeight = Math.abs(this.currentChosenFormField.canvasY1 - mouseY);
              }

              break;
          }
          break;
      }
    }
    else
    {
      if (this.currentEditMode == EEditMode.MoveAndResize && this.currentChosenFormField != null)
      {
        this.InitCanvasMoveAndResizeCursor(mouseX, mouseY, this.currentChosenFormField);
      }
    }
  
    if (this.currentEditMode != EEditMode.NewFormField)
    {
      this.InitCanvasFields(mouseX, mouseY);
    }
  }

  private OnCanvasMouseDown(event): void
  {
    let rect = this.canvas.getBoundingClientRect();
    let mouseX = event.clientX - rect.left;
    let mouseY = event.clientY - rect.top;

    if (this.currentChosenFormField != null && this.currentChosenFormField.canvasInput != null)
    {
      this.currentChosenFormField.strValue = this.currentChosenFormField.canvasInput.value; 
      this.contractCanvasRoot.removeChild(this.currentChosenFormField.canvasInput);
      this.textInputCanvasContext.clearRect(0, 0, this.textInputCanvas.width, this.textInputCanvas.height);
    }

    for (let field of this.contractFormFields)
    {
      if (mouseX < field.canvasX1 + field.canvasWidth &&
          mouseX + 2 > field.canvasX1 &&
          mouseY < field.canvasY1 + field.canvasHeight &&
          mouseY + 2 > field.canvasY1)
      {
        this.currentChosenFormField = field;

        event.preventDefault();

        if (this.currentEditMode == EEditMode.AppendWorksheetData)
        {
          this.contextMenuPosition.x = event.clientX + 'px';
          this.contextMenuPosition.y = event.clientY + 'px';
          this.contextMenu.openMenu();
          return;
        }
        else if (this.currentEditMode == EEditMode.ClearFieldContents)
        {
          this.currentChosenFormField.strValue = "";
          this.currentChosenFormField = null;
          this.InitCanvasFields();
          return;
        }
        else if (this.currentEditMode == EEditMode.EditFieldText)
        {
          if (this.currentChosenFormField.canvasHeight > 60)
          {
            this.currentChosenFormField.canvasInput = document.createElement('textarea');
            this.currentChosenFormField.canvasInput.style.resize = "none";
            this.currentChosenFormField.isTextArea = true;
          }
          else
          {
            this.currentChosenFormField.canvasInput = document.createElement('input');
            this.currentChosenFormField.canvasInput.type = 'text';
            this.currentChosenFormField.isTextArea = false;
          }

          this.currentChosenFormField.canvasInput.style.left = this.currentChosenFormField.canvasX1 - this.canvas.offsetLeft + "px";
          this.currentChosenFormField.canvasInput.style.top = this.currentChosenFormField.canvasY1 - this.canvas.offsetTop + "px";
          this.currentChosenFormField.canvasInput.style.minWidth = this.currentChosenFormField.canvasWidth + "px";
          this.currentChosenFormField.canvasInput.style.maxWidth = this.currentChosenFormField.canvasInput.style.minWidth;
          this.currentChosenFormField.canvasInput.style.minHeight = this.currentChosenFormField.canvasHeight + "px";
          this.currentChosenFormField.canvasInput.style.maxHeight = this.currentChosenFormField.canvasInput.style.minHeight;
          this.currentChosenFormField.canvasInput.style.position = "absolute";
          this.currentChosenFormField.canvasInput.style.zIndex = "5000";
          this.currentChosenFormField.canvasInput.style.fontSize = "16px";
          this.currentChosenFormField.canvasInput.style.fontFamily = "Arial, Helvetica, sans-serif";

          this.currentChosenFormField.canvasInput.value = this.currentChosenFormField.strValue;

          this.contractCanvasRoot.appendChild(this.currentChosenFormField.canvasInput);
          this.currentChosenFormField.canvasInput.focus();

          this.currentChosenFormField.canvasInput.addEventListener('keydown', (event) => 
          { 
            this.currentChosenFormField.strValue = this.currentChosenFormField.canvasInput.value; 

            let shouldSubmit: boolean = this.currentChosenFormField.isTextArea ? event.which == 13 && (event.ctrlKey || event.metaKey) : event.which == 13;
            if (shouldSubmit)
            {
              // Enter key pressed
              event.preventDefault();
              this.contractCanvasRoot.removeChild(this.currentChosenFormField.canvasInput);
              this.currentChosenFormField.canvasInput = null; 
              this.currentChosenFormField = null;
              this.InitCanvasFields();
            }
          });

          this.InitCanvasFields();
          return;
        }
        else if (this.currentEditMode == EEditMode.MoveAndResize)
        {
          this.isDragging = true;

          this.InitCanvasFields();
          this.InitCanvasMoveAndResizeCursor(mouseX, mouseY, this.currentChosenFormField);
          return;
        }
        else if (this.currentEditMode = EEditMode.DeleteField)
        {
          for (let i = 0; i < field.hostAnnot["/P"]["/Annots"].length; ++i)
          {
            if (field.hostAnnot["/P"]["/Annots"][i] == field.hostAnnot)
            {
              delete field.hostAnnot["/P"]["/Annots"][i];
              break;
            }
          }

          this.contractFormFields.delete(field);
          this.InitCanvasFields();
          return;
        }
      }
    }

    this.currentChosenFormField = null;
    this.InitCanvasFields();

    if (this.currentEditMode == EEditMode.NewFormField)
    {
      this.isDragging = true;
      this.currentDragRect.x1 = mouseX;
      this.currentDragRect.y1 = mouseY;
      this.currentDragMode = EMouseDragMode.DrawField;

      return;
    }
  }

  private OnCanvasMouseUp(event): void
  {
    let rect = this.canvas.getBoundingClientRect();
    let mouseX = event.clientX - rect.left;
    let mouseY = event.clientY - rect.top;

    if (this.isDragging)
    {
      switch (this.currentEditMode)
      {
        case EEditMode.NewFormField:
          this.AppendNewFormField(rect, mouseX, mouseY);
          break;
        case EEditMode.MoveAndResize:
          let tempVector = {x1: this.currentChosenFormField.canvasX1, y1: this.currentChosenFormField.canvasY1, x2: this.currentChosenFormField.canvasX1 + this.currentChosenFormField.canvasWidth, y2: this.currentChosenFormField.canvasY1 + this.currentChosenFormField.canvasHeight};    

          let hostPage = (this.pdfStructure["/Root"]["/Pages"]["/Kids"])[this.currentChosenFormField.pageNumber - 1];
          let pageBoundingBox = hostPage["/MediaBox"];
          let pageMinX = pageBoundingBox[0];
          let pageMinY = pageBoundingBox[1];
          let pageWidth = pageBoundingBox[2];
          let pageHeight = pageBoundingBox[3];
          this.CanvasToPDFPoint(tempVector, this.currentChosenFormField.pageNumber - 1, pageMinX, pageMinY, pageWidth, pageHeight);

          this.currentChosenFormField.pdfX1 = tempVector.x1;
          this.currentChosenFormField.pdfY1 = tempVector.y1;
          this.currentChosenFormField.pdfX2 = tempVector.x2;
          this.currentChosenFormField.pdfY2 = tempVector.y2;
          break;
      }
    }

    this.isDragging = false;
    this.currentDragMode = EMouseDragMode.None;

    switch (this.currentEditMode)
    {
      case EEditMode.MoveAndResize:
      case EEditMode.AppendWorksheetData:
        break;
      case EEditMode.EditFieldText:
        if (this.currentChosenFormField != null)
        {
          this.textInputCanvas.style.visibility = "visible";
          this.InitCanvasFields();
        }
        
        break;
      default:
        this.currentChosenFormField = null;
        break;
    }
  }

  private AppendNewFormField(rect, mouseX: number, mouseY: number)
  {
    this.dragCanvasContext.clearRect(0, 0, this.dragCanvas.width, this.dragCanvas.height);

    let newField: ContractFormField = new ContractFormField();
    newField.parentComponent = this;
    newField.canvasX1 = this.currentDragRect.x1;
    newField.canvasY1 = this.currentDragRect.y1;
    newField.canvasWidth = this.currentDragRect.w;
    newField.canvasHeight = this.currentDragRect.h;
    newField.type = EContractFieldType.TextField;

    for (let i = 0; i < this.canvasPageStartOffsets.length - 1; ++i)
    {
      if (mouseY > this.canvasPageStartOffsets[i] && mouseY < this.canvasPageStartOffsets[i + 1])
      {
        newField.pageNumber = i + 1;
        break;
      }
    }

    let page = (this.pdfStructure["/Root"]["/Pages"]["/Kids"])[newField.pageNumber - 1];

    let pageBoundingBox = page["/MediaBox"];
    let pageMinX = pageBoundingBox[0];
    let pageMinY = pageBoundingBox[1];
    let pageWidth = pageBoundingBox[2];
    let pageHeight = pageBoundingBox[3];

    this.CanvasToPDFPoint(this.currentDragRect, newField.pageNumber - 1, pageMinX, pageMinY, pageWidth, pageHeight);
    newField.pdfX1 = this.currentDragRect.x1;
    newField.pdfY1 = this.currentDragRect.y1;
    newField.pdfX2 = this.currentDragRect.x2;
    newField.pdfY2 = this.currentDragRect.y2;

    newField.strValue = "";

    let length = Object.keys(page).includes("/Annots") ? Object.keys((page["/Annots"])).length : 0;
    if (length == 0) 
    { 
      page["/Annots"] = new Array<object>(); 
    }

    (page["/Annots"])[length] = 
    {
      "/DA": "(/Helv 12 Tf 0 g)",
      "/F": 4,
      "/FT": "/Tx",
      "/MK": {},
      "/P": page,
      "/Rect": [newField.pdfX1, newField.pdfY1, newField.pdfX2, newField.pdfY2],
      "/Subtype": "/Widget",
      "/T": "(field " + length + ")",
      "/Type": "/Annot",
      "/V": "()",
      "gen": 0,
      "num": 0
    };

    newField.hostAnnot = (page["/Annots"])[length];

    this.contractFormFields.add(newField);
    this.InitCanvasFields();
  }

  public OnUploadContractButtonPressed(): void
  {
    if (this.contractNameField.value == "")
    {
      this.contractNameField.setErrors(['required']);
      return;
    }

    for (let field of this.contractFormFields)
    {
      field.BindToPDF(this.pdfStructure);
    }

    let contractPDFName: string = this.contractNameField.value;

    this.pdfAssembler.assemblePdf(contractPDFName).then(function(file)
    {
      let fileReader: FileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = function(event)
      {
        let base64PDF: string = event.target.result;
        
        if (this.data.selectedContract == null && this.data.selectedContractTemplate == null)
        {
          if (this.data.showContractFilePicker)
          {
            this.projectsService.CreateNewContractTemplate(this.data.selectedProject, contractPDFName, base64PDF).subscribe((result: boolean) => 
            {
              this.dialogRef.close(result);
            });
          }
          else
          {
            this.projectsService.CreateNewContract(this.data.selectedProject, this.selectedWorksheet.worksheet_id, contractPDFName, base64PDF).subscribe((result: boolean) => 
            {
              this.dialogRef.close(result);
            });
          }
        }
        else if (this.data.selectedContractTemplate != null)
        {
          this.projectsService.UpdateContractTemplate(this.data.selectedProject, this.data.selectedContractTemplate.contract_template_id, contractPDFName, base64PDF).subscribe((result: boolean) => 
          {
            this.dialogRef.close(result);
          });
        }
        else
        {
          this.projectsService.UpdateProjectContract(this.data.selectedProject, this.data.selectedContract.contract_id, contractPDFName, base64PDF).subscribe((result: boolean) => 
          {
            this.dialogRef.close(result);
          });
        }
      }.bind(this);
    }.bind(this));
  }

  public OnSaveContractButtonPressed(): void
  {
    if (this.contractNameField.value == "")
    {
      this.contractNameField.setErrors(['required']);
      return;
    }

    if (Object.keys(this.pdfStructure["/Root"]).includes("/AcroForm"))
    {
      delete this.pdfStructure["/Root"]["/AcroForm"];
    }

    for (let field of this.contractFormFields)
    {
      field.BindToPDF(this.pdfStructure);
    }

    let contractPDFName: string = this.contractNameField.value;
    this.pdfAssembler.assemblePdf(contractPDFName).then(function(file)
    {
      fileSaver.saveAs(file, contractPDFName + ".pdf");
    });
  }

  OnDataOptionSelected(text: string)
  {
    if (this.currentChosenFormField !== null)
    {
      if (this.currentChosenFormField.strValue == "")
        this.currentChosenFormField.strValue = text;
      else
        this.currentChosenFormField.strValue += ', '  + text;

      // this.currentChosenFormField.strValue = text;
      this.currentChosenFormField.DrawText(this.fieldCanvasContext);
    }
  }

  OnSuiteSubMenuSelected(suite: Unit)
  {
    this.currentChosenSuite = suite;
  }

  OnHomeSubMenuSelected(home: Linker)
  {
    this.currentChosenHome = home;
  }

  OnPurchaserSubMenuSelected(purchaser: Purchaser)
  {
    this.currentChosenPurchaser = purchaser;
  }

  OnDepositSubMenuSelected(deposit: Deposit)
  {
    this.currentChosenDeposit = deposit;
  }

  public OnContractFileChosen(pdfInput, pdfFileNameDisplay)
  {
    pdfFileNameDisplay.value = pdfInput.files[0].name;
    
    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(pdfInput.files[0]);
    fileReader.onloadend = function(event)
    {
      this.selectedPDFName = pdfInput.files[0].name;
      this.contractNameField.setValue(this.selectedPDFName);

      pdfjs.GlobalWorkerOptions.workerSrc = "./assets/pdf.worker.min.js";
      this.selectedPDFData = event.target.result;

      var loadingTask = pdfjs.getDocument({ data: this.appUtilityService.Base64ToArrayBuffer(this.selectedPDFData), verbosity: 0});
      loadingTask.promise.then(function(pdf) { this.RenderPDFPages(pdf, 1); }.bind(this));
    }.bind(this);
  }

  public OpenContractTemplate()
  {
    pdfjs.GlobalWorkerOptions.workerSrc = "./assets/pdf.worker.min.js";

    var loadingTask = pdfjs.getDocument({ data: this.appUtilityService.Base64ToArrayBuffer(this.selectedPDFData), verbosity: 0});
    loadingTask.promise.then(function(pdf) { this.RenderPDFPages(pdf, 1); }.bind(this));
  }

  SetContractTemplate()
  {
    if (this.selectedTemplate != null)
    {
      this.selectedPDFName = this.selectedTemplate.contract_template_pdf_name;
      this.selectedPDFData = this.selectedTemplate.contract_template_pdf_data;
    }
  }

  TryOpenContractEditor()
  {
    if (this.selectedPDFName != null && this.selectedPDFData != null && this.selectedWorksheet != null)
    {
      this.contractNameField.setValue(this.selectedPDFName);
      pdfjs.GlobalWorkerOptions.workerSrc = "./assets/pdf.worker.min.js";

      var loadingTask = pdfjs.getDocument({ data: this.appUtilityService.Base64ToArrayBuffer(this.selectedPDFData), verbosity: 0});
      loadingTask.promise.then(function(pdf) { this.RenderPDFPages(pdf, 1); }.bind(this));
    }
  }

  private RenderPDFPages(pdf, currentPageNumber: number): void
  {
    pdf.getPage(currentPageNumber).then(function(page) 
    {
      var scale = 1.5;
      var viewport = page.getViewport({ scale: scale, });

      this.canvas.width = viewport.width;
      this.canvas.height = viewport.height;
      this.canvasTotalHeight += this.canvas.height;

      this.textInputCanvas.width = viewport.width;
      this.textInputCanvas.height = viewport.height;

      this.fieldCanvas.width = viewport.width;
      this.fieldCanvas.height = viewport.height;

      this.dragCanvas.width = viewport.width;
      this.dragCanvas.height = viewport.height;

      var renderContext = { canvasContext: this.context, viewport: viewport };
      page.render(renderContext).promise.then(function()
      {
        this.canvasPages.push(this.context.getImageData(0, 0, this.canvas.width, this.canvas.height));
        this.canvasPageStartOffsets.push(this.canvas.height + this.canvasPageStartOffsets[currentPageNumber - 1]);

        if (currentPageNumber < pdf.numPages)
        {
          this.RenderPDFPages(pdf, currentPageNumber + 1);
        }
        else
        {
          this.canvas.height = this.canvasTotalHeight;
          this.textInputCanvas.height = this.canvasTotalHeight;
          this.fieldCanvas.height = this.canvasTotalHeight;
          this.dragCanvas.height = this.canvasTotalHeight;

          for (let i = 0; i < this.canvasPages.length; ++i)
          {
            this.context.putImageData(this.canvasPages[i], 0, this.canvasPageStartOffsets[i]);
          }

          this.dragCanvasContext.clearRect(0, 0, this.dragCanvas.width, this.dragCanvas.height);
          this.fieldCanvasContext.clearRect(0, 0, this.fieldCanvas.width, this.fieldCanvas.height);
          this.textInputCanvasContext.clearRect(0, 0, this.textInputCanvas.width, this.textInputCanvas.height);
          this.InitContractFormBoundingBoxes();

          this.ShouldExpandView = true;
        }
      }.bind(this));
    }.bind(this));
  }

  private InitContractFormBoundingBoxes()
  {
    let contractPDFName: string = (this.data.selectedContract != null && this.data.selectedContract.contract_pdf_name != null) ? this.data.selectedContract.contract_pdf_name : this.selectedPDFName;
    let contractPDFData: string = (this.data.selectedContract != null && this.data.selectedContract.contract_pdf_data != null) ? this.data.selectedContract.contract_pdf_data : this.selectedPDFData;

    this.pdfAssembler = new PDFAssembler.PDFAssembler(this.appUtilityService.Base64ToFileHandle(contractPDFData, contractPDFName));

    this.pdfAssembler.getPDFStructure().then(function(pdfStructure) 
    {
      this.pdfStructure = pdfStructure;

      for (let i = 0; i < Object.keys(pdfStructure["/Root"]["/Pages"]["/Kids"]).length; ++i)
      {
        let page = (pdfStructure["/Root"]["/Pages"]["/Kids"])[i];

        let pageBoundingBox = page["/MediaBox"];
        let pageMinX = pageBoundingBox[0];
        let pageMinY = pageBoundingBox[1];
        let pageWidth = pageBoundingBox[2];
        let pageHeight = pageBoundingBox[3];

        if (Object.keys(page).includes("/Annots"))
        {
          for (let annot of page["/Annots"])
          {
            let rect = new Array<number>();
            rect.push(annot["/Rect"][0]);
            rect.push(annot["/Rect"][1]);
            rect.push(annot["/Rect"][2]);
            rect.push(annot["/Rect"][3]);

            let currentField: ContractFormField = new ContractFormField();
            currentField.parentComponent = this;
            currentField.hostAnnot = annot;

            currentField.pdfX1 = rect[0];
            currentField.pdfY1 = rect[1];
            currentField.pdfX2 = rect[2];
            currentField.pdfY2 = rect[3];
            currentField.type = annot["/FT"];
            currentField.pageNumber = i + 1;

            this.PDFToCanvasPoint(rect, i, pageMinX, pageMinY, pageWidth, pageHeight);
            currentField.canvasX1 = rect[0];
            currentField.canvasY1 = rect[1];
            currentField.canvasWidth = rect[2];
            currentField.canvasHeight = rect[3];

            currentField.isTextArea = currentField.canvasWidth > 60;

            if (Object.keys(annot).includes("/FT"))
            {
              if (annot["/FT"] == "/Btn" || annot["/FT"] == "/Tx")
              {
                currentField.type = annot["/FT"];
                this.contractFormFields.add(currentField);
              }
            }
            else
            {
              currentField.type = EContractFieldType.TextField;
              this.contractFormFields.add(currentField);
            }

            if (Object.keys(annot).includes("/V"))
            {
              if (annot["/V"].length > 2)
              {
                currentField.strValue = annot["/V"].substring(1, annot["/V"].length - 1);
              }
            }
          }
        }
      }
    }.bind(this));
  }

  private PDFToCanvasPoint(pdfVector: Array<number>, pageIndex: number, pageMinX: number, pageMinY: number, pageWidth: number, pageHeight: number): void
  {
    let canvasPageMinX = 0;
    let canvasPageMinY = 0;
    let canvasPageWidth = this.canvas.width;
    let canvasPageHeight = this.canvasPages[pageIndex].height;

    let x1 = pdfVector[0];
    let y1 = pdfVector[1];
    let width = pdfVector[2] - x1;
    let height = pdfVector[3] - y1;

    let resX1 = this.Normalize(x1, pageMinX, pageWidth) * Math.abs(canvasPageWidth - canvasPageMinX) + canvasPageMinX;
    let resY1 = this.Normalize(y1, pageMinY, pageHeight) * Math.abs(canvasPageHeight - canvasPageMinY) + canvasPageMinY;
    let resWidth = this.Normalize(width, pageMinX, pageWidth) * Math.abs(canvasPageWidth - canvasPageMinX) + canvasPageMinX;
    let resHeight = this.Normalize(height, pageMinY, pageHeight) * Math.abs(canvasPageHeight - canvasPageMinY) + canvasPageMinY;

    pdfVector[0] = resX1;
    pdfVector[1] = (canvasPageHeight - resY1 - resHeight) + this.canvasPageStartOffsets[pageIndex];
    pdfVector[2] = resWidth;
    pdfVector[3] = resHeight;
  }

  private CanvasToPDFPoint(canvasVector, pageIndex: number, pageMinX: number, pageMinY: number, pageWidth: number, pageHeight: number): void
  {
    let canvasPageMinX = 0;
    let canvasPageMinY = 0;
    let canvasPageWidth = this.canvas.width;
    let canvasPageHeight = this.canvasPages[pageIndex].height;
    let canvasPageYOffset = this.canvasPageStartOffsets[pageIndex];

    let x1 = canvasVector.x1;
    let y1 = canvasVector.y1 - canvasPageYOffset;
    let x2 = canvasVector.x2;
    let y2 = canvasVector.y2 - canvasPageYOffset;

    let resX1 = this.Normalize(x1, canvasPageMinX, canvasPageWidth) * Math.abs(pageWidth - pageMinX) + pageMinX;
    let resY1 = this.Normalize(y1, canvasPageMinY, canvasPageHeight) * Math.abs(pageHeight - pageMinY) + pageMinY;
    let resX2 = this.Normalize(x2, canvasPageMinX, canvasPageWidth) * Math.abs(pageWidth - pageMinX) + pageMinX;
    let resY2 = this.Normalize(y2, canvasPageMinY, canvasPageHeight) * Math.abs(pageHeight - pageMinY) + pageMinY;

    canvasVector.x1 = resX1
    canvasVector.y2 = pageHeight - resY1;
    canvasVector.x2 = resX2;
    canvasVector.y1 = pageHeight - resY2;
  }

  private Normalize(value: number, min: number, max: number): number
  {
    return Math.abs((value - min) / (max - min));  
  }

/*
  =============
  DATA GETTERS
  =============
*/
//#region 
  GetWorksheetsIterator(): Array<Worksheet>
  {
    if (this.data.selectedProject != null && this.data.selectedProject.worksheets.size > 0)
    {
      return Array.from(this.data.selectedProject.worksheets.values());
    }

    return null;
  }

  GetContractTemplatesIterator(): Array<ContractTemplate>
  {
    if (this.data.selectedProject.contract_templates != null && this.data.selectedProject.contract_templates.size > 0)
    {
      return Array.from(this.data.selectedProject.contract_templates.values());
    }

    return null;
  }

  ShouldDisplaySuitesSubMenu(): boolean
  {
    if (this.data.selectedProject.has_condos || this.data.selectedProject.has_townhomes)
    {
      if (this.selectedWorksheet !== null && this.selectedWorksheet.str_unit_ids.length > 0)
      {
        return true;
      }
    }

    return false;
  }

  ShouldDisplayHomesSubMenu(): boolean
  {
    if (this.data.selectedProject.has_detached)
    {
      if (this.selectedWorksheet !== null && this.selectedWorksheet.str_linker_ids.length > 0)
      {
        return true;
      }
    }

    return false;
  }

  ShouldDisplayPurchasersSubMenu(): boolean
  {
    if (this.selectedWorksheet.str_purchaser_ids !== null && this.selectedWorksheet.str_purchaser_ids !== "")
    {
      let arrPurchaserIDs = this.selectedWorksheet.str_purchaser_ids.split(", ");

      for (let purchaserID of arrPurchaserIDs)
      {
        if (this.data.selectedProject.purchasers.has(purchaserID))
        {
          return true;
        }
      }
    }

    return false;
  }

  ShouldDisplayDepositsSubMenu(): boolean
  {
    return this.data.selectedProject.uses_worksheets_deposit_structure_field;
  }

  GetWorksheetSuitesIterator(): Array<Unit>
  {
    if (this.selectedWorksheet !== null && this.selectedWorksheet.str_unit_ids !== null && this.selectedWorksheet.str_unit_ids !== "")
    {
      let arrUnitIDs = this.selectedWorksheet.str_unit_ids.split(", ");
      if (arrUnitIDs.length > 0)
      {
        let units: Array<Unit> = new Array<Unit>();

        for (let unitID of arrUnitIDs)
        {
          if (this.data.selectedProject.units.has(Number(unitID)))
          {
            units.push(this.data.selectedProject.units.get(Number(unitID)));
          }
        }

        return units;
      }      
    }

    return null;
  }

  GetSuiteName(suite: Unit): string
  {
    if (suite !== null)
    {
      if (this.data.selectedProject.model_types.has(suite.model_type_id))
      {
        return this.data.selectedProject.model_types.get(suite.model_type_id).name;
      }
    }

    return null;
  }

  GetWorksheetHomesIterator(): Array<Linker>
  {
    if (this.selectedWorksheet !== null && this.selectedWorksheet.str_linker_ids !== null && this.selectedWorksheet.str_linker_ids !== "")
    {
      let arrLinkerIDs = this.selectedWorksheet.str_linker_ids.split(", ");
      if (arrLinkerIDs.length > 0)
      {
        let linkers: Array<Linker> = new Array<Linker>();

        for (let linkerID of arrLinkerIDs)
        {
          if (this.data.selectedProject.linkers.has(Number(linkerID)))
          {
            linkers.push(this.data.selectedProject.linkers.get(Number(linkerID)));
          }
        }

        return linkers;
      }      
    }

    return null;
  }

  GetHomeName(linker: Linker): string
  {
    if (linker !== null)
    {
      return this.data.selectedProject.homes.get(this.data.selectedProject.elevations.get(linker.elevation_id).detached_id).name;
    }

    return "";
  }

  GetWorksheetPurchasersIterator(): Array<Purchaser>
  {
    if (this.selectedWorksheet !== null && this.selectedWorksheet.str_purchaser_ids !== null && this.selectedWorksheet.str_purchaser_ids !== "")
    {
      let purchasers: Array<Purchaser> = new Array<Purchaser>();

      let arrPurchaserIDs = this.selectedWorksheet.str_purchaser_ids.split(", ");
      for (let purchaserID of arrPurchaserIDs)
      {
        if (this.data.selectedProject.purchasers.has(purchaserID))
        {
          purchasers.push(this.data.selectedProject.purchasers.get(purchaserID));
        }
      }

      return purchasers;
    }

    return null;
  }

  GetPurchaserName(purchaser: Purchaser): string
  {
    return purchaser.full_name;
  }

  GetWorksheetDepositsIterator(): Array<Deposit>
  {
    if (this.selectedWorksheet !== null)
    {
      if (this.data.selectedProject !== null  && this.data.selectedProject.worksheetDeposits.has(this.selectedWorksheet.worksheet_id))
      {
        return this.data.selectedProject.worksheetDeposits.get(this.selectedWorksheet.worksheet_id);
      }
    }

    return null;
  }

  GetDepositName(deposit: Deposit): string
  {
    return "Deposit " + deposit.deposit_number;
  }

  GetCurrentSuiteNumber(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.currentChosenSuite.suite_number.toString();
    }
    
    return "";
  }

  GetCurrentSuiteFloorNumber(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.currentChosenSuite.floor_number.toString();
    }
    
    return "";
  }

  GetCurrentSuiteModelTypeName(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.data.selectedProject.model_types.get(this.currentChosenSuite.model_type_id).name;
    }
    
    return "";
  }

  GetCurrentSuiteOrientation(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.currentChosenSuite.orientation.toString();
    }
    
    return "";
  }

  GetCurrentSuiteFloorPremium(): string
  {
    if (this.currentChosenSuite !== null)
    {
      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.currentChosenSuite.building_id && floor.floor_number == this.currentChosenSuite.floor_number)
        {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(floor.premium);
        }
      }
    }
    
    return "";
  }

  GetCurrentSuiteOrientationPremium(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.currentChosenSuite.orientation_premium);
    }
    
    return "";
  }

  GetCurrentSuitePremium(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.currentChosenSuite.premium);
    }
    
    return "";
  }

  GetCurrentSuiteTotalPrice(): string
  {
    if (this.currentChosenSuite !== null)
    {
      let totalPrice: number = 0;
      totalPrice += this.currentChosenSuite.premium;
      totalPrice += this.currentChosenSuite.orientation_premium;
      totalPrice += this.data.selectedProject.model_types.get(this.currentChosenSuite.model_type_id).price;
      
      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.currentChosenSuite.building_id && floor.floor_number == this.currentChosenSuite.floor_number)
        {
          totalPrice += floor.premium;
          break;
        }
      }

      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalPrice);
    }
    
    return "";
  }

  GetCurrentSuiteSquareFootage(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.data.selectedProject.model_types.get(this.currentChosenSuite.model_type_id).square_footage.toString() + " sqft";
    }
  }

  GetCurrentSuiteOutdoorSquareFootage(): string
  {
    if (this.currentChosenSuite !== null)
    {
      return this.data.selectedProject.model_types.get(this.currentChosenSuite.model_type_id).square_footage_outdoor.toString() + " sqft";
    }
  }

  GetCurrentHomeName(): string
  {
    if (this.currentChosenHome !== null)
    {
      return this.data.selectedProject.homes.get(this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).detached_id).name;
    }

    return "";
  }

  GetCurrentHomeElevation(): string
  {
    if (this.currentChosenHome !== null)
    {
      return this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).elevation;
    }

    return "";
  }

  GetCurrentHomeStoreys(): string
  {
    if (this.currentChosenHome !== null)
      return this.data.selectedProject.homes.get(this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).detached_id).storeys.toString();
    
    return "";
  }

  GetCurrentHomeFrontage(): string
  {
    if (this.currentChosenHome !== null)
      return this.data.selectedProject.homes.get(this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).detached_id).frontage.toString() + " sqft";

    return "";
  }

  GetCurrentHomeGrading(): string
  {
    if (this.currentChosenHome !== null)
      return this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).grading;

    return "";
  }

  GetCurrentHomeLotFrontage(): string
  {
    if (this.currentChosenHome !== null)
    {
      return this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).lot_frontage.toString() + " sqft";
    }

    return "";
  }

  GetCurrentHomeLotNumber(): string
  {
    if (this.currentChosenHome !== null)
    {
      return this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).lot_number.toString();
    }

    return "";
  }

  GetCurrentHomeLotPremium(): string
  {
    if (this.currentChosenHome !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).lot_price_premium);
    }

    return "";
  }

  GetCurrentHomeUpgradePrice(): string
  {
    if (this.currentChosenHome !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).upgrade_price);
    }

    return "";
  }

  GetCurrentHomeGradingPrice(): string
  {
    if (this.currentChosenHome !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.data.selectedProject.lots.get(this.currentChosenHome.lot_id).grading_price);
    }

    return "";
  }

  GetCurrentHomeElevationPrice(): string
  {
    if (this.currentChosenHome !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).price);
    }

    return "";
  }

  GetCurrentHomeElevationFootage(): string
  {
    if (this.currentChosenHome !== null)
    {
      return this.data.selectedProject.elevations.get(this.currentChosenHome.elevation_id).footage.toString() + " sqft";
    }

    return "";
  }

  GetWorksheetSecurityDepositAmount(): string
  {
    if (this.selectedWorksheet !== null)
    {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.selectedWorksheet.security_deposit_amount);
    }

    return "";
  }

  GetCurrentDepositAmount(): string
  {
    if (this.currentChosenDeposit !== null)
    {
      let totalDepositAmount = 0;

      for (let strSuiteID of this.selectedWorksheet.str_unit_ids.split(", "))
      {
        let suite: Unit = this.data.selectedProject.units.get(Number(strSuiteID));
        let modeltype: ModelType = this.data.selectedProject.model_types.get(suite.model_type_id);

        totalDepositAmount += suite.orientation_premium;
        totalDepositAmount += suite.premium;
        totalDepositAmount += modeltype.price;

        for (let floor of this.data.selectedProject.floors.values())
        {
          if (floor.building_id == suite.building_id && floor.floor_number == suite.floor_number)
          {
            totalDepositAmount += floor.premium;
            break;
          }
        }
      }

      for (let strHomeID of this.selectedWorksheet.str_linker_ids.split(", "))
      {
        let linker: Linker = this.data.selectedProject.linkers.get(Number(strHomeID));
        let elevation: Elevation = this.data.selectedProject.elevations.get(linker.elevation_id);
        let lot: Lot = this.data.selectedProject.lots.get(linker.lot_id);

        totalDepositAmount += elevation.price;
        totalDepositAmount += lot.lot_price_premium;
      }

      if (totalDepositAmount == 0) 
      {         
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalDepositAmount); 
      }   

      let fixedDepositPayment: number = (totalDepositAmount / this.selectedWorksheet.deposit_percentage) / (this.data.selectedProject.worksheetDeposits.get(this.selectedWorksheet.worksheet_id).length - 1);

      if (this.currentChosenDeposit.deposit_number == 1)
      {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.selectedWorksheet.security_deposit_amount);
      }
      else if (this.currentChosenDeposit.deposit_number == 2)
      {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fixedDepositPayment - this.selectedWorksheet.security_deposit_amount)
      }
      else
      {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fixedDepositPayment);
      }
    }

    return "";
  }

  GetCurrentDepositDueDate(): string
  {
    if (this.currentChosenDeposit !== null)
    {
      return moment().add(this.currentChosenDeposit.days_until_due, 'days').format("DD/MM/YYYY");
    }

    return "";
  }

  GetWorksheetPurchaserNames(): string
  {
    if (this.selectedWorksheet !== null)
    {
      let purchaserNames: string = "";

      for (let purchaserID of this.selectedWorksheet.str_purchaser_ids.split(", "))
      {
        if (this.data.selectedProject.purchasers.has(purchaserID))
        {
          purchaserNames += this.data.selectedProject.purchasers.get(purchaserID).full_name + ", ";
        }
      }

      return purchaserNames.slice(0, -2);
    }

    return "";
  }

  ShouldDisplayPurchaserDriverLicenseField(): boolean
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.id_type == EIdentificationType.DriverLicense;
    }

    return false;
  }

  ShouldDisplayPurchaserPassportField(): boolean
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.id_type == EIdentificationType.Passport;
    }

    return false;
  }

  GetCurrentPurchaserName(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.full_name;
    }

    return "";
  }

  GetCurrentPurchaserBirthDate(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.date_of_birth.format("MM/DD/YYYY");
    }

    return "";
  }

  GetCurrentPurchaserAddress(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.address;
    }

    return "";
  }

  GetCurrentPurchaserPostalCode(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.postal_code;
    }

    return "";
  }

  GetCurrentPurchaserCity(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.city;
    }

    return "";
  }

  GetCurrentPurchaserProvince(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.province;
    }

    return "";
  }

  GetCurrentPurchaserCountry(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.country;
    }

    return "";
  }

  GetCurrentPurchaserPhoneNumber(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.phone;
    }

    return "";
  }

  GetCurrentPurchaserEmailAddress(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.email;
    }

    return "";
  }

  GetCurrentPurchaserSIN(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.sin_number;
    }

    return "";
  }

  GetCurrentPurchaserIDNumber(): string
  {
    if (this.currentChosenPurchaser !== null)
    {
      return this.currentChosenPurchaser.id_number;
    }

    return "";
  }

  GetWorksheetAgentName(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.agent_full_name;
    }

    return "";
  }

  GetWorksheetAgentEmail(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.agent_email;
    }

    return "";
  }

  GetWorksheetAgentPhone(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.agent_phone;
    }

    return "";
  }

  GetWorksheetBrokerageName(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.brokerage_name;
    }

    return "";
  }

  GetWorksheetBrokerageAddress(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.brokerage_address;
    }

    return "";
  }

  GetWorksheetBrokerageCity(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.brokerage_city;
    }

    return "";
  }

  GetWorksheetBrokerageProvince(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.brokerage_province;
    }

    return "";
  }

  GetWorksheetBrokeragePostalCode(): string
  {
    if (this.selectedWorksheet != null)
    {
      return this.selectedWorksheet.brokerage_postal_code;
    }

    return "";
  }
//#endregion
}


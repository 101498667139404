import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ChangeDetectorRef, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { Project, Lot, EAvailabilityCode, Linker } from 'src/DataModels';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CreateLotDialogComponent } from '../dialog/create-lot-dialog/create-lot-dialog.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of } from 'rxjs';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { FormControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LotsBaseTableViewComponent } from '../lots-base-table-view/lots-base-table-view.component'
import { viewClassName } from '@angular/compiler';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: LotsTableViewComponent }],
  selector: 'app-lots-table-view',
  templateUrl: './lots-table-view.component.html',
  styleUrls: ['./lots-table-view.component.css'],
})
export class LotsTableViewComponent implements OnInit, AfterViewInit
{
  @ViewChild(LotsBaseTableViewComponent,{static:false}) lotsBaseTableView: LotsBaseTableViewComponent;
  
  public lotsDataSource: MatTableDataSource<Lot> = new MatTableDataSource<Lot>();
  
  public masterAvailabilitySetField: FormControl = new FormControl();

  public availabilityFilterField: FormControl = new FormControl();
  public associatedHomeFilterField: FormControl = new FormControl();
  public elevationFilterField: FormControl = new FormControl();
  public mirrorFilterField: FormControl = new FormControl();
  public exteriorEnhancementFilterField: FormControl = new FormControl();

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project()
  {
    return this._project;
  }
  


  constructor(@Host() public parent: ProjectDetailsTableViewComponent, private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef)
  {  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }

  public GetAssociatedHomesArray(): Set<string>
  {
    if (this.project.homes != null && this.project.homes.size > 0)
    {
      let allHomeNames: Set<string> = new Set<string>();

      for (let home of this.project.homes.values())
      {
        allHomeNames.add(home.name);
      }

      return allHomeNames;
    }
    else
    {
      return null;
    }
  }

  public GetElevationTypesArray(): Set<string>
  {
    if (this.project.elevations != null && this.project.elevations.size > 0)
    {
      let allElevationTypes: Set<string> = new Set<string>();

      for (let elevation of this.project.elevations.values())
      {
        allElevationTypes.add(elevation.elevation);
      }

      return allElevationTypes;
    }
    else
    {
      return null;
    }
  }

  public ClearLotsFilter()
  {
    this.elevationFilterField.setValue(null);
    this.availabilityFilterField.setValue(null);
    this.associatedHomeFilterField.setValue(null);
    this.exteriorEnhancementFilterField.setValue(null);
    this.mirrorFilterField.setValue(null);

    this.lotsBaseTableView.ClearLotsFilter();
  }

  public ApplyLotsTableFilter()
  {
    if (this.lotsDataSource == null) { return; }

    let filterDataDict = {};
    filterDataDict["elevation"] = this.elevationFilterField.value;
    filterDataDict["availability"] = this.availabilityFilterField.value;
    filterDataDict["associatedHome"] = this.associatedHomeFilterField.value;
    filterDataDict["mirror"] = this.mirrorFilterField.value ? true : null;
    filterDataDict["exteriorEnhancement"] = this.exteriorEnhancementFilterField.value ? true : null;

    let strFilterData: string = JSON.stringify(filterDataDict);
    this.lotsBaseTableView.ApplyLotsTableFilter(filterDataDict)
  }

  public RenderTableRows() {
    this.lotsBaseTableView.RenderTableRows();
  }

  ngOnInit() { }

  ngAfterViewInit() { }

  SetAllLotsAvailability(availability: Number): void
  {
    if (this.project.lots.size == 0) { return; }

    let updateInfoDict: Map<string, any> = new Map<string, any>();
    updateInfoDict.set("availability", availability);

    let lotUpdateCallbacks: Array<Observable<Lot>> = new Array<Observable<Lot>>();
    let linkerUpdateCallbacks: Array<Observable<Linker>> = new Array<Observable<Linker>>();

    for (let lot of this.project.lots.values())
    {
      lotUpdateCallbacks.push(this.projectsService.UpdateProjectLot(this.project.project_id, lot.lot_id, updateInfoDict));

      if (this.project.linkers.size > 0)
      {
        for (let linker of this.project.linkers.values())
        {
          if (linker.lot_id === lot.lot_id)
          {
            linkerUpdateCallbacks.push(this.projectsService.UpdateProjectLinker(this.project.project_id, linker.linker_id, updateInfoDict));

            if (availability == EAvailabilityCode.Sold)
              break;
          }
        }
      }
      else
      {
        linkerUpdateCallbacks.push(of(null));
      }
    }

    forkJoin([forkJoin(...lotUpdateCallbacks), forkJoin(...linkerUpdateCallbacks)]).subscribe(results => 
    {
      if (results[0] !== null)
      {
        for (let updatedLot of results[0].values())
        {
          if (updatedLot !== null)
          {
            this.project.lots.set(updatedLot.lot_id, updatedLot);
          }
        }
      }

      if (results[1] !== null)
      {
        for (let updatedLinker of results[1].values())
        {
          if (updatedLinker !== null)
          {
            this.project.linkers.set(updatedLinker.linker_id, updatedLinker);
          }
        }
      }

      this.appUtilityService.StoreCMSData();
      this.lotsBaseTableView.RenderTableRows();
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateLotDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewLot(this.project.project_id, result).subscribe((newLot: Lot) => 
        {
          if (newLot != null)
          {
            this.project.lots.set(newLot.lot_id, newLot);
            this.appUtilityService.StoreCMSData();
            this.lotsBaseTableView.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.lotsBaseTableView.selectedLotIDs.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  
  OnDeleteButtonPressed(): void
  {
    if (this.lotsBaseTableView.selectedLotIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Lots?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteLotsFromProject(this.project.project_id, Array.from(this.lotsBaseTableView.selectedLotIDs)).subscribe((updatedLots: Map<number, Lot>) => 
          {
            if (updatedLots != null)
            {
              this.project.lots = updatedLots;
              this.appUtilityService.StoreCMSData();
              this.lotsBaseTableView.selectedLotIDs.clear();
              
              this.lotsBaseTableView.RenderTableRows();
            }
          });
        }
      });
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { Model, Project, Building } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';
import { EArchitectureType } from 'src/DataModels';
import { LinkModelBuildingsDialogComponent } from '../link-model-buildings-dialog/link-model-buildings-dialog.component';

export interface CreateModelDialogData
{
  selectedProject: Project;
  modelToEdit: Model;
}

@Component({
  selector: 'app-create-model-dialog',
  templateUrl: './create-model-dialog.component.html',
  styleUrls: ['./create-model-dialog.component.css']
})
export class CreateModelDialogComponent implements OnInit 
{
  public nameField: FormControl = new FormControl('');
  public architectureTypeField: FormControl = new FormControl('');

  protected selectedBuildings: Array<Building> = null;

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<CreateModelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateModelDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public GetModalViewTitle(): string
  {
    if (this.data.modelToEdit != null)
    {
      return "Edit Model";
    }
    else
    {
      return "Create Model";
    }
  }

  ngOnInit() 
  {
    this.nameField.setValidators([Validators.required]);

    if (this.data.modelToEdit != null)
    {
      this.nameField.setValue(this.data.modelToEdit.name);
      this.architectureTypeField.setValue(this.data.modelToEdit.architecture_type);
    }
    else
    {
      this.architectureTypeField.setValue(EArchitectureType.condo);
    }
  }

  public GetNameFieldError(): string
  {
    if (this.nameField.hasError('required'))
    {
      return "Please enter a name for this model";
    }
    else if (this.nameField.hasError('unique'))
    {
      return this.nameField.value + " is already taken";
    }
  }

  public OnLinkBuildingsButtonClicked(): void
  {
    let buildingsDialog: MatDialogRef<LinkModelBuildingsDialogComponent> = null;

    if (this.selectedBuildings !== null && this.selectedBuildings.length > 0)
      buildingsDialog = this.dialog.open(LinkModelBuildingsDialogComponent, {panelClass: "worksheets-dialog", width: '350px', height: '400px', data: { selectedProject: this.data.selectedProject, selectedBuildings: this.selectedBuildings }});
    else if (this.data.modelToEdit !== null)
      buildingsDialog = this.dialog.open(LinkModelBuildingsDialogComponent, {panelClass: "worksheets-dialog", width: '350px', height: '400px', data: { selectedProject: this.data.selectedProject, selectedModel: this.data.modelToEdit }});
    else
      buildingsDialog = this.dialog.open(LinkModelBuildingsDialogComponent, {panelClass: "worksheets-dialog", width: '350px', height: '400px', data: { selectedProject: this.data.selectedProject }});

    buildingsDialog.afterClosed().subscribe((buildings: Array<Building>) => 
    {
      if (buildings !== null && buildings !== undefined)
      {
        this.selectedBuildings = buildings;
      }
    });
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isModelInfoValid: boolean = true;

    if (this.nameField.value == "")
    {
      this.nameField.setErrors({required: true});
      this.nameField.markAsTouched();
      isModelInfoValid = false;
    }
    else if (this.nameField.value != "" != null)
    {
      for (let model of this.data.selectedProject.models.values())
      {
        if (this.data.modelToEdit !== null && this.data.modelToEdit == model) continue;

        if (model.name == this.nameField.value)
        {
          if (model.linked_building_ids.length > 0 && this.selectedBuildings !== null && this.selectedBuildings.length > 0)
          {
            for (let building of this.selectedBuildings)
            {
              for (let buildingID of model.linked_building_ids)
              {
                if (building.building_id == buildingID)
                {
                  this.nameField.setErrors({unique: true});
                  this.nameField.markAsTouched();

                  isModelInfoValid = false;
                  break;
                }
              }
            }
          }
          else
          {
            if (this.data.modelToEdit == null || this.data.modelToEdit.model_id != model.model_id)
            {
              this.nameField.setErrors({unique: true});
              this.nameField.markAsTouched();
              isModelInfoValid = false;
            }

            break;
          }
        }
      }
    } 

    if (isModelInfoValid)
    {
      let modelInfoDict: Map<string, any> = new Map<string, any>();
      modelInfoDict.set("name", this.nameField.value);
      modelInfoDict.set("architecture_type", this.architectureTypeField.value);

      if (this.selectedBuildings !== null && this.selectedBuildings.length > 0)
      {
        let strBuildingIds = "";

        for (let building of this.selectedBuildings)
        {
          strBuildingIds += building.building_id.toString() + ",";
        }

        strBuildingIds = strBuildingIds.substring(0, strBuildingIds.length - 1);
        modelInfoDict.set("building_ids", strBuildingIds);
      }   
      else
      {
        modelInfoDict.set("building_ids", "");
      }

      this.dialogRef.close(modelInfoDict);
    }
  }
}

import { Component, OnInit, Host, Input } from '@angular/core';
import { Project } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-worksheet-agent-details-module',
  templateUrl: './worksheet-agent-details-module.component.html',
  styleUrls: ['./worksheet-agent-details-module.component.css']
})
export class WorksheetAgentDetailsModuleComponent implements OnInit 
{
  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  public fullNameField: FormControl = new FormControl();
  public emailField: FormControl = new FormControl();
  public phoneField: FormControl = new FormControl();

  constructor(public appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
  }
}

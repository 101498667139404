import { Component, OnInit, Inject } from '@angular/core';
import { Unit, EAvailabilityCode, Project, Floor, EOrientationType } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl, Validators } from '@angular/forms';

export interface CreateUnitDialogData
{
  selectedProject: Project
  selectedUnitIDs: Array<number>;
}

@Component({
  selector: 'app-create-unit-dialog',
  templateUrl: './create-unit-dialog.component.html',
  styleUrls: ['./create-unit-dialog.component.css']
})
export class CreateUnitDialogComponent implements OnInit 
{
  public availabilityField: FormControl = new FormControl({value: EAvailabilityCode.Unavailable, disabled: !this.CanEditUnitAvailabilityStatus()});
  public buildingField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public floorField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public modelTypeField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});

  public unitIDField: FormControl = new FormControl(0);
  public suiteNumberField: FormControl = new FormControl(0);
  public facingDirectionField: FormControl = new FormControl('');
  public premiumField: FormControl = new FormControl(0);
  public isReversedField: FormControl = new FormControl(false);
  public parkingIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public lockerIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public storageIncludedField: FormControl = new FormControl({value: null, disabled: !this.CanEditUnitData()});
  public orientationField: FormControl = new FormControl(EOrientationType.E);
  public orientationPremiumField: FormControl = new FormControl(0);

  constructor(private dialogRef: MatDialogRef<CreateUnitDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateUnitDialogData, public authService: AuthService, public appUtilityService: AppUtilityService) { }

  public IsStepperLinear(): boolean
  {
    return this.data.selectedUnitIDs == null || this.data.selectedUnitIDs.length == 0;
  }

  public GetOrientationOptions(): Array<string>
  {
    let keys = Object.keys(EOrientationType);
    return keys;
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public IsAvailabilityStatusDisabled(status: string): boolean
  {
    let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
    if (projectRole != null)
    {
      switch (status)
      {
        case EAvailabilityCode[EAvailabilityCode.Sold]:
          return !projectRole.can_finalize_sales;
        default:
          return false;
      }
    }
  }

  public GetAvailabilityStatusCodeByName(status: string): number
  {
    return EAvailabilityCode[status];
  }

  public GetSelectedBuildingFloors(): Array<Floor>
  {
    if (this.buildingField.value != null)
    {
      let selectedBuildingFloors: Array<Floor> = new Array<Floor>();

      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.buildingField.value)
        {
          selectedBuildingFloors.push(floor);
        }
      }

      return selectedBuildingFloors;
    }
  }

  public CanEditUnitAvailabilityStatus(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public CanEditUnitPricingInfo(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing;
      }

      return false;
    }

    return false;
  }

  public CanEditUnitData(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.data.selectedProject)
      if (projectRole != null)
      {
        return projectRole.can_modify_unit_data;
      }

      return false;
    }

    return false;
  }

  public GetModalViewTitle(): string
  {
    if (this.data.selectedUnitIDs == null || this.data.selectedUnitIDs.length == 0)
    {
      return "Create Unit";
    }
    else if (this.data.selectedUnitIDs.length > 1)
    {
      return "Edit Units";
    }
    else if (this.data.selectedUnitIDs.length == 1)
    {
      return "Edit Unit";
    }
  }

  public IsInMultiEditMode(): boolean
  {
    return this.data.selectedUnitIDs != null && this.data.selectedUnitIDs.length > 1;
  }

  public IsInSingleEditMode(): boolean
  {
    return this.data.selectedUnitIDs != null && this.data.selectedUnitIDs.length == 1;
  }

  ngOnInit() 
  {
    this.unitIDField.setValidators([Validators.required]);
    this.suiteNumberField.setValidators([Validators.required]);
    this.facingDirectionField.setValidators([Validators.required]);
    this.premiumField.setValidators([Validators.required]);
    this.orientationField.setValidators([Validators.required]);
    this.orientationPremiumField.setValidators([Validators.required]);

    if (this.IsInSingleEditMode())
    {
      let unitToEdit: Unit = this.data.selectedProject.units.get(this.data.selectedUnitIDs[0]);
      if (unitToEdit != null)
      {
        this.availabilityField.setValue(unitToEdit.availability);
        this.buildingField.setValue(unitToEdit.building_id);
        this.floorField.setValue(unitToEdit.floor_number);
        this.modelTypeField.setValue(unitToEdit.model_type_id);

        this.unitIDField.setValue(unitToEdit.unit_id);
        this.suiteNumberField.setValue(unitToEdit.suite_number);
        this.facingDirectionField.setValue(unitToEdit.facing_direction);
        this.premiumField.setValue(unitToEdit.premium);
        this.isReversedField.setValue(unitToEdit.is_reversed);
        this.parkingIncludedField.setValue(unitToEdit.parking_included);
        this.lockerIncludedField.setValue(unitToEdit.locker_included);
        this.storageIncludedField.setValue(unitToEdit.storage_included);
        this.orientationField.setValue(unitToEdit.orientation);
        this.orientationPremiumField.setValue(unitToEdit.orientation_premium);
      }
    }
    else if (this.IsInMultiEditMode())
    {
        this.availabilityField.setValue(null);
        this.buildingField.setValue(null);
        this.floorField.setValue(null);
        this.modelTypeField.setValue(null);

        this.unitIDField.setValue(null);
        this.suiteNumberField.setValue(null);
        this.facingDirectionField.setValue(null);
        this.premiumField.setValue(null);
        this.isReversedField.setValue(null);
        this.parkingIncludedField.setValue(null);
        this.lockerIncludedField.setValue(null);
        this.storageIncludedField.setValue(null);
        this.orientationField.setValue(null);
        this.orientationPremiumField.setValue(null);
    }
  }

  public GetUnitIDFieldError(): string
  {
    if (this.unitIDField.hasError('required'))
    {
      return "Please enter a unit ID";
    }
    else if (this.unitIDField.hasError('unique'))
    {
      return this.unitIDField.value + " is already taken";
    }
  }

  public GetSuiteNumberFieldError(): string
  {
    if (this.suiteNumberField.hasError('required'))
    {
      return "Please enter a suite number";
    }
    else if (this.suiteNumberField.hasError('unique'))
    {
      return this.suiteNumberField.value + " is already taken";
    }
  }

  public GetPremiumFieldError(): string
  {
    if (this.premiumField.hasError('required'))
    {
      return "Please enter a suite premium";
    }
    else if (this.premiumField.hasError('too_low'))
    {
      return "Suite premium must be greater than 0";
    }
  }

  public GetOrientationPremiumFieldError(): string
  {
    if (this.orientationPremiumField.hasError('required'))
    {
      return "Please enter a suite premium";
    }
    else if (this.orientationPremiumField.hasError('too_low'))
    {
      return "Suite premium must be greater than 0";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isUnitInfoValid: boolean = true;

    // Only need to validate Suite Number field, as it is only active when creating or editing single units
    if (!this.IsInMultiEditMode() && this.IsInSingleEditMode())
    {
      let unitToEdit: Unit = this.data.selectedProject.units.get(this.data.selectedUnitIDs[0]);

      for (let unit of this.data.selectedProject.units.values())
      {
        if (unit.suite_number == this.suiteNumberField.value && unit.building_id == this.buildingField.value)
        {
          if (unitToEdit == null || unitToEdit.unit_id != unit.unit_id)
          {
            this.suiteNumberField.setErrors({unique: true});
            this.suiteNumberField.markAsTouched();
            isUnitInfoValid = false;
          }

          break;
        }
      }
    }

    if (this.IsInSingleEditMode())
    {
      let unitToEdit: Unit = this.data.selectedProject.units.get(this.data.selectedUnitIDs[0]);

      for (let unit of this.data.selectedProject.units.values())
      {
        if (unit.unit_id == this.unitIDField.value && unit != unitToEdit)
        {
          // Unit IDs must be unique across all units in the project
          this.unitIDField.setErrors({unique: true});
          this.unitIDField.markAsTouched();
          isUnitInfoValid = false;

          break;
        }
      }
    }
    else if (!this.IsInSingleEditMode() && !this.IsInMultiEditMode())
    {
      if (this.data.selectedProject.units.has(this.unitIDField.value))
      {
        this.unitIDField.setErrors({unique: true});
        this.unitIDField.markAsTouched();
        isUnitInfoValid = false;
      }
    }

    if (this.premiumField.value < 0)
    {
      this.premiumField.setErrors({too_low: true});
      this.premiumField.markAsTouched();
      isUnitInfoValid = false;
    }

    if (this.orientationPremiumField.value < 0)
    {
      this.orientationPremiumField.setErrors({too_low: true});
      this.orientationPremiumField.markAsTouched();
      isUnitInfoValid = false;
    }

    if (isUnitInfoValid)
    {
      let unitInfoDict: Map<string, any> = new Map<string, any>();

      unitInfoDict.set("unit_id", this.unitIDField.value);
      unitInfoDict.set('suite_number', this.suiteNumberField.value);
      unitInfoDict.set('building_id', this.buildingField.value);
      unitInfoDict.set('floor_number', this.floorField.value);
      unitInfoDict.set('model_type_id', this.modelTypeField.value);
      unitInfoDict.set('facing_direction', this.facingDirectionField.value);
      unitInfoDict.set('premium', this.premiumField.value);
      unitInfoDict.set('is_reversed', this.isReversedField.value);
      unitInfoDict.set('parking_included', this.parkingIncludedField.value);
      unitInfoDict.set('locker_included', this.lockerIncludedField.value);
      unitInfoDict.set('storage_included', this.storageIncludedField.value);
      unitInfoDict.set('orientation', this.orientationField.value);
      unitInfoDict.set('orientation_premium', this.orientationPremiumField.value);
      unitInfoDict.set('availability', this.availabilityField.value);
      
      this.dialogRef.close(unitInfoDict);
    }
  }
}
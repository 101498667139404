import { Component, OnInit, Host, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ProjectSettingsDialogComponent } from '../project-settings-dialog/project-settings-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ContractTemplate } from 'src/DataModels';
import { ProjectsService } from 'src/app/projects.service';
import { CreateContractDialogComponent } from '../../create-contract-dialog/create-contract-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../../generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-contract-settings-tab',
  templateUrl: './contract-settings-tab.component.html',
  styleUrls: ['./contract-settings-tab.component.css']
})
export class ContractSettingsTabComponent implements OnInit
{
  constructor(@Host() public parent: ProjectSettingsDialogComponent, protected dialog: MatDialog, private projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef) { }

  public selectedTemplateIDs: Set<number>;

  ngOnInit() 
  {
    this.selectedTemplateIDs = new Set<number>();
  }

  TrimContractName(contract: ContractTemplate): string
  {
    if (contract.contract_template_pdf_name.endsWith(".pdf"))
    {
      return contract.contract_template_pdf_name.slice(0, -4);
    }
    else
    {
      return contract.contract_template_pdf_name;
    }
  }

  FormatDateCreated(contract: ContractTemplate): string
  {
    if (contract.date_created != null)
    {
      let timestamp = moment(contract.date_created);
      return timestamp.format("LLLL");
    }

    return "";
  }

  public GetContractTemplatesIterator(): Array<ContractTemplate>
  {
    if (this.parent.data.project.contract_templates != null && this.parent.data.project.contract_templates.size > 0)
    {
      return Array.from(this.parent.data.project.contract_templates.values());
    }

    return null;
  }

  public ShouldDisplayDeleteButton(): boolean
  {
    return this.selectedTemplateIDs.size > 0;
  }

  public OnTemplateCheckboxChanged(checked: boolean, template: ContractTemplate)
  {
    if (checked)
    {
      this.selectedTemplateIDs.add(template.contract_template_id);
    }
    else
    {
      this.selectedTemplateIDs.delete(template.contract_template_id);
    }
  }

  public OnAddButtonPressed(): void
  {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, { panelClass: 'worksheets-dialog', width: '250px', height: '250px', data: { selectedProject: this.parent.data.project, showContractFilePicker: true } });

    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result == true)
      {
      }
    });
  }

  public OnEditButtonPressed(template: ContractTemplate): void
  {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, { panelClass: 'worksheets-dialog', width: '250px', height: '250px', data: { selectedProject: this.parent.data.project, selectedContractTemplate: template } });

    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result == true)
      {
      }
    });
  }

  public OnDeleteButtonPressed(): void
  {
    if (this.selectedTemplateIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Contract Templates?" }});
    
      dialogRef.afterClosed().subscribe((result: boolean) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteContractTemplates(this.parent.data.project, Array.from(this.selectedTemplateIDs.values())).subscribe((result: boolean) => 
          {
            if (result == true)
            {
            }
          });
        }
      });
    }
  }
}

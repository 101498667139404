import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginScreenComponent } from '../login-screen/login-screen.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { CompanyProjectsTableViewComponent } from '../company-projects-table-view/company-projects-table-view.component';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { CompanyRolesTableViewComponent } from '../company-roles-table-view/company-roles-table-view.component';
import { UsersTableViewComponent } from '../users-table-view/users-table-view.component';
import { EUserType } from '../../DataModels';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginScreenComponent 
  },
  {
    path: 'projectroles',
    component: CompanyRolesTableViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.all]},
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'users',
    component: UsersTableViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.all]},
    runGuardsAndResolvers: 'always',
  },
  { 
    path: 'projects', 
    component: CompanyProjectsTableViewComponent, 
    canActivate: [AuthGuard], 
    data: { allowedRoles: [EUserType.all] },
    runGuardsAndResolvers: 'always', 
  },
  {
    path: 'project/:projectID',
    component: ProjectDetailsTableViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [EUserType.all] },
    runGuardsAndResolvers: 'always',
  },
  { 
    path: 'admin',
    loadChildren: () => import('../admin/admin-routing/admin-routing.module').then(mod => mod.AdminRoutingModule),
    canLoad: [AuthGuard],
    data: { allowedRoles: [EUserType.neezo] },
    runGuardsAndResolvers: 'always',
  },
  // { 
  //   path: '', 
  //   redirectTo: '/login', 
  //   pathMatch: 'full' 
  // }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {initialNavigation: 'disabled', onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { AuthService } from 'src/app/auth.service';
import { Floor, Project } from 'src/DataModels';
import { FormControl, Validators } from '@angular/forms';

export interface CreateFloorsDialogData
{
  selectedProject: Project;
  selectedBuildingID: number,
  floorToEdit: Floor
}

@Component({
  selector: 'app-create-floors-dialog',
  templateUrl: './create-floors-dialog.component.html',
  styleUrls: ['./create-floors-dialog.component.css']
})
export class CreateFloorsDialogComponent implements OnInit, AfterViewInit
{
  public floorNumberField: FormControl = new FormControl(0);
  public premiumField: FormControl = new FormControl(0);

  constructor(private dialogRef: MatDialogRef<CreateFloorsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateFloorsDialogData, public appUtilityService: AppUtilityService, public authService: AuthService) { }

  public GetModalViewTitle(): string
  {
    if (this.data.floorToEdit != null)
    {
      return "Edit Floor";
    }
    else
    {
      return "Create Floor";
    }
  }

  ngOnInit() 
  {
    this.floorNumberField.setValidators([Validators.required]);
    this.premiumField.setValidators([Validators.required]);

    if (this.data.floorToEdit != null)
    {
      this.floorNumberField.setValue(this.data.floorToEdit.floor_number);
      this.premiumField.setValue(this.data.floorToEdit.premium);
    }
  }

  ngAfterViewInit()
  {
  }

  public GetPremiumFieldError(): string
  {
    if (this.premiumField.hasError('required'))
    {
      return "Please enter a floor price premium";
    }
    else if (this.premiumField.hasError('too_low'))
    {
      return "Floor premium must be greater than 0";
    }
  }

  public GetFloorNumberFieldError(): string
  {
    if (this.floorNumberField.hasError('required'))
    {
      return "Please enter a floor number";
    } 
    else if (this.floorNumberField.hasError('too_low'))
    {
      return "Floor number must be greater than 0";
    } 
    else if (this.floorNumberField.hasError("unique"))
    {
      return "Floor " + this.floorNumberField.value + " is not unique in this building";
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let isFloorInfoValid: boolean = true;

    if (this.floorNumberField.value <= 0)
    {
      this.floorNumberField.setErrors({too_low: true});
      this.floorNumberField.markAsTouched();
      isFloorInfoValid = false;
    }
    else
    {
      for (let floor of this.data.selectedProject.floors.values())
      {
        if (floor.building_id == this.data.selectedBuildingID && floor.floor_number == this.floorNumberField.value)
        {
          // If we're currently editing a floor, then it's not an issue if
          // the floor number is left the same.
          if (this.data.floorToEdit == null || this.data.floorToEdit.floor_id != floor.floor_id)
          {
            this.floorNumberField.setErrors({unique: true});
            this.floorNumberField.markAsTouched();
            isFloorInfoValid = false; 
          }
          
          break;
        }
      }
    }

    if (this.premiumField.value < 0)
    {
      this.premiumField.setErrors({too_low: true});
      this.premiumField.markAsTouched();
      isFloorInfoValid = false;
    }

    if (isFloorInfoValid)
    {
      let responseDict: Map<string, any> = new Map<string, any>();
      responseDict.set('building_id', this.data.selectedBuildingID);
      responseDict.set("floor_number", this.floorNumberField.value);
      responseDict.set('premium', this.premiumField.value);

      this.dialogRef.close(responseDict);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User, WebCMSAPIResponse, EUserType } from 'src/DataModels';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class UsersService
{
  constructor(private http: HttpClient, private appConstants: AppConstantsService) { }

  /*
    Attempts to create a new user on the database using the following parameters, and if successful, returns a User istance representing that new user
  */
  public CreateNewUser(username: string, email: string, password: string, companyID: string, userRoleID: string): Observable<User>
  {
    let params = {"username": username, "email": email, "password": password, "companyID": companyID, "userRoleID": userRoleID };

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`, params).pipe(map((response: WebCMSAPIResponse) =>
    {
      if (response == null) { return null; }

      if (response.status == "success")
      {
        let userInfoFromPacket = JSON.parse(response.data);
        let user: User = new User(userInfoFromPacket);
        return user;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return null;
    }));
  }

  public UpdateUserAccountInfo(userID: string, username?: string, email?: string, password?: string, companyID?: string, userRoleID?: string): Observable<User>
  {
    if (userID === null)
    {
      throw new Error("ERROR: Use of UpdateUserAccountInfo requires the userID followed by any amount of username, password, companyID, and user type");
    }
    if (username === null && email === null && password === null && companyID === null && userRoleID === null)
    {
      throw new Error("ERROR: Use of UpdateUserAccountInfo requires the userID followed by any amount of username, password, companyID, and user type");
    }
    else
    {
      let params = { "userID": userID };
      if (username != null) { params["username"] = username; }
      if (email != null) { params["email"] = email; }
      if (password != null) { params["password"] = password; }
      if (companyID != null) { params["companyID"] = companyID; }
      if (userRoleID != null) { params["userRoleID"] = userRoleID; }

      return this.http.put<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`, params).pipe(map((response: WebCMSAPIResponse) =>
      {
        if (response == null) { return null; }

        if (response.status == "success")
        {
          let userInfoFromPacket = JSON.parse(response.data);
          let user: User = new User(userInfoFromPacket);
          return user;
        }
        else if (response.status == "error")
        {
          console.log(response);
        }

        return null;
      }));
    }
  }

  public DeleteUserAccounts(companyID: string, selectedUserIDs: Array<string>): Observable<boolean>
  {
    let params = {"companyID": companyID, "usersSelection": JSON.stringify(selectedUserIDs)};

    return this.http.delete<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`, {"params": params}).pipe(map((response: WebCMSAPIResponse) =>
    {
      if (response == null) { return false; }
      return response.status == "success";
    }));
  }

  public GetAllUsers(): Observable<Map<string, User>>
  {
    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`).pipe(map((response: WebCMSAPIResponse) =>
    {
      return this.ParseGetUsersResponse(response);
    }));
  }

  public GetUsersByProjectID(projectID: string): Observable<Map<string, User>>
  {
    let params = new HttpParams().set("projectID", projectID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`, {"params": params}).pipe(map((response: WebCMSAPIResponse) =>
    {
      return this.ParseGetUsersResponse(response);
    }));
  }

  public GetUsersByCompanyID(companyID: string): Observable<Map<string, User>>
  {
    let params = new HttpParams().set("companyID", companyID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/users`, {"params": params}).pipe(map((response: WebCMSAPIResponse) =>
    {
      return this.ParseGetUsersResponse(response);
    }));
  }

  /*
    Parses the response of any of the GetUsers methods and generates User objects
    for any and all users found by these methods.
  */
  private ParseGetUsersResponse(response: WebCMSAPIResponse): Map<string, User>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let usersDict: Map<string, User> = new Map<string, User>();
      let responseDataJSON = JSON.parse(response.data);

      for (let userJSON of responseDataJSON)
      {
        let userInfoFromPacket = JSON.parse(userJSON);
        let user: User = new User(userInfoFromPacket);
        usersDict.set(user.user_id, user);
      }

      return usersDict;
    }
    else if (response.status == "error")
    {
      // TODO - More intelligent error handling / in-app presentation?
      console.log(response);
    }

    return null;
  }
}

import { Component, OnInit, Inject, ViewChild, QueryList, AfterContentInit, OnChanges, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetailsFormComponent } from 'src/app/user-details-form/user-details-form.component';
import { User } from 'src/DataModels';
import { AuthService } from 'src/app/auth.service';

export interface CreateUserDialogData
{
  userToEdit: User
}

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.css']
})
export class CreateUserDialogComponent implements AfterViewInit
{
  @ViewChild(UserDetailsFormComponent, {static: false}) userDetailsForm !: UserDetailsFormComponent;

  constructor(private dialogRef: MatDialogRef<CreateUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: CreateUserDialogData, public authService: AuthService) { }

  public GetModalViewTitle(): string
  {
    if (this.data.userToEdit != null)
    {
      if (this.authService.activeUserRole.can_edit_users)
      {
        return "Edit User";
      }
      else
      {
        return "User Details";
      }
    }
    else
    {
      return "New User";
    }
  }

  ngAfterViewInit()
  {
    if (this.data.userToEdit != null)
    {
      this.userDetailsForm.SetEditMode(true);

      this.userDetailsForm.SetUsername(this.data.userToEdit.username);
      this.userDetailsForm.SetEmail(this.data.userToEdit.email);
      this.userDetailsForm.SetUserRoleID(this.data.userToEdit.user_role_id);

      this.userDetailsForm.changeDetectorRef.detectChanges();
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    let userDetailsDict: Map<string, any> = new Map<string, any>();
    userDetailsDict.set("username", this.userDetailsForm.GetUsername());
    userDetailsDict.set("email", this.userDetailsForm.GetEmail());
    userDetailsDict.set("userRoleID", this.userDetailsForm.GetUserRoleID());

    if (this.data.userToEdit != null && this.userDetailsForm.GetPassword() != "")
    {
      userDetailsDict.set("password", this.userDetailsForm.GetPassword());
    }
    else if (this.data.userToEdit == null)
    {
      userDetailsDict.set("password", this.userDetailsForm.GetPassword());
    }

    this.dialogRef.close(userDetailsDict);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppUtilityService } from 'src/app/app-utility.service';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-settings-view',
  templateUrl: './settings-view.component.html',
  styleUrls: ['./settings-view.component.css']
})
export class SettingsViewComponent implements OnInit 
{
  public salesfishURLField: FormControl = new FormControl("");

  constructor(public appUtilityService: AppUtilityService, private settingsService: SettingsService) { }

  ngOnInit() 
  {
    this.salesfishURLField.setValue(this.appUtilityService.currentSalesfishWebcallPath);
  }

  public OnSubmitButtonPressed()
  {
    if (this.salesfishURLField.value != this.appUtilityService.currentSalesfishWebcallPath)
    {
      this.settingsService.SetSalesfishWebcallPath(this.salesfishURLField.value).subscribe((result: string) => 
      {
        if (result != null)
        {
          this.appUtilityService.currentSalesfishWebcallPath = result;
        }
      });
    }
  }
}

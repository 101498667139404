import { Component, OnInit, Inject } from '@angular/core';
import { Project } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectsService } from 'src/app/projects.service';
import { AppUtilityService } from 'src/app/app-utility.service';

export interface ProjectSettingsDialogData
{
  project: Project
}

@Component({
  selector: 'app-project-settings-dialog',
  templateUrl: './project-settings-dialog.component.html',
  styleUrls: ['./project-settings-dialog.component.css']
})
export class ProjectSettingsDialogComponent implements OnInit 
{
  public saveButtonBehaviourCallback: () => Map<string, any> = null;

  public shouldDisplayFooterButtons: boolean = true;

  constructor(public dialogRef: MatDialogRef<ProjectSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ProjectSettingsDialogData, private projectsService: ProjectsService, private appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
  }

  public OnSettingsTabIndexChanged(tabIndex: number): void
  {
    switch (tabIndex)
    {
      case 2:
        this.shouldDisplayFooterButtons = false;
        break;
      default:
        this.shouldDisplayFooterButtons = true;
        break;
    }
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked()
  {
    if (this.saveButtonBehaviourCallback != null)
    {
      let settingsUpdateDict: Map<string, any> = this.saveButtonBehaviourCallback();
      if (settingsUpdateDict !== null && settingsUpdateDict.size > 0)
      {
        this.projectsService.UpdateProjectProductTypesAndModules(this.data.project.project_id, settingsUpdateDict).subscribe((updatedProject: Project) => 
        {
          if (updatedProject != null)
          {
            this.data.project.should_display_pricing = updatedProject.should_display_pricing;
            this.data.project.should_display_unit_availability = updatedProject.should_display_unit_availability;
            this.data.project.require_login_screen = updatedProject.require_login_screen;

            this.data.project.uses_worksheets_parking_field = updatedProject.uses_worksheets_parking_field;
            this.data.project.uses_worksheets_locker_field = updatedProject.uses_worksheets_locker_field;
            this.data.project.uses_worksheets_international_status_field = updatedProject.uses_worksheets_international_status_field;
            this.data.project.uses_worksheets_id_type_field = updatedProject.uses_worksheets_id_type_field;
            this.data.project.uses_worksheets_upload_photo_id_field = updatedProject.uses_worksheets_upload_photo_id_field;
            this.data.project.uses_worksheets_upload_cheque_photo_field = updatedProject.uses_worksheets_upload_cheque_photo_field;
            this.data.project.uses_worksheets_notes_section_field = updatedProject.uses_worksheets_notes_section_field;
            this.data.project.uses_worksheets_deposit_structure_field = updatedProject.uses_worksheets_deposit_structure_field;

            this.data.project.default_deposit_percentage = updatedProject.default_deposit_percentage;
            this.data.project.default_security_deposit_amount = updatedProject.default_security_deposit_amount;

            this.data.project.contract_template_pdf_name = updatedProject.contract_template_pdf_name;
            this.data.project.contract_template_pdf_data = updatedProject.contract_template_pdf_data;

            this.projectsService.GetMetadataFor(this.data.project).subscribe()
            {
              this.appUtilityService.StoreCMSData();
              this.dialogRef.close();
            }
          }
        });
      }

      this.dialogRef.close();
    }
    else
    {
      this.dialogRef.close();
    }
  }
}

import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Home, Project, Elevation } from 'src/DataModels';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateHomeDialogComponent } from '../dialog/create-home-dialog/create-home-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { ImportCsvDialogComponent } from '../admin/dialog/import-csv-dialog/import-csv-dialog.component';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { ElevationsTableViewComponent } from '../elevations-table-view/elevations-table-view.component';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: HomesTableViewComponent }],
  selector: 'app-homes-table-view',
  templateUrl: './homes-table-view.component.html',
  styleUrls: ['./homes-table-view.component.css'],
})
export class HomesTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Home>;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  public homesDataSource: MatTableDataSource<Home> = new MatTableDataSource<Home>();

  private _project: Project = null;

  public expandedRow: Home = null;
  public prevExpandedRow: Home = null;

  private innerTableRef: ComponentRef<ElevationsTableViewComponent> = null;
  private innerTableRootDiv = null;

  public selectedHomeIDs: Set<number> = new Set<number>();

  public clientDisplayedColumns = ['Name', 'Number', 'Start Elevation', 'Storeys', 'Bedrooms', 'Bathrooms', 'Den', 'Frontage', 'Sticky Column'];
  public adminDisplayedColumns = ['Checkbox', 'Detached ID', 'Name', 'Number', 'Start Elevation', 'Storeys', 'Bedrooms', 'Bathrooms', 'Den', 'Frontage', 'Sticky Column'];

  @Input()
  set project(project: Project)
  {
    this._project = project;

    if (this._project.homes !== null || this._project.homes !== undefined)
      this.homesDataSource = new MatTableDataSource(Array.from(this.project.homes.values()));
  }

  get project(): Project 
  {
    return this._project;
  }

  public GetDisplayedColumns(): Array<string>
  {
    return this.authService.IsActiveUserAdmin() ? this.adminDisplayedColumns : this.clientDisplayedColumns;
  }

  public GetStartElevationType(home: Home): string
  {
    if (this.project.elevations.has(home.start_elevation))
    {
      let elevation = this.project.elevations.get(home.start_elevation);
      return elevation.elevation;
    }
    else
    {
      return "";
    }
  }

  constructor(@Host() private parent: ProjectDetailsTableViewComponent, public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  {
    super();
  }

  public RenderTableRows()
  {
    if (this.project.homes !== null || this.project.homes !== undefined)
    {
      let homes: Array<Home> = Array.from(this.project.homes.values());
      homes = homes.sort((h1, h2) => 
      {
        let h1Name = h1.name.toLowerCase();
        let h2Name = h2.name.toLowerCase();

        if (h1Name < h2Name)
        {
          return -1;
        }
        else if (h1Name > h2Name)
        {
          return 1;
        }
        else
        {
          return 0;
        }
      });

      this.homesDataSource.data = homes;
      this.table.renderRows();
    }
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit(): void 
  {
    this.RenderTableRows();
    this.InitInnerTableView();
  }

  public InitInnerTableView()
  {
    this.innerTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ElevationsTableViewComponent);
    this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);
    this.innerTableRef.instance.project = this.project;

    this.applicationRef.attachView(this.innerTableRef.hostView);
  }

  public BindInnerTableView(home: Home)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.detached_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
    }

    this.innerTableRef.instance.homeID = home.detached_id;
    let expandableRowContainer = document.getElementById('inner-table-host-' + home.detached_id);
    expandableRowContainer.appendChild(this.innerTableRootDiv);

    this.innerTableRef.instance.RenderTableRows();
  }

  public OnUploadCSVButtonPressed()
  {
    const dialogRef = this.dialog.open(ImportCsvDialogComponent, {width: '600px', height: '425px', data: { selectedProject: this.project, importRequestType: "homes" }});

    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result == true)
      {
        this.appUtilityService.StoreCMSData();
        this.RenderTableRows();

        this.parent.RefreshAllTables();
      }
    });
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let home of this.homesDataSource.filteredData)
      {
        this.selectedHomeIDs.add(home.detached_id);
      }
    }
    else
    {
      this.selectedHomeIDs.clear();
    }
  }

  public OnHomeRowCheckboxToggled(checked: boolean, homeID: number)
  {
    if (checked == true)
    {
      this.selectedHomeIDs.add(homeID);
    }
    else
    {
      this.selectedHomeIDs.delete(homeID);
    }
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_unit_data;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(home: Home)
  {
    const dialogRef = this.dialog.open(CreateHomeDialogComponent, { width: '350px', height: '500px', data: { selectedProject: this.project, homeToEdit: home } });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectHome(this.project.project_id, home.detached_id, result).subscribe((updatedHome: Home) => 
        {
          if (updatedHome != null)
          {
            this.project.homes.set(home.detached_id, updatedHome);
            this.appUtilityService.StoreCMSData();

            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateHomeDialogComponent, { width: '350px', height: '500px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe((result) =>
    {
      if (result != null)
      {
        this.projectsService.CreateNewHome(this.project.project_id, result, true).subscribe((newHomeResponse: Home | Map<string, any>) => 
        {
          if (newHomeResponse != null && newHomeResponse instanceof Map)
          {
            let newHome: Home = newHomeResponse.get("home");
            let startElevation: Elevation = newHomeResponse.get("elevation");
            
            this.project.homes.set(newHome.detached_id, newHome);
            this.project.elevations.set(startElevation.elevation_id, startElevation);
            this.appUtilityService.StoreCMSData();

            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedHomeIDs.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnDeleteButtonPressed(): void 
  {
    if (this.selectedHomeIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Homes?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteHomesFromProject(this.project.project_id, Array.from(this.selectedHomeIDs)).subscribe((updatedHomes: Map<number, Home>) => 
          {
            this.project.homes = updatedHomes;
            this.appUtilityService.StoreCMSData();
            this.selectedHomeIDs.clear();
            
            this.RenderTableRows();
          });
        }
      });
    }
  }

  HasSelectedRows(): boolean { return this.selectedHomeIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedHomeIDs; } 
  ClearSelectedRows(): void { this.selectedHomeIDs.clear(); }
  public OnTabEntered(): void{}
}

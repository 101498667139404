import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ComponentFactoryResolver, ComponentRef, Host } from '@angular/core';
import { CMSTableViewBase } from '../interfaces/cms-component-base';
import { Project, ProjectRole, Worksheet, EWorksheetStatus, Linker, Unit, Building, ModelType, Elevation, Lot, Home, Floor } from 'src/DataModels';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CreateWorksheetDialogComponent } from '../dialog/worksheets/create-worksheet-dialog/create-worksheet-dialog.component';
import { ProjectsService } from '../projects.service';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { MatSelect } from '@angular/material/select';

import html2canvas from "html2canvas";
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { CaptureDirective } from '../capture.directive';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import * as moment from 'moment';

import * as PDFAssembler from 'pdfassembler';
import * as fileSaver from 'file-saver';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: WorksheetsTableViewComponent }],
  selector: 'app-worksheets-table-view',
  templateUrl: './worksheets-table-view.component.html',
  styleUrls: ['./worksheets-table-view.component.css']
})
export class WorksheetsTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit 
{
  @ViewChild(MatTable, {static: false}) table: MatTable<Worksheet>;
  @ViewChild(CaptureDirective, {static: true}) captureHost: CaptureDirective;

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project(): Project 
  {
    return this._project;
  }

  public displayedColumns = ['Checkbox', 'Worksheet ID', 'Purchasers', 'Model Names', 'Unit Numbers', 'Home Names', 'Lot Numbers', 'Worksheet Status', 'Sticky Column'];

  public worksheetsDataSource: MatTableDataSource<Worksheet> = new MatTableDataSource<Worksheet>();
  public selectedWorksheetIDs: Set<number> = new Set<number>();

  constructor(@Host() public parent: ProjectDetailsTableViewComponent, public dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, public changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver) 
  {
    super();
  }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
    this.RenderTableRows();
  }

  GetWorksheetPurchaserNames(worksheet: Worksheet): string
  {
    if (worksheet.str_purchaser_ids !== null && worksheet.str_purchaser_ids !== "")
    {
      let strWorksheetPurchaserNames = "";

      let arrPurchaserIDs = worksheet.str_purchaser_ids.split(",");
      if (arrPurchaserIDs.length > 0)
      {
        for (let purchaserID of arrPurchaserIDs)
        {
          let purchaserIDTrim = purchaserID.trim();

          if (this.project.purchasers.has(purchaserIDTrim))
          {
            let currentPurchaserName: string = this.project.purchasers.get(purchaserIDTrim).full_name;
            if (currentPurchaserName.length > 0)
              strWorksheetPurchaserNames += this.project.purchasers.get(purchaserIDTrim).full_name + ", ";
          }
        }

        strWorksheetPurchaserNames = strWorksheetPurchaserNames.substring(0, strWorksheetPurchaserNames.length - 2);
      }

      if (strWorksheetPurchaserNames == "")
      {
        return "No Purchasers";  
      }
      else
      {
        return strWorksheetPurchaserNames;
      }
    }
    else
    {
      return "No Purchasers";
    }
  }

  GetWorksheetModelNames(worksheet: Worksheet): string
  {
    if (worksheet.str_model_ids !== null && worksheet.str_model_ids !== "")
    {
      let strModelNames = "";
      
      let arrModelIDs = worksheet.str_model_ids.split(",");
      if (arrModelIDs.length > 0)
      {
        for (let modelID of arrModelIDs)
        {
          let modelIDTrim = Number(modelID.trim());

          if (this.project.model_types.has(modelIDTrim))
          {
            strModelNames += this.project.model_types.get(modelIDTrim).name + ", ";
          }
        }

        strModelNames = strModelNames.substring(0, strModelNames.length - 2);
      }

      return strModelNames;
    }
    else
    {
      return "No Models Selected";
    }
  }

  GetWorksheetUnitNumbers(worksheet: Worksheet): string
  {
    if (worksheet.str_unit_ids !== null && worksheet.str_unit_ids !== "")
    {
      let strUnitNumbers = "";
      
      let arrUnitIDs = worksheet.str_unit_ids.split(",");
      if (arrUnitIDs.length > 0)
      {
        for (let unitID of arrUnitIDs)
        {
          let unitIDTrim = Number(unitID.trim());

          if (this.project.units.has(unitIDTrim))
          {
            strUnitNumbers += this.project.units.get(unitIDTrim).suite_number + ", ";
          }
        }

        strUnitNumbers = strUnitNumbers.substring(0, strUnitNumbers.length - 2);
      }

      return strUnitNumbers;
    }
    else
    {
      return "No Units Selected";
    }
  }

  GetWorksheetHomeNames(worksheet: Worksheet): string
  {
    if (worksheet.str_linker_ids !== null && worksheet.str_linker_ids !== "")
    {
      let strHomeNames: string = "";
      
      let arrLinkerIDs: Array<string> = worksheet.str_linker_ids.split(", ");
      for (let strLinkerID of arrLinkerIDs)
      {
        let linkerID = Number(strLinkerID);
        if (this.project.linkers.has(linkerID))
        {
          strHomeNames += this.project.homes.get(this.project.elevations.get(this.project.linkers.get(linkerID).elevation_id).detached_id).name + ", ";
        }
      }

      strHomeNames = strHomeNames.substring(0, strHomeNames.length - 2);
      return strHomeNames;
    }
    else
    {
      return "No Homes Selected";
    }
  }

  GetWorksheetLotNumbers(worksheet: Worksheet): string
  {
    if (worksheet.str_linker_ids !== null && worksheet.str_linker_ids !== "")
    {
      let strLotNumbers: string = "";
      
      let arrLinkerIDs: Array<string> = worksheet.str_linker_ids.split(", ");
      for (let strLinkerID of arrLinkerIDs)
      {
        let linkerID = Number(strLinkerID);
        if (this.project.linkers.has(linkerID))
        {
          strLotNumbers += this.project.lots.get(this.project.linkers.get(linkerID).lot_id).lot_number + ", ";
        }
      }

      strLotNumbers = strLotNumbers.substring(0, strLotNumbers.length - 2);
      return strLotNumbers;
    }
    else
    {
      return "No Lots Selected";
    }
  }

  GetWorksheetStatus(worksheet: Worksheet): string
  {
    switch (worksheet.worksheet_status)
    {
      case EWorksheetStatus.PendingApproval:
        return "Pending Approval";
      case EWorksheetStatus.Approved:
        return "Approved";
      case EWorksheetStatus.Declined:
        return "Declined";
      case EWorksheetStatus.ContractSigned:
        return "Contract Signed";
    }
  }

  RenderTableRows(): void 
  {
    if (this.project.worksheets.size > 0)
    {
      let worksheets = Array.from(this.project.worksheets.values());
      this.worksheetsDataSource.data = worksheets;

      this.table.renderRows();
      this.changeDetectorRef.detectChanges();
    }
  }

  OnSelectAllCheckboxChanged(checked: boolean): void
  {
    if (checked)
    {
      for (let worksheet of this.worksheetsDataSource.data)
      {
        this.selectedWorksheetIDs.add(worksheet.worksheet_id);
      }
    }
    else
    {
      this.selectedWorksheetIDs.clear();
    }
  }

  OnWorksheetCheckboxChanged(checked: boolean, worksheetID: number): void
  {
    if (checked == true)
    {
      this.selectedWorksheetIDs.add(worksheetID);
    }
    else
    {
      this.selectedWorksheetIDs.delete(worksheetID);
    }
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_create_worksheets;
      }
    }

    return false;
  }

  ShouldDisplayEditButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_create_worksheets;
      }
    }

    return false;
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data && this.selectedWorksheetIDs.size > 0;
    }
    // else if (!this.authService.IsActiveUserAdmin())
    // {
    //   let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
    //   if (activeProjectRole !== null)
    //   {
    //     return activeProjectRole.can_finalize_sales;
    //   }
    // }

    return false;
  }

  ShouldDisplayWorksheetStatusDropdown(): boolean
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return this.authService.activeUserRole.can_edit_projects_data;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
      if (activeProjectRole !== null)
      {
        return activeProjectRole.can_approve_worksheets;
      }
    }
  }

  OnAddButtonPressed() 
  {
    const dialogRef = this.dialog.open(CreateWorksheetDialogComponent, { panelClass: 'worksheets-dialog', width: '80%', height: '100%', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewWorksheet(this.project, result).subscribe(result => 
        {
          if (result == true)
          {
            this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: true) =>
            {
              this.appUtilityService.StoreCMSData();
              this.parent.RefreshAllTables();
            });
          }
        });
      }
    });
  }

  OnEditButtonPressed(worksheet: Worksheet) 
  {
    const dialogRef = this.dialog.open(CreateWorksheetDialogComponent, { panelClass: 'worksheets-dialog', width: '80%', height: '100%', data: { selectedProject: this.project, selectedWorksheet: worksheet } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      if (result != null)
      {
        this.projectsService.UpdateProjectWorksheetFormData(this.project, worksheet.worksheet_id, result).subscribe(result => 
        {
          if (result == true)
          {
            this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: true) =>
            {
              this.appUtilityService.StoreCMSData();
              this.parent.RefreshAllTables();
            });
          }
        });
      }
    });
  }

  OnWorksheetStatusChanged(worksheetID: number, worksheetStatusSelect: MatSelect)
  {
    let newStatus: EWorksheetStatus;

    switch (worksheetStatusSelect.value)
    {
      case "Pending Approval":
        newStatus = EWorksheetStatus.PendingApproval;
        break;
      case "Approved":
        newStatus = EWorksheetStatus.Approved;
        break;
      case "Declined":
        newStatus = EWorksheetStatus.Declined;
        break;
      case "Contract Signed":
        newStatus = EWorksheetStatus.ContractSigned;
        break;
    }

    this.projectsService.UpdateProjectWorksheetStatus(this.project, worksheetID, newStatus).subscribe((result: boolean) => 
    {
      if (result === true)
      {
        this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: true) =>
        {
          this.appUtilityService.StoreCMSData();
          this.parent.RefreshAllTables();
        });
      }
      else
      {
        switch (worksheetStatusSelect.value)
        {
          case "Pending Approval":
            worksheetStatusSelect.value = "Approved";
            break;
          case "Approved":
            worksheetStatusSelect.value = "Pending Approval";
            break;
          case "Declined":
            worksheetStatusSelect.value = "Declined";
            break;
          case "Contract Signed":
            worksheetStatusSelect.value = "Contract Signed";
            break;
        }

        this.RenderTableRows();
      }
    });
  }

  OnDeleteButtonPressed() 
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Worksheets?" }});
    
    dialogRef.afterClosed().subscribe((result: boolean) => 
    {
      if (result === true)
      {
        this.projectsService.DeleteWorksheetsFromProject(this.project, Array.from(this.selectedWorksheetIDs.values())).subscribe((result: boolean) => 
        {
          if (result === true)
          {
            this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: boolean) =>
            {
              this.appUtilityService.StoreCMSData();
              this.parent.RefreshAllTables();
            });
          }
        });
      }
    });
  }

  HasSelectedRows(): boolean { return this.selectedWorksheetIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedWorksheetIDs; }
  ClearSelectedRows(): void { this.selectedWorksheetIDs.clear(); }

  OnDownloadWorksheetButtonPressed(worksheet: Worksheet)
  {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CreateWorksheetDialogComponent);
    
    const viewContainerRef = this.captureHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: ComponentRef<CreateWorksheetDialogComponent> = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.data = { selectedProject: this.project, selectedWorksheet: worksheet, selectedSuite: null, selectedHome: null, isInExportMode: true };
    componentRef.instance.OnDialogLoadComplete.subscribe(() => 
    {  
      const dialogRef = document.getElementById("worksheet-dialog");
      dialogRef.style.overflow = "visible";
      dialogRef.style.position = "absolute";
      dialogRef.style.right = "1000px";    
      dialogRef.style.width = "900px";

      let tempProject: Project = this.project;

      let totalWorksheetHeight: number = dialogRef.clientHeight;
      for (let i = 0; i < dialogRef.children.length; ++i)
      {
        totalWorksheetHeight += dialogRef.children[i].clientHeight;
      }

      dialogRef.style.height = totalWorksheetHeight.toString() + "px";

      html2canvas(dialogRef, {allowTaint: true, useCORS: true, logging: false}).then(function(canvas)
      {
        var pdf = new jsPDF('p', 'pt', 'letter');

        for (let i = 0; i < totalWorksheetHeight / 980; ++i)
        {
          var srcImg = canvas;
          var sX = 0;
          var sY = 980 * i;
          var sWidth = 900;
          var sHeight = 980;
          var dX = 0;
          var dY = 0;
          var dWidth = 900;
          var dHeight = 980;

          var onePageCanvas = document.createElement("canvas");
          onePageCanvas.setAttribute('width', '900');
          onePageCanvas.setAttribute('height', '980');

          var ctx = onePageCanvas.getContext('2d');
          ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

          var canvasDataURL = onePageCanvas.toDataURL();

          var width = onePageCanvas.width;
          var height = onePageCanvas.clientHeight;

          if (i > 0)
          {
            pdf.addPage(612, 791);
          }

          pdf.setPage(i + 1);
          pdf.addImage(canvasDataURL, 'PNG', 20, 40, width * .62, height * .62);
        }

        let worksheetFileName: string = "Worksheet_" + tempProject.project_name;

        for (let strUnitID of worksheet.str_unit_ids.split(", "))
        {
          worksheetFileName += "_Unit" + strUnitID;
        }

        for (let strLinkerID of worksheet.str_linker_ids.split(", "))
        {
          let linkerID = Number(strLinkerID);
          
          let homeLinker: Linker = tempProject.linkers.get(linkerID);
          if (homeLinker)
          {
            worksheetFileName += "_Lot" + homeLinker.lot_id.toString();
          }
        }

        pdf.save(worksheetFileName + ".pdf");
      });
    });
  }
}

import { Component, OnInit, Host, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Unit, EInternationalStatus, Project, Linker } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-worksheet-preferences-module',
  templateUrl: './worksheet-preferences-module.component.html',
  styleUrls: ['./worksheet-preferences-module.component.css']
})
export class WorksheetPreferencesModuleComponent implements OnInit 
{
  @ViewChild('internationalSelect', {static: false}) public internationalSelect: MatSelect;

  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }

  private _maxSuites: number = 3;

  @Input()
  set MaxSuites(maxSuites: number)
  {
    if (maxSuites >= 1)
      this._maxSuites = maxSuites;
  }

  get MaxSuites()
  {
    return this._maxSuites;
  }

  private _maxHomes: number = 3;

  @Input()
  set MaxHomes(maxHomes: number)
  {
    if (maxHomes >= 1)
      this._maxHomes = maxHomes;
  }

  get MaxHomes()
  {
    return this._maxHomes;
  }

  private _internationalStatus: EInternationalStatus = null;

  public set InternationalStatus(internationalStatus: EInternationalStatus)
  {
    this._internationalStatus = internationalStatus;
  }

  public get InternationalStatus()
  {
    return this._internationalStatus;
  }

  public currentChosenSuites: Array<Unit> = new Array<Unit>();
  public currentChosenHomes: Array<Linker> = new Array<Linker>();

  constructor(public appUtilityService: AppUtilityService, public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() 
  {
  }

  public parkingField: FormControl = new FormControl(false);
  public lockerField: FormControl = new FormControl(false);

  public OnAddSuiteButtonPressed(): void
  {
    this.currentChosenSuites.push(null);
  }

  public OnAddHomeButtonPressed(): void
  {
    this.currentChosenHomes.push(null);
  }

  public OnBuyerInternationalStatusChanged(internationalStatus: string)
  {
    if (internationalStatus == 'resident')
    {
      this.InternationalStatus = EInternationalStatus.Resident;
    }
    else if (internationalStatus == 'international')
    {
      this.InternationalStatus = EInternationalStatus.International;
    }
  }
}

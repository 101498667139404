import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DictionaryUtilitiesService } from './dictionary-utilities.service';
import { WebCMSAPIResponse, ProjectAnalytic, EAnalyticType } from 'src/DataModels';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConstantsService } from './app-constants.service';

@Injectable({ providedIn: 'root' })
export class ProjectAnalyticsService 
{
  constructor(private http: HttpClient, private dictionaryUtilitiesService: DictionaryUtilitiesService, private appConstants: AppConstantsService) { }

  // For debug purposes only, the Unity application will be responsible for any real data pushes
  public PushClientAnalyticData(projectID: string, analyticType: EAnalyticType, dataTypeID: number, dataTypeName: string): Observable<boolean>
  {
    let params = {"projectID": projectID, "analyticType": analyticType, "dataTypeID": dataTypeID, "dataTypeName": dataTypeName}; 

    return this.http.post<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_analytics`, params).pipe(map((response: WebCMSAPIResponse) => 
    {
      if (response == null) { return false; }

      if (response.status == "success")
      {
        return true;
      }
      else if (response.status == "error")
      {
        console.log(response);
      }

      return false;
    }));
  }

  public GetAllProjectAnalyticsRecords(projectID: string): Observable<Map<number, ProjectAnalytic>>
  {
    let params = new HttpParams().set("projectID", projectID);

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_analytics`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectAnalyticsResponse(response);
    }));
  }

  public GetAllProjectAnalyticsOfType(projectID: string, analyticType: EAnalyticType)
  {
    let params = new HttpParams().set("projectID", projectID).set("analyticType", analyticType.toString());

    return this.http.get<WebCMSAPIResponse>(`${this.appConstants.baseURL}/project_analytics`, {"params": params}).pipe(map((response: WebCMSAPIResponse) => 
    {
      return this.ParseGetProjectAnalyticsResponse(response);
    }));
  }

  private ParseGetProjectAnalyticsResponse(response: WebCMSAPIResponse): Map<number, ProjectAnalytic>
  {
    if (response == null) { return null; }

    if (response.status == "success")
    {
      let analyticsDict: Map<number, ProjectAnalytic> = new Map<number, ProjectAnalytic>();
      let responseDataJSON = JSON.parse(response.data);

      for (let analyticJSON of responseDataJSON)
      {
        let analyticInfoFromPacket = JSON.parse(analyticJSON);
        let analytic: ProjectAnalytic = new ProjectAnalytic(analyticInfoFromPacket);
        analyticsDict.set(analytic.analytic_id, analytic);
      }

      return analyticsDict;
    }
    else if (response.status == "error")
    {
      console.log(response);
    }

    return null;
  }
}

import { Component, OnInit, Inject, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppUtilityService } from 'src/app/app-utility.service';
import { Project, ProjectRole, ProjectAssignment, EUserType, Company } from 'src/DataModels';
import { MatSelect } from '@angular/material/select';
import { Observable, forkJoin, of } from 'rxjs';
import { ProjectAssignmentsService } from 'src/app/project-assignments.service';
import { AuthService } from 'src/app/auth.service';
import { GenericDeleteConfirmationDialogComponent } from '../generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';

export interface ProjectAssignmentsDialogData
{
  selectedCompanyID: string,
  selectedUserID: string,
}

@Component({
  selector: 'app-project-assignments-dialog',
  templateUrl: './project-assignments-dialog.component.html',
  styleUrls: ['./project-assignments-dialog.component.css']
})
export class ProjectAssignmentsDialogComponent implements OnInit, AfterViewInit 
{
  @ViewChildren(MatSelect) roleSelectors !: QueryList<MatSelect>;

  public userProjects: Set<Project> = new Set<Project>();

  // Maps project ID to project role ID
  public initialSelectorValues: Map<string, string> = new Map<string, string>();
  public currentSelectorValues: Map<string, string> = new Map<string, string>();

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<ProjectAssignmentsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ProjectAssignmentsDialogData, public appUtilityService: AppUtilityService, private projectAssignmentsService: ProjectAssignmentsService, public authService: AuthService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit()
  {
    if (this.appUtilityService.userAssignmentsDict.has(this.data.selectedUserID))
    {
      let userAssignments: Set<ProjectAssignment> = this.appUtilityService.userAssignmentsDict.get(this.data.selectedUserID);
      for (let assignment of userAssignments.values())
      {
        this.userProjects.add(this.appUtilityService.projectsDict.get(assignment.project_id));
        this.initialSelectorValues.set(assignment.project_id, assignment.project_role_id);
      }
    }
  }

  ngAfterViewInit() 
  {
    this.currentSelectorValues = new Map<string, string>(this.initialSelectorValues);
    this.changeDetectorRef.detectChanges();
  }

  public GetModalViewTitle(): string
  {
    return "Project Assignments";
  }

  public GetCompaniesIterator(): Array<Company>
  {
    let companiesArray: Array<Company> = new Array<Company>();

    for (let company of this.appUtilityService.companiesDict.values())
    {
      if (this.appUtilityService.companyProjectsDict.has(company.company_id) && this.appUtilityService.companyProjectsDict.get(company.company_id).size > 0)
      {
        companiesArray.push(company);
      }
    }

    return companiesArray;
  }

  public GetCompanyProjectsIterator(companyID: string): Array<Project>
  {
    let companyProjectIDs = this.appUtilityService.companyProjectsDict.get(companyID);
    let companyProjectsArray: Array<Project> = new Array<Project>();

    for (let projectID of companyProjectIDs)
    {
      companyProjectsArray.push(this.appUtilityService.projectsDict.get(projectID));
    }

    return companyProjectsArray;  
  }

  public GetAllAvailableProjectsIterator(): Array<Project>
  {
    if (this.authService.activeUserRole.user_type == EUserType.neezo)
    {
      return Array.from(this.appUtilityService.projectsDict.values());
    }
    else if (this.data.selectedCompanyID != null && this.data.selectedCompanyID != "")
    {
      let companyProjectIDs = this.appUtilityService.companyProjectsDict.get(this.data.selectedCompanyID);
      let companyProjectsArray: Array<Project> = new Array<Project>();

      for (let projectID of companyProjectIDs)
      {
        companyProjectsArray.push(this.appUtilityService.projectsDict.get(projectID));
      }

      return companyProjectsArray;  
    }
    else
    {
      return null;
    }
  }

  public GetProjectCompanyName(project: Project): string
  {
    let projectOwningCompany: Company = this.appUtilityService.companiesDict.get(project.company_id);
    if (projectOwningCompany != null)
    {
      return projectOwningCompany.company_name;
    }
  }

  public GetNeezoProjectRolesIterator(): Array<ProjectRole>
  {
    let neezoProjectRoleIDs: Set<string> = null;
    let neezoRolesArray: Array<ProjectRole> = new Array<ProjectRole>();

    for (let company of this.appUtilityService.companiesDict.values())
    {
      if (company.company_name == "NEEZO Studios")
      {
        neezoProjectRoleIDs = this.appUtilityService.companyProjectRolesDict.get(company.company_id);
        break;
      }
    }

    for (let roleID of neezoProjectRoleIDs)
    {
      let role = this.appUtilityService.projectRolesDict.get(roleID);
      if (role != null)
      {
        neezoRolesArray.push(role);
      }
    }

    return neezoRolesArray;
  }

  public GetCompanyProjectRolesIterator(project: Project): Array<ProjectRole>
  {
    if (this.data.selectedUserID != null && this.data.selectedCompanyID != null)
    {
      let companyRoleIDs: Set<string> = null;
      let companyRolesArray: Array<ProjectRole> = new Array<ProjectRole>();
      
      if (project.company_id != this.data.selectedCompanyID)
      {
        // The selected user is assigned to an external project, so use the external company's roles
        companyRoleIDs = this.appUtilityService.companyProjectRolesDict.get(project.company_id); 
      }
      else
      {
        // The selected user is assigned to an internal project, so use the parent company roles
        companyRoleIDs = this.appUtilityService.companyProjectRolesDict.get(this.data.selectedCompanyID);
      }

      for (let roleID of companyRoleIDs)
      {
        companyRolesArray.push(this.appUtilityService.projectRolesDict.get(roleID));
      }

      return companyRolesArray;
    }
    else
    {
      return null;
    }
  }

  public OnDeleteAssignmentButtonClicked(project: Project)
  {
    const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Assignment?" }});
    dialogRef.afterClosed().subscribe((result) => 
    {
      if (result == true)
      {
        // If this project assignment actually exists on the backend, go through the motions of requesting its deletion from the database.
        let requestedAssignment: ProjectAssignment = null;
        if (this.appUtilityService.userAssignmentsDict.has(this.data.selectedUserID))
        {
          for (let assignment of this.appUtilityService.userAssignmentsDict.get(this.data.selectedUserID))
          {
            if (assignment.project_id == project.project_id)
            {
              requestedAssignment = assignment;
              break;
            }
          }

          if (requestedAssignment != null)
          {
            this.projectAssignmentsService.DeleteProjectAssignment(this.data.selectedUserID, project.project_id).subscribe((response: Map<string, any>) => 
            {
              if (response != null)
              {
                this.userProjects.delete(project);

                this.appUtilityService.userAssignmentsDict.get(this.data.selectedUserID).delete(requestedAssignment);
                this.appUtilityService.StoreCMSData();
              }
            });
          }
          else
          {
            // Otherwise, we're only deleting a project assignment we had started making but had yet to hit apply and push that change to the backend.
            this.userProjects.delete(project);
          }
        }
        else
        {
          this.userProjects.delete(project);
        }
      }
    });
  }

  public OnAddProjectButtonClicked(project: Project)
  {
    if (project != null)
    {
      if (this.appUtilityService.userAssignmentsDict.has(this.data.selectedUserID))
      {
        for (let assignment of this.appUtilityService.userAssignmentsDict.get(this.data.selectedUserID).values())
        {
          if (assignment.project_id == project.project_id)
          {
            return;
          }
        }
      }

      this.userProjects.add(project);
      this.initialSelectorValues.set(project.project_id, "None");
      this.currentSelectorValues.set(project.project_id, "None");
    }
  }

  public OnProjectRoleSelectionChanged(projectID: string, projectRoleID: string)
  {
    this.currentSelectorValues.set(projectID, projectRoleID);
  }

  public OnCancelButtonClicked()
  {
    this.dialogRef.close();
  }

  public OnApplyButtonClicked()
  {
    let createCallbacks: Array<Observable<any>> = new Array<Observable<any>>();
    let updateCallbacks: Array<Observable<any>> = new Array<Observable<any>>();

    for (let projectID of this.initialSelectorValues.keys())
    {
      let project: Project = this.appUtilityService.projectsDict.get(projectID);

      if (this.initialSelectorValues.get(projectID) == "None" && this.currentSelectorValues.get(projectID) != "None")
      {
        // If we're going from None to some project role ID, then we must be creating a new project assignment
        let selectedProjectRoleID = this.currentSelectorValues.get(projectID);
        let createNewAssignment = this.projectAssignmentsService.AssignUserToProject(this.data.selectedUserID, project.project_id, selectedProjectRoleID, project.company_id);
        createCallbacks.push(createNewAssignment);
      }
      else if (this.initialSelectorValues.get(projectID) != "None" && this.currentSelectorValues.get(projectID) != "None" && this.initialSelectorValues.get(projectID) != this.currentSelectorValues.get(projectID))
      {
        // If we're going from some project role ID to some other project role ID, then we must be changing the user's role (Update)
        let selectedProjectRoleID = this.currentSelectorValues.get(projectID);
        let changeProjectRole = this.projectAssignmentsService.ChangeProjectRoleForAssignment(this.data.selectedUserID, project.project_id, selectedProjectRoleID, project.company_id);
        updateCallbacks.push(changeProjectRole);
      }
    }
    
    let callbacks: Array<Observable<any>> = new Array<Observable<any>>();
    
    if (createCallbacks.length > 0) { callbacks.push(forkJoin(createCallbacks)); }
    else { callbacks.push(of(null)); }

    if (updateCallbacks.length > 0) { callbacks.push(forkJoin(updateCallbacks)); }
    else { callbacks.push(of(null)); }

    if (callbacks.length > 0)
    {
      this.dialogRef.close(callbacks);
    }
    else
    {
      this.dialogRef.close(null);
    }
  }
}

import { Component, OnInit, Host, Input } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { AppUtilityService } from 'src/app/app-utility.service';

@Component({
  selector: 'app-purchasers-form-image-field',
  templateUrl: './purchasers-form-image-field.component.html',
  styleUrls: ['./purchasers-form-image-field.component.css']
})
export class PurchasersFormImageFieldComponent implements OnInit 
{
  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _title: string = "";

  @Input()
  set Title(title: string)
  {
    this._title = title;
  }

  get Title()
  {
    return this._title;
  }

  private _hasSelectedImage: boolean = false;

  set HasSelectedImage(hasSelectedImage)
  {
    this._hasSelectedImage = hasSelectedImage;
  }

  get HasSelectedImage()
  {
    return this._hasSelectedImage;
  }

  private _selectedImageURL: string = "";

  set SelectedImageURL(selectedImageURL: string)
  {
    this._selectedImageURL = selectedImageURL;
  }

  get SelectedImageURL()
  {
    return this._selectedImageURL;
  }

  private _selectedImageBinary: string = "";

  set SelectedImageBinary(selectedImageBinary: string)
  {
    this._selectedImageBinary = selectedImageBinary;
  }

  get SelectedImageBinary()
  {
    return this._selectedImageBinary;
  }

  private _selectedImageName: string = "";

  set SelectedImageName(selectedImageName: string)
  {
    this._selectedImageName = selectedImageName;
  }

  get SelectedImageName()
  {
    return this._selectedImageName;
  }

  constructor(public appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
  }

  public OnImageChosen(imageFileInput, imageFileNameDisplay: MatInput): void
  {
    imageFileNameDisplay.value = imageFileInput.files[0].name;

    let fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(imageFileInput.files[0]);
    fileReader.onloadend = function(event)
    {
      this.SelectedImageURL = event.target.result;
      this.SelectedImageBinary = event.target.result;
      this.SelectedImageName = imageFileInput.files[0].name;
      this.HasSelectedImage = true;
    }.bind(this);
  }
}

import { Component, OnInit, Host, Input } from '@angular/core';
import { ProjectSettingsDialogComponent } from '../project-settings-dialog/project-settings-dialog.component';
import { getMatInputUnsupportedTypeError } from '@angular/material/input';
import { Deposit } from 'src/DataModels';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-worksheet-deposit-structure-tab',
  templateUrl: './worksheet-deposit-structure-tab.component.html',
  styleUrls: ['./worksheet-deposit-structure-tab.component.css']
})
export class WorksheetDepositStructureTabComponent implements OnInit 
{
  private _minDeposits: number = 2;

  @Input() set MinDeposits(minDeposits: number)
  {
    this._minDeposits = minDeposits;
  }

  get MinDeposits()
  {
    return this._minDeposits;
  }

  private _dueDateIncrement: number = 30;

  @Input() set DueDateIncrement(dueDateIncrement: number)
  {
    this._dueDateIncrement = dueDateIncrement;
  }

  get DueDateIncrement()
  {
    return this._dueDateIncrement;
  }

  public securityDepositField: FormControl = new FormControl();
  public depositPercentageField: FormControl = new FormControl();

  public deposits: Array<Deposit>;

  constructor(@Host() public parent: ProjectSettingsDialogComponent) { }

  ngOnInit() 
  {
    this.parent.saveButtonBehaviourCallback = this.OnDepositStructureSaveButtonClicked.bind(this);

    this.securityDepositField.setValue(this.parent.data.project.default_security_deposit_amount);
    this.depositPercentageField.setValue(this.parent.data.project.default_deposit_percentage);

    if (this.parent.data.project.defaultDepositStructure.length > 0)
    {
      this.deposits = Array.from(this.parent.data.project.defaultDepositStructure);
    }
    else
    {
      this.deposits = new Array<Deposit>();

      for (let i = 0; i < this.MinDeposits; ++i)
      {
        this.deposits.push(new Deposit());
        this.deposits[i].deposit_number = i + 1;
        this.deposits[i].days_until_due = this.DueDateIncrement * (this.deposits.length - 1);
      }
    }
  }

  GetDepositMinDueDate(deposit: Deposit): number
  {
    let depositIndex = deposit.deposit_number - 1;
    
    if (depositIndex - 1 >= 0)
    {
      return this.deposits[depositIndex - 1].days_until_due + 1;
    }
    else
    {
      return 0;
    }
  }

  OnDepositDueDateChanged(deposit: Deposit)
  {
    let minDueDate = this.GetDepositMinDueDate(deposit);
    if (deposit.days_until_due < minDueDate)
    {
      deposit.days_until_due = minDueDate;
    }
  }

  OnAddButtonPressed(): void
  {
    this.deposits.push(new Deposit());
    this.deposits[this.deposits.length - 1].deposit_number = this.deposits.length;
    this.deposits[this.deposits.length - 1].days_until_due = this.DueDateIncrement * (this.deposits.length - 1);
  }

  OnDeleteButtonPressed(): void
  {
    if (this.deposits.length - 1 >= this.MinDeposits)
    {
      this.deposits.pop();
    }
  }

  OnDepositStructureSaveButtonClicked(): Map<string, any>
  {
    let depositStructureInfoDict: Map<string, any> = new Map<string, any>();

    depositStructureInfoDict.set("default_security_deposit_amount", this.securityDepositField.value);
    depositStructureInfoDict.set("default_deposit_percentage", this.depositPercentageField.value);

    let depositsDict: Map<number, number> = new Map<number, number>();

    for (let deposit of this.deposits)
    {
      depositsDict.set(deposit.deposit_number, deposit.days_until_due);
    }

    if (this.deposits.length > 0)
      depositStructureInfoDict.set("deposits", depositsDict);

    return depositStructureInfoDict;
  }
}

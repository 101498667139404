import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Project, Model, Building } from 'src/DataModels';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

export interface LinkModelBuildingsDialogData
{
  selectedProject: Project;
  selectedModel: Model;
  selectedBuildings: Array<Building>;
}

@Component({
  selector: 'app-link-model-buildings-dialog',
  templateUrl: './link-model-buildings-dialog.component.html',
  styleUrls: ['./link-model-buildings-dialog.component.css']
})
export class LinkModelBuildingsDialogComponent implements OnInit 
{
  public availableBuildings: Set<Building>;
  public selectedBuildings: Array<Building>;

  constructor(private dialogRef: MatDialogRef<LinkModelBuildingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: LinkModelBuildingsDialogData) { }

  ngOnInit() 
  {
    this.selectedBuildings = new Array<Building>();

    if (this.data.selectedProject !== null && this.data.selectedProject.buildings.size > 0)
    {
      if (this.data.selectedModel !== null && this.data.selectedModel !== undefined && this.data.selectedModel.linked_building_ids.length > 0)
      {
        this.availableBuildings = new Set<Building>();

        for (let building of this.data.selectedProject.buildings.values())
        {
          if (!this.data.selectedModel.linked_building_ids.includes(building.building_id))
          {
            this.availableBuildings.add(building);
          }
          else
          {
            this.selectedBuildings.push(building);
          }
        }
      }
      else if (this.data.selectedProject !== null && this.data.selectedBuildings !== null && this.data.selectedBuildings !== undefined && this.data.selectedBuildings.length > 0)
      {
        this.selectedBuildings = this.data.selectedBuildings;

        this.availableBuildings = new Set<Building>();
        for (let building of this.data.selectedProject.buildings.values())
        {
          if (this.selectedBuildings.indexOf(building) == -1)
          {
            this.availableBuildings.add(building);
          }
        }
      }
      else
      {
        this.availableBuildings = new Set(this.data.selectedProject.buildings.values());
      }
    }
  }

  public GetSelectableBuildingsIterator(index: number): Array<Building>
  {
    let buildings = new Array<Building>();

    buildings.push(this.selectedBuildings[index]);

    for (let building of this.availableBuildings)
    {
      buildings.push(building);
    }

    return buildings;
  }

  public OnAddBuildingButtonClicked()
  {
    if (this.availableBuildings.size > 0)
    {
      let nextBuilding: Building = Array.from(this.availableBuildings.values())[0];
      this.selectedBuildings.push(nextBuilding);
      this.availableBuildings.delete(nextBuilding);
    }
  }

  public OnDeleteButtonClicked(selectedBuilding: Building, index: number)
  {
    let tempBuildings: Array<Building> = new Array<Building>();
    for (let building of this.selectedBuildings)
    {
      if (building != selectedBuilding)
      {
        tempBuildings.push(building);
      }
    }

    this.selectedBuildings = tempBuildings;

    this.availableBuildings.add(selectedBuilding);
  }

  public OnBuildingSelected($event, index: number)
  {
    if ($event.value == this.selectedBuildings[index])
      return;
    
    let selectedBuilding: Building = $event.value;
    
    this.availableBuildings.add(this.selectedBuildings[index]);
    this.selectedBuildings[index] = selectedBuilding;
    this.availableBuildings.delete(selectedBuilding);
  }

  public OnCancelButtonClicked(): void
  {
    this.dialogRef.close();
  }

  public OnSaveButtonClicked(): void
  {
    this.dialogRef.close(this.selectedBuildings);
  }
}

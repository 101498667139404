import { Component, OnInit, Host } from '@angular/core';
import { ProjectSettingsDialogComponent } from '../project-settings-dialog/project-settings-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-worksheet-settings-tab',
  templateUrl: './worksheet-settings-tab.component.html',
  styleUrls: ['./worksheet-settings-tab.component.css']
})
export class WorksheetSettingsTabComponent implements OnInit 
{
  public parkingField: FormControl = new FormControl(true);
  public lockerField: FormControl = new FormControl(true);
  public internationalStatusField: FormControl = new FormControl(true);
  
  public idTypeField: FormControl = new FormControl(true);
  public uploadPhotoIDField: FormControl = new FormControl(true);
  public uploadChequePictureField: FormControl = new FormControl(true);

  public notesSectionField: FormControl = new FormControl(true);

  public depositStructureSectionField: FormControl = new FormControl(true);

  constructor(@Host() public parent: ProjectSettingsDialogComponent) { }

  ngOnInit() 
  {
    this.parent.saveButtonBehaviourCallback = this.OnWorksheetSettingsSaveButtonClicked.bind(this);

    this.parkingField.setValue(this.parent.data.project.uses_worksheets_parking_field);
    this.lockerField.setValue(this.parent.data.project.uses_worksheets_locker_field);
    this.internationalStatusField.setValue(this.parent.data.project.uses_worksheets_international_status_field);
    this.idTypeField.setValue(this.parent.data.project.uses_worksheets_id_type_field);
    this.uploadPhotoIDField.setValue(this.parent.data.project.uses_worksheets_upload_photo_id_field);
    this.uploadChequePictureField.setValue(this.parent.data.project.uses_worksheets_upload_cheque_photo_field);
    this.notesSectionField.setValue(this.parent.data.project.uses_worksheets_notes_section_field);
    this.depositStructureSectionField.setValue(this.parent.data.project.uses_worksheets_deposit_structure_field);
  }

  OnWorksheetSettingsSaveButtonClicked(): Map<string, any>
  {
    let worksheetSettingsInfoDict: Map<string, any> = new Map<string, any>();
    
    worksheetSettingsInfoDict.set("uses_worksheets_parking_field", this.parkingField.value);
    worksheetSettingsInfoDict.set("uses_worksheets_locker_field", this.lockerField.value);
    worksheetSettingsInfoDict.set("uses_worksheets_international_status_field", this.internationalStatusField.value);
    
    worksheetSettingsInfoDict.set("uses_worksheets_id_type_field", this.idTypeField.value);
    worksheetSettingsInfoDict.set("uses_worksheets_upload_photo_id_field", this.uploadPhotoIDField.value);
    worksheetSettingsInfoDict.set("uses_worksheets_upload_cheque_photo_field", this.uploadChequePictureField.value);

    worksheetSettingsInfoDict.set("uses_worksheets_notes_section_field", this.notesSectionField.value);

    worksheetSettingsInfoDict.set("uses_worksheets_deposit_structure_field", this.depositStructureSectionField.value);

    return worksheetSettingsInfoDict;
  }
}

import { Component, OnInit, Input, ViewChild, Host, AfterViewInit, ChangeDetectorRef, ComponentRef, ComponentFactoryResolver, Injector, ApplicationRef } from '@angular/core';
import { Project, Lot, Phase, EAvailabilityCode, Linker, ProjectRole } from 'src/DataModels';
import { CMSComponentBase, CMSTableViewBase } from '../interfaces/cms-component-base';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { AppUtilityService } from '../app-utility.service';
import { ProjectsService } from '../projects.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CreateModelDialogComponent } from '../dialog/create-model-dialog/create-model-dialog.component';
import { GenericDeleteConfirmationDialogComponent } from '../dialog/generic-delete-confirmation-dialog/generic-delete-confirmation-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CreateLotDialogComponent } from '../dialog/create-lot-dialog/create-lot-dialog.component';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable, of } from 'rxjs';
import { ProjectDetailsTableViewComponent } from '../project-details-table-view/project-details-table-view.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CreateWorksheetDialogComponent } from '../dialog/worksheets/create-worksheet-dialog/create-worksheet-dialog.component';
import { LinkersTableViewComponent } from '../linkers-table-view/linkers-table-view.component';

@Component({
  providers: [ {provide: CMSTableViewBase, useExisting: LotsBaseTableViewComponent }],
  selector: 'app-lots-base-table-view',
  templateUrl: './lots-base-table-view.component.html',
  styleUrls: ['./lots-base-table-view.component.css']
})
export class LotsBaseTableViewComponent extends CMSTableViewBase implements OnInit, AfterViewInit {
  @ViewChild(MatTable, {static: false}) table: MatTable<Lot>;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;
  @ViewChild('lotsTablePaginator', {read: MatPaginator, static: false}) paginator: MatPaginator;
  @ViewChild('headerCheckbox', {read: MatCheckbox, static: false}) selectAllCheckbox: MatCheckbox;

  public selectedLotIDs: Set<number> = new Set<number>();
  public lotsDataSource: MatTableDataSource<Lot> = new MatTableDataSource<Lot>();
  
  public expandedRow: Lot = null;
  private prevExpandedRow: Lot = null;
  public innerContext = false;

  private innerColumns = ['Lot Number', 'Status'];
  private clientColumns = ['Lot Number', 'Phase ID','Grading', 'Lot Frontage', 'Lot Price Premium', 'Upgrade Price', 'Grading Price', 'Mirror', 'Status', 'Sticky Column'];
  private adminColumns = ['Checkbox', 'Lot ID', 'Lot Number', 'Phase ID', 'Grading', 'Lot Frontage', 'Lot Price Premium', 'Upgrade Price', 'Grading Price', 'X-Position', 'Y-Position', 'Z-Position', 'Rotation Angle', 'Mirror', 'Exterior Enhancement', 'Status', 'Sticky Column'];

  private innerTableRef: ComponentRef<LinkersTableViewComponent> = null;
  private innerTableRootDiv = null;

  private _project: Project = null;

  @Input()
  set project(project: Project)
  {
    this._project = project;
  }

  get project()
  {
    return this._project;
  }

  private _phase: Phase = null;
  @Input()
  set phase(phase: Phase)
  {
    this._phase = phase;
  }

  get phase()
  {
    return this._phase;
  }

  public GetDisplayedColumns()
  {
    if (this.innerContext == false) {
      return this.authService.IsActiveUserAdmin() ? this.adminColumns : this.clientColumns;
    } else {
      return this.innerColumns
    }
  }

  constructor( private dialog: MatDialog, public authService: AuthService, public appUtilityService: AppUtilityService, public projectsService: ProjectsService, private changeDetectorRef: ChangeDetectorRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private applicationRef: ApplicationRef) 
  { 
    super();
  }

  public GetAvailabilityStatusArray(): Array<string>
  {
    let keys = Object.keys(EAvailabilityCode);
    return keys.slice(keys.length / 2);
  }

  public GetAvailabilityStatusCodeName(statusCode: number): string
  {
    return EAvailabilityCode[statusCode];
  }

  public GetAssociatedHomesArray(): Set<string>
  {
    if (this.project.homes != null && this.project.homes.size > 0)
    {
      let allHomeNames: Set<string> = new Set<string>();

      for (let home of this.project.homes.values())
      {
        allHomeNames.add(home.name);
      }

      return allHomeNames;
    }
    else
    {
      return null;
    }
  }

  public GetElevationTypesArray(): Set<string>
  {
    if (this.project.elevations != null && this.project.elevations.size > 0)
    {
      let allElevationTypes: Set<string> = new Set<string>();

      for (let elevation of this.project.elevations.values())
      {
        allElevationTypes.add(elevation.elevation);
      }

      return allElevationTypes;
    }
    else
    {
      return null;
    }
  }
  public ClearLotsFilter()
  {
    this.lotsDataSource.filter = "";
  }

  public ApplyLotsTableFilter(filterDataDict)
  {
    if (this.lotsDataSource == null) { return; }

    let strFilterData: string = JSON.stringify(filterDataDict);
    this.lotsDataSource.filter = strFilterData;

    this.selectedLotIDs.clear();
    this.OnSelectAllCheckboxChanged(this.selectAllCheckbox.checked);
  }
  private ValidateLotsTableFilter(data: Lot, filter: string): boolean
  {
    let filterDataDict = JSON.parse(filter);
    let numFieldsMatched: number = 0;
    let numFieldsQueried: number = 0;

    if (filterDataDict["elevation"] != null)
    {
      ++numFieldsQueried;

      if (this.project.linkers != null && this.project.linkers.size > 0)
      {
        for (let linker of this.project.linkers.values())
        {
          if (linker.lot_id == data.lot_id && this.project.elevations.get(linker.elevation_id).elevation == filterDataDict["elevation"])
          {
            ++numFieldsMatched;
            break;
          }
        }
      }
    }

    if (filterDataDict["associatedHome"] != null)
    {
      ++numFieldsQueried;

      if (this.project.linkers != null && this.project.linkers.size > 0)
      {
        for (let linker of this.project.linkers.values())
        {
          if (linker.lot_id == data.lot_id &&  this.project.elevations.has(linker.elevation_id))
          {
            let elevation = this.project.elevations.get(linker.elevation_id);
            if (this.project.homes.has(elevation.detached_id))
            {
              let home = this.project.homes.get(elevation.detached_id);
              if (home.name === filterDataDict["associatedHome"])
              {
                ++numFieldsMatched;
                break;
              }
            }
          }
        }
      }
    }

    if (filterDataDict["availability"] != null)
    {
      ++numFieldsQueried;

      if (filterDataDict["availability"] == data.availability)
      {
        ++numFieldsMatched;
      }
    }
    if (filterDataDict["phase_id"] != null)
    {
      ++numFieldsQueried;

      if (filterDataDict["phase_id"] == data.phase_id)
      {
        ++numFieldsMatched;
      }
    }
    if (filterDataDict["mirror"] != null)
    {
      ++numFieldsQueried;

      if (filterDataDict["mirror"] == data.mirror)
      {
        ++numFieldsMatched;
      }
    }

    if (filterDataDict["exteriorEnhancement"] != null)
    {
      ++numFieldsQueried;

      if (filterDataDict["exteriorEnhancement"] == data.exterior_enhancement)
      {
        ++numFieldsMatched;
      }
    }

    return numFieldsMatched == numFieldsQueried;
  }
  ngOnInit() { }

  ngAfterViewInit()
  {
    this.lotsDataSource.sort = this.matSort;
    this.lotsDataSource.sortingDataAccessor = this.SortColumnDataAccessor.bind(this);
    this.lotsDataSource.paginator = this.paginator;
    this.lotsDataSource.filterPredicate = this.ValidateLotsTableFilter.bind(this);

    this.RenderTableRows();
    this.InitInnerTableView();
  }

  public InitInnerTableView()
  {
    this.innerTableRootDiv = document.createElement("div");

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LinkersTableViewComponent);
    this.innerTableRef = componentFactory.create(this.injector, [], this.innerTableRootDiv);
    this.innerTableRef.instance.project = this.project;

    this.applicationRef.attachView(this.innerTableRef.hostView);
  }

  public BindInnerTableView(lot: Lot)
  {
    if (this.prevExpandedRow != null)
    {
      let prevRowContainer = document.getElementById('inner-table-host-' + this.prevExpandedRow.lot_id);
      prevRowContainer.removeChild(prevRowContainer.childNodes[0]);
    }

    this.innerTableRef.instance.lot = lot;
    let expandableRowContainer = document.getElementById('inner-table-host-' + lot.lot_id);
    expandableRowContainer.appendChild(this.innerTableRootDiv);

    this.innerTableRef.instance.RenderTableRows();
  }

  public TryExpandLotsTableRow(row): void
  {
    this.expandedRow = this.expandedRow === row ? null : row;
    if (this.expandedRow != null)
    {
        this.BindInnerTableView(row);
    }
  }

  public OnSelectAllCheckboxChanged(checked: boolean)
  {
    if (checked)
    {
      for (let lot of this.lotsDataSource.filteredData)
      {
        this.selectedLotIDs.add(lot.lot_id);
      }
    }
    else
    {
      this.selectedLotIDs.clear();
    }
  }

  public OnLotRowCheckboxToggled(checked: boolean, lotID: number)
  {
    if (checked == true)
    {
      this.selectedLotIDs.add(lotID);
    }
    else
    {
      this.selectedLotIDs.delete(lotID);
    }
  }

  public RenderTableRows()
  {
    let lots: Array<Lot> = Array.from(this.project.lots.values());
    lots = lots.sort((l1, l2) => 
    {
      if (l1.lot_number < l2.lot_number)
      {
        return -1;
      }
      else if (l1.lot_number > l2.lot_number)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    });

    this.lotsDataSource.data = lots;
    if (this.table != null) {
      this.table.renderRows();
    }
      this.changeDetectorRef.detectChanges();

  }

  private SortColumnDataAccessor(row: Lot, columnHeader: string): string | number
  {
    switch (columnHeader)
    {
      case "Lot ID":
        return row.lot_id;
      case "Lot Number":
        return row.lot_number;
      case "Phase ID":
        return row.phase_id;
      case "Status":
        return row.availability;
      case "Grading":
        return row.grading;
      case "Lot Frontage":
        return row.lot_frontage;
      case "Lot Price Premium":
        return row.lot_price_premium;
      case "Upgrade Price":
        return row.upgrade_price;
      case "Grading Price":
        return row.grading_price;
      case "X-Position":
        return row.x_position;
      case "Y-Position":
        return row.y_position;
      case "Z-Position":
        return row.z_position;
      case "Rotation Angle":
        return row.rotation_angle;
      case "Mirror":
        return row.mirror ? 1 : 0;
      case "Exterior Enhancement":
        return row.exterior_enhancement ? 1 : 0;
      default:
        return null;
    }
  }

  public ShouldDisplayEditButton(): boolean
  {
    if (this.authService.IsActiveUserAdmin() && this.authService.activeUserRole.can_edit_projects_data)
    {
      return true;
    }
    else if (!this.authService.IsActiveUserAdmin())
    {
      let projectRole = this.appUtilityService.GetActiveUserProjectRole(this.project)
      if (projectRole != null)
      {
        return projectRole.can_modify_pricing || projectRole.can_hold_units || projectRole.can_finalize_sales;
      }

      return false;
    }

    return false;
  }

  public OnEditButtonPressed(lot: Lot)
  {
    const dialogRef = this.dialog.open(CreateLotDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project, lotToEdit: lot } });

    dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
    {
      this.CascadeResults(result, lot)
    });
  }

  public CascadeResults(result: Map<string, any>, lot:Lot) {
    if (result != null) {
      let updateLotCallback = this.projectsService.UpdateProjectLot(this.project.project_id, lot.lot_id, result);
      let updateLinkerCallback = of(null);

      if (this.project.linkers.size > 0) {
        let updateLinkerInfoDict = new Map<string, any>();
        updateLinkerInfoDict.set("availability", result.get("availability"));

        let linkerToChange: Linker = null;
        for (let linker of this.project.linkers.values()) {
          if (linker.lot_id == lot.lot_id) {
            linkerToChange = linker;
            updateLinkerCallback = this.projectsService.UpdateProjectLinker(this.project.project_id, linkerToChange.linker_id, updateLinkerInfoDict);
            break;
          }
        }
      }

      forkJoin([updateLotCallback, updateLinkerCallback]).subscribe(results => {
        if (results[0] != null) {
          this.project.lots.set(results[0].lot_id, results[0]);
          this.appUtilityService.StoreCMSData();
          this.RenderTableRows();
        }

        if (results[1] != null) {
          this.project.linkers.set(results[1].linker_id, results[1]);
          this.appUtilityService.StoreCMSData();
          this.RenderTableRows();
        }
      });
    }
  }
  SetAllLotsAvailability(availability: Number): void
  {
    if (this.project.lots.size == 0) { return; }

    let updateInfoDict: Map<string, any> = new Map<string, any>();
    updateInfoDict.set("availability", availability);

    let lotUpdateCallbacks: Array<Observable<Lot>> = new Array<Observable<Lot>>();
    let linkerUpdateCallbacks: Array<Observable<Linker>> = new Array<Observable<Linker>>();

    for (let lot of this.project.lots.values())
    {
      lotUpdateCallbacks.push(this.projectsService.UpdateProjectLot(this.project.project_id, lot.lot_id, updateInfoDict));

      if (this.project.linkers.size > 0)
      {
        for (let linker of this.project.linkers.values())
        {
          if (linker.lot_id === lot.lot_id)
          {
            linkerUpdateCallbacks.push(this.projectsService.UpdateProjectLinker(this.project.project_id, linker.linker_id, updateInfoDict));

            if (availability == EAvailabilityCode.Sold)
              break;
          }
        }
      }
      else
      {
        linkerUpdateCallbacks.push(of(null));
      }
    }

    forkJoin([forkJoin(...lotUpdateCallbacks), forkJoin(...linkerUpdateCallbacks)]).subscribe(results => 
    {
      if (results[0] !== null)
      {
        for (let updatedLot of results[0].values())
        {
          if (updatedLot !== null)
          {
            this.project.lots.set(updatedLot.lot_id, updatedLot);
          }
        }
      }

      if (results[1] !== null)
      {
        for (let updatedLinker of results[1].values())
        {
          if (updatedLinker !== null)
          {
            this.project.linkers.set(updatedLinker.linker_id, updatedLinker);
          }
        }
      }

      this.appUtilityService.StoreCMSData();
      this.RenderTableRows();
    });
  }

  ShouldDisplayAddButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin())
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  OnAddButtonPressed(): void 
  {
    const dialogRef = this.dialog.open(CreateLotDialogComponent, { width: '350px', height: '480px', data: { selectedProject: this.project } });

    dialogRef.afterClosed().subscribe(result => 
    {
      if (result != null)
      {
        this.projectsService.CreateNewLot(this.project.project_id, result).subscribe((newLot: Lot) => 
        {
          if (newLot != null)
          {
            this.project.lots.set(newLot.lot_id, newLot);
            this.appUtilityService.StoreCMSData();
            this.RenderTableRows();
          }
        });
      }
    });
  }

  ShouldDisplayDeleteButton(): boolean 
  {
    if (this.authService.IsActiveUserAdmin() && this.selectedLotIDs.size > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  
  OnDeleteButtonPressed(): void
  {
    if (this.selectedLotIDs.size > 0)
    {
      const dialogRef = this.dialog.open(GenericDeleteConfirmationDialogComponent, {width: '250px', height: '200px', data: { title: "Delete Lots?" }});

      dialogRef.afterClosed().subscribe((result) => 
      {
        if (result == true)
        {
          this.projectsService.DeleteLotsFromProject(this.project.project_id, Array.from(this.selectedLotIDs)).subscribe((updatedLots: Map<number, Lot>) => 
          {
            if (updatedLots != null)
            {
              this.project.lots = updatedLots;
              this.appUtilityService.StoreCMSData();
              this.selectedLotIDs.clear();
              
              this.RenderTableRows();
            }
          });
        }
      });
    }
  }

  HasSelectedRows(): boolean { return this.selectedLotIDs.size > 0; }
  GetSelectedRows(): Set<string | number> { return this.selectedLotIDs; } 
  ClearSelectedRows(): void { this.selectedLotIDs.clear(); }

  ShouldDisplayCreateWorksheetButton(lot: Lot): boolean
  {
    if (this.project.uses_worksheets && lot.availability == EAvailabilityCode.Available && this.project.linkers.size > 0)
    {
      let hasLinker: boolean = false;
      for (let linker of this.project.linkers.values())
      {
        if (linker.lot_id == lot.lot_id)
        {
          hasLinker = true;
          break;
        }
      }

      if (hasLinker && this.authService.IsActiveUserAdmin())
      {
        return this.authService.activeUserRole.can_edit_projects_data;
      }
      else if (hasLinker && !this.authService.IsActiveUserAdmin())
      {
        let activeProjectRole: ProjectRole = this.appUtilityService.GetActiveUserProjectRole(this.project);
        if (activeProjectRole !== null)
        {
          return activeProjectRole.can_create_worksheets;
        }
      }
    }

    return false;
  }

  OnCreateWorksheetButtonPressed(lot: Lot)
  {
    if (this.project.linkers.size > 0)
    {
      let firstAvailableLinker: Linker = null;

      for (let linker of this.project.linkers.values())
      {
        if (linker.lot_id == lot.lot_id && linker.availability == EAvailabilityCode.Available)
        {
          firstAvailableLinker = linker;
          break;
        }
      }

      const dialogRef = this.dialog.open(CreateWorksheetDialogComponent, { panelClass: 'worksheets-dialog', width: '80%', height: '100%', data: { selectedProject: this.project, selectedHome: firstAvailableLinker } });

      dialogRef.afterClosed().subscribe((result: Map<string, any>) => 
      {
        if (result != null)
        {
          this.projectsService.CreateNewWorksheet(this.project, result).subscribe(result => 
          {
            if (result == true)
            {
              this.projectsService.GetMetadataFor(this.project).subscribe((metadataFetchResult: true) =>
              {
                this.appUtilityService.StoreCMSData();
                //this.parent.RefreshAllTables();
              });
            }
          });
        }
      });
    }
  }
}

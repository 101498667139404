import { Component, OnInit, Host, Input, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Project } from 'src/DataModels';
import { AppUtilityService } from 'src/app/app-utility.service';
import { WorksheetPurchaserFormComponent } from '../worksheet-purchaser-form/worksheet-purchaser-form.component';

@Component({
  selector: 'app-worksheet-purchasers-module',
  templateUrl: './worksheet-purchasers-module.component.html',
  styleUrls: ['./worksheet-purchasers-module.component.css']
})
export class WorksheetPurchasersModuleComponent implements OnInit, AfterViewInit
{
  @ViewChildren(WorksheetPurchaserFormComponent) public purchaserForms: QueryList<WorksheetPurchaserFormComponent>;

  private _isInExportMode: boolean = false;

  @Input()
  set IsInExportMode(isInExportMode: boolean)
  {
    this._isInExportMode = isInExportMode;
  }

  get IsInExportMode()
  {
    return this._isInExportMode;
  }

  private _selectedProject: Project = null;
  
  @Input()
  set SelectedProject(project: Project)
  {
    this._selectedProject = project;
  }

  get SelectedProject()
  {
    return this._selectedProject;
  }
  
  constructor(public appUtilityService: AppUtilityService) { }

  ngOnInit() 
  {
  }

  ngAfterViewInit()
  {
  }
}
